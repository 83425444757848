export enum TYPE {
  TIPOLOGIA = 'TIPOLOGIA',
  NATURA = 'NATURA',
  MODALITA = 'MODALITA',
  GESTORE = 'GESTORE',
  LEDGER = 'LEDGER',
  ACCOUNT = 'ACCOUNT',
  GESTORE_PN = 'GESTORE_PN',
  DATA_ESPORTAZIONE = 'DATA_ESPORTAZIONE',
  FREQUENZA_SCHEDULAZIONE = 'FREQUENZA_SCHEDULAZIONE',
  TIPO_DESTINATARIO = 'TIPO_DESTINATARIO',
  NOME_REPORT = 'NOME_REPORT',
  IFR_TYPE = 'ifr',
  IFR_MODE = 'mod_ifr',
  AGENCY = 'AGENCIES',
}
