export class Product {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Type {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Mode {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Producer {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Agency {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class CoinsuranceAgency {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Category {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class VarCond {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class VarType {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class VarAli {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class Variation {
  id: number | null;
  variation: VarAli;
  conditions: VarCond;
  type: VarType;
  date: string | null;
  percentageValue: number;
  netValue: number;

  constructor(
    id: number | null,
    variation: VarAli,
    conditions: VarCond,
    type: VarType,
    date: string | null,
    percentageValue: number,
    netValue: number
  ) {
    this.id = id ?? null;
    this.variation = variation;
    this.conditions = conditions;
    this.type = type;
    this.date = date;
    this.percentageValue = percentageValue;
    this.netValue = netValue;
  }

  static fromForm(form: any): Variation {
    return new Variation(
      form['id'] ?? null,
      form['variation'],
      form['conditions'],
      form['type'],
      form['date'],
      form['percentageValue'],
      form['netValue']
    );
  }
}

export class RateType {
  id: number | null;
  type: Type;
  mode: Mode;
  types: Type[];
  modes: Mode[];
  percentage: number;
  variations: Variation[];

  constructor(
    id: number | null,
    type: Type,
    mode: Mode,
    types: Type[],
    modes: Mode[],
    percentage: number,
    variations: Variation[]
  ) {
    this.id = id ?? null;
    this.type = type;
    this.mode = mode;
    this.types = types;
    this.modes = modes;
    this.percentage = percentage;
    this.variations = variations;
  }

  static fromForm(form: any): RateType {
    return new RateType(
      form['id'] ?? null,
      form['type'],
      form['mode'],
      form['types'],
      form['modes'],
      form['percentage'],
      form['variations']
    );
  }
}

export class CommissionDetail {
  id: number;
  commissionTable: string;
  category: Category | undefined;
  product: Product[] | undefined;
  producer: Producer[] | undefined;
  coinsuranceAgency: CoinsuranceAgency[];
  agency: Agency;
  isBase: boolean;
  validityStartDate: string;
  validityEndDate: string;
  rateTypes: RateType[];

  constructor(
    id: number,
    commissionTable: string,
    category: Category | undefined,
    product: Product[] | undefined,
    producer: Producer[] | undefined,
    coinsuranceAgency: CoinsuranceAgency[],
    agency: Agency,
    isBase: boolean,
    validityStartDate: string,
    validityEndDate: string,
    rateTypes: RateType[]
  ) {
    this.id = id;
    this.commissionTable = commissionTable;
    this.category = category;
    this.product = product;
    this.producer = producer;
    this.coinsuranceAgency = coinsuranceAgency;
    this.agency = agency;
    this.isBase = isBase;
    this.validityStartDate = validityStartDate;
    this.validityEndDate = validityEndDate;
    this.rateTypes = rateTypes;
  }

  static fromJson(jsonData: CommissionDetail): CommissionDetail {
    return new CommissionDetail(
      jsonData.id,
      jsonData.commissionTable,
      jsonData.category,
      jsonData.product,
      jsonData.producer,
      jsonData.coinsuranceAgency,
      jsonData.agency,
      jsonData.isBase,
      jsonData.validityStartDate,
      jsonData.validityEndDate,
      jsonData.rateTypes
    );
  }
}
