<div *ngIf="isLoading" class="text-center">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div *ngIf="!isLoading">
  <div class="row">
    <div class="d-flex align-items-center my-4 p-0 go-back">
      <div (click)="goBack()">
        <i class="pi pi-arrow-left me-2 icon-list"> </i>
        <span class="label">Indietro</span>
      </div>
      <div class="hidden"></div>
      <div>
        <i
          *ngIf="this.bankRecordDetail?.consolidated"
          title="Deconsolida"
          class="pi pi-lock-open m-2 icon-list"
          (click)="
            this.bankRecordDetail?.nextBankStatementId
              ? $event.stopPropagation()
              : onDeconso()
          "
          [class.disabled]="this.bankRecordDetail?.nextBankStatementId"
        ></i>
        <a
          *ngIf="!this.bankRecordDetail?.consolidated"
          [href]="onModifyRedirect()"
          (click)="onModify($event)"
        >
          <i
            title="Modifica Bozza"
            class="pi pi-pen-to-square m-2 icon-list"
          ></i>
        </a>
        <i
          title="Stampa PDF"
          class="pi pi-print m-2 icon-list"
          (click)="onStamp()"
        ></i>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Stato:</div>
      <div class="ms-2 value">
        {{ this.getState() }}
      </div>
    </div>
    <div
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
      *ngIf="this.commissionService.isProducer"
    >
      <div class="label">Collaboratore:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.collaboratorSurname }}
        {{ this.bankRecordDetail?.collaboratorName }}
      </div>
    </div>
    <div
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
      *ngIf="!this.commissionService.isProducer"
    >
      <div class="label">Agenzia coassicuratrice:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.desCoas }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Estratto conto successivo:</div>
      <div class="ms-2 value">
        <a
          (click)="
            this.onClickLoadNewDetail(
              this.bankRecordDetail?.nextBankStatementId
            )
          "
          class="underline"
        >
          {{ this.bankRecordDetail?.nextBankStatementId }}</a
        >
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Estratto conto precedente:</div>
      <div class="ms-2 value">
        <a
          (click)="
            this.onClickLoadNewDetail(
              this.bankRecordDetail?.prevBankStatementId
            )
          "
          class="underline"
        >
          {{ this.bankRecordDetail?.prevBankStatementId }}</a
        >
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Premio lordo:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.totStatementLordo ?? 0 | customCurrency }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Premio netto:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.totStatementNetto ?? 0 | customCurrency }}
      </div>
    </div>
    <div
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
      *ngIf="this.commissionService.isProducer"
    >
      <div class="label">Provv. age.:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.totProvvAge ?? 0 | customCurrency }}
      </div>
    </div>
    <div
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
      *ngIf="this.commissionService.isProducer"
    >
      <div class="label">Tipo IFR:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.IFRType?.type?.description }}
      </div>
    </div>
    <div
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
      *ngIf="this.commissionService.isProducer"
    >
      <div class="label">Totale IFR:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.IFRType?.value ?? 0 | customCurrency }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Recupero sospesi al paga:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.totRecuperoSosp ?? 0 | customCurrency }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Totale pagato:</div>
      <div class="ms-2 value">
        {{ this.bankRecordDetail?.totGiven ?? 0 | customCurrency }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Data inserimento:</div>
      <div class="ms-2 value">
        {{
          this.bankRecordDetail?.registrationDate
            | date : "dd/MM/yyyy HH:mm" : "UTC+2"
        }}
        {{ this.bankRecordDetail?.consolidatingUser }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Data modifica:</div>
      <div class="ms-2 value">
        {{
          this.bankRecordDetail?.lastUpdateDate
            | date : "dd/MM/yyyy HH:mm" : "UTC+2"
        }}
        {{ this.bankRecordDetail?.lastUpdateUser }}
      </div>
    </div>
  </div>
  <hr class="my-4" />
  <p-table
    [tableStyle]="{ 'min-width': '50rem' }"
    [value]="recapValuesTable"
    styleClass="table-commission"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Tipo Provvigione</th>
        <th>Dare</th>
        <th>Avere</th>
      </tr>
    </ng-template>

    <ng-template let-recap pTemplate="body">
      <tr
        *ngIf="
          !(
            recap.key === 'Tot. IFR Immediato' &&
            this.bankRecordDetail?.IFRType?.type?.description === 'DIFFERITO'
          )
        "
      >
        <td>{{ recap.key }}</td>
        <td>{{ recap.value?.negativeSum ?? 0 | customCurrency }}</td>
        <td>{{ recap.value?.positiveSum ?? 0 | customCurrency }}</td>
      </tr></ng-template
    >
  </p-table>
  <hr />
  <p-accordion [activeIndex]="0" styleClass="accordion">
    <p-accordionTab header="Righe estratto conto ({{ bankRows.length }})">
      <div>
        <p-table
          [value]="bankRows"
          dataKey="rowId"
          [scrollable]="true"
          [tableStyle]="{ 'min-width': '60rem' }"
          styleClass=" table-commission text-center"
        >
          <ng-template pTemplate="header">
            <tr>
              <th
                *ngIf="commissionService.isProducer"
                pSortableColumn="codPdre"
                style="width: auto"
              >
                Codice produttore
                <p-sortIcon field="codPdre"></p-sortIcon>
              </th>
              <th
                *ngIf="!commissionService.isProducer"
                pSortableColumn="codCoas"
                style="width: auto"
              >
                Codice compagnia coassicuratrice
                <p-sortIcon field="codCoas"></p-sortIcon>
              </th>
              <th pSortableColumn="collectionDate" style="width: auto">
                Registrazione <p-sortIcon field="collectionDate"></p-sortIcon>
              </th>
              <th pSortableColumn="numPoli" style="width: auto">
                Polizza <p-sortIcon field="numPoli"></p-sortIcon>
              </th>
              <th pSortableColumn="fullName" style="width: auto">
                Cliente <p-sortIcon field="fullName"></p-sortIcon>
              </th>
              <th pSortableColumn="isCoass" style="width: auto">
                Coassicurazione
                <p-sortIcon field="isCoass"></p-sortIcon>
              </th>
              <th pSortableColumn="branch" style="width: auto">
                Ramo
                <p-sortIcon field="branch"></p-sortIcon>
              </th>
              <th pSortableColumn="reason" style="width: auto">
                Causale
                <p-sortIcon field="reason"></p-sortIcon>
              </th>
              <th pSortableColumn="gross" style="width: auto">
                Lordo
                <p-sortIcon field="gross"></p-sortIcon>
              </th>
              <th pSortableColumn="net" style="width: auto">
                Netto
                <p-sortIcon field="net"></p-sortIcon>
              </th>
              <th
                *ngIf="commissionService.isProducer"
                pSortableColumn="provvAgency"
                style="width: auto"
              >
                Provv. Age.
                <p-sortIcon field="provvAgency"></p-sortIcon>
              </th>
              <th
                *ngIf="commissionService.isProducer"
                pSortableColumn="provvCalcolata"
                style="width: auto"
              >
                Provv. Prod.
                <p-sortIcon field="provvCalcolata"></p-sortIcon>
              </th>
              <th *ngIf="!commissionService.isProducer" style="width: auto">
                Provv. Coass.
              </th>
              <th
                *ngIf="commissionService.isProducer"
                pSortableColumn="ifrImmediato"
                style="width: auto"
              >
                IFR immediato
                <p-sortIcon field="ifrImmediato"></p-sortIcon>
              </th>
              <th
                *ngIf="commissionService.isProducer"
                pSortableColumn="ifrDifferito"
                style="width: auto"
              >
                IFR differito
                <p-sortIcon field="ifrDifferito"></p-sortIcon>
              </th>
              <th pSortableColumn="totSospeso" style="width: auto">
                Sospeso
                <p-sortIcon field="totSospeso"></p-sortIcon>
              </th>
              <th pSortableColumn="manuallyUpdated" style="width: auto">
                Modifica manuale
                <p-sortIcon field="manuallyUpdated"></p-sortIcon>
              </th>
              <th pSortableColumn="manuallyCreated" style="width: auto">
                Creazione manuale
                <p-sortIcon field="manuallyCreated"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th>
                <p-columnFilter
                  *ngIf="commissionService.isProducer"
                  field="codPdre"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter
                  *ngIf="!commissionService.isProducer"
                  field="codCoas"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th>
                <p-columnFilter
                  field="formatedCollectionDate"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th>
                <p-columnFilter
                  field="numPoli"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th>
                <p-columnFilter
                  field="fullName"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th></th>
              <th>
                <p-columnFilter
                  field="branch"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th>
                <p-columnFilter
                  field="reason"
                  matchMode="contains"
                  [showMenu]="false"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <input
                      type="text"
                      pInputText
                      [ngModel]="value"
                      (ngModelChange)="filter($event)"
                      class="p-inputtext"
                    />
                  </ng-template>
                </p-columnFilter>
              </th>
              <th></th>
              <th></th>
              <th *ngIf="commissionService.isProducer"></th>
              <th *ngIf="commissionService.isProducer"></th>
              <th *ngIf="!commissionService.isProducer"></th>
              <th *ngIf="commissionService.isProducer"></th>
              <th *ngIf="commissionService.isProducer"></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-bankRecordRow let-i="rowIndex">
            <tr>
              <td class="text-center" *ngIf="commissionService.isProducer">
                {{ bankRecordRow.codPdre }}
              </td>
              <td class="text-center" *ngIf="!commissionService.isProducer">
                {{ bankRecordRow.codCoas }}
              </td>
              <td class="text-center">
                {{
                  bankRecordRow.collectionDate | date : "dd/MM/yyyy" : "UTC+2"
                }}
              </td>
              <td class="text-center">
                <a [href]="bankRecordRow?.policyLink" target="_blank">
                  {{ bankRecordRow?.numPoli }}
                </a>
              </td>
              <td class="text-center">
                {{ bankRecordRow.fullName }}
              </td>
              <td class="text-center">
                {{ bankRecordRow.isCoass ? "Si" : "No" }}
              </td>

              <td class="text-center">
                {{ bankRecordRow.branch ?? "" }}
              </td>

              <td class="text-center">
                {{ bankRecordRow.reason }}
              </td>
              <td class="text-center">
                {{ bankRecordRow.gross ?? 0 | customCurrency }}
              </td>
              <td class="text-center">
                {{ bankRecordRow.net ?? 0 | customCurrency }}
              </td>
              <td class="text-center" *ngIf="commissionService.isProducer">
                {{ bankRecordRow.provvAgency ?? 0 | customCurrency }}
              </td>
              <td class="text-center" *ngIf="commissionService.isProducer">
                {{ bankRecordRow.provvCalcolata ?? 0 | customCurrency }}
              </td>
              <td class="text-center" *ngIf="!commissionService.isProducer">
                {{ bankRecordRow.provvCalcolata ?? 0 | customCurrency }}
              </td>
              <td class="text-center" *ngIf="commissionService.isProducer">
                {{ bankRecordRow.ifrImmediato ?? 0 | customCurrency }}
              </td>
              <td class="text-center" *ngIf="commissionService.isProducer">
                {{ bankRecordRow.ifrDifferito ?? 0 | customCurrency }}
              </td>
              <td class="text-center">
                {{ bankRecordRow.totSospeso ?? 0 | customCurrency }}
              </td>
              <td>
                <div class="row text-center">
                  <div *ngIf="bankRecordRow.manuallyUpdated" class="col-md">
                    <p-image
                      src="../../../../../assets/images/touched.svg"
                      title="Modifica manuale"
                      alt="Touched SVG"
                      width="22"
                      class="ms-1"
                    ></p-image>
                  </div>
                </div>
              </td>
              <td>
                <div class="row text-center">
                  <div *ngIf="bankRecordRow.manuallyCreated" class="col-md">
                    <i
                      class="pi pi-user-edit icon-green"
                      title="Creata manualmente"
                    ></i>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table></div></p-accordionTab
  ></p-accordion>
</div>
