import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CensusCollaborator } from '../../models/census/census-collaborator';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CensusService {
  censusForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    agency: new FormControl(null),
  });
  visibilyAddModifyModal: Subject<boolean> = new Subject<boolean>();
  updateCollaborators: Subject<void> = new Subject<void>();
  censusSearchValues: CensusCollaborator[] | undefined;
  showSearchTable: boolean = false;
  constructor() {}
}
