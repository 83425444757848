<div class="commission-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="commissionTable"> Nome Tabella provvigionale</label>
        <input
          pInputText
          id="commissionTable"
          type="text"
          formControlName="commissionTable"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <div *ngIf="this.commissionFormService.isProducer">
          <label for="producer">Produttore</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown my-2"
            formControlName="producer"
            [dropdown]="true"
            showClear="true"
            [suggestions]="filteredValues"
            showEmptyMessage="false"
            (completeMethod)="filterValues($event, this.enumType.PRODUCERS)"
            placeholder="Seleziona..."
            field="description"
          />
        </div>
        <div *ngIf="!this.commissionFormService.isProducer">
          <label for="coinsuranceAgency">Agenzia Coassicurazione</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown my-2"
            formControlName="coinsuranceAgency"
            [dropdown]="true"
            showClear="true"
            [suggestions]="filteredValues"
            (completeMethod)="filterValues($event, this.enumType.COINSURANCES)"
            placeholder="Seleziona..."
            showEmptyMessage="false"
            field="description"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="product">Prodotto</label>
        <p-autoComplete
          styleClass="autocomplete-dropdown my-2"
          formControlName="product"
          [dropdown]="true"
          showClear="true"
          [suggestions]="filteredValues"
          (completeMethod)="filterValues($event, this.enumType.PRODUCTS)"
          placeholder="Seleziona..."
          showEmptyMessage="false"
          field="description"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="category">Categoria</label>
        <p-dropdown
          id="category"
          formControlName="category"
          [options]="cetegoryOptions"
          placeholder="Seleziona..."
          styleClass="commission-dropdown my-2  "
          showClear="true"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="category">Tipologia tabella</label>
        <p-dropdown
          id="tableType"
          formControlName="tableType"
          [options]="baseOptions"
          placeholder="Seleziona..."
          styleClass="commission-dropdown mt-2"
          showClear="true"
        ></p-dropdown>
      </div>
      <div
        class="col-lg-4 col-md-12 d-flex justify-content-start align-items-end p-3"
      >
        <p-checkbox
          formControlName="isActive"
          [binary]="true"
          inputId="active"
          styleClass="checkbox me-2"
        />
        <label for="active">Non attivo</label>
      </div>
      <div class="col-lg-4 col-md-12">
        <div *ngIf="this.userInfo.isDirectionalUser">
          <label for="agency">Agenzia</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown my-2"
            formControlName="agency"
            showClear="true"
            [dropdown]="true"
            [suggestions]="filteredValues"
            (completeMethod)="filterValues($event, this.enumType.AGENCIES)"
            placeholder="Seleziona..."
            showEmptyMessage="false"
            field="description"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong>CERCA</strong>
      </button>
      <button
        type="button"
        class="btn btn-primary-white mx-3 my-4"
        (click)="onReset()"
        [disabled]="this.formIsEmpty()"
      >
        <strong>RIPRISTINA </strong>
      </button>
    </div>
  </form>
</div>
