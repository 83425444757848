import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { Subscription } from 'rxjs';
import { BANK_RECORD_PATH } from '../../../enums/path';
import { BankRecordTableResponse } from '../../../models/bank-records/bank-records-table-response';
import { HTTPUtilsSSO } from '../../../network/http-sso';
import { HttpUtilsBankRecords } from '../../../network/http-utilis-bank-records';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { BankRecordsService } from '../../../services/bank-records/bank-records.service';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { getType } from '../../../utils';

@Component({
  selector: 'app-bank-record-table',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    CommonModule,
    TagModule,
    ProgressSpinnerModule,
    CustomCurrencyPipe,
  ],
  providers: [],
  templateUrl: './bank-record-table.component.html',
  styleUrl: './bank-record-table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BankRecordTableComponent implements OnInit, OnDestroy {
  missingResponse: string[] = [];
  bankRecords: BankRecordTableResponse[] = [];
  httpUtlis: HTTPUtilsSSO = new HTTPUtilsSSO(this.http);
  $subBanksResponse: Subscription | undefined;
  isLoading: boolean = true;

  constructor(
    public commissionFormService: CommissionsFormService,
    private router: Router,
    public bankRecService: BankRecordsService,
    private http: HttpClient,
    private httpUtilsCom: HttpUtilisCommissions,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService
  ) {}
  ngOnDestroy(): void {
    this.$subBanksResponse?.unsubscribe();
  }
  ngOnInit(): void {
    let form = this.bankRecService.getBankRecordForm();
    this.$subBanksResponse =
      this.bankRecService.bankRecordResponseSub.subscribe({
        next: (form) => {
          if (form) {
            this.getBankRecords(form);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
        },
      });

    this.getBankRecords(form);
  }

  onModify(id: number): void {
    this.router.navigate([BANK_RECORD_PATH.EDIT], {
      queryParams: { id: id },
    });
  }

  onDetail(id: number): void {
    this.router.navigate([BANK_RECORD_PATH.DETAIL], {
      queryParams: { id: id },
    });
  }

  embeddedIsEmpty(): boolean {
    return this.bankRecords?.length === 0;
  }

  testingCustomSort(): void {}

  getBankRecords(form: FormGroup): void {
    this.httpUtilsBank
      .getBankRecordList(
        form.get('collaborator')?.value,
        form.get('registryDate')?.value
      )
      .subscribe({
        next: (response) => {
          this.bankRecords = [];
          response['statements']?.forEach((element: any) => {
            this.bankRecords.push(BankRecordTableResponse.fromJson(element));
          });

          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
  }
}
