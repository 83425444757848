<p-dialog
  header="Partita doppia"
  [(visible)]="isVisible"
  [modal]="true"
  [style]="{ width: '75vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="goBack()"
>
  <div class="modal-body position-relative">
    <div *ngIf="isLoading" class="spinner-overlay">
      <div class="spinner-container">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>
    <div class="row">
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <p-table
          [value]="this.getDoubleEntryRow().controls"
          dataKey="id"
          [scrollable]="true"
          [tableStyle]="{ 'min-width': '60rem' }"
          styleClass="table-commission text-center"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 2rem">
                <p-button
                  type="button"
                  icon="pi pi-plus"
                  styleClass="p-button-text"
                  (click)="addRow()"
                ></p-button>
              </th>
              <th style="width: 700px">Conti</th>
              <th style="width: auto">Entrate</th>
              <th style="width: auto">Uscite</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
            <tr>
              <td style="width: 2rem">
                <i class="pi pi-trash icon" (click)="onRemove(rowIndex)"></i>
              </td>
              <td>
                <div formArrayName="doubleEntryRow">
                  <div [formGroupName]="rowIndex" style="width: 700px">
                    <p-dropdown
                      appendTo="body"
                      showClear="true"
                      filterBy="name,code"
                      formControlName="account"
                      optionLabel="displayName"
                      placeholder="Seleziona conto..."
                      styleClass="commission-dropdown"
                      [id]="'accountNumber' + rowIndex"
                      [filter]="true"
                      [options]="accountsList"
                      [panelStyle]="{ 'max-width': '700px' }"
                      (onChange)="onAccountChange($event, rowIndex)"
                      optionDisabled="isDisabled"
                    >
                      <ng-template let-item pTemplate="body">
                        <div class="dropdown-item" [title]="item.displayName">
                          {{ item.displayName }}
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </td>
              <td>
                <div formArrayName="doubleEntryRow">
                  <div [formGroupName]="rowIndex">
                    <input
                      pInputText
                      type="text"
                      id="revenueGive"
                      formControlName="revenueGive"
                      class="commission-input"
                      [readOnly]="isExpenseGetFilled(rowIndex)"
                      [ngClass]="{
                        'commission-input-disabled':
                          isExpenseGetFilled(rowIndex)
                      }"
                      (change)="onInputChange()"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div formArrayName="doubleEntryRow">
                  <div [formGroupName]="rowIndex">
                    <input
                      pInputText
                      type="text"
                      id="expenseGet"
                      formControlName="expenseGet"
                      class="commission-input"
                      [readOnly]="isRevenueGiveFilled(rowIndex)"
                      [ngClass]="{
                        'commission-input-disabled':
                          isRevenueGiveFilled(rowIndex)
                      }"
                      (change)="onInputChange()"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <hr class="my-4" />
        <div class="row d-flex justify-content-center">
          <div class="col-lg-2 col-md-12 d-flex justify-content-center">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isSaveDisabled"
            >
              <strong>SALVA</strong>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
