<div *ngIf="isLoading" class="text-center">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div *ngIf="!isLoading">
  <div class="row">
    <div class="d-flex align-items-center my-4 p-0 go-back">
      <div (click)="goBack()">
        <i class="pi pi-arrow-left me-2 icon-list"> </i>
        <span class="label">Indietro</span>
      </div>
      <div class="hidden"></div>
      <div>
        <i
          title="Salva"
          class="pi pi-save icon-list m-2"
          (click)="onSave()"
        ></i>
        <i
          title="Ricalcola"
          class="pi pi-calculator m-2 icon-list"
          (click)="onRecalculate()"
        ></i>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Data:</div>
      <div class="ms-2 value">
        {{ detailCashControl?.date | date : "dd/MM/yyyy" : "UTC+2" }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Gestore nodo:</div>
      <div class="ms-2 value">
        {{ detailCashControl?.firstNoteManager?.description }}
      </div>
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label">Quadratura di cassa:</div>
      <div class="ms-2 value">
        <i
          *ngIf="detailCashControl?.auditing"
          class="pi pi-check-circle icon-green"
        ></i>
        <i
          *ngIf="!detailCashControl?.auditing"
          class="pi pi-times-circle icon-red"
        ></i>
      </div>
    </div>
  </div>

  <hr />
  <div class="row">
    <h5 class="label mb-3">Controllo modelli contabili</h5>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.uniqueModel)"
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    >
      <div class="label col-md-5 col-xxl-4">Modello unico:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.uniqueModel! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.mm)"
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    >
      <div class="label col-md-7 col-xxl-5">MM:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.mm! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.agencyRic)"
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    >
      <div class="label col-md-5 col-xxl-4">Ric. agenziale:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.agencyRic! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.cctn)"
      class="col-lg-6 col-md-12 d-flex align-items-center"
    >
      <div class="label col-md-7 col-xxl-5">CCTN:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.cctn! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.svp)"
      class="col-lg-6 col-md-12 d-flex align-items-center"
    >
      <div class="label col-md-7 col-xxl-5">SVP:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.svp! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.sos)"
      class="col-lg-6 col-md-12 d-flex align-items-center"
    >
      <div class="label col-md-7 col-xxl-5">SOS:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.sos! | customCurrency }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.dailyClosingMod6)"
      class="col-lg-6 col-md-12 d-flex align-items-center"
    >
      <div class="label col-md-7 col-xxl-5">006:</div>
      <div class="ms-2 value col-3">
        {{
          detailCashControl?.accountingModel?.dailyClosingMod6! | customCurrency
        }}
      </div>
    </div>
    <div
      *ngIf="this.exist(detailCashControl?.accountingModel?.dailyClosingMod20)"
      class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    >
      <div class="label col-md-7 col-xxl-5">020:</div>
      <div class="ms-2 value col-3">
        {{
          detailCashControl?.accountingModel?.dailyClosingMod20!
            | customCurrency
        }}
      </div>
    </div>
    <div class="col-12">
      <hr />
    </div>
    <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
      <div class="label col-md-7 col-xxl-5">Totale MODELLI:</div>
      <div class="ms-2 value col-3">
        {{ detailCashControl?.accountingModel?.total ?? 0 | customCurrency }}
      </div>
    </div>
  </div>
  <hr />
  <form [formGroup]="this.form">
    <div [formArrayName]="'accounts'">
      <div
        *ngFor="
          let details of detailCashControl?.accountsCashControl;
          let i = index
        "
        [formGroupName]="i"
      >
        <div class="mb-3">
          <p-panel
            [header]="details.account.description"
            [toggleable]="true"
            [expandIcon]="'pi pi-angle-up'"
            [collapseIcon]="'pi pi-angle-down'"
            iconPos="start"
            class="detail-cash-control-screen"
          >
            <div class="col-12 d-flex align-items-center mb-3">
              <div class="col-md-3"></div>
              <div class="col-md-3 label">Movimentazioni</div>
              <div class="col-md-3 label">Valore Reale</div>
              <div class="col-md-3"></div>
            </div>

            <div class="col-12 d-flex align-items-center mb-3">
              <div class="col-md-3 label">
                Riporto
                {{ details.account.description }}
              </div>
              <div class="col-md-3">
                {{ details.carryover | customCurrency }}
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3"></div>
            </div>

            <hr class="hr-heigth" />

            <div
              *ngFor="let subAccount of details.subAccountValues; let j = index"
              [formArrayName]="'realValues'"
            >
              <div class="col-12 d-flex align-items-center mb-3">
                <div class="col-md-3 label">
                  {{ subAccount?.mode?.description }}
                </div>
                <div class="col-md-3 d-flex align-items-center">
                  <a
                    [href]="onNavigateToFirstNote(subAccount, i)"
                    target="_blank"
                  >
                    <div class="clickable">
                      {{ subAccount?.movements ?? 0 | customCurrency }}
                    </div>
                  </a>
                  <i
                    class="ms-2 pi pi-info-circle"
                    [pTooltip]="
                      'Riporto: ' +
                      (subAccount?.tooltip?.carryover ?? 0 | customCurrency) +
                      '\n Movimentazioni: ' +
                      (subAccount?.tooltip?.movements ?? 0 | customCurrency)
                    "
                    tooltipPosition="top"
                    autoHide="false"
                  >
                  </i>
                </div>
                <div class="col-md-3">
                  <input
                    pInputText
                    id="realValue"
                    type="number"
                    [formControlName]="j"
                    min="0"
                    placeholder="Inserisci"
                    class="commission-input my-2"
                  />
                </div>
                <div class="col-md-3">
                  <i
                    *ngIf="showStatus(i, j)"
                    class="pi pi-check-circle icon-green"
                  ></i>
                  <i
                    *ngIf="!showStatus(i, j)"
                    class="pi pi-times-circle icon-red"
                  ></i>
                </div>
              </div>
            </div>
            <hr class="hr-heigth" />

            <div class="col-12 d-flex align-items-center mb-3">
              <div class="col-md-3 label">
                Totale
                {{ details.account.description }}
              </div>
              <div class="col-md-3">
                {{ details.total.movements | customCurrency }}
              </div>
              <div class="col-md-3">
                {{ this.getTotalRealValue(i) | customCurrency }}
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </div>
  </form>
</div>
