<div class="modal" tabindex="-1" id="modalDetailAccount">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title label">Dettaglio conto</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="goBack()"
        ></button>
      </div>
      <div class="modal-body position-relative">
        <div *ngIf="isLoading" class="spinner-overlay">
          <div class="spinner-container">
            <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
            <div class="label">Nome:</div>
            <div class="ms-2 value">
              {{ this.account?.name }}
            </div>
          </div>
          <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
            <div class="label">Codice:</div>
            <div class="ms-2 value">
              {{ this.account?.code }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="label">Tipologia:</div>
            <div class="ms-2 value">
              {{ this.account?.type?.description }}
            </div>
          </div>
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="label">Importo:</div>
            <div class="ms-2 value">
              {{ this.account?.value }}
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="row d-flex align-items-center" *ngIf="isEditable">
          <div class="col-lg-3 col-md-12">
            <button
              type="button"
              class="btn btn-primary w-100"
              (click)="showSubAccountListDropdown()"
            >
              <strong>COLLEGA SOTTOCONTO</strong>
            </button>
          </div>
          <div
            class="col-lg-7 col-md-12"
            *ngIf="isSubAccountListDropdownVisible"
          >
            <form [formGroup]="form" class="w-100">
              <p-dropdown
                id="subAccountId"
                formControlName="subAccount"
                [options]="subAccountsList"
                optionLabel="displayName"
                placeholder="Seleziona sottoconto..."
                styleClass="commission-dropdown"
                showClear="true"
                [filter]="true"
                filterBy="name,code"
              ></p-dropdown>
            </form>
          </div>

          <!-- Pulsante "AGGIUNGI" che prende 2 colonne -->
          <div
            class="col-lg-2 col-md-12"
            *ngIf="isSubAccountListDropdownVisible"
          >
            <button
              type="button"
              class="btn btn-primary w-100"
              [disabled]="!this.form.get('subAccount')?.value"
              (click)="addSubAccount()"
            >
              <strong>AGGIUNGI</strong>
            </button>
          </div>
        </div>

        <div class="row my-4">
          <p-table
            [value]="linkedSubAccounts ?? []"
            dataKey="id"
            [scrollable]="true"
            [tableStyle]="{ 'min-width': '60rem' }"
            styleClass=" table-commission text-center"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name" style="width: auto">
                  Nome
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="code" style="width: auto">
                  Codice <p-sortIcon field="code"></p-sortIcon>
                </th>
                <th pSortableColumn="type" style="width: auto">
                  Modalità <p-sortIcon field="type.description"></p-sortIcon>
                </th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p-columnFilter
                    type="text"
                    field="name"
                    matchMode="contains"
                  />
                </th>
                <th>
                  <p-columnFilter
                    type="text"
                    field="code"
                    matchMode="contains"
                  />
                </th>
                <th>
                  <p-columnFilter
                    type="text"
                    field="type.description"
                    matchMode="contains"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-linkedSubAccount let-i="rowIndex">
              <tr>
                <td class="text-center">
                  {{ linkedSubAccount.name }}
                </td>
                <td class="text-center">
                  {{ linkedSubAccount.code }}
                </td>
                <td class="text-center">
                  {{ linkedSubAccount.type.description }}
                </td>
                <td *ngIf="linkedSubAccount.isNew">
                  <i
                    title="Elimina conto"
                    class="pi pi-trash icon col-md-6"
                    title="Elimina"
                    (click)="removeSubAccount(linkedSubAccount)"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="row d-flex justify-content-center" *ngIf="isEditable">
          <div class="col-lg-2 col-md-12 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary"
              (click)="saveAddedSubAccounts()"
              [disabled]="checkNewSubAccountsAdded()"
            >
              <strong>SALVA</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Attenzione"
  [modal]="true"
  [(visible)]="showDialog"
  [position]="'top'"
  class="custom-dialog"
>
  <div class="flex align-items-center gap-3 mb-5">
    Hai delle modifiche non salvate. Se prosegui ora, tutte le modifiche
    apportate andranno perse!
  </div>
  <div class="button-container">
    <p-button
      label="ANNULLA"
      styleClass="btn btn-secondary"
      (onClick)="hideDialog()"
    />
    <p-button
      label="PROSEGUI"
      styleClass="btn btn-primary"
      (onClick)="closeModal()"
    />
  </div>
</p-dialog>
