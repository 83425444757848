import { from } from 'rxjs';
import { Agency, Type } from '../commission-detail';
import { FormGroup } from '@angular/forms';

export class CensusProducer {
  id?: number;
  code: string;
  active: boolean;

  constructor(code: string, active: boolean, id?: number) {
    this.code = code;
    this.active = active;
    this.id = id;
  }
}
export class CensusCollaborator {
  idColl: number;
  name: string;
  surname: string;
  city: string;
  active: boolean;
  address: string;
  zipCode: string;
  province: string;
  vatNumber: string;
  fc: string;
  ccs: boolean;
  ifr: Type;
  modCalcIfr?: Type;
  percIfr?: number;
  accIfr?: number;
  percRitFisc: number;
  productorCodes: CensusProducer[];
  agency?: Agency;
  displayProducerCodes?: string;

  constructor(
    idColl: number,
    name: string,
    surname: string,
    city: string,
    active: boolean,
    address: string,
    zipCode: string,
    province: string,
    vatNumber: string,
    fc: string,
    ccs: boolean,
    ifr: Type,
    percRitFisc: number,
    productorCodes: CensusProducer[],
    agency?: Agency,
    modCalcIfr?: Type,
    percIfr?: number,
    accIfr?: number,
    displayProducerCodes?: string
  ) {
    this.idColl = idColl;
    this.name = name;
    this.surname = surname;
    this.city = city;
    this.active = active;
    this.address = address;
    this.zipCode = zipCode;
    this.province = province;
    this.vatNumber = vatNumber;
    this.fc = fc;
    this.ccs = ccs;
    this.ifr = ifr;
    this.modCalcIfr = modCalcIfr;
    this.percIfr = percIfr;
    this.accIfr = accIfr;
    this.percRitFisc = percRitFisc;
    this.productorCodes = productorCodes;
    this.agency = agency;
    this.displayProducerCodes = displayProducerCodes;
  }
  static fromJson(json: CensusCollaborator): CensusCollaborator {
    const producerCodes: string[] = json.productorCodes.map((producerCode) => {
      return producerCode.code;
    });

    return new CensusCollaborator(
      json.idColl,
      json.name,
      json.surname,
      json.city,
      json.active,
      json.address,
      json.zipCode,
      json.province,
      json.vatNumber,
      json.fc,
      json.ccs,
      json.ifr,
      json.percRitFisc,
      json.productorCodes,
      json.agency,
      json.modCalcIfr,
      json.percIfr,
      json.accIfr,
      producerCodes.join(', ')
    );
  }

  static fromJsonArray(json: CensusCollaborator[]): CensusCollaborator[] {
    return json.map((censusCollaborator) => {
      return this.fromJson(censusCollaborator);
    });
  }

  static fromForm(form: FormGroup): CensusCollaborator {
    {
      return new CensusCollaborator(
        form.get('idColl')?.value,
        form.get('name')?.value,
        form.get('surname')?.value,
        form.get('city')?.value,
        form.get('active')?.value,
        form.get('address')?.value,
        form.get('zipCode')?.value,
        form.get('province')?.value,
        form.get('vatNumber')?.value,
        form.get('fc')?.value,
        form.get('ccs')?.value,
        form.get('ifr')?.value,
        form.get('percRitFisc')?.value,
        form.get('productorCodes')?.value,
        form.get('agency')?.value,
        form.get('modCalcIfr')?.value,
        form.get('percIfr')?.value,
        form.get('accIfr')?.value
      );
    }
  }
}
