import { FormGroup } from '@angular/forms';
import { CommissionType } from '../commission/commission-data';
import { StatementType } from './request/bank-records-row-add-request';

export class BankRecRowDetailResp {
  rowId: number;
  collectionDate: string;
  insertDate: string;
  lastUpdateDate: string;
  lastUpdateUsr: string;
  collaboratorName: string;
  collaboratorSurname: string;
  codPdre: string;
  codCoas: string;
  desCoas: string;
  statementType: StatementType;
  statementDes: string;
  gross: number;
  net: number;
  provvAgency: number;
  provvCalcolata: number;
  totSospeso: number;
  recuperoSosp: number;
  calculated: boolean;
  manuallyUpdated: boolean;
  manuallyCreated: boolean;
  ifr?: TaxInfo;
  witholdingTax?: TaxInfo;
  numPoli: string;
  branchPoli: string;
  desClie: string;
  reason: string;
  details: CommissionDetail[];
  clienteName: string;
  clienteSurname?: string;

  constructor(data: any) {
    this.rowId = data.rowId;
    this.collectionDate = data.collectionDate;
    this.insertDate = data.insertDate;
    this.lastUpdateDate = data.lastUpdateDate;
    this.lastUpdateUsr = data.lastUpdateUsr;
    this.collaboratorName = data.collaboratorName;
    this.collaboratorSurname = data.collaboratorSurname;
    this.codPdre = data.codPdre;
    this.codCoas = data.codCoas;
    this.desCoas = data.desCoas;
    this.statementType = data.statementType;
    this.statementDes = data.statementDes;
    this.gross = data.gross;
    this.net = data.net;
    this.provvAgency = data.provvAgency;
    this.provvCalcolata = data.provvCalcolata;
    this.totSospeso = data.totSospeso;
    this.recuperoSosp = data.recuperoSosp;
    this.calculated = data.calculated;
    this.manuallyUpdated = data.manuallyUpdated;
    this.manuallyCreated = data.manuallyCreated;
    this.ifr = data.ifr ? new TaxInfo(data.ifr) : undefined;
    this.witholdingTax = data.witholdingTax
      ? new TaxInfo(data.witholdingTax)
      : undefined;
    this.numPoli = data.numPoli;
    this.branchPoli = data.branchPoli;
    this.desClie = data.desClie;
    this.reason = data.reason;
    this.clienteName = data.clienteName;
    this.clienteSurname = data.clienteSurname;
    this.details = data.details.map(
      (detail: any) => new CommissionDetail(detail)
    );
  }

  static fromEditForm(
    form: FormGroup,
    response: BankRecRowDetailResp
  ): BankRecRowDetailResp {
    response.collectionDate = form.get('collectionDate')?.value;
    response.ifr =
      form.get('ifr')?.value !== null
        ? new TaxInfo({
            type: new TaxType({
              code: form.get('ifr')?.value.code,
              description: form.get('ifr')?.value.description,
            }),
            value: form.get('ifrValue')?.value,
          })
        : undefined;
    response.witholdingTax =
      form.get('witholdingTax')?.value !== null
        ? new TaxInfo({
            type: new TaxType({
              code: form.get('witholdingTax')?.value.code,
              description: form.get('witholdingTax')?.value.description,
            }),
            value: form.get('witholdingTaxValue')?.value,
          })
        : undefined;

    response.statementType = form.get('statementType')?.value;
    response.statementDes = form.get('statementDes')?.value;
    response.provvCalcolata = form.get('provvCalcolata')?.value;

    return response;
  }
}
export class TaxInfo {
  type: TaxType;
  value: number;

  constructor(data: any) {
    this.type = new TaxType(data.type);
    this.value = data.value;
  }
}

export class TaxType {
  code: string;
  description: string;

  constructor(data: any) {
    this.code = data.code;
    this.description = data.description;
  }
}

export class CommissionMode {
  code: string;
  description: string;

  constructor(data: any) {
    this.code = data.code;
    this.description = data.description;
  }
}

export class CommissionDetail {
  commissionType: CommissionType;
  commissionMode: CommissionMode;
  commissionPerc: number;
  commissionNet: number;
  rowValue: number;

  constructor(data: any) {
    this.commissionType = data.commissionType;
    this.commissionMode = data.commissionMode;
    this.commissionPerc = data.commissionPerc;
    this.commissionNet = data.commissionNet;
    this.rowValue = data.rowValue;
  }
}
