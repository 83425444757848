<div
  class="row"
  [ngClass]="{
    'hide-table': !this.showTable
  }"
>
  <div class="row">
    <div class="col-6">
      <p-button
        type="button"
        icon="pi pi-plus"
        class="mx-2"
        styleClass="p-button-text"
        label="Crea"
        (click)="onCreate()"
      ></p-button>
      <p-button
        type="button"
        icon="pi pi-file-export"
        class="mx-2"
        styleClass="p-button-text"
        label="Scarica"
        (click)="onDownload()"
        [disabled]="!this.selectedValue"
      ></p-button>
    </div>
  </div>
  <div *ngIf="isLoading" class="spinner-overlay">
    <div class="spinner-overlay">
      <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
    </div>
  </div>
  <p-table
    *ngIf="!this.isLoading"
    #table
    [value]="this.tableValues ?? []"
    dataKey="id"
    [scrollable]="true"
    [paginator]="true"
    [(selection)]="selectedValue"
    selectionMode="single"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass="table-commission text-center"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th></th>
        <th pSortableColumn="reportName.description" style="width: auto">
          Nome report
          <p-sortIcon field="reportName.description"></p-sortIcon>
        </th>
        <th pSortableColumn="displayInterval" style="width: auto">
          Periodo <p-sortIcon field="displayInterval"></p-sortIcon>
        </th>
        <th pSortableColumn="exportingDate.description" style="width: auto">
          Data di Esportazione
          <p-sortIcon field="exportingDate.description"></p-sortIcon>
        </th>
        <th pSortableColumn="frequency.description" style="width: auto">
          Frequenza <p-sortIcon field="frequency.description"></p-sortIcon>
        </th>
        <th pSortableColumn="receivers" style="width: auto">
          Destinatari
          <p-sortIcon field="receivers"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agency.description"
          style="width: auto"
        >
          Agenzia
          <p-sortIcon field="agency.description"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter
            field="reportName.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <!-- <p-columnFilter
            type="text"
            field="displayInterval"
            matchMode="contains"
          /> -->
          <p-columnFilter
            field="displayInterval"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="exportingDate.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="frequency.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="receivers.length"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            *ngIf="this.userInfo?.isDirectionalUser"
            field="agency.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-report let-i="rowIndex">
      <tr>
        <td>
          <p-tableRadioButton [value]="report" />
        </td>
        <td>
          <i
            class="pi pi-pen-to-square icon col-md-6"
            title="Modifica"
            (click)="onModify(report)"
          ></i>
        </td>
        <td class="text-center">
          {{ report.reportName.description }}
        </td>
        <td class="text-center">
          {{ report.displayInterval }}
        </td>
        <td class="text-center">
          {{ report.exportingDate.description }}
        </td>
        <td class="text-center">
          {{ report.frequency.description ?? "" }}
        </td>
        <td class="text-center">
          {{ report.receivers.length ?? 0 }}
        </td>
        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ report.agency.description ?? "" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-add-modify-report-modal
  [report]="this.modifingReport"
></app-add-modify-report-modal>
