import {
  BANK_RECORD_PATH,
  CASH_CONTROL_PATH,
  COMMISSION_PATH,
  FIRST_NOTE_PATH,
  PLAN_ACCOUNT_PATH,
  REPORTING_PATH,
} from '../enums/path';
import { VISIBILITY as ENUM_VISIBILITY } from '../enums/visibility';

export const VISIBILITY_MAP: Map<string, string> = new Map<string, string>([
  [COMMISSION_PATH.EDIT, ENUM_VISIBILITY.PROVVIGIONI],
  [COMMISSION_PATH.DETAIL, ENUM_VISIBILITY.PROVVIGIONI],
  [COMMISSION_PATH.ADD, ENUM_VISIBILITY.PROVVIGIONI],
  [COMMISSION_PATH.SEARCH, ENUM_VISIBILITY.PROVVIGIONI],
  [BANK_RECORD_PATH.EDIT, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.DETAIL, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.ADD, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.SEARCH, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.ADD_ROW, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.EDIT_ROW, ENUM_VISIBILITY.ESTRATTICONTO],
  [BANK_RECORD_PATH.DETAIL_ROW, ENUM_VISIBILITY.ESTRATTICONTO],
  [
    PLAN_ACCOUNT_PATH.MASTERS,
    ENUM_VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_VISUALIZZA,
  ],
  [
    PLAN_ACCOUNT_PATH.ACCOUNTS,
    ENUM_VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT,
  ],
  [
    PLAN_ACCOUNT_PATH.ACCOUNTS,
    ENUM_VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZAPARZ,
  ],
  [
    PLAN_ACCOUNT_PATH.SUB_ACCOUNTS,
    ENUM_VISIBILITY.PRIMANOTA_PIANODEICONTI_SOTTOCONTI,
  ],
  [FIRST_NOTE_PATH.NODE, ENUM_VISIBILITY.PRIMANOTA_PRIMANOTA],
  [CASH_CONTROL_PATH.SEARCH, ENUM_VISIBILITY.CONTROLLO_CASSA],
  [CASH_CONTROL_PATH.DETAIL, ENUM_VISIBILITY.CONTROLLO_CASSA],
  [CASH_CONTROL_PATH.EDIT, ENUM_VISIBILITY.CONTROLLO_CASSA],
  [REPORTING_PATH.TABLE, ENUM_VISIBILITY.REPORTISTICA],
]);
