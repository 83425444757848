export enum RowMode {
  MODE01 = 'Contributo produttivo',
  MODE02 = 'Premio produzione',
  MODE03 = 'Anticipo provvigionale',
  MODE04 = 'Anticipo IFR Differito',
  MODE05 = 'Saldo IFR Differito',
  MODE06 = 'Provvigioni extra capitolati',
  MODE07 = 'Storno credito E / C collaboratore',
  MODE08 = 'Altro',
}

export enum ValueModes {
  AUTOMATICO = 'Automatico',
  MANUALE = 'Manuale',
}

export enum ConDecons {
  consolidate = 'CONSOLIDATE',
  deconsolidate = 'DECONSOLIDATE',
}
