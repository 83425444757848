import { Component } from '@angular/core';
import { AddFormComponent } from '../../../components/forms/commission/add-commission-form/add-form.component';

@Component({
  selector: 'app-add-screen',
  standalone: true,
  templateUrl: './add-screen.component.html',
  styleUrl: './add-screen.component.scss',
  imports: [AddFormComponent],
})
export class AddScreenComponent {}
