import { Injectable } from '@angular/core';
import { BankRecDetailResp } from '../../models/bank-records/bank-records-detail-response';
import { BankRecordTableResponse } from '../../models/bank-records/bank-records-table-response';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class BankRecordsService {
  bankRecordDetail: BankRecDetailResp | undefined;
  bankRecordResponse: BankRecordTableResponse[] = [];
  bankRecordResponseSub: Subject<FormGroup | undefined> = new Subject();
  showInfoTableBankRecord: boolean = false;
  showRowsTable: boolean = false;
  bankRecordForm: FormGroup = new FormGroup({
    collaborator: new FormControl(null, []),
    registryDate: new FormControl<Date[]>([], [Validators.required]),
  });
  bankRecordRowForm: FormGroup = new FormGroup({
    collaborator: new FormControl(null, [Validators.required]),
    titleCollectionDate: new FormControl<Date[]>([], [Validators.required]),
  });
  constructor() {
    this.setDefaultDateBankRecForm();
  }

  setDefaultDateBankRecForm(): void {
    let today: Date = new Date();

    let threeMonthsAgo: Date = new Date();
    threeMonthsAgo.setDate(today.getDate() - 90);

    this.bankRecordForm.get('registryDate')?.setValue([threeMonthsAgo, today]);
    this.bankRecordRowForm
      .get('titleCollectionDate')
      ?.setValue([threeMonthsAgo, today]);
  }

  getBankRecordForm(): FormGroup {
    return this.bankRecordForm;
  }

  resetSavedData() {
    this.bankRecordForm.reset();
    this.setDefaultDateBankRecForm();
  }
}
