import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { UserInfo } from '../../../models/user/user-info';
import { Report } from '../../../models/reporting/reports';
import { ReportingService } from '../../../services/reporting/reporting-service';
import { AddModifyReportModalComponent } from '../../../components/modals/reporting/add-modify-report-modal/add-modify-report-modal.component';
import { Subscription } from 'rxjs';
import { HttpUtilisReporting } from '../../../network/http-reporting';
import { ModalService } from '../../../services/modal/modal.service';
import { MessageService } from 'primeng/api';
import { BankRecordUtilis } from '../../../utils';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-table-screen',
  standalone: true,
  imports: [
    DropdownModule,
    CommonModule,
    TableModule,
    ProgressSpinnerModule,
    ButtonModule,
    AddModifyReportModalComponent,
    FormsModule,
  ],
  templateUrl: './table-screen.component.html',
  styleUrl: './table-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TableScreenComponent implements OnInit, OnDestroy {
  tableValues: Report[] | undefined;
  showTable: boolean = true;
  selectedValue: Report | undefined;
  isLoading: boolean = false;
  userInfo: UserInfo | undefined;
  modifingReport: Report | undefined;
  tableReportsSub$: Subscription | undefined;

  constructor(
    public reportService: ReportingService,
    public httpUtilisReporting: HttpUtilisReporting,
    public modalService: ModalService,
    public messageService: MessageService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }

    this.tableReportsSub$ = this.reportService.tableReportsSub.subscribe(() => {
      this.isLoading = true;
      this.httpUtilisReporting.getReports().subscribe({
        next: (response) => {
          this.tableValues = Report.fromData(response['reports']);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
    });
  }
  ngOnDestroy(): void {
    this.tableReportsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    this.initStates();
  }

  initStates() {
    this.httpUtilisReporting.getReports().subscribe({
      next: (response) => {
        this.tableValues = Report.fromData(response['reports']);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.modalService.showError(error);
      },
    });
  }

  onCreate() {
    this.modifingReport = undefined;
    this.reportService.modifyAddModalVisibilitySub.next(true);
  }

  onDownload() {
    if (
      this.selectedValue?.id === undefined ||
      this.selectedValue?.id === null
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Errore',
        key: 'br',
        detail: 'Impossibile esportare un report senza selezionarlo',
      });
      return;
    }

    this.httpUtilisReporting.exportFile(this.selectedValue?.id).subscribe({
      next: (response) => {
        BankRecordUtilis.downloadExcel(response['base64']);
      },
      error: (_error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Errore',
          key: 'br',
          detail: 'Impossibile esportare il report',
        });
      },
    });
  }

  onModify(report: Report) {
    this.modifingReport = report;
    this.reportService.modifyAddModalVisibilitySub.next(true);
  }
}
