import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { debugForm, getDirty } from '../../../../utils';
import { Agency, Type } from '../../../../models/commission-detail';
import { forkJoin, Subscription } from 'rxjs';
import { CensusService } from '../../../../services/census/census.service';
import { CensusCollaborator } from '../../../../models/census/census-collaborator';
import { InputSwitchModule } from 'primeng/inputswitch';
import { IFR } from '../../../../enums/census';
import { TYPE } from '../../../../enums/path-of-accounts';
import { HttpUtilisCommissions } from '../../../../network/http-utilis-commissions';
import { HttpUtilisCensus } from '../../../../network/http-census';
import { MessageService } from 'primeng/api';
import { UserInfo } from '../../../../models/user/user-info';
import { ModalService } from '../../../../services/modal/modal.service';
import { ModalInfos } from '../../../../models/modal/modal-infos';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-modify-census-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    InputTextModule,
    InputSwitchModule,
  ],
  templateUrl: './add-modify-census-modal.component.html',
  styleUrl: './add-modify-census-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddModifyCensusModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() collaborator: CensusCollaborator | undefined;
  modalTitle: string = 'Crea Censimento';
  isLoading: boolean = false;
  form: FormGroup = new FormGroup({});
  ifrTypesOptions: Type[] | undefined;
  ifrModesOptions: Type[] | undefined;
  isModifing: boolean = false;
  visible: boolean = false;
  visibiltyModalSub$: Subscription | undefined;
  userInfos: UserInfo | undefined;
  filteredValues: Agency[] = [];

  constructor(
    public censusService: CensusService,
    private httpServiceCommission: HttpUtilisCommissions,
    private httpUtilisSCensus: HttpUtilisCensus,
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilsCom: HttpUtilisCommissions
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfos = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );

      this.form = new FormGroup({
        idColl: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        surname: new FormControl(null, []),
        address: new FormControl(null, []),
        zipCode: new FormControl(null),
        city: new FormControl(null),
        province: new FormControl(null),
        vatNumber: new FormControl(null),
        fc: new FormControl(null),
        active: new FormControl(true, [Validators.required]),
        ccs: new FormControl(false),
        ifr: new FormControl(null, [Validators.required]),
        modCalcIfr: new FormControl(null, [Validators.required]),
        percIfr: new FormControl(null, [Validators.required]),
        accIfr: new FormControl(0, [Validators.required]),
        percRitFisc: new FormControl(0, [Validators.required]),
        productorCodes: new FormArray([]),
        agency: new FormControl(null, [Validators.required]),
      });
    } else {
      this.modalService.showModal(
        new ModalInfos('Errore', 'Informazioni utente mancanti', () => {
          window.open('/', '_self');
        })
      );
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['collaborator'].currentValue === undefined) {
      this.resetForm();
    } else {
      this.populateForm();
    }
  }
  ngOnInit(): void {
    this.visibiltyModalSub$ =
      this.censusService.visibilyAddModifyModal.subscribe({
        next: (visible) => {
          this.visible = visible;
        },
      });
    this.initState();
  }
  ngOnDestroy(): void {
    this.visibiltyModalSub$?.unsubscribe();
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();

      return;
    }
    this.isLoading = true;
    this.httpUtilisSCensus
      .putCollaborator(CensusCollaborator.fromForm(this.form))
      .subscribe({
        next: () => {
          this.isLoading = false;

          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: `Collaboratore ${
              this.isModifing ? 'modificato ' : 'creato '
            }con successo`,
          });
          if (!this.isModifing) {
            this.resetForm();
          }
          this.censusService.updateCollaborators.next();
          this.visible = false;
        },
        error: (_error) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            key: 'br',
            detail: `Impossibile ${
              this.isModifing ? 'modificare ' : 'creare '
            }un collaboratore`,
          });
        },
      });
  }

  onDialogHide() {
    if (!this.isModifing) {
      return;
    }

    this.populateForm();
  }

  public onIfrChange(event: DropdownChangeEvent): void {
    let code: string | undefined = event.value.code ?? null;

    if (code === null) {
      return;
    }

    switch (code!) {
      case IFR.DIFFERITO:
        this.enableAllIfrOptions();
        break;
      case IFR.IMMEDIATO:
        this.form.get('modCalcIfr')?.enable();
        this.form.get('percIfr')?.enable();
        this.form.get('accIfr')?.disable();
        this.form.get('accIfr')?.setValue(null);
        break;
      case IFR.NO:
        this.disableAllIfrOptions();
        break;
    }
  }

  private enableAllIfrOptions(): void {
    this.form.get('modCalcIfr')?.enable();
    this.form.get('percIfr')?.enable();
    this.form.get('accIfr')?.enable();
  }

  private disableAllIfrOptions(): void {
    this.form.get('modCalcIfr')?.disable();
    this.form.get('percIfr')?.disable();
    this.form.get('accIfr')?.disable();
    this.form.get('modCalcIfr')?.setValue(null);
    this.form.get('percIfr')?.setValue(null);
    this.form.get('accIfr')?.setValue(null);
  }

  private resetForm(): void {
    this.isModifing = false;
    this.modalTitle = 'Crea Censimento';
    this.form.reset({ active: true, ccs: false, percRitFisc: 0 });
    this.getProducersArray().clear();
    if (this.userInfos?.isDirectionalUser) {
      this.form.get('agency')?.enable();
    } else {
      this.form.get('agency')?.disable();
    }
  }

  private populateForm(): void {
    if (this.collaborator === undefined) {
      return;
    }

    this.modalTitle = 'Modifica Censimento';
    this.isModifing = true;

    this.form.patchValue({
      idColl: this.collaborator.idColl,
      name: this.collaborator.name,
      surname: this.collaborator.surname,
      address: this.collaborator.address,
      zipCode: this.collaborator.zipCode,
      city: this.collaborator.city,
      province: this.collaborator.province,
      vatNumber: this.collaborator.vatNumber,
      fc: this.collaborator.fc,
      active: this.collaborator.active,
      ccs: this.collaborator.ccs,
      ifr: this.collaborator.ifr,
      modCalcIfr: this.collaborator.modCalcIfr,
      percIfr: this.collaborator.percIfr,
      accIfr: this.collaborator.accIfr,
      percRitFisc: this.collaborator.percRitFisc,
      agency: this.collaborator.agency,
    });

    let productorCodes = this.form.get('productorCodes') as FormArray;
    productorCodes.clear();
    this.collaborator.productorCodes?.forEach((productorCode) => {
      productorCodes.push(
        new FormGroup({
          id: new FormControl(productorCode.id),
          code: new FormControl(productorCode.code, [Validators.required]),
          active: new FormControl(productorCode.active, [Validators.required]),
        })
      );
    });

    const ifrControl = this.form.get('ifr');
    if (ifrControl) {
      this.onIfrChange({
        value: { code: ifrControl.value.code },
      } as DropdownChangeEvent);
    }

    this.form.get('agency')?.disable();
  }

  public addRow(): void {
    let receivers = this.form.get('productorCodes') as FormArray;
    receivers.push(
      new FormGroup({
        id: new FormControl(null),
        code: new FormControl(null, [Validators.required]),
        active: new FormControl(true, Validators.required),
      })
    );
  }

  public getProducersArray(): FormArray {
    return this.form.get('productorCodes') as FormArray;
  }

  public onRemove(rowIndex: number) {
    let receivers = this.form.get('productorCodes') as FormArray;
    receivers.removeAt(rowIndex);
  }

  private initState(): void {
    const getIFRType$ = this.httpServiceCommission.getAllInfos(TYPE.IFR_TYPE);
    const getIFRMODE$ = this.httpServiceCommission.getAllInfos(TYPE.IFR_MODE);

    forkJoin([getIFRType$, getIFRMODE$]).subscribe({
      next: ([ifrType, ifrMode]) => {
        this.ifrTypesOptions = ifrType['items'];
        this.ifrModesOptions = ifrMode['items'];
      },
    });
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  public getDirty = getDirty;
}
