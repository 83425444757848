import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { concatMap, Subscription } from 'rxjs';
import { CATEGORY_OPTIONS, COMMISSION_BASE } from '../../../../constants/test';
import {
  CATEGORY,
  CondizioniAliquota,
  QueryTypeEnum,
  STORAGE_KEYS,
  VariationType,
  VariazioneAliquota,
} from '../../../../enums/commission';
import { COMMISSION_PATH } from '../../../../enums/path';
import { ParzialErrorsResponse } from '../../../../models/associate-dissociate/parzial-errors-response';
import {
  Agency,
  Category,
  CoinsuranceAgency,
  CommissionDetail,
  Producer,
  Product,
  RateType,
  Type,
  Variation,
} from '../../../../models/commission-detail';
import { CommissionTableEditRequest } from '../../../../models/commission-table/commission-table-edit-request';
import { IddPair } from '../../../../models/commission-table/idd-pair';
import { CommissionData } from '../../../../models/commission/commission-data';
import { ModalInfos } from '../../../../models/modal/modal-infos';
import { UserInfo } from '../../../../models/user/user-info';
import { HttpUtilisCommissions } from '../../../../network/http-utilis-commissions';
import { CommissionsFormService } from '../../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { AddUtils } from '../../../../utils/add-utilis';
import { atLeastOneValidator } from '../../../../validators/variation-validator';
import { AssociateDissociateComponent } from '../../../associate-dissociate/associate-dissociate.component';
import { getType } from '../../../../utils';
import { sortModes } from '../../../../utils/commission-utilis';

@Component({
  selector: 'app-add-form',
  standalone: true,
  templateUrl: './add-form.component.html',
  styleUrl: './add-form.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CommonModule,
    CalendarModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    AutoCompleteModule,
    TableModule,
    AssociateDissociateComponent,
    ProgressSpinnerModule,
  ],
})
export class AddFormComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  isLoading: boolean = true;
  cetegoryOptions: string[] = CATEGORY_OPTIONS;
  modalTitle: string = '';
  lastCategoryVal: Category | undefined = undefined;
  filteredValues: Agency[] = [];
  queryEnum = QueryTypeEnum;
  categories: Category[] | undefined;
  baseTable: string[] = COMMISSION_BASE;
  varConditions = Object.entries(CondizioniAliquota).map(
    ([code, description]) => ({
      code,
      description,
    })
  );

  varAli = Object.entries(VariazioneAliquota).map(([code, description]) => ({
    code,
    description,
  }));

  varType = Object.entries(VariationType).map(([code, description]) => ({
    code,
    description,
  }));

  notAssociatedProducer: Producer[] = [];
  notAssociatedCoinsuranceAgency: CoinsuranceAgency[] = [];
  notAssociatedProduct: Product[] = [];
  iddPair: IddPair; //when implemented remove dataKeyFirstTable and dataKeySecondTable
  dataKeyFirstTable: number = 0;
  dataKeySecondTable: number = 0;
  netValueDisabled: boolean = false;
  noReset: boolean = false;
  percentageValueDisabled: boolean = false;
  isModifying: boolean = false;
  showModal: boolean = false;
  categoryIsAutoOrARD: boolean | undefined;
  subCategory: Subscription | undefined = undefined;
  idCommission: number | undefined;
  commissionData: CommissionData | undefined;
  showMissingProducer: boolean = false;
  showMissingCoinsuranceAgency: boolean = false;
  showMissingProduct: boolean = false;
  private addUtilis: AddUtils;
  userInfo: UserInfo;

  constructor(
    public commissionFormService: CommissionsFormService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpUtils: HttpUtilisCommissions,
    private modalService: ModalService,
    private messageService: MessageService
  ) {
    this.userInfo = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.USER_INFO) || '{}'
    );

    this.addUtilis = new AddUtils(this.commissionFormService, this.userInfo);
    this.iddPair = new IddPair(0, 0);
    this.form = new FormGroup({
      commissionTable: new FormControl(null, [Validators.required]),
      producer: new FormControl<Producer[]>(
        [],
        this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      coinsuranceAgency: new FormControl<CoinsuranceAgency[]>(
        [],
        !this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      product: new FormControl<Product[]>([], [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      validityStartDate: new FormControl(new Date(), [Validators.required]),
      validityEndDate: new FormControl(null, []),
      tableType: new FormControl(null, [Validators.required]),
      agency: new FormControl(
        null,
        this.userInfo.isDirectionalUser ? [Validators.required] : []
      ),
      rateTypes: new FormArray([], [Validators.required]),
    });
  }
  ngOnDestroy(): void {
    this.subCategory?.unsubscribe();
    this.commissionFormService.assoDissoWrapper.reset();
  }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['id']) {
        this.idCommission = params['id'];
        let commission: CommissionDetail | undefined;

        this.httpUtils
          .getCommissionDetail(params['id'])
          .pipe(
            concatMap((detailResponse) => {
              commission = CommissionDetail.fromJson(detailResponse);
              this.populateForm(commission!);
              /*this.form = this.addUtilis.populateForm(
                commission!,
                this.iddPair
              );*/
              this.initializePickList(commission.id);
              this.isModifying = true;
              this.form.get('category')?.disable();
              return this.httpUtils.getCommissionData(commission.category!);
            })
          )
          .subscribe({
            next: (response) => {
              this.commissionData = response;
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
            },
          });
      } else {
        let categoryControl: FormControl = this.form.get(
          'category'
        ) as FormControl;

        this.subCategory = categoryControl.valueChanges.subscribe(
          (value: Category) => {
            let modalReset = document.getElementById('modalResetPro');
            this.checkResetCommissionData(value);

            if (
              modalReset &&
              this.form.get('product')?.value.length > 0 &&
              value != this.lastCategoryVal
            ) {
              new Modal(modalReset, {
                backdrop: 'static',
                keyboard: false,
              }).show();
            } else {
              this.lastCategoryVal = value;
              if (value !== null && !this.noReset) {
                this.httpUtils
                  .getProduct(undefined, undefined, value.code)
                  .subscribe({
                    next: (response) => {
                      this.notAssociatedProduct = response['items'];
                    },
                    error: (error: HttpErrorResponse) => {
                      this.modalService.showError(error);
                    },
                  });
              } else {
                this.noReset = false;
              }
            }
          }
        );

        this.initializePickList();
        this.isLoading = false;
      }
    });

    this.httpUtils.getCategories().subscribe({
      next: (response) => {
        this.categories = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  createRateType() {
    return new FormGroup({
      idd: new FormControl(this.dataKeyFirstTable++),
      type: new FormControl(null, [Validators.required]),
      mode: new FormControl(null, [Validators.required]),
      types: new FormControl(this.commissionData?.commissionsType),
      modes: new FormControl(null),
      percentage: new FormControl(0, [Validators.required]),
      variations: new FormArray([]),
    });
  }

  createVariation() {
    return new FormGroup(
      {
        idd: new FormControl(this.dataKeySecondTable++),
        variation: new FormControl(null, [Validators.required]),
        conditions: new FormControl(null, [Validators.required]),
        type: new FormControl({ code: 'NEGATIVA', description: 'NEGATIVA' }, [
          Validators.required,
        ]),
        date: new FormControl({ value: null, disabled: true }, [
          Validators.required,
        ]),
        percentageValue: new FormControl(0, []),
        netValue: new FormControl(0, []),
      },
      { validators: atLeastOneValidator }
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      let commissionTableEditRequest: CommissionTableEditRequest =
        new CommissionTableEditRequest(
          this.idCommission ? this.idCommission : null,
          this.form.get('commissionTable')?.value,
          this.form.get('category')?.value,
          this.userInfo.isDirectionalUser
            ? this.form.get('agency')?.value
            : {
                code: this.userInfo?.node,
                description: this.userInfo?.agencyDescription,
              },
          this.form.get('tableType')?.value == 'BASE' ? true : false,
          (this.form.get('validityStartDate')?.value as Date).toISOString(),
          (this.form.get('validityEndDate')?.value as Date)?.toISOString(),
          this.form.get('rateTypes')?.value
        );
      this.isLoading = true;
      if (this.commissionFormService.assoDissoWrapper.isEmpty()) {
        this.httpUtils
          .sendCommissionDetail(commissionTableEditRequest)
          .subscribe({
            next: (response) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Provvigione creata con successo',
              });
              this.router.navigate([COMMISSION_PATH.SEARCH]);
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
            },
          });
      } else {
        this.httpUtils
          .addOrModifyCommission(
            commissionTableEditRequest,
            this.commissionFormService.assoDissoWrapper
          )
          .subscribe({
            next: (response: ParzialErrorsResponse) => {
              this.isLoading = false;
              if (
                (response.producerOrCoasErrors === null ||
                  response.producerOrCoasErrors === undefined) &&
                (response.productErrors === null ||
                  response.productErrors === undefined)
              ) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Successo',
                  key: 'br',
                  detail: 'Provvigione creata con successo',
                });
                this.router.navigate([COMMISSION_PATH.SEARCH]);
              } else {
                let partialReponseBody =
                  this.addUtilis.createPartialResponseBody(
                    response,
                    this.isModifying
                  );
                this.modalService.showModal(
                  new ModalInfos('Attenzione', partialReponseBody, () => {
                    this.router.navigate([COMMISSION_PATH.SEARCH]);
                  })
                );
              }
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
            },
          });
      }
    } else {
      this.checkValidity();
    }
  }

  goBack(): void {
    let collaborator = getType(this.commissionFormService);
    this.commissionFormService.assoDissoWrapper.reset();
    this.router.navigate([COMMISSION_PATH.SEARCH], {
      queryParams: { type: collaborator },
    });
  }
  /*
  onReset(): void {
    (this.form.get('rateTypes') as FormArray).clear();
    this.resetModals();

    new Date();
    this.form.reset({
      product: [],
      producer: [],
      coinsuranceAgency: [],
      isActive: false,
      validityStartDate: new Date(),
      category: this.isModifying ? this.form.get('category')?.value : null,
    });
  }
    */

  filterValues($event: AutoCompleteCompleteEvent, type: string) {
    let query = $event.query;

    if (query.length < 3) {
      this.filteredValues = [];

      return;
    }

    this.httpUtils?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  addRateType() {
    let rateTypes = this.form.get('rateTypes') as FormArray;
    rateTypes.push(this.createRateType());
  }

  addVariation(index: number) {
    let rateTypes = this.form.get('rateTypes') as FormArray;
    let variations = rateTypes.controls[index].get('variations') as FormArray;
    variations.push(this.createVariation());
  }

  getRateTypes() {
    let rateTypes = this.form.get('rateTypes') as FormArray;

    return rateTypes;
  }

  getVariations(formGroup: FormGroup) {
    return formGroup.get('variations') as FormArray;
  }

  deleteRateType(index: number) {
    let rateTypes = this.form.get('rateTypes') as FormArray;
    rateTypes.removeAt(index);
  }

  deleteVariation(rowIndex: number, variationIndex: number) {
    let rateTypes = this.form.get('rateTypes') as FormArray;
    let variations = rateTypes.controls[rowIndex].get(
      'variations'
    ) as FormArray;
    variations.removeAt(variationIndex);
  }

  clickOpenModal(type: string) {
    this.modalTitle = type;
  }

  onCloseModal() {
    let controlProduct = this.form.get('product') as FormControl<Product[]>;
    let controlProducer = this.form.get('producer') as FormControl<Producer[]>;
    let controlCoinsuranceAgency = this.form.get(
      'coinsuranceAgency'
    ) as FormControl<CoinsuranceAgency[]>;
    controlProducer.updateValueAndValidity();
    controlProduct.updateValueAndValidity();
    controlCoinsuranceAgency.updateValueAndValidity();

    if (this.modalTitle === 'produttori') {
      this.showMissingProducer = controlProducer.value.length === 0;
    } else if (this.modalTitle === 'agenzia Coassicurazione') {
      this.showMissingCoinsuranceAgency =
        controlCoinsuranceAgency.value.length === 0;
    } else if (this.modalTitle === 'prodotti') {
      this.showMissingProduct = controlProduct.value.length === 0;
    }
  }

  checkValidity() {
    let val = this.form.controls;

    for (let i in val) {
      if (val[i].invalid) {
        val[i].markAsTouched();
        val[i].markAsDirty();
      }
    }
    this.showMissingProducer = this.form.get('producer')?.value.length === 0;
    this.showMissingCoinsuranceAgency =
      this.form.get('coinsuranceAgency')?.value.length === 0;
    this.showMissingProduct = this.form.get('product')?.value.length === 0;
  }

  isPercentageValueFilled(formGroup: FormGroup): boolean {
    return formGroup.value.percentageValue;
  }

  isNetValueFilled(formGroup: FormGroup): boolean {
    return formGroup.value.netValue;
  }

  disablePickList() {
    if ((this.form.get('category') as FormControl) !== null) {
      let categoryControl = this.form.get('category') as FormControl;
      return categoryControl.value === null && !this.isModifying;
    } else {
      return false;
    }
  }

  private resetModals() {
    if (this.form.get('producer')?.value.length > 0) {
      this.form.get('producer')?.value.forEach((producer: Producer) => {
        this.notAssociatedProducer.push(producer);
      });
    }
    if (this.form.get('coinsuranceAgency')?.value.length > 0) {
      this.form
        .get('coinsuranceAgency')
        ?.value.forEach((coinsuranceAgency: CoinsuranceAgency) => {
          this.notAssociatedCoinsuranceAgency.push(coinsuranceAgency);
        });
    }
    if (this.form.get('product')?.value.length > 0) {
      this.form.get('product')?.value.forEach((product: Product) => {
        this.notAssociatedProduct.push(product);
      });
    }
  }

  private populateForm(commission: CommissionDetail) {
    this.form = new FormGroup({
      commissionTable: new FormControl(commission.commissionTable, [
        Validators.required,
      ]),
      producer: new FormControl<Producer[]>(
        commission.producer ?? [],
        this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      coinsuranceAgency: new FormControl<CoinsuranceAgency[]>(
        commission.coinsuranceAgency ?? [],
        !this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      product: new FormControl<Product[]>(commission.product ?? [], [
        Validators.required,
      ]),
      category: new FormControl(commission.category, [Validators.required]),
      validityStartDate: new FormControl(
        new Date(commission.validityStartDate),
        [Validators.required]
      ),
      validityEndDate: new FormControl(
        commission.validityEndDate
          ? new Date(commission.validityEndDate)
          : null,
        []
      ),
      tableType: new FormControl(commission.isBase ? 'BASE' : 'NON BASE', [
        Validators.required,
      ]),
      agency: new FormControl(
        commission.agency,
        this.userInfo.isDirectionalUser ? [Validators.required] : []
      ),
      rateTypes: new FormArray([], [Validators.required]),
    });

    if (commission.rateTypes.length !== 0) {
      commission.rateTypes.forEach((rateType: RateType) => {
        let rateTypes = this.form.get('rateTypes') as FormArray;
        let rateTypeGroup = new FormGroup({
          id: new FormControl(rateType.id),
          idd: new FormControl(this.dataKeyFirstTable++),
          type: new FormControl(rateType.type, [Validators.required]),
          mode: new FormControl(rateType.mode, [Validators.required]),
          types: new FormControl(rateType.types),
          modes: new FormControl(sortModes(rateType.modes)),
          percentage: new FormControl(rateType.percentage, [
            Validators.required,
          ]),
          variations: new FormArray([], []),
        });

        rateType.variations.forEach((variation: Variation) => {
          let variations = rateTypeGroup.get('variations') as FormArray;
          let variationGroup = new FormGroup({
            id: new FormControl(variation.id),
            idd: new FormControl(this.dataKeySecondTable++),
            variation: new FormControl(variation.variation, [
              Validators.required,
            ]),
            conditions: new FormControl(variation.conditions, [
              Validators.required,
            ]),
            type: new FormControl(variation.type, [Validators.required]),
            date: new FormControl(
              {
                value: variation.date ? new Date(variation.date) : null,
                disabled: variation.date ? false : true,
              },
              [Validators.required]
            ),
            percentageValue: new FormControl(variation.percentageValue, []),
            netValue: new FormControl(variation.netValue, []),
          });

          variations.push(variationGroup);
        });

        rateTypes.push(rateTypeGroup);
      });
    }
  }

  private initializePickList(idCommission?: number) {
    this.httpUtils.getProducerOrCoass(undefined, idCommission).subscribe({
      next: (response) => {
        this.commissionFormService.isProducer
          ? (this.notAssociatedProducer = response['items'])
          : (this.notAssociatedCoinsuranceAgency = response['items']);
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
    if (idCommission) {
      const currentCategory: Category = this.form.get('category')?.value;
      this.httpUtils
        .getProduct(undefined, idCommission, currentCategory.code)
        .subscribe({
          next: (response) => {
            this.notAssociatedProduct = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
    }
  }

  public resetProduct() {
    this.form.get('product')?.setValue([]);
    this.lastCategoryVal = this.form.get('category')?.value;
    this.httpUtils
      .getProduct(undefined, undefined, this.lastCategoryVal?.code)
      .subscribe({
        next: (response) => {
          this.notAssociatedProduct = response['items'];
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
        },
      });
  }

  public checkResetCommissionData(category: Category) {
    if (category === null) {
      const rateTypes = this.form.get('rateTypes') as FormArray;
      const lenght = rateTypes.length;

      for (let i = 0; i < lenght; i++) {
        rateTypes.removeAt(0);
      }

      this.categoryIsAutoOrARD == undefined;
    } else if (
      this.categoryIsAutoOrARD === undefined ||
      this.categoryIsAutoOrARD !==
        (category.code === CATEGORY.AUTO || category.code === CATEGORY.ARD)
    ) {
      this.categoryIsAutoOrARD =
        category.code === CATEGORY.AUTO || category.code === CATEGORY.ARD;
      this.resetCommissionData(category);
    }
  }

  public resetCommissionData(category: Category) {
    const rateTypes = this.form.get('rateTypes') as FormArray;
    const length = rateTypes.length;
    for (let i = 0; i < length; i++) {
      rateTypes.removeAt(0);
    }

    this.form.updateValueAndValidity();
    this.httpUtils.getCommissionData(category).subscribe({
      next: (response) => {
        this.commissionData = response;
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  getModeInRateType(index: number) {
    let rateTypesArray = this.form.get('rateTypes') as FormArray;
    let code = rateTypesArray.controls[index].get('type')?.value.code;

    this.httpUtils
      .getCommissionData(this.form.get('category')?.value, code)
      .subscribe({
        next: (response) => {
          console.log(response);
          rateTypesArray.controls[index]
            .get('modes')
            ?.setValue(sortModes(response.modes));
          rateTypesArray.controls[index].get('mode')?.setValue(null);
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
        },
      });
  }

  public noResetProduct() {
    this.noReset = true;
    this.form.get('category')?.setValue(this.lastCategoryVal);
  }

  public dataReseter(variation: FormGroup): void {
    let conditions = variation.value.conditions?.description;

    switch (conditions) {
      case CondizioniAliquota.COND_000:
      case CondizioniAliquota.COND_001:
        variation.get('date')?.enable();
        break;
      default:
        variation.get('date')?.disable();
        variation.get('date')?.setValue(null);
        break;
    }
  }

  public onCloseModalPickList() {
    this.onCloseModal();
  }
}
