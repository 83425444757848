<div class="commission-form">
  <div (click)="goBack()" class="mb-4 go-back">
    <i class="pi pi-arrow-left me-2 icon-list"> </i>
    <span class="label">Indietro</span>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <label for="collaborator">Collaboratore*</label>
        <p-autoComplete
          styleClass="autocomplete-dropdown my-2"
          formControlName="collaborator"
          showEmptyMessage="false"
          [dropdown]="true"
          showClear="true"
          [suggestions]="filteredValues"
          [ngClass]="{ 'ng-dirty': getDirty(form, 'collaborator') }"
          (completeMethod)="filterValues($event, this.enumType.PRODUCERS)"
          placeholder="Seleziona..."
          field="description"
        />
      </div>
      <div class="col-lg-6 col-md-12">
        <label for="titleCollectionDate">Data incasso titolo*</label>
        <p-calendar
          id="titleCollectionDate"
          formControlName="titleCollectionDate"
          [showIcon]="true"
          selectionMode="range"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong>CERCA</strong>
      </button>
    </div>
  </form>
  <div
    class="mt-2"
    *ngIf="
      !responseIsEmpty() && this.bankRecService.showRowsTable && !isLoading
    "
  >
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <p-button
          type="button"
          icon="pi pi-plus"
          styleClass="p-button-text "
          [label]="getButtonCreateModify()"
          [disabled]="this.selectedBankRows.length === 0"
          (click)="onCreateDraft()"
        ></p-button>
      </div>
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-3 col-md-12 d-flex justify-content-between">
        <p-button
          type="button"
          icon="pi pi-plus"
          styleClass="p-button-text"
          label="Crea nuova riga"
          (click)="onCreatRow()"
        ></p-button>
        <p-button
          type="button"
          icon="pi pi-calculator"
          styleClass="p-button-text "
          label="Ricalcola"
          [disabled]="this.selectedBankRows.length === 0"
          (click)="onRecalculates()"
        ></p-button>
      </div>
    </div>
    <div>
      <p-table
        [value]="bankRows"
        dataKey="rowId"
        [scrollable]="true"
        [(selection)]="selectedBankRows"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [tableStyle]="{ 'min-width': '60rem' }"
        [rows]="10"
        styleClass=" table-commission text-center"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th
              *ngIf="commissionService.isProducer"
              pSortableColumn="codPdre"
              style="width: auto"
            >
              Produttore
              <p-sortIcon field="codPdre"></p-sortIcon>
            </th>
            <th
              *ngIf="!commissionService.isProducer"
              pSortableColumn="codCoas"
              style="width: auto"
            >
              Codice compagnia coassicuratrice
              <p-sortIcon field="codCoas"></p-sortIcon>
            </th>
            <th pSortableColumn="collectionDate" style="width: auto">
              Registrazione <p-sortIcon field="collectionDate"></p-sortIcon>
            </th>
            <th pSortableColumn="numPoli" style="width: auto">
              Polizza <p-sortIcon field="numPoli"></p-sortIcon>
            </th>
            <th pSortableColumn="fullName" style="width: auto">
              Cliente <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            <th pSortableColumn="isCoass" style="width: auto">
              Coassicurazione
              <p-sortIcon field="isCoass"></p-sortIcon>
            </th>
            <th pSortableColumn="branch" style="width: auto">
              Ramo
              <p-sortIcon field="branch"></p-sortIcon>
            </th>
            <th pSortableColumn="reason" style="width: auto">
              Causale
              <p-sortIcon field="reason"></p-sortIcon>
            </th>
            <th pSortableColumn="gross" style="width: auto">
              Lordo
              <p-sortIcon field="gross"></p-sortIcon>
            </th>
            <th pSortableColumn="net" style="width: auto">
              Netto
              <p-sortIcon field="net"></p-sortIcon>
            </th>
            <th
              *ngIf="commissionService.isProducer"
              pSortableColumn="provvAgency"
              style="width: auto"
            >
              Provv. Age.
              <p-sortIcon field="provvAgency"></p-sortIcon>
            </th>
            <th
              *ngIf="commissionService.isProducer"
              pSortableColumn="provvCalcolata"
              style="width: auto"
            >
              Provv. Prod.
              <p-sortIcon field="provvCalcolata"></p-sortIcon>
            </th>
            <th *ngIf="!commissionService.isProducer" style="width: auto">
              Provv. Coass.
            </th>
            <th
              *ngIf="commissionService.isProducer"
              pSortableColumn="ifrImmediato"
              style="width: auto"
            >
              IFR immediato
              <p-sortIcon field="ifrImmediato"></p-sortIcon>
            </th>
            <th
              *ngIf="commissionService.isProducer"
              pSortableColumn="ifrDifferito"
              style="width: auto"
            >
              IFR differito
              <p-sortIcon field="ifrDifferito"></p-sortIcon>
            </th>
            <th pSortableColumn="totSospeso" style="width: auto">
              Sospeso
              <p-sortIcon field="totSospeso"></p-sortIcon>
            </th>
            <th pSortableColumn="manuallyUpdated" style="width: auto">
              Modifica manuale
              <p-sortIcon field="manuallyUpdated"></p-sortIcon>
            </th>
            <th pSortableColumn="manuallyCreated" style="width: auto">
              Creazione manuale
              <p-sortIcon field="manuallyCreated"></p-sortIcon>
            </th>
            <th class="custom-width"></th>
          </tr>
          <tr>
            <th></th>
            <th>
              <p-columnFilter
                *ngIf="commissionService.isProducer"
                type="text"
                field="codPdre"
                matchMode="contains"
              />
              <p-columnFilter
                *ngIf="!commissionService.isProducer"
                type="text"
                field="codCoas"
                matchMode="contains"
              />
            </th>
            <th>
              <p-columnFilter
                type="text"
                field="formatedCollectionDate"
                matchMode="contains"
              />
            </th>
            <th>
              <p-columnFilter
                type="text"
                field="numPoli"
                matchMode="contains"
              />
            </th>
            <th>
              <p-columnFilter
                type="text"
                field="fullName"
                matchMode="contains"
              />
            </th>
            <th></th>
            <th>
              <p-columnFilter type="text" field="branch" matchMode="contains" />
            </th>
            <th>
              <p-columnFilter type="text" field="reason" matchMode="contains" />
            </th>
            <th></th>
            <th></th>
            <th *ngIf="commissionService.isProducer"></th>
            <th *ngIf="commissionService.isProducer"></th>
            <th *ngIf="!commissionService.isProducer"></th>
            <th *ngIf="commissionService.isProducer"></th>
            <th *ngIf="commissionService.isProducer"></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="width: 6rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-bankRecordRow let-i="rowIndex">
          <tr
            [ngClass]="{
              'calculated-bold':
                !bankRecordRow.calculated && !bankRecordRow.manuallyCreated
            }"
          >
            <td>
              <p-tableCheckbox [value]="bankRecordRow" />
            </td>
            <td *ngIf="commissionService.isProducer" class="text-center">
              {{ bankRecordRow.codPdre }}
            </td>
            <td *ngIf="!commissionService.isProducer">
              {{ bankRecordRow.codCoas }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.collectionDate | date : "dd/MM/yyyy" : "UTC+2" }}
            </td>
            <td>{{ bankRecordRow.numPoli }}</td>
            <td class="text-center">
              {{ bankRecordRow.fullName ?? "" }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.isCoass ? "Si" : "No" }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.branch ?? "" }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.reason }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.gross ?? 0 | customCurrency }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.net ?? 0 | customCurrency }}
            </td>
            <td class="text-center" *ngIf="commissionService.isProducer">
              {{ bankRecordRow.provvAgency ?? 0 | customCurrency }}
            </td>
            <td class="text-center" *ngIf="commissionService.isProducer">
              {{
                (!bankRecordRow.calculated &&
                  !bankRecordRow.manuallyUpdated &&
                  !bankRecordRow.manuallyCreated) ||
                !bankRecordRow.provvCalcolata
                  ? "-"
                  : (bankRecordRow.provvCalcolata | customCurrency)
              }}
            </td>
            <td class="text-center" *ngIf="commissionService.isProducer">
              {{
                (!bankRecordRow.calculated &&
                  !bankRecordRow.manuallyUpdated &&
                  !bankRecordRow.manuallyCreated) ||
                !bankRecordRow.ifrImmediato
                  ? "-"
                  : (bankRecordRow.ifrImmediato | customCurrency)
              }}
            </td>
            <td class="text-center" *ngIf="commissionService.isProducer">
              {{
                (!bankRecordRow.calculated &&
                  !bankRecordRow.manuallyUpdated &&
                  !bankRecordRow.manuallyCreated) ||
                !bankRecordRow.ifrDifferito
                  ? "-"
                  : (bankRecordRow.ifrDifferito ?? 0 | customCurrency)
              }}
            </td>
            <td class="text-center">
              {{ bankRecordRow.totSospeso ?? 0 | customCurrency }}
            </td>
            <td>
              <div class="row text-center">
                <div *ngIf="bankRecordRow.manuallyUpdated" class="col-md">
                  <p-image
                    src="../../../../../assets/images/touched.svg"
                    alt="Touched SVG"
                    title="Modifica manuale"
                    width="22"
                    class="ms-1"
                  ></p-image>
                </div>
              </div>
            </td>
            <td>
              <div class="row text-center">
                <div *ngIf="bankRecordRow.manuallyCreated" class="col-md">
                  <i
                    class="pi pi-user-edit icon-green"
                    title="Creata manualmente"
                  ></i>
                </div>
              </div>
            </td>
            <td>
              <div class="row text-end custom-width">
                <div class="col-md">
                  <i
                    class="pi pi-pen-to-square icon"
                    (click)="
                      onModify(
                        bankRecordRow.rowId,
                        bankRecordRow.manuallyCreated
                      )
                    "
                    title="Modifica"
                  ></i>
                </div>
                <div class="col-md">
                  <i
                    class="pi pi-address-book icon"
                    (click)="onDetail(bankRecordRow.rowId)"
                    title="Dettaglio"
                  ></i>
                </div>
                <div class="col-md" *ngIf="bankRecordRow.manuallyCreated">
                  <i
                    title="Elimina bozza"
                    class="pi pi-trash icon"
                    (click)="onDelete(bankRecordRow.rowId)"
                    title="Elimina"
                  ></i>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorcenter"> </ng-template>
      </p-table>
      <p *ngIf="this.selectedBankRows.length > 0">
        Righe selezionate: {{ this.selectedBankRows.length }}
      </p>
    </div>
    <div *ngIf="responseIsEmpty()" class="text-center text">
      <p>La ricerca non ha prodotto nessun risultato</p>
    </div>
  </div>
  <div *ngIf="isLoading" class="text-center">
    <p-progressSpinner ariaLabel="loading" />
  </div>
</div>
