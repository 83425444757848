import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Manager } from '../../models/plan-accounts/account/create-account-request';
import { FirstNoteFilter } from '../../enums/first-note';
import { ManagerOperation } from '../../models/first-note/manager-operation';

@Injectable({
  providedIn: 'root',
})
export class FirstNoteService {
  modifyModalVisibilitySub: Subject<boolean> = new Subject();
  doubleEntryModalVisibilitySub: Subject<boolean> = new Subject();
  firstNoteMangerSub: Subject<ManagerOperation> = new Subject();
  filterSubject: Subject<FirstNoteFilter> = new Subject<FirstNoteFilter>();
  constructor() {}
}
