import { FormGroup } from '@angular/forms';
import { Agency, Type } from '../../commission-detail';
import { splitAgencyCode } from '../../../constants/general';

export class Manager {
  code: string;
  description: string;
  detail: string;

  constructor(code: string, description: string, detail: string) {
    this.code = code;
    this.description = description;
    this.detail = detail;
  }
}

export class CreateAccountRequest {
  name: string;
  code: string;
  type: Type;
  pathNodo: string;
  carry?: number;
  carryDate?: string;
  firstNoteManager: Manager;
  agency: Agency;
  agencyCode: string;
  insertDate?: string;
  lastUpdate?: string;
  accountNumber?: number;

  constructor(
    name: string,
    code: string,
    type: Type,
    pathNodo: string,
    firstNoteManager: Manager,
    agency: Agency,
    agencyCode: string,
    insertDate?: string,
    lastUpdate?: string,
    carry?: number,
    carryDate?: string,
    accountNumber?: number
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.pathNodo = pathNodo;
    this.carry = carry;
    this.carryDate = carryDate;
    this.firstNoteManager = firstNoteManager;
    this.agency = agency;
    this.agencyCode = agencyCode;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
    this.accountNumber = accountNumber;
  }

  static fromForm(form: FormGroup, code: number): CreateAccountRequest {
    const agency = form.get('agency')?.value;

    return new CreateAccountRequest(
      form.get('name')?.value,
      form.get('code')?.value,
      form.get('type')?.value,
      agency.code,
      form.get('firstNoteManager')?.value,
      agency,
      agency.description.split(splitAgencyCode)[0],
      form.get('insertDate')?.value,
      form.get('lastUpdate')?.value,
      form.get('carry')?.value,
      form.get('carryDate')?.value,
      code
    );
  }
}
