import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { STORAGE_KEYS } from '../enums/commission';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomError } from '../models/error/custom-error';
import { Agency } from '../models/commission-detail';
import { CensusCollaborator } from '../models/census/census-collaborator';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisCensus {
  constructor(private http: HttpClient) {}

  /**
   * Fetches census data for collaborators based on the provided full name and agency.
   *
   * @param {string} [fullName] - The full name of the collaborator to search for.
   * @param {Agency} [agency] - The agency of the collaborators.
   * @returns {Observable<any>} - An observable containing the census data.

   * @throws {CustomError} - Throws an error if the HTTP request fails.
   */
  public getCensus(fullName?: string, agency?: Agency): Observable<any> {
    let params: { [key: string]: number | string } = {};

    if (fullName) {
      params['fullName'] = fullName;
    }
    if (agency) {
      params['agency'] = agency.code;
    }

    return this.http
      .get(environment.URL_COMMISSION + 'collaborators', {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public putCollaborator(collaborator: CensusCollaborator): Observable<any> {
    return this.http
      .put(environment.URL_COMMISSION + 'collaborators', collaborator, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public deleteCollaborator(id: number): Observable<any> {
    return this.http
      .delete(environment.URL_COMMISSION + 'collaborators/' + id, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }
}
