import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CATEGORY_OPTIONS, COMMISSION_BASE } from '../../../constants/test';
import { QueryTypeEnum, STORAGE_KEYS } from '../../../enums/commission';
import {
  Agency,
  CoinsuranceAgency,
  Producer,
  Product,
} from '../../../models/commission-detail';
import {
  CommissionTableRequest,
  Filters,
} from '../../../models/commission-table/commission_table_request';
import { UserInfo } from '../../../models/user/user-info';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';

@Component({
  selector: 'app-commisions-search-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    CommonModule,
    AutoCompleteModule,
    CheckboxModule,
  ],
  templateUrl: './commissions-search-form.component.html',
  styleUrl: './commissions-search-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CommissionsSearchFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  enumType = QueryTypeEnum;
  baseOptions: string[] = COMMISSION_BASE;
  filteredValues: Agency[] | Producer[] | Product[] | CoinsuranceAgency[] = [];
  cetegoryOptions: string[] | undefined = CATEGORY_OPTIONS;
  userInfo: UserInfo;
  constructor(
    public commissionFormService: CommissionsFormService,
    private httpUtils: HttpUtilisCommissions,
    private modalService: ModalService
  ) {
    this.userInfo = JSON.parse(
      sessionStorage.getItem(STORAGE_KEYS.USER_INFO) || '{}'
    );
    this.form = this.commissionFormService.formComSearch;
  }
  ngOnDestroy(): void {
    this.form.reset({ isActive: false });
  }
  ngOnInit(): void {
    this.commissionFormService.commissionTableRequest =
      new CommissionTableRequest(
        this.commissionFormService.isProducer
          ? QueryTypeEnum.PRODUCERS
          : QueryTypeEnum.COINSURANCES,
        Filters.fromForm(this.form.value, this.userInfo)
      );
  }

  onSubmit(): void {
    this.commissionFormService.$CommissionTableSub.next(
      new CommissionTableRequest(
        this.commissionFormService.isProducer
          ? QueryTypeEnum.PRODUCERS
          : QueryTypeEnum.COINSURANCES,
        Filters.fromForm(this.form.value, this.userInfo)
      )
    );
    this.commissionFormService.showInfoTableCommission = true;
  }

  onReset(): void {
    this.form.reset({ isActive: false });
    this.commissionFormService.$CommissionTableSub.next(
      new CommissionTableRequest(
        this.commissionFormService.isProducer
          ? QueryTypeEnum.PRODUCERS
          : QueryTypeEnum.COINSURANCES,
        Filters.fromForm(this.form.value, this.userInfo)
      )
    );
  }

  filterValues($event: AutoCompleteCompleteEvent, type: string): void {
    let query = $event.query;

    if (query.length == 1 || query.length == 2) {
      this.filteredValues = [];
      return;
    }

    if (
      (type == this.enumType.AGENCIES && query.length < 3) ||
      (type == this.enumType.PRODUCTS && query.length < 3)
    ) {
      this.filteredValues = [];
      return;
    }

    switch (type) {
      case this.enumType.AGENCIES:
        this.httpUtils?.getAgencies(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;
      case this.enumType.PRODUCERS:
        this.httpUtils?.getProducerOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;

      case this.enumType.COINSURANCES:
        this.httpUtils?.getProducerOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;
      case this.enumType.PRODUCTS:
        this.httpUtils?.getProduct(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;
    }
  }

  formIsEmpty(): boolean {
    return Object.keys(this.form.controls).every((key) => {
      if (key === 'isActive') {
        const controlValue = this.form.controls[key].value;
        return controlValue === false || controlValue === null;
      }
      const controlValue = this.form.controls[key].value;
      return (
        controlValue === null ||
        controlValue === undefined ||
        controlValue === ''
      );
    });
  }
}
