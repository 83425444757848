import { FormGroup } from '@angular/forms';
import { Agency, Type } from '../commission-detail';

export class CoinsuranceAgency {
  idAgency?: number;
  nameAgency: string;
  active: boolean;
  companies: Company[];
  policies: Policy[];
  displayCompanies?: string;
  displayPolicies?: string;
  address: string;
  zipCode: string;
  city: string;
  province: string;
  vatNumber: string;
  fiscalCode: string;
  withdrawalTax: number;
  agency: Agency;

  constructor(
    nameAgency: string,
    active: boolean,
    companies: Company[],
    policies: Policy[],
    address: string,
    zipCode: string,
    city: string,
    province: string,
    vatNumber: string,
    fiscalCode: string,
    withdrawalTax: number,
    agency: Agency,
    idAgency?: number,
    displayCompanies?: string,
    displayPolicies?: string
  ) {
    this.idAgency = idAgency;
    this.nameAgency = nameAgency;
    this.active = active;
    this.companies = companies;
    this.policies = policies;
    this.address = address;
    this.zipCode = zipCode;
    this.city = city;
    this.province = province;
    this.vatNumber = vatNumber;
    this.fiscalCode = fiscalCode;
    this.withdrawalTax = withdrawalTax;
    this.agency = agency;
    this.displayCompanies = displayCompanies;
    this.displayPolicies = displayPolicies;
  }

  static fromJsonArray(jsonArray: CoinsuranceAgency[]): CoinsuranceAgency[] {
    return jsonArray.map((agency) => {
      const companiesCode: string[] = agency.companies?.map((company) => {
        return company.description.description;
      });
      const policiesCode: string[] = agency.policies?.map((policy) => {
        return policy.desc;
      });

      return new CoinsuranceAgency(
        agency.nameAgency,
        agency.active,
        Company.fromJsonArray(agency.companies),
        Policy.fromJsonArray(agency.policies),
        agency.address,
        agency.zipCode,
        agency.city,
        agency.province,
        agency.vatNumber,
        agency.fiscalCode,
        agency.withdrawalTax,
        agency.agency,
        agency.idAgency,
        companiesCode?.join(', '),
        policiesCode?.join(', ')
      );
    });
  }

  static fromForm(
    form: FormGroup,
    coinsuranceAgency?: CoinsuranceAgency
  ): CoinsuranceAgency {
    const formValue = form.value;

    return new CoinsuranceAgency(
      formValue.nameAgency,
      formValue.active,
      [...Company.fromJsonArray(formValue.companies)],
      [...Policy.fromJsonArray(formValue.policies)],
      formValue.address,
      formValue.zipCode,
      formValue.city,
      formValue.province,
      formValue.vatNumber,
      formValue.fiscalCode,
      formValue.withdrawalTax ?? 0,
      formValue.agency,
      coinsuranceAgency?.idAgency
    );
  }
}

export class Company {
  id: number;
  active: boolean;
  description: Type;

  constructor(id: number, active: boolean, description: Type) {
    this.id = id;

    this.active = active;
    this.description = description;
  }

  static fromJsonArray(jsonArray: Company[]): Company[] {
    return jsonArray?.map((json) => {
      return new Company(json.id, json.active, json.description);
    });
  }
}

export class Policy {
  active: boolean;
  id: number;
  desc: string;

  constructor(active: boolean, id: number, desc: string) {
    this.active = active;
    this.id = id;
    this.desc = desc;
  }

  static fromJsonArray(jsonArray: Policy[]): Policy[] {
    return jsonArray?.map((json) => {
      return new Policy(
        json.active,
        json.id,
        json.desc.toLocaleUpperCase().trim()
      );
    });
  }
}
