import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CommissionsFormService } from '../../services/commissions-table/commissions-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import {
  BANK_RECORD_PATH,
  CASH_CONTROL_PATH,
  COMMISSION_PATH,
  FIRST_NOTE_PATH,
  PLAN_ACCOUNT_PATH,
} from '../../enums/path';
import {
  CommissionTableRequest,
  Filters,
} from '../../models/commission-table/commission_table_request';
import { QueryTypeEnum, STORAGE_KEYS } from '../../enums/commission';
import { BankRecordsService } from '../../services/bank-records/bank-records.service';
import { Title } from '@angular/platform-browser';
import { getType } from '../../utils';
import { UserInfo } from '../../models/user/user-info';
import { VISIBILITY } from '../../enums/visibility';
import { waitForUserInfo } from '../../guard/visibility-guard';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MenubarModule, MenuModule, ToastModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  commission: MenuItem[] | undefined;
  backRecord: MenuItem[] | undefined;
  plansAccounts: MenuItem[] | undefined;
  cashControl: MenuItem[] | undefined;
  userInfo: UserInfo | undefined;

  constructor(
    private commissionFormService: CommissionsFormService,
    private bankRecordService: BankRecordsService,
    private router: Router,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.userInfo = await waitForUserInfo();
    this.commission = [
      ...(this.userInfo?.userFunctions?.includes(VISIBILITY.PROVVIGIONI)
        ? [
            {
              label: 'Provvigioni',

              items: [
                ...(this.userInfo?.userFunctions?.includes(
                  VISIBILITY.PROVVIGIONI_PRODUTTORE
                )
                  ? [
                      {
                        label: 'Produttori',
                        command: () => {
                          this.commissionFormService.isProducer = true;
                          sessionStorage.setItem(
                            'isProducer',
                            JSON.stringify(
                              this.commissionFormService.isProducer
                            )
                          );
                          this.commissionFormService.formComSearch.reset();
                          this.commissionFormService.$CommissionTableSub.next(
                            new CommissionTableRequest(
                              QueryTypeEnum.PRODUCERS,
                              new Filters(
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                false,
                                null
                              )
                            )
                          );
                          this.titleService.setTitle('Provvigioni Produttore');
                          this.router.navigate([COMMISSION_PATH.SEARCH], {
                            queryParams: {
                              type: getType(this.commissionFormService),
                            },
                          });
                        },
                      },
                    ]
                  : []),

                /*
              ...(this.userInfo?.userFunctions?.includes(
                VISIBILITY.PROVVIGIONI_COASSICURAZIONE
              )
                ? [
                    {
                      label: 'Coassicurazione',
                      command: () => {
                        this.commissionFormService.isProducer = false;
                        sessionStorage.setItem(
                          'isProducer',
                          JSON.stringify(this.commissionFormService.isProducer)
                        );
                        this.commissionFormService.formComSearch.reset();
                        this.commissionFormService.$CommissionTableSub.next(
                          new CommissionTableRequest(
                            QueryTypeEnum.COINSURANCES,
                            new Filters(
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              false,
                              null
                            )
                          )
                        );
                        this.titleService.setTitle('Provvigioni Coassicurazione');
                        this.router.navigate([COMMISSION_PATH.SEARCH], {
                          queryParams: {
                            type: getType(this.commissionFormService),
                          },
                        });
                      },
                    },
                  ]
                : []), */
              ],
            },
          ]
        : []),
    ];

    this.backRecord = [
      ...(this.userInfo?.userFunctions?.includes(VISIBILITY.ESTRATTICONTO)
        ? [
            {
              label: 'Estratti conto',
              items: [
                ...(this.userInfo?.userFunctions?.includes(
                  VISIBILITY.ESTRATTICONTO_PRODUTTORE
                )
                  ? [
                      {
                        label: 'Produttori',
                        command: () => {
                          this.commissionFormService.isProducer = true;
                          this.bankRecordService.bankRecordRowForm
                            .get('collaborator')
                            ?.reset();
                          sessionStorage.setItem(
                            'isProducer',
                            JSON.stringify(
                              this.commissionFormService.isProducer
                            )
                          );
                          this.bankRecordService.showInfoTableBankRecord =
                            false;
                          this.bankRecordService.showRowsTable = false;
                          this.titleService.setTitle(
                            'Estratti Conto Produttore'
                          );
                          this.router.navigate([BANK_RECORD_PATH.SEARCH], {
                            queryParams: {
                              type: getType(this.commissionFormService),
                            },
                          });
                        },
                      },
                    ]
                  : []),
                /*
              ...(this.userInfo?.userFunctions?.includes(
                VISIBILITY.ESTRATTICONTO_COASSICURAZIONE
              )
                ? [
                    {
                      label: 'Coassicurazione',
                      command: () => {
                        this.commissionFormService.isProducer = false;
                        this.bankRecordService.bankRecordRowForm
                          .get('collaborator')
                          ?.reset();
                        sessionStorage.setItem(
                          'isProducer',
                          JSON.stringify(this.commissionFormService.isProducer)
                        );
                        this.bankRecordService.showInfoTableBankRecord = false;
                        this.bankRecordService.showRowsTable = false;
                        this.titleService.setTitle(
                          'Estratti Conto Coassicurazione'
                        );
                        this.router.navigate([BANK_RECORD_PATH.SEARCH], {
                          queryParams: {
                            type: getType(this.commissionFormService),
                          },
                        });
                      },
                    },
                  ]
                : []),
                */
              ],
            },
          ]
        : []),
    ];

    this.plansAccounts = [
      ...(this.userInfo?.userFunctions?.includes(VISIBILITY.PRIMANOTA)
        ? [
            {
              label: 'Prima nota',
              items: [
                ...(this.userInfo?.userFunctions?.includes(
                  VISIBILITY.PRIMANOTA_PIANODEICONTI
                )
                  ? [
                      {
                        label: 'Piano dei conti',
                        items: [
                          ...(this.userInfo?.userFunctions?.includes(
                            VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_VISUALIZZA
                          )
                            ? [
                                {
                                  label: 'Mastri',
                                  command: () => {
                                    this.titleService.setTitle('Mastri');
                                    this.router.navigate([
                                      PLAN_ACCOUNT_PATH.MASTERS,
                                    ]);
                                  },
                                },
                              ]
                            : []),

                          ...(this.userInfo?.userFunctions?.includes(
                            VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT
                          ) ||
                          this.userInfo?.userFunctions?.includes(
                            VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT
                          )
                            ? [
                                {
                                  label: 'Conti',
                                  command: () => {
                                    this.titleService.setTitle('Conti');
                                    this.router.navigate([
                                      PLAN_ACCOUNT_PATH.ACCOUNTS,
                                    ]);
                                  },
                                },
                              ]
                            : []),

                          ...(this.userInfo?.userFunctions?.includes(
                            VISIBILITY.PRIMANOTA_PIANODEICONTI_SOTTOCONTI
                          )
                            ? [
                                {
                                  label: 'Sottoconti',
                                  command: () => {
                                    this.titleService.setTitle('Sottoconti');
                                    this.router.navigate([
                                      PLAN_ACCOUNT_PATH.SUB_ACCOUNTS,
                                    ]);
                                  },
                                },
                              ]
                            : []),
                        ],
                      },
                    ]
                  : []),
                ...(this.userInfo?.userFunctions?.includes(
                  VISIBILITY.PRIMANOTA_PRIMANOTA
                )
                  ? [
                      {
                        label: 'Prima nota',
                        command: () => {
                          this.titleService.setTitle('Prima nota');
                          this.router.navigate([FIRST_NOTE_PATH.NODE]);
                        },
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ];

    this.cashControl = [
      //TODO: set the visibility
      {
        label: 'Controllo cassa',
        command: () => {
          this.titleService.setTitle('Controllo Cassa');
          this.router.navigate([CASH_CONTROL_PATH.SEARCH]);
        },
      },
    ];
  }
}
