import { TRAFFIC_LIGHT } from '../../enums/cash-control';
import { Mode, Type } from '../commission-detail';
import { Manager } from '../plan-accounts/account/create-account-request';

export class Total {
  movements: number;
  realValue: number;

  constructor(movements: number, realValue: number) {
    this.movements = movements;
    this.realValue = realValue;
  }
}

export class Tooltip {
  carryover: number;
  movements: number;

  constructor(carryover: number, movements: number) {
    this.carryover = carryover;
    this.movements = movements;
  }
}

export class SubAccountValue {
  id: number;
  mode: Mode;
  code: string;
  agencyCode: string;
  pathNodo: string;
  movements: number;
  realValue: number;
  status: boolean;
  tooltip: Tooltip;
  date?: string;

  constructor(
    id: number,
    mode: Mode,
    code: string,
    agencyCode: string,
    pathNodo: string,
    movements: number,
    realValue: number,
    status: boolean,
    tooltip: Tooltip,
    date?: string
  ) {
    this.id = id;
    this.mode = mode;
    this.code = code;
    this.agencyCode = agencyCode;
    this.pathNodo = pathNodo;
    this.movements = movements;
    this.realValue = realValue;
    this.status = status;
    this.tooltip = tooltip;
    this.date = date;
  }
}

export class AccountCashControl {
  id: number;
  account: Type;
  carryover: number;
  accountNumber: number;
  agencyCode: string;
  pathNodo: string;
  subAccountValues: SubAccountValue[];
  total: Total;

  constructor(
    id: number,
    account: Type,
    carryover: number,
    accountNumber: number,
    agencyCode: string,
    pathNodo: string,
    subAccountValues: SubAccountValue[],
    total: Total
  ) {
    this.id = id;
    this.account = account;
    this.carryover = carryover;
    this.accountNumber = accountNumber;
    this.agencyCode = agencyCode;
    this.pathNodo = pathNodo;
    this.subAccountValues = subAccountValues;
    this.total = total;
  }
}

export class AccountingModel {
  total: number;
  dailyClosingMod20?: number;
  dailyClosingMod6?: number;
  uniqueModel?: number;
  mm?: number;
  agencyRic?: number;
  cctn?: number;
  svp?: number;
  sos?: number;

  constructor(
    total: number,
    dailyClosingMod20?: number,
    dailyClosingMod6?: number,
    uniqueModel?: number,
    mm?: number,
    agencyRic?: number,
    cctn?: number,
    svp?: number,
    sos?: number
  ) {
    this.total = total;
    this.dailyClosingMod20 = dailyClosingMod20;
    this.dailyClosingMod6 = dailyClosingMod6;
    this.uniqueModel = uniqueModel;
    this.mm = mm;
    this.agencyRic = agencyRic;
    this.cctn = cctn;
    this.svp = svp;
    this.sos = sos;
  }
}

export class DetailCashControl {
  id: number;
  date: string;
  firstNoteManager: Manager;
  auditing: boolean;
  accountingModel: AccountingModel;
  accountsCashControl: AccountCashControl[];
  next?: number;
  previous?: number;

  constructor(
    id: number,
    date: string,
    firstNoteManager: Manager,
    auditing: boolean,
    accountingModel: AccountingModel,
    accountsCashControl: AccountCashControl[],
    next?: number,
    previous?: number
  ) {
    this.id = id;
    this.date = date;
    this.firstNoteManager = firstNoteManager;
    this.auditing = auditing;
    this.accountingModel = accountingModel;
    this.accountsCashControl = accountsCashControl;
    this.next = next;
    this.previous = previous;
  }

  static fromJson(json: DetailCashControl): DetailCashControl {
    return new DetailCashControl(
      json.id,
      json.date,
      json.firstNoteManager,
      json.auditing,
      json.accountingModel,
      json.accountsCashControl.map((accountCashControl) => {
        return new AccountCashControl(
          accountCashControl.id,
          accountCashControl.account,
          accountCashControl.carryover,
          accountCashControl.accountNumber,
          accountCashControl.agencyCode,
          accountCashControl.pathNodo,
          accountCashControl.subAccountValues.map((subAccountValue) => {
            return new SubAccountValue(
              subAccountValue.id,
              subAccountValue.mode,
              subAccountValue.code,
              subAccountValue.agencyCode,
              subAccountValue.pathNodo,
              subAccountValue.movements,
              subAccountValue.realValue,
              subAccountValue.status,
              subAccountValue.tooltip,

              subAccountValue.date
            );
          }),
          accountCashControl.total
        );
      }),
      json.next,
      json.previous
    );
  }

  clone(): DetailCashControl {
    return new DetailCashControl(
      this.id,
      this.date,
      this.firstNoteManager,
      this.auditing,
      this.accountingModel,
      this.accountsCashControl.map((accountCashControl) => {
        return new AccountCashControl(
          accountCashControl.id,
          accountCashControl.account,
          accountCashControl.carryover,
          accountCashControl.accountNumber,
          accountCashControl.agencyCode,
          accountCashControl.pathNodo,
          accountCashControl.subAccountValues.map((subAccountValue) => {
            return new SubAccountValue(
              subAccountValue.id,
              subAccountValue.mode,
              subAccountValue.code,
              subAccountValue.agencyCode,
              subAccountValue.pathNodo,
              subAccountValue.movements,
              subAccountValue.realValue,
              subAccountValue.status,
              subAccountValue.tooltip,
              subAccountValue.date
            );
          }),
          accountCashControl.total
        );
      }),
      this.next,
      this.previous
    );
  }
}
