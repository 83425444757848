export enum IFR {
  IMMEDIATO = '1',
  DIFFERITO = '2',
  NO = '3',
}

export enum MOD_IFR {
  PROVVIGIONI = '1',
  PREMI = '2',
}
