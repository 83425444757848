import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const atLeastOneValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const percentageValue = control.get('percentageValue')?.value;
  const netValue = control.get('netValue')?.value;

  if (percentageValue || netValue) {
    return null;
  }

  return { atLeastOneRequired: true };
};
