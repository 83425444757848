export enum VISIBILITY {
  ESTRATTICONTO = 'estrattiConto',
  ESTRATTICONTO_PRODUTTORE = 'estrattiConto.produttore',
  ESTRATTICONTO_COASSICURAZIONE = 'estrattiConto.coassicurazione',
  PROVVIGIONI = 'provvigioni',
  PROVVIGIONI_PRODUTTORE = 'provvigioni.produttore',
  PROVVIGIONI_COASSICURAZIONE = 'provvigioni.coassicurazione',
  PRIMANOTA = 'primaNota',
  PRIMANOTA_PIANODEICONTI = 'primaNota.pianoDeiConti',
  PRIMANOTA_PIANODEICONTI_MASTRI_VISUALIZZA = 'primaNota.pianoDeiConti.mastri.visualizza',
  PRIMANOTA_PIANODEICONTI_MASTRI_AGGIORNA = 'primaNota.pianoDeiConti.mastri.aggiorna',
  PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT = 'primaNota.pianoDeiConti.conti.visualizzaTot',
  PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZAPARZ = 'primaNota.pianoDeiConti.conti.visualizzaParz',
  PRIMANOTA_PIANODEICONTI_CONTI_AGGIORNA = 'primaNota.pianoDeiConti.conti.aggiorna',
  PRIMANOTA_PIANODEICONTI_SOTTOCONTI = 'primaNota.pianoDeiConti.sottoconti',
  PRIMANOTA_PRIMANOTA = 'primaNota.primaNota',
  REPORTISTICA = 'reportistica',
}
