import { DatePipe } from '@angular/common';
import { Producer, Type } from '../commission-detail';
import { Manager } from '../plan-accounts/account/create-account-request';
import { FormGroup } from '@angular/forms';
import { Account } from '../plan-accounts/account/account';

export class FirstNote {
  manager: Manager;
  operationID: string | undefined;
  registryDate: string;
  competenceDate: string;
  account?: Account;
  mode?: Type;
  description: string;
  revenueGive: number;
  expenseGet: number;
  policyLink?: string;
  policyNumber: string;
  firstNoteManager: Manager;
  producer: Producer;
  operationIdRev: string;
  manuallyCreated?: boolean;
  manuallyUpdated?: boolean;
  isBrogliaccio?: boolean;
  isPartitaDoppia?: boolean;
  formattedRegistryDate?: string;
  formattedCompetenceDate?: string;
  sortableOperationID?: number;
  filterableRevenueGive?: string;
  filterableExpenseGet?: string;

  constructor(
    manager: Manager,
    operationID: string | undefined,
    registryDate: string,
    competenceDate: string,
    account: Account,
    mode: Type,
    description: string,
    revenueGive: number,
    expenseGet: number,
    policyNumber: string,
    firstNoteManager: Manager,
    producer: Producer,
    operationIdRev: string,
    policyLink?: string,
    manuallyCreated?: boolean,
    manuallyUpdated?: boolean,
    isBrogliaccio?: boolean,
    isPartitaDoppia?: boolean,
    formattedRegistryDate?: string,
    formattedCompetenceDate?: string,
    sortableOperationID?: number,
    filterableRevenueGive?: string,
    filterableExpenseGet?: string
  ) {
    this.manager = manager;
    this.operationID = operationID;
    this.registryDate = registryDate;
    this.competenceDate = competenceDate;
    this.account = account;
    this.mode = mode;
    this.description = description;
    this.revenueGive = revenueGive;
    this.expenseGet = expenseGet;
    this.policyNumber = policyNumber;
    this.firstNoteManager = firstNoteManager;
    this.producer = producer;
    this.operationIdRev = operationIdRev;
    this.manuallyUpdated = manuallyUpdated;
    this.isBrogliaccio = isBrogliaccio;
    this.isPartitaDoppia = isPartitaDoppia;
    this.formattedRegistryDate = formattedRegistryDate;
    this.formattedCompetenceDate = formattedCompetenceDate;
    this.policyLink = policyLink;
    this.manuallyCreated = manuallyCreated;
    this.sortableOperationID = sortableOperationID;
    this.filterableRevenueGive = filterableRevenueGive;
    this.filterableExpenseGet = filterableExpenseGet;
  }

  static createRequest(form: FormGroup): FirstNote {
    return new FirstNote(
      form.get('manager')?.value,
      undefined,
      form.get('registryDate')?.value,
      form.get('competenceDate')?.value,
      form.get('account')?.value,
      form.get('mode')?.value,
      form.get('description')?.value,
      form.get('revenue')?.value,
      form.get('expense')?.value,
      form.get('policyNumber')?.value,
      form.get('firstNoteManager')?.value,
      form.get('policyProducer')?.value,
      form.get('transactionNumberRev')?.value,
      undefined,
      undefined,
      undefined
    );
  }

  static duplicateRequest(firstNote: FirstNote): FirstNote {
    return new FirstNote(
      firstNote.manager,
      undefined,
      firstNote.registryDate,
      firstNote.competenceDate,
      firstNote.account ?? ({} as Account),
      firstNote.mode ?? ({} as Type),
      firstNote.description,
      firstNote.revenueGive,
      firstNote.expenseGet,
      firstNote.policyNumber,
      firstNote.firstNoteManager,
      firstNote.producer,
      firstNote.operationIdRev,
      firstNote.policyLink,
      true
    );
  }

  private static formatCurrency(
    value: number,
    currencyCode: string = 'EUR',
    locale: string = 'it-IT'
  ): string {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  static fromJson(data: FirstNote) {
    const datePipe = new DatePipe('en-US');
    const formattedRegistryDateString = datePipe.transform(
      data.registryDate,
      'dd/MM/yyyy',
      'UTC+2'
    );

    const formattedCompetenceDateString = datePipe.transform(
      data.competenceDate,
      'dd/MM/yyyy',
      'UTC+2'
    );

    const sortableOperationID = Number(data.operationID?.split('-')[0]);

    const filterableRevenueGive = this.formatCurrency(data.revenueGive);
    const filterableExpenseGet = this.formatCurrency(data.expenseGet);

    return new FirstNote(
      data.manager,
      data.operationID,
      data.registryDate,
      data.competenceDate,
      Account.fromJson(data.account ?? ({} as Account)),
      data.mode ?? ({} as Type),
      data.description,
      data.revenueGive,
      data.expenseGet,
      data.policyNumber,
      data.firstNoteManager,
      data.producer,
      data.operationIdRev,
      data.policyLink,
      data.manuallyCreated,
      data.manuallyUpdated,
      data.isBrogliaccio,
      data.isPartitaDoppia,
      formattedRegistryDateString?.toString(),
      formattedCompetenceDateString?.toString(),
      sortableOperationID,
      filterableRevenueGive,
      filterableExpenseGet
    );
  }

  static fromCall(datas: FirstNote[]) {
    return datas?.map((data: FirstNote) => FirstNote.fromJson(data));
  }
}
