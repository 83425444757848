import { DatePipe } from '@angular/common';
import { Agency, Type } from '../commission-detail';
import { FormGroup } from '@angular/forms';
import { UserInfo } from '../user/user-info';
export class Receivers {
  id: number;
  email: string;
  type: Type;

  constructor(email: string, type: Type, id: number) {
    this.id = id;
    this.email = email;
    this.type = type;
  }
}

export class Report {
  id: number;
  reportName: Type;
  from: string;
  to: string;
  exportingDate: Type;
  frequency: Type;
  receivers: Receivers[];
  agency?: Agency;
  displayInterval?: string;
  constructor(
    id: number,
    reportName: Type,
    from: string,
    to: string,
    exportingDate: Type,
    frequency: Type,
    receivers: Receivers[],
    agency?: Agency,
    displayInterval?: string
  ) {
    this.id = id;
    this.reportName = reportName;
    this.from = from;
    this.to = to;
    this.exportingDate = exportingDate;
    this.frequency = frequency;
    this.receivers = receivers;
    this.agency = agency;
    this.displayInterval = displayInterval;
  }

  static fromJson(json: Report): Report {
    const datePipe = new DatePipe('en-US');
    const formattedFrom = datePipe.transform(json.from, 'dd/MM/yyyy', 'UTC+1');
    const formattedTo = datePipe.transform(json.to, 'dd/MM/yyyy', 'UTC+1');
    const displayInterval = `${formattedFrom} - ${formattedTo}`;

    return new Report(
      json.id,
      json.reportName,
      json.from,
      json.to,
      json.exportingDate,
      json.frequency,
      json.receivers,
      json.agency,
      displayInterval
    );
  }

  static fromData(data: Report[]): Report[] {
    return data.map((report) => Report.fromJson(report));
  }

  static createRequestFromForm(form: FormGroup, userInfo: UserInfo): Report {
    const from = form.get('dates')?.value[0];
    const to = form.get('dates')?.value[1];

    return new Report(
      form.get('id')?.value,
      form.get('reportName')?.value,
      from,
      to,
      form.get('exportingDate')?.value,
      form.get('frequency')?.value,
      form.get('receivers')?.value,
      form.get('agency')?.value ??
        new Agency(userInfo.node, userInfo.agencyDescription)
    );
  }
}
