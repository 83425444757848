import { Agency } from '../../commission-detail';
import { Manager } from '../account/create-account-request';

export class Type {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }

  static fromJson(json: any): Type {
    return new Type(json.code, json.description);
  }
}

export class LinkedAccount {
  name: string;
  code: string;
  type: Type;
  pathNodo: string;
  value: number;
  carry: number;
  carryDate: string;
  firstNoteManager: Manager;
  status: boolean;
  agency: Agency;
  agencyCode: string;
  insertDate: string;
  lastUpdate: string;
  isNew?: boolean;
  displayName?: string;
  isDisabled?: boolean;

  constructor(
    name: string,
    code: string,
    type: Type,
    pathNodo: string,
    value: number,
    carry: number,
    carryDate: string,
    firstNoteManager: Manager,
    status: boolean,
    agency: Agency,
    agencyCode: string,
    insertDate: string,
    lastUpdate: string,
    isNew?: boolean,
    displayName?: string,
    isDisabled?: boolean
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.pathNodo = pathNodo;
    this.value = value;
    this.carry = carry;
    this.carryDate = carryDate;
    this.firstNoteManager = firstNoteManager;
    this.status = status;
    this.agency = agency;
    this.agencyCode = agencyCode;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
    this.isNew = isNew;
    this.displayName = displayName;
    this.isDisabled = isDisabled
  }

  static fromJson(json: any): LinkedAccount {
    return new LinkedAccount(
      json.name,
      json.code,
      new Type(json.type.code, json.type.description),
      json.pathNodo,
      json.value,
      json.carry,
      json.carryDate,
      json.firstNoteManager,
      json.status,
      new Agency(json.agency.code, json.agency.description),
      json.agencyCode,
      json.insertDate,
      json.lastUpdate,
      json.isNew,
      `${json.code} - ${json.name} - ${json.firstNoteManager.description}`
    );
  }

  static fromCall(json: any): LinkedAccount[] {
    return json.map((linkedAccount: any) => {
      return LinkedAccount.fromJson(linkedAccount);
    });
  }
}

export class Master {
  name: string;
  code: number;
  type: Type;
  value: number;
  linkedAccounts: LinkedAccount[];
  pathNodo: string;
  agency: Agency;
  agencyCode: string;
  insertDate: string;
  lastUpdate: string;
  id?: string;

  constructor(
    name: string,
    code: number,
    type: Type,
    value: number,
    linkedAccounts: LinkedAccount[],
    insertDate: string,
    lastUpdate: string,
    pathNodo: string,
    agency: Agency,
    agencyCode: string
  ) {
    this.name = name;
    this.code = code;
    this.pathNodo = pathNodo;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
    this.type = type;
    this.value = value;
    this.linkedAccounts = linkedAccounts;
    this.agencyCode = agencyCode;
    this.agency = agency;
    this.id = `${this.code}${this.pathNodo}`;
  }

  static fromJson(json: any): Master {
    return new Master(
      json.name,
      json.code,
      json.type,
      json.value,
      json.linkedAccounts ?? [],
      json.insertDate,
      json.lastUpdate,
      json.pathNodo,
      json.agency,
      json.agencyCode
    );
  }

  static fromCall(json: any): Master[] {
    return json.map((master: any) => {
      return Master.fromJson(master);
    });
  }
}
