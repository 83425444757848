import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SubAccount } from '../../../models/plan-accounts/sub-account/sub-account';
import { Modal } from 'bootstrap';
import { UserInfo } from '../../../models/user/user-info';
import { MessageService } from 'primeng/api';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { ModalAddSubAccountComponent } from '../../../components/modals/modal-add-sub-account/modal-add-sub-account.component';
import { splitAgencyCode } from '../../../constants/general';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalService } from '../../../services/modal/modal.service';
import { Subscription } from 'rxjs';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
@Component({
  selector: 'app-sub-accounts-screen',
  standalone: true,
  imports: [
    CustomCurrencyPipe,
    CommonModule,
    TableModule,
    ModalAddSubAccountComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './sub-accounts-screen.component.html',
  styleUrl: './sub-accounts-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SubAccountsScreenComponent implements OnInit, OnDestroy {
  subAccounts: SubAccount[] | undefined;
  selecteddSubAccounts: SubAccount | undefined;
  userInfo: UserInfo | undefined;
  modifiedSubAccount: SubAccount | undefined;
  modalAdd: any | undefined;
  modalRefAdd: Modal | undefined;
  subAccountsSub$: Subscription | undefined;
  isLoading = true;

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService
  ) {
    if (sessionStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(sessionStorage.getItem('userInfo')!)
      );
    }
  }
  ngOnDestroy(): void {
    this.subAccountsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeModals();
    this.subAccountsSub$ = this.planOfAccountsService.subAccountsSub.subscribe({
      next: (subAccounts) => {
        this.subAccounts = subAccounts;
      },
    });
    if (this.userInfo) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .getSubAccount(
          this.userInfo.agencyDescription.split(splitAgencyCode)[0]
        )
        .subscribe({
          next: (response) => {
            this.isLoading = false;

            this.planOfAccountsService.subAccountsSub.next(
              SubAccount.fromCall(response['subaccounts'])
            );
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  onModify(subAccount: SubAccount) {
    this.modifiedSubAccount = subAccount;
    this.modalRefAdd?.show();
  }

  initializeModals(): void {
    this.modalAdd = document.getElementById('modalAddSubAccount');

    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });
    }
  }
}
