<div class="row">
  <div class="d-flex align-items-center my-4 p-0 go-back">
    <div (click)="goBack()">
      <i class="pi pi-arrow-left me-2 icon-list"> </i>
      <span class="label">Indietro</span>
    </div>
    <div class="hidden"></div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Descrizione:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.statementDes }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Polizza:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.numPoli }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Cliente:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.clienteName }}
      {{ this.rowDetail?.clienteSurname ?? "" }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Data registrazione:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.collectionDate | date : "dd/MM/yyyy" : "UTC+2" }}
    </div>
  </div>
  <div
    class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    *ngIf="this.commissionService.isProducer"
  >
    <div class="label">Produttore:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.codPdre }}
    </div>
  </div>
  <div
    class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    *ngIf="!this.commissionService.isProducer"
  >
    <div class="label">Compagnia coassicuratrice:</div>
    <div class="ms-2 value">{{ this.rowDetail?.desCoas }}</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Ramo:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.branchPoli }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Causale:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.reason }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Premio lordo:</div>
    <div class="ms-2 value">{{ this.rowDetail?.gross ?? 0 }}€</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Premio netto:</div>
    <div class="ms-2 value">{{ this.rowDetail?.net ?? 0 }}€</div>
  </div>
  <div
    class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    *ngIf="this.commissionService.isProducer"
  >
    <div class="label">Provvigioni di agenzia:</div>
    <div class="ms-2 value">{{ this.rowDetail?.provvAgency ?? 0 }}€</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Tot. Provvigioni:</div>
    <div class="ms-2 value">{{ this.rowDetail?.provvCalcolata }}</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Modifica manuale:</div>
    <div class="ms-2 value">
      <div class="ms-2 value">
        {{ this.rowDetail?.manuallyUpdated ? "Si" : "No" }}
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Ritenuta fiscale:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.witholdingTax?.value ?? 0 }}€
    </div>
  </div>
  <div
    class="col-lg-6 col-md-12 d-flex align-items-center mb-3"
    *ngIf="this.commissionService.isProducer"
  >
    <div class="label">IFR imm.:</div>
    <div class="ms-2 value">{{ this.rowDetail?.ifr?.value ?? 0 }}€</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Sospesi:</div>
    <div class="ms-2 value">{{ this.rowDetail?.totSospeso ?? 0 }}€</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Recupero sospesi:</div>
    <div class="ms-2 value">{{ this.rowDetail?.recuperoSosp ?? 0 }}€</div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Data inserimento:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.insertDate | date : "dd/MM/yyyy" : "UTC+2" }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Data di modifica:</div>
    <div class="ms-2 value">
      {{ this.rowDetail?.lastUpdateDate | date : "dd/MM/yyyy" : "UTC+2" }}
    </div>
  </div>
</div>

<hr class="my-4" />
<p-table
  [tableStyle]="{ 'min-width': '50rem' }"
  [value]="this.rowDetail?.details || []"
  styleClass="table-commission"
  dataKey="id"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Tipo Provvigione</th>
      <th>Modalità</th>
      <th>Perc. (%)</th>
      <th>Valore (€)</th>
    </tr>
  </ng-template>

  <ng-template let-detail pTemplate="body">
    <tr>
      <td>{{ detail.commissionType.description }}</td>
      <td>{{ detail.commissionMode.description }}</td>
      <td>{{ detail.commissionPerc }}</td>
      <td>{{ detail.rowValue }}</td>
    </tr></ng-template
  >
</p-table>
