import { TaxInfo } from './bank-record-detail-row-response';

export class Recap {
  totManualMovements: Totals;
  totProvisions: Totals;
  provisionsBalance: Totals;
  totWitholdingTax: Totals;
  totBankStatementPdre: Totals;
  totSospesi: Totals;
  totRecuperoSospesi: Totals;
  totIFRImmediato?: Totals;
  totBalance: Totals;

  constructor(
    totManualMovements: Totals,
    totProvisions: Totals,
    provisionsBalance: Totals,
    totWitholdingTax: Totals,
    totBankStatementPdre: Totals,
    totSospesi: Totals,
    totRecuperoSospesi: Totals,
    totIFRImmediato: Totals,
    totBalance: Totals
  ) {
    this.totManualMovements = totManualMovements;
    this.totProvisions = totProvisions;
    this.provisionsBalance = provisionsBalance;
    this.totWitholdingTax = totWitholdingTax;
    this.totBankStatementPdre = totBankStatementPdre;
    this.totSospesi = totSospesi;
    this.totRecuperoSospesi = totRecuperoSospesi;
    this.totIFRImmediato = totIFRImmediato;
    this.totBalance = totBalance;
  }
}

export class Totals {
  positiveSum: number;
  negativeSum: number;

  constructor(positiveSum: number, negativeSum: number) {
    this.positiveSum = positiveSum;
    this.negativeSum = negativeSum;
  }
}

export class BankRecDetailResp {
  id: number;
  consolidated: boolean;
  registrationDate: string;
  codCoas: string;
  desCoas: string;
  codCollaborator: string;
  collaboratorName: string;
  collaboratorSurname: string;
  totStatementLordo: number;
  totStatementNetto: number;
  totProvvAge: number;
  IFRType: TaxInfo;
  totIFR: number;
  totIFRAmassed: number;
  totWitholdingTax: number;
  totRecuperoSosp: number;
  totGiven: number;
  consolidatingUser: string;
  lastUpdateUser: string;
  lastUpdateDate: string;
  signedDocument: boolean;
  recap: Recap;
  nextBankStatementId?: number | null;
  prevBankStatementId?: number | null;

  constructor(
    id: number,
    consolidated: boolean,
    registrationDate: string,
    codCoas: string,
    desCoas: string,
    codCollaborator: string,
    collaboratorName: string,
    collaboratorSurname: string,
    totStatementLordo: number,
    totStatementNetto: number,
    totProvvAge: number,
    IFRType: TaxInfo,
    totIFR: number,
    totIFRAmassed: number,
    totWitholdingTax: number,
    totRecuperoSosp: number,
    totGiven: number,
    consolidatingUser: string,
    lastUpdateUser: string,
    lastUpdateDate: string,
    signedDocument: boolean,
    recap: Recap,
    nextBankStatementId?: number | null,
    prevBankStatementId?: number | null
  ) {
    this.id = id;
    this.nextBankStatementId = nextBankStatementId;
    this.prevBankStatementId = prevBankStatementId;
    this.consolidated = consolidated;
    this.registrationDate = registrationDate;
    this.codCoas = codCoas;
    this.desCoas = desCoas;
    this.codCollaborator = codCollaborator;
    this.collaboratorName = collaboratorName;
    this.collaboratorSurname = collaboratorSurname;
    this.totStatementLordo = totStatementLordo;
    this.totStatementNetto = totStatementNetto;
    this.totProvvAge = totProvvAge;
    this.IFRType = IFRType;
    this.totIFR = totIFR;
    this.totIFRAmassed = totIFRAmassed;
    this.totWitholdingTax = totWitholdingTax;
    this.totRecuperoSosp = totRecuperoSosp;
    this.totGiven = totGiven;
    this.consolidatingUser = consolidatingUser;
    this.lastUpdateUser = lastUpdateUser;
    this.lastUpdateDate = lastUpdateDate;
    this.signedDocument = signedDocument;
    this.recap = recap;
  }

  static fromJson(json: BankRecDetailResp): BankRecDetailResp {
    return new BankRecDetailResp(
      json.id,
      json.consolidated,

      json.registrationDate,
      json.codCoas,
      json.desCoas,
      json.codCollaborator,
      json.collaboratorName,
      json.collaboratorSurname,
      json.totStatementLordo,
      json.totStatementNetto,
      json.totProvvAge,
      json.IFRType,
      json.totIFR,
      json.totIFRAmassed,
      json.totWitholdingTax,
      json.totRecuperoSosp,
      json.totGiven,
      json.consolidatingUser,
      json.lastUpdateUser,
      json.lastUpdateDate,
      json.signedDocument,
      json.recap,
      json.nextBankStatementId,
      json.prevBankStatementId
    );
  }
}
