<div class="text-end mt-3">
  <div *ngIf="isEditable">
    <!-- <button
      class="btn btn-danger mb-2 me-2"
      (click)="onDeleteMultipleAccounts()"
      [disabled]="this.selectedAccounts.length === 0"
    >
      <strong>ELIMINA CONTI</strong>
    </button> -->

    <button class="btn btn-primary mb-2" (click)="createNewAccount()">
      <strong>CREA NUOVO</strong>
    </button>
  </div>

  <p-table
    [value]="accounts ?? []"
    dataKey="accountNumber"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass=" table-commission text-center"
    [(selection)]="selectedAccounts"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem" *ngIf="isEditable">
          <p-tableHeaderCheckbox />
        </th>
        <th style="width: auto" *ngIf="isEditable"></th>
        <!-- <th style="width: auto"></th> -->

        <th style="width: auto" *ngIf="isEditable"></th>
        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code" style="width: auto">
          Codice <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="type.description" style="width: auto">
          Natura <p-sortIcon field="type.description"></p-sortIcon>
        </th>
        <th pSortableColumn="ledger.description" style="width: auto">
          Mastro <p-sortIcon field="ledger.description"></p-sortIcon>
        </th>
        <th pSortableColumn="value" style="width: auto">
          Importo totale <p-sortIcon field="value"></p-sortIcon>
        </th>
        <th pSortableColumn="carry" style="width: auto">
          Riporto <p-sortIcon field="carry"></p-sortIcon>
        </th>
        <th pSortableColumn="showDate" style="width: auto">
          Data Riporto <p-sortIcon field="showDate"></p-sortIcon>
        </th>
        <th pSortableColumn="firstNoteManager.description" style="width: auto">
          Gestore Prima Nota
          <p-sortIcon field="firstNoteManager.description"></p-sortIcon>
        </th>
        <th pSortableColumn="status" style="width: auto">
          Stato <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th pSortableColumn="linkedSubAccounts" style="width: auto">
          Sottoconti collegati
          <p-sortIcon field="linkedSubAccounts"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agencyCode"
          style="width: auto"
        >
          Codice Agenzia
          <p-sortIcon field="agencyCode"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngIf="isEditable"></th>
        <th *ngIf="isEditable"></th>
        <!-- <th></th> -->
        <th *ngIf="isEditable"></th>
        <th>
          <p-columnFilter type="text" field="name" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="code" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="type.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="ledger.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter type="text" field="value" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="carry" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="showDate" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="firstNoteManager.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter type="text" field="showStatus" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="linkedSubAccounts.length"
            matchMode="contains"
          />
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter type="text" field="agencyCode" matchMode="contains" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account let-i="rowIndex">
      <tr>
        <td *ngIf="isEditable">
          <p-tableCheckbox [value]="account" />
        </td>
        <td *ngIf="isEditable">
          <i
            class="pi pi-pen-to-square icon col-md-6"
            (click)="onModify(account)"
            title="Modifica"
          ></i>
        </td>
        <!-- <td>
          <i
            title="Elimina bozza"
            class="pi pi-trash icon col-md-6"
            (click)="onDelete(account)"
            title="Elimina"
          ></i>
        </td> -->
        <td *ngIf="isEditable">
          <p-inputSwitch
            class="col-12"
            (onChange)="onSwitch($event, account)"
            [(ngModel)]="account.status"
          />
        </td>
        <td class="text-center">
          {{ account.name }}
        </td>
        <td class="text-center">
          {{ account.code }}
        </td>
        <td class="text-center">
          {{ account.type.description }}
        </td>
        <td class="text-center">{{ account.ledger.description }}</td>
        <td class="text-center">{{ account.value | customCurrency }}</td>
        <td class="text-center">{{ account.carry | customCurrency }}</td>
        <td class="text-center">
          {{ account.showDate }}
        </td>
        <td class="text-center">{{ account.firstNoteManager.description }}</td>
        <td class="text-center">
          {{ account.showStatus }}
        </td>
        <td class="text-center">
          <a class="underline" (click)="this.showAccountDetail(account)">
            {{ account.linkedSubAccounts.length ?? 0 }}
          </a>
        </td>
        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ account.agencyCode }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-modal-add-account
  [account]="modifiedAccount"
  [modalRef]="this.modalRefAdd"
></app-modal-add-account>
<app-modal-detail-account
  [account]="this.detailAccount"
  [modalRef]="this.modalRefDetail"
></app-modal-detail-account>
