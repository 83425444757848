import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommissionTableComponent } from './components/tables/commission-table/commission-table.component';
import { CommissionsSearchFormComponent } from './components/forms/commissions-search-form/commissions-search-form.component';
import { CommissionsFormService } from './services/commissions-table/commissions-form.service';
import { CommonModule } from '@angular/common';
import { EditCommisionFormComponent } from './components/forms/commission/edit-commision-form/edit-commision-form.component';
import { DetailScreenComponent } from './screens/commissions/detail-screen-commission/detail-screen.component';
import { HeaderComponent } from './components/header/header.component';
import { AssociateDissociateComponent } from './components/associate-dissociate/associate-dissociate.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import {
  DAY_NAMES,
  DAY_NAMES_MIN,
  DAY_NAMES_SHORT,
  MONTH_NAMES,
  MONTH_NAMES_SHORT,
} from './constants/calendar';
import { ErrorModalComponent } from './components/shared/error-modal/error-modal.component';
import { ToastModule } from 'primeng/toast';
import { ModalComponent } from './components/shared/custom-modal/partial-error-modal.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    HeaderComponent,
    CommonModule,
    RouterOutlet,
    CommissionTableComponent,
    CommissionsSearchFormComponent,
    EditCommisionFormComponent,
    DetailScreenComponent,
    AssociateDissociateComponent,
    ErrorModalComponent,
    ModalComponent,
    ToastModule,
    ModalComponent,
  ],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  showAssociate: boolean = false;
  constructor(
    public commissionTableService: CommissionsFormService,
    private configService: PrimeNGConfig
  ) {}
  ngOnInit(): void {
    this.configService.setTranslation({
      dayNames: DAY_NAMES,
      dayNamesMin: DAY_NAMES_MIN,
      dayNamesShort: DAY_NAMES_SHORT,
      monthNames: MONTH_NAMES,
      monthNamesShort: MONTH_NAMES_SHORT,
    });
  }
}
