import { TRAFFIC_LIGHT } from '../../enums/cash-control';
import { DatePipe } from '@angular/common';
import { Manager } from '../plan-accounts/account/create-account-request';

export class AgencyCashControl {
  id: string;
  name: string;
  date: string;
  auditing: boolean;
  node: Manager;
  showDate?: string;

  constructor(
    id: string,
    name: string,
    date: string,
    auditing: boolean,
    node: Manager,
    showDate?: string
  ) {
    this.id = id;
    this.name = name;
    this.date = date;
    this.auditing = auditing;
    this.showDate = showDate;
    this.node = node;
  }

  static fromJson(json: AgencyCashControl): AgencyCashControl {
    const datePipe = new DatePipe('en-US');
    const formattedRegistryDateString = datePipe.transform(
      json.date,
      'dd/MM/yyyy',
      'UTC+1'
    );
    return new AgencyCashControl(
      json.id,
      json.name,
      json.date,
      json.auditing,
      json.node,
      formattedRegistryDateString?.toString()
    );
  }

  static fromJsonArray(jsonArray: AgencyCashControl[]): AgencyCashControl[] {
    return jsonArray.map((json) => this.fromJson(json));
  }
}
