import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { Account } from '../../../models/plan-accounts/account/account';
import { UserInfo } from '../../../models/user/user-info';
import { MessageService } from 'primeng/api';
import { Modal } from 'bootstrap';
import { ModalAddAccountComponent } from '../../../components/modals/modal-add-account/modal-add-account.component';
import { ModalDetailAccountComponent } from '../../../components/modals/modal-detail-account/modal-detail-account.component';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { splitAgencyCode } from '../../../constants/general';
import { ModalService } from '../../../services/modal/modal.service';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { concatMap, Subscription } from 'rxjs';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { LinkedSubAccountsService } from '../../../services/linked-sub-accounts/linked-accounts.service';
import { VISIBILITY } from '../../../enums/visibility';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Agency } from '../../../models/commission-detail';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TYPE } from '../../../enums/path-of-accounts';
import { getDirty } from '../../../utils';
@Component({
  selector: 'app-accounts-screen',
  standalone: true,
  imports: [
    CustomCurrencyPipe,
    CommonModule,
    TableModule,
    ModalAddAccountComponent,
    ModalDetailAccountComponent,
    InputSwitchModule,
    FormsModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  templateUrl: './accounts-screen.component.html',
  styleUrl: './accounts-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AccountsScreenComponent implements OnInit, OnDestroy {
  accounts: Account[] | undefined;
  selectedAccounts: Account[] = [];
  form: FormGroup;
  userInfo: UserInfo | undefined;
  modalAdd: any | undefined;
  modalDetail: any | undefined;
  modifiedAccount: Account | undefined;
  detailAccount: Account | undefined;
  modalRefAdd: Modal | undefined;
  modalRefDetail: Modal | undefined;
  accountsSub$: Subscription | undefined;
  isLoading = false;
  isEditable: boolean = false;
  showTable: boolean = false;
  agenciesOptions: Agency[] | undefined;

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService,
    private linkedSubAccountsService: LinkedSubAccountsService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }

    this.form = new FormGroup({
      agency: new FormControl(null, [Validators.required]),
    });
  }
  ngOnDestroy(): void {
    this.accountsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.userInfo) {
      return;
    }
    this.isEditable = this.userInfo.userFunctions.includes(
      VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_AGGIORNA
    );
    this.initializeModals();
    this.accountsSub$ = this.planOfAccountsService.accountsSub.subscribe({
      next: (next) => {
        const accounts = Account.fromCall(next ?? []);
        this.accounts = accounts;
      },
    });

    if (!this.userInfo.isDirectionalUser) {
      this.loadingState();
    } else {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts.getAllInfos(TYPE.AGENCY).subscribe({
        next: (response) => {
          this.agenciesOptions = response['types'];
          this.isLoading = false;
        },
        error: (error) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loadingState(this.form.value.agency);
  }

  onAgencyChange(event: DropdownChangeEvent): void {
    this.planOfAccountsService.accountAgency = event.value;
  }

  loadingState(agency?: Agency): void {
    this.isLoading = true;

    this.httpUtilisPlanOfAccounts
      .getAccounts(
        this.userInfo?.agencyDescription.split(splitAgencyCode)[0]!,
        undefined,
        undefined,
        agency
      )
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.planOfAccountsService.accountsSub.next(response['accounts']);
          this.showTable = true;
        },
        error: (error) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
  }

  createNewAccount() {
    this.modifiedAccount = undefined;

    this.modalRefAdd?.show();
  }

  onModify(account: Account) {
    this.modifiedAccount = account;
    this.modalRefAdd?.show();
  }

  onDelete(account: Account) {
    if (this.userInfo) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .deleteAccount([account])
        .pipe(
          concatMap((_deleteResponse) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Conto eliminato con successo',
              key: 'br',
            });
            return this.httpUtilisPlanOfAccounts.getAccounts(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
              undefined,
              undefined,
              this.planOfAccountsService.accountAgency
            );
          })
        )
        .subscribe({
          next: (getAccountResponse) => {
            this.planOfAccountsService.accountsSub.next(
              getAccountResponse['accounts']
            );
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  onDeleteMultipleAccounts(): void {
    if (this.selectedAccounts.length > 0) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .deleteAccount(this.selectedAccounts)
        .pipe(
          concatMap((_deleteResponse) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Conti eliminati con successo',
              key: 'br',
            });
            return this.httpUtilisPlanOfAccounts.getAccounts(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
              undefined,
              undefined,
              this.planOfAccountsService.accountAgency
            );
          })
        )
        .subscribe({
          next: (getAccountResponse) => {
            this.planOfAccountsService.accountsSub.next(
              getAccountResponse['accounts']
            );
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  showAccountDetail(account: Account) {
    this.detailAccount = account;
    this.linkedSubAccountsService.linkedSubAccountsSubject.next([
      ...this.detailAccount.linkedSubAccounts,
    ]);
    this.modalRefDetail?.show();
  }

  onSwitch(event: InputSwitchChangeEvent, account: Account) {
    account.status = event.checked;
    account.showStatus = event.checked ? 'Abilitato' : 'Disabilitato';

    this.httpUtilisPlanOfAccounts.modifyAccount(account).subscribe({
      next: (_modifyResponse) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Successo',
          detail: 'Stato modificato con successo',
          key: 'br',
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'br',
          detail: 'Impossibile modificare lo stato',
        });
        account.status = !event.checked;
        account.showStatus = event.checked ? 'Abilitato' : 'Disabilitato';

        //this.modalService.showError(error);
      },
    });
  }

  initializeModals(): void {
    console.log('initializeModals');
    this.modalAdd = document.getElementById('modalAddAccount');
    this.modalDetail = document.getElementById('modalDetailAccount');

    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });
    }
    if (this.modalDetail) {
      this.modalRefDetail = new Modal(this.modalDetail, {
        keyboard: false,
        backdrop: 'static',
      });
    }
  }

  getDirty = getDirty;
}
