import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { CommissionsFormService } from '../../../../services/commissions-table/commissions-form.service';
import { CATEGORY_OPTIONS } from '../../../../constants/test';

@Component({
  selector: 'app-edit-commision-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
  ],
  templateUrl: './edit-commision-form.component.html',
  styleUrl: './edit-commision-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EditCommisionFormComponent {
  form: FormGroup;
  categoryOptions: string[] = CATEGORY_OPTIONS;

  constructor(private commissionFormService: CommissionsFormService) {
    this.form = new FormGroup({
      producer: new FormControl(
        this.commissionFormService.selectedCommission?.producer,
        []
      ),
      category: new FormControl(
        this.commissionFormService.selectedCommission?.category,
        []
      ),
      product: new FormControl(
        this.commissionFormService.selectedCommission?.product,
        []
      ),
      validityStartDate: new FormControl(
        this.commissionFormService.selectedCommission?.validityStartDate,
        []
      ),
      validityEndDate: new FormControl(
        this.commissionFormService.selectedCommission?.validityEndDate,
        []
      ),
      desciption: new FormControl('', []),
    });
  }

  printForm() {
    console.log(this.form.value);
  }

  getBack() {
    this.commissionFormService.showInfoTableCommission = true;
  }
}
