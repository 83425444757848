import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-landing-screen',
  standalone: true,
  imports: [],
  templateUrl: './landing-screen.component.html',
  styleUrl: './landing-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LandingScreenComponent implements OnInit {
  constructor(private serviceTitle: Title) {}

  ngOnInit(): void {
    this.serviceTitle.setTitle('Home | Gestionale Agenzia');
  }
}
