import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { getDirty, handleError } from '../../../../utils';
import { Agency, Type } from '../../../../models/commission-detail';
import { HttpUtilisCommissions } from '../../../../network/http-utilis-commissions';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../../services/modal/modal.service';
import { CoinsuranceAgency } from '../../../../models/census/census-coinsurance';
import { UserInfo } from '../../../../models/user/user-info';
import { Subscription } from 'rxjs';
import { CensusCoinsuranceService } from '../../../../services/census-coinsurance/census-coinsurance.service';
import { HttpUtilisCoinCensus } from '../../../../network/http-coinsurance-census';
import { MessageService } from 'primeng/api';
import { TYPE } from '../../../../enums/path-of-accounts';

@Component({
  selector: 'app-add-modify-coinsurance-census-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    InputTextModule,
    InputSwitchModule,
  ],
  templateUrl: './add-modify-coinsurance-census-modal.component.html',
  styleUrl: './add-modify-coinsurance-census-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddModifyCoinsuranceCensusModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() coinsuranceAgency: CoinsuranceAgency | undefined;
  modalTitle: string = 'Crea Agenzia Coassicurazione';
  isLoading: boolean = false;
  form: FormGroup = new FormGroup({});
  isModifing: boolean = false;
  visible: boolean = false;
  filteredValues: Agency[] = [];
  filteredCompanies: Type[] = [];
  userInfos: UserInfo | undefined;
  visibiltyModalSub$: Subscription | undefined;

  constructor(
    public httpUtilsCom: HttpUtilisCommissions,
    public httpUtilisCoinAgency: HttpUtilisCoinCensus,
    public modalService: ModalService,
    public messageService: MessageService,
    public censusService: CensusCoinsuranceService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfos = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
      this.initForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coinsuranceAgency'].currentValue === undefined) {
      this.resetForm();
    } else {
      this.populateForm();
    }
  }
  ngOnInit(): void {
    this.form.patchValue({ active: true });
    this.visibiltyModalSub$ =
      this.censusService.visibilyAddModifyModal.subscribe({
        next: (visible) => {
          this.visible = visible;
        },
      });
  }
  ngOnDestroy(): void {
    this.visibiltyModalSub$?.unsubscribe();
  }

  public onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const coinsuranceAgency: CoinsuranceAgency = CoinsuranceAgency.fromForm(
      this.form,
      this.coinsuranceAgency
    );

    this.isLoading = true;
    this.httpUtilisCoinAgency
      .addOrModifyCoinAgency(coinsuranceAgency)
      .subscribe({
        next: (_response) => {
          this.isLoading = false;
          this.visible = false;
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: 'Successo',
            detail: `Agenzia di coassicurazione ${
              this.isModifing ? 'modificata' : 'creata'
            } con successo`,
          });

          this.resetForm();

          this.censusService.updateCollaborators.next();
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          handleError(error, this.modalService, this.messageService);
        },
      });
  }

  public onDialogHide(): void {
    if (!this.isModifing) {
      return;
    }

    this.populateForm();
  }

  public filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  public filterValuesCompanyConsurance(
    $event: AutoCompleteCompleteEvent
  ): void {
    let query = $event.query;

    this.httpUtilsCom?.getAllInfos(TYPE.COMPANY_COINSURANCE, query).subscribe({
      next: (response) => {
        this.filteredCompanies = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  private initForm(): void {
    this.form = new FormGroup({
      nameAgency: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      zipCode: new FormControl(null),
      city: new FormControl(null),
      province: new FormControl(null),
      active: new FormControl(true),
      vatNumber: new FormControl(null),
      fiscalCode: new FormControl(null),
      withdrawalTax: new FormControl(0),
      agency: new FormControl(null, [Validators.required]),
      companies: new FormArray([]),
      policies: new FormArray([]),
    });
  }

  private resetForm(): void {
    this.modalTitle = 'Crea Agenzia Coassicurazione';
    this.isModifing = false;

    this.form.reset({ active: true, withdrawalTax: 0 });
    this.getCompaniesArray().clear();
    this.getPoliciesArray().clear();

    if (this.userInfos?.isDirectionalUser) {
      this.form.get('agency')?.enable();
    } else {
      this.form.get('agency')?.disable();
    }
  }
  private populateForm(): void {
    if (
      this.coinsuranceAgency === null ||
      this.coinsuranceAgency === undefined
    ) {
      return;
    }

    this.modalTitle = 'Modifica Agenzia Coassicurazione';
    this.isModifing = true;

    this.form.patchValue({
      nameAgency: this.coinsuranceAgency.nameAgency,
      address: this.coinsuranceAgency.address,
      zipCode: this.coinsuranceAgency.zipCode,
      city: this.coinsuranceAgency.city,
      province: this.coinsuranceAgency.province,
      active: this.coinsuranceAgency.active,
      vatNumber: this.coinsuranceAgency.vatNumber,
      fiscalCode: this.coinsuranceAgency.fiscalCode,
      withdrawalTax: this.coinsuranceAgency.withdrawalTax,
      agency: this.coinsuranceAgency.agency,
    });

    const companies = this.form.get('companies') as FormArray;
    const policies = this.form.get('policies') as FormArray;
    companies.clear();
    policies.clear();

    this.coinsuranceAgency.companies?.forEach((company) => {
      companies.push(
        new FormGroup({
          id: new FormControl(company.id),
          description: new FormControl(company.description),
          active: new FormControl(company.active),
        })
      );
    });

    this.coinsuranceAgency.policies?.forEach((policy) => {
      policies.push(
        new FormGroup({
          id: new FormControl(policy.id),
          active: new FormControl(policy.active),
          desc: new FormControl(policy.desc),
        })
      );
    });
  }

  public onRemoveCompany(rowIndex: number) {
    let companies = this.form.get('companies') as FormArray;
    companies.removeAt(rowIndex);
  }

  public onRemovePolicy(rowIndex: number) {
    let companies = this.form.get('policies') as FormArray;
    companies.removeAt(rowIndex);
  }

  public addCompany(): void {
    let companies = this.form.get('companies') as FormArray;
    companies.push(
      new FormGroup({
        id: new FormControl(null),
        description: new FormControl(null, [Validators.required]),
        active: new FormControl(true, Validators.required),
      })
    );
  }

  public addPolicy(): void {
    let policies = this.form.get('policies') as FormArray;

    policies.push(
      new FormGroup({
        id: new FormControl(null),
        active: new FormControl(true, Validators.required),
        desc: new FormControl(null, Validators.required),
      })
    );
  }

  public getCompaniesArray(): FormArray {
    return this.form.get('companies') as FormArray;
  }

  public getPoliciesArray(): FormArray {
    return this.form.get('policies') as FormArray;
  }

  public getDirty = getDirty;
}
