import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '../enums/commission';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomError } from '../models/error/custom-error';
import { Manager } from '../models/plan-accounts/account/create-account-request';
import { FirstNote } from '../models/first-note/first-note';
import { AccountEntry } from '../models/first-note/accounting-entries-request';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisFirstNote {
  constructor(private http: HttpClient) {}

  /**
   * Fetches the first notes for a given manager and year.
   *
   * @param {Manager} manager - The manager whose first notes are to be fetched.
   * @param {string} year - The year for which the first notes are to be fetched.
   * @param {string} type - The type of first note's data to be fetched.
   * @returns {Observable<any>} An observable containing the first notes data.
   *
   * @throws {CustomError} Throws an error if the HTTP request fails.
   */
  public getFirstNotes(
    manager: Manager,
    interval: Date[],
    type: string
  ): Observable<any> {
    if (manager === undefined || interval === undefined || type === undefined) {
      return throwError(() => new Error('Invalid parameters'));
    }

    let params: { [key: string]: number | string } = {};
    interval[0].setHours(15, 0, 0, 0);
    interval[1].setHours(15, 0, 0, 0);
    params['firstNoteManager'] = manager.code;
    params['dateFrom'] = interval[0].toISOString();
    params['dateTo'] = interval[1].toISOString();
    params['typeDate'] = type;

    return this.http
      .get(environment.URL_PLAN_OF_ACCOUNTS + 'accountingEntries', {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new first note by sending a POST request to the accounting entries endpoint.
   *
   * @param {FirstNote} firstNote - The first note object to be created.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If the HTTP request fails, the error is caught and rethrown.
   */
  public createFirstNote(firstNote: FirstNote): Observable<any> {
    this.fixDates(firstNote);

    return this.http
      .post(environment.URL_PLAN_OF_ACCOUNTS + 'accountingEntries', firstNote, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies an existing first note entry.
   *
   * @param {FirstNote} firstNote - The first note object containing the details to be modified.
   * @returns {Observable<any>} An observable that emits the server's response or an error.
   *
   * @throws {CustomError} Throws an error if the HTTP request fails.
   */
  public modifyFirstNote(firstNote: FirstNote): Observable<any> {
    this.fixDates(firstNote);

    return this.http
      .put(
        environment.URL_PLAN_OF_ACCOUNTS +
          `accountingEntries/${firstNote.operationID}`,
        firstNote,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes a first note by sending a POST request to the server.
   *
   * @param {FirstNote} firstNote - The first note to be deleted.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public deleteFirstNote(firstNote: FirstNote): Observable<any> {
    return this.http
      .post(
        environment.URL_PLAN_OF_ACCOUNTS + 'accountingEntries/deletion',
        firstNote,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public createAccountingEntris(
    accounts: AccountEntry[],
    operationID: string
  ): Observable<any> {
    //TODO: change any create a model for the request

    return this.http
      .post(
        environment.URL_PLAN_OF_ACCOUNTS +
          `accountingEntries/${operationID}/doubleEntry`,
        { accounts: accounts },
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Exports the provided first note rows to obtain the excel files.
   *
   * @param {FirstNote[]} firstNotes - An array of first note entries to be exported.
   * @returns {Observable<any>} An observable that emits the server response or an error.
   *
   * @remarks
   * This method sends a POST request to the server to export the provided first note rows.
   * The request includes authorization headers with tokens retrieved from session storage.
   * In case of an error, the observable will emit a `CustomError`.
   */
  public exportFirstNoteRows(
    firstNotes: (string | undefined)[]
  ): Observable<any> {
    const firstNoteRows = {
      accountingEntries: firstNotes,
    };

    return this.http
      .post(
        environment.URL_PLAN_OF_ACCOUNTS + 'accountingEntries/export',
        firstNoteRows,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public getAccountDetail(accountID: number, agencyCode: string) {
    return this.http
      .get(
        environment.URL_PLAN_OF_ACCOUNTS +
          `accounts/${agencyCode}/${accountID}/detail`,

        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  private fixDates(firstNote: FirstNote) {
    let competenceDate = new Date(firstNote.competenceDate);
    competenceDate.setHours(15, 0, 0, 0);
    firstNote.competenceDate = competenceDate.toISOString();
    let registryDate = new Date(firstNote.registryDate);
    registryDate.setHours(15, 0, 0, 0);
    firstNote.registryDate = registryDate.toISOString();
  }
}
