import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Manager } from '../models/plan-accounts/account/create-account-request';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { STORAGE_KEYS } from '../enums/commission';
import { DetailCashControl } from '../models/cash-control/detail-cash-control';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisCashControl {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves cash control data from the server.
   *
   * @param {Manager} [manager] - Optional manager object to filter the cash control data.
   * @returns {Observable<any>} - An observable containing the cash control data.
   *
   * @throws {HttpErrorResponse} - Throws an error if the HTTP request fails.
   *
   */
  public getCashControl(manager?: Manager) {
    let params: { [key: string]: number | string } = {};
    if (manager) {
      params['firstNoteManager'] = manager.code;
    }

    return this.http
      .get(environment.URL_CASH_CONTROL, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Retrieves the details of a cash control entry by its ID.
   *
   * @param {number} id - The ID of the cash control entry to retrieve.
   * @returns {Observable<any>} An observable containing the details of the cash control entry.
   *
   * @throws {HttpErrorResponse} Throws an error if the HTTP request fails.
   */
  public getCashControlDetail(id: number) {
    return this.http
      .get(environment.URL_CASH_CONTROL + id, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies the cash control details for a specific ID.
   *
   * @param detailCashControl - The details of the cash control to be modified.
   * @param id - The ID of the cash control to be modified.
   * @returns An Observable that emits the HTTP response with the modified cash control object or an error.
   *
   * @throws HttpErrorResponse - If the HTTP request fails.
   */
  public modifyCashControl(
    detailCashControl: DetailCashControl,
    id: number
  ): Observable<DetailCashControl> {
    return this.http
      .put<DetailCashControl>(
        environment.URL_CASH_CONTROL + id,
        detailCashControl,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  public recalculateCashControl(id: number): Observable<DetailCashControl> {
    return this.http
      .put<DetailCashControl>(
        `${environment.URL_CASH_CONTROL}${id}/recalculate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}
