import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Listbox, ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { COMMISSION_PATH } from '../../../enums/path';
import {
  CoinsuranceAgency,
  CommissionDetail,
  Producer,
  Product,
} from '../../../models/commission-detail';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { getType } from '../../../utils';

@Component({
  selector: 'app-detail-screen',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ListboxModule,
    ButtonModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './detail-screen.component.html',
  styleUrl: './detail-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class DetailScreenComponent implements OnInit {
  @ViewChild('listbox') listbox: Listbox | undefined;
  commission: CommissionDetail = {} as CommissionDetail;
  modalTitle: string = '';
  isLoading: boolean = true;

  modalValues: Product[] | Producer[] | CoinsuranceAgency[] = [];
  constructor(
    public commissionFormService: CommissionsFormService,
    private router: Router,
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute,
    private httpUtils: HttpUtilisCommissions,
    private modalService: ModalService
  ) {}
  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['id'] === null) {
      } else {
        this.httpUtils.getCommissionDetail(params['id']).subscribe({
          next: (response) => {
            this.commission = response;
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        /*
        this.httpUtils.getlocalStorageValue().subscribe((value) => {
          if (value !== null) {
            this.httpUtils.getCommissionDetail(params['id']).subscribe({
              next: (response) => {
                this.commission = response;
                this.isLoading = false;
              },
              error: (error: HttpErrorResponse) => {
                this.modalService.showError(error);
              },
            });
          }
        });
        */
      }
    });
  }

  goBack() {
    let collaborator = getType(this.commissionFormService);
    this.commissionFormService.showInfoTableCommission = true;

    this.router.navigate([COMMISSION_PATH.SEARCH], {
      queryParams: { type: collaborator },
    });
  }

  openModal(
    title: string,
    products: Product[] | Producer[] | CoinsuranceAgency[]
  ) {
    this.modalTitle = title;
    this.modalValues = products;
  }

  isBase(): string {
    return this.commission?.isBase ? 'Si' : 'No';
  }

  onClickDelete() {
    if (true) {
      let modalReset = document.getElementById('deleteModal');
      if (modalReset) {
        new Modal(modalReset).show();
      }
    }
  }

  onModify(event: MouseEvent): void {
    event?.preventDefault();
    this.router.navigate([COMMISSION_PATH.EDIT], {
      queryParams: { id: this.commission.id },
    });
  }

  onModifyRedirect() {
    return this.router
      .createUrlTree([COMMISSION_PATH.EDIT], {
        queryParams: { id: this.commission.id },
      })
      .toString();
  }

  deleteCommission() {
    this.isLoading = true;
    this.httpUtils.deleteCommission(this.commission.id).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Successo',
          key: 'br',
          detail: 'Provvigione eliminata con successo',
        });

        this.router.navigate([COMMISSION_PATH.SEARCH]);
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  onBlur() {
    if (this.listbox) {
      this.listbox.filterValue = '';
    }
  }
}
