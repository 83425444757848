import { Mode } from '../models/commission-detail';

export const SORT_MAP: Map<string, number> = new Map<string, number>([
  ['INC004', 0],
  ['INC003', 1],
  ['INC002', 2],
  ['INC001', 3],
]);

export const sortModes = (modes: Mode[]): Mode[] => {
  return modes.sort((a, b) => {
    const aValue = SORT_MAP.get(a.code) ?? -1;
    const bValue = SORT_MAP.get(b.code) ?? -1;
    return aValue - bValue;
  });
};
