import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CoinsuranceAgency,
  CommissionDetail,
  Producer,
  Product,
  RateType,
  Variation,
} from '../models/commission-detail';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';
import { IddPair } from '../models/commission-table/idd-pair';
import { ParzialErrorsResponse } from '../models/associate-dissociate/parzial-errors-response';
import { UserInfo } from '../models/user/user-info';

export class AddUtils {
  constructor(private commissionFormService: CommissionsFormService, private userInfo: UserInfo) {}

  public populateForm(
    commission: CommissionDetail,
    iddPair: IddPair
  ): FormGroup {
    let form = new FormGroup({
      commissionTable: new FormControl(commission.commissionTable, [
        Validators.required,
      ]),
      producer: new FormControl<Producer[]>(
        commission.producer ?? [],
        this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      coinsuranceAgency: new FormControl<CoinsuranceAgency[]>(
        commission.coinsuranceAgency,
        !this.commissionFormService.isProducer ? [Validators.required] : []
      ),
      product: new FormControl<Product[]>(commission.product ?? [], [
        Validators.required,
      ]),
      category: new FormControl(commission.category, [Validators.required]),
      validityStartDate: new FormControl(
        new Date(commission.validityStartDate),
        [Validators.required]
      ),
      validityEndDate: new FormControl(
        commission.validityEndDate
          ? new Date(commission.validityEndDate)
          : null,
        []
      ),
      tableType: new FormControl(commission.isBase ? 'BASE' : 'NON BASE', [
        Validators.required,
      ]),
      agency: new FormControl(
        commission.agency,
        this.userInfo.isDirectionalUser ? [Validators.required] : []
      ),
      rateTypes: new FormArray([], [Validators.required]),
    });

    if (commission.rateTypes.length !== 0) {
      commission.rateTypes.forEach((rateType: RateType) => {
        let rateTypes = form.get('rateTypes') as FormArray;
        let rateTypeGroup = new FormGroup({
          id: new FormControl(rateType.id),
          idd: new FormControl(iddPair.dataKeyFirstTable++),
          type: new FormControl(rateType.type, [Validators.required]),
          mode: new FormControl(rateType.mode, [Validators.required]),
          percentage: new FormControl(rateType.percentage, [
            Validators.required,
          ]),
          variations: new FormArray([], [Validators.required]),
        });
        rateType.variations.forEach((variation: Variation) => {
          let variations = rateTypeGroup.get('variations') as FormArray;
          let variationGroup = new FormGroup({
            id: new FormControl(variation.id),
            idd: new FormControl(iddPair.dataKeySecondTable++),
            variation: new FormControl(variation.variation, [
              Validators.required,
            ]),
            conditions: new FormControl(variation.conditions, [
              Validators.required,
            ]),
            type: new FormControl(variation.type, [Validators.required]),
            date: new FormControl(
              variation.date ? new Date(variation.date) : null,
              [Validators.required]
            ),
            percentageValue: new FormControl(variation.percentageValue, []),
            netValue: new FormControl(variation.netValue, []),
          });

          variations.push(variationGroup);
        });

        rateTypes.push(rateTypeGroup);
      });
    }
    return form;
  }

  createPartialResponseBody(
    partialResponse: ParzialErrorsResponse,
    isModifying: boolean
  ): string {
    let partialResponseBody: string = `Provvigione ${
      isModifying ? 'modificata' : 'aggiunta'
    } con successo.\n`;
    if (partialResponse.producerOrCoasErrors !== undefined) {
      partialResponseBody +=
        'I seguenti Collaboratori non sono stati associati:\n';
      partialResponse.producerOrCoasErrors?.forEach((error) => {
        partialResponseBody += `- ${error['item']} \n`;
      });
    }

    if (partialResponse.productErrors !== undefined) {
      partialResponseBody += 'I seguenti Prodotti non sono stati associati:\n';
      partialResponse.productErrors?.forEach((error) => {
        partialResponseBody += `- ${error['item']} \n`;
      });
    }
    return partialResponseBody;
  }
}
