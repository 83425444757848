import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { STORAGE_KEYS } from '../enums/commission';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomError } from '../models/error/custom-error';
import { Report } from '../models/reporting/reports';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisReporting {
  constructor(private http: HttpClient) {}

  /**Call to obtain all the reports from the server.
   *
   * @returns {Observable<any>}  An observable that emits the server response with the all the reports.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */

  public getReports(): Observable<any> {
    return this.http
      .get(environment.URL_REPORT, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new report by sending a POST request to the reporting endpoint.
   *
   * @param {Report} newReport - The report object to be created.
   * @returns {Observable<any>} - An observable that emits the server response.
   *
   * @throws {CustomError} - Throws an error if the HTTP request fails.
   *
   */

  public createReport(newReport: Report): Observable<any> {
    this.fixDates(newReport);
    return this.http
      .post<Report>(environment.URL_REPORT, newReport, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies an existing report by sending a PUT request to the server.
   *
   * @param {Report} modifiedReport - The report object containing the updated data.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public modifyReport(modifiedReport: Report): Observable<any> {
    this.fixDates(modifiedReport);
    return this.http
      .put<Report>(
        `${environment.URL_REPORT}${modifiedReport.id}`,
        modifiedReport,
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Exports a file by making an HTTP GET request to the specified URL with the given ID.
   *
   * @param id - The ID of the file to be exported.
   * @returns An Observable that emits the response of the HTTP GET request.
   *
   * If an error occurs during the request, it is caught and rethrown as a `CustomError`.
   */
  public exportFile(id: number | undefined): Observable<any> {
    return this.http
      .get(`${environment.URL_REPORT}${id}/export`, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  private fixDates(report: Report) {
    let fromData = new Date(report.from);
    let toData = new Date(report.to);
    fromData.setHours(15, 0, 0, 0);
    toData.setHours(15, 0, 0, 0);

    report.from = fromData.toISOString();
    report.to = toData.toISOString();
  }
}
