import { Injectable } from '@angular/core';
import { BankRecDetailResp, Totals } from '../models/bank-records/bank-records-detail-response';

export class BankRecordUtilis {
  constructor() {}

  public static downloadPdf(base64String: string) {
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    const blob = new Blob([bytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  public static downloadExcel(base64String: string) {
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    const blob = new Blob([bytes], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
}

//TODO: incomplete function for recapToArray (refactor). This function is not used in the codebase.
export const recapToArray = (recapValuesTable: { key: string; value: Totals | undefined }[],
    isProducer: boolean | undefined,
    bankRecordDetail: BankRecDetailResp | undefined)=>{
  recapValuesTable.push({
      key: 'Tot. Movimenti manuali',
      value: bankRecordDetail?.recap.totManualMovements,
    });

    recapValuesTable.push({
      key: 'Tot. Provvigioni',
      value: bankRecordDetail?.recap.totProvisions,
    });

    recapValuesTable.push({
      key: 'Saldo Gestione Provvigionale',
      value: bankRecordDetail?.recap.provisionsBalance,
    });

    recapValuesTable.push({
      key: 'Ritenute fiscali',
      value: bankRecordDetail?.recap.totWitholdingTax,
    });

    if (isProducer) {
      recapValuesTable.push({
        key: 'E/C ' + bankRecordDetail?.collaboratorName,
        value: bankRecordDetail?.recap.totBankStatementPdre,
      });
    }

    if (isProducer) {
      recapValuesTable.push({
        key: 'Sospesi',
        value: bankRecordDetail?.recap.totSospesi,
      });
    }

    if (isProducer) {
      recapValuesTable.push({
        key: 'Recupero Sospesi',
        value: bankRecordDetail?.recap.totRecuperoSospesi,
      });
    }

    if (isProducer) {
      recapValuesTable.push({
        key: 'Tot. IFR Immediato',
        value: bankRecordDetail?.recap.totIFRImmediato,
      });
    }

    recapValuesTable.push({
      key: 'Saldo',
      value: bankRecordDetail?.recap.totBalance,
    });
}
