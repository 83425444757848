import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { QueryTypeEnum } from '../../../../enums/commission';
import {
  CoinsuranceAgency,
  Producer,
} from '../../../../models/commission-detail';
import { HttpUtilsBankRecords } from '../../../../network/http-utilis-bank-records';
import { BankRecordsService } from '../../../../services/bank-records/bank-records.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { Router } from '@angular/router';
import { BANK_RECORD_PATH } from '../../../../enums/path';
import { getType } from '../../../../utils';
import { CommissionsFormService } from '../../../../services/commissions-table/commissions-form.service';

@Component({
  selector: 'app-search-bank-records-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    CommonModule,
    AutoCompleteModule,
  ],
  templateUrl: './search-bank-records-form.component.html',
  styleUrl: './search-bank-records-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SearchBankRecordsFormComponent implements OnInit {
  form: FormGroup;
  filteredValues: Producer[] | CoinsuranceAgency[] = [];
  enumType = QueryTypeEnum;
  constructor(
    public bankRecordService: BankRecordsService,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService,
    private router: Router,
    private commissionFormService: CommissionsFormService
  ) {
    this.form = this.bankRecordService.bankRecordForm;
  }
  ngOnInit(): void {}

  onSubmit(): void {
    if (this.form.valid) {
      this.bankRecordService.showInfoTableBankRecord = true;

      this.bankRecordService.bankRecordResponseSub.next(this.form);
    } else {
      this.form.markAllAsTouched();
    }
  }

  onReset(): void {
    let today: Date = new Date();
    let threeMonthsAgo: Date = new Date();
    threeMonthsAgo.setDate(today.getDate() - 90);

    this.bankRecordService.showInfoTableBankRecord = false;
    this.form.reset({ registryDate: [threeMonthsAgo, today] });
  }

  onCreate(): void {
    this.router.navigate([BANK_RECORD_PATH.ADD], {
      queryParams: { type: getType(this.commissionFormService) },
    });
  }

  filterValues($event: AutoCompleteCompleteEvent, type: string): void {
    let query = $event.query;

    if (query.length == 1 || query.length == 2) {
      this.filteredValues = [];
      return;
    }

    switch (type) {
      case this.enumType.PRODUCERS:
        this.httpUtilsBank?.getCollabOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;

      case this.enumType.COINSURANCES:
        this.httpUtilsBank?.getCollabOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;
    }
  }
}
