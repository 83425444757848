import { DatePipe } from '@angular/common';
import { Agency } from '../../commission-detail';
import { Type } from '../master/master';
import { Manager } from './create-account-request';

export class LinkedSubAccount {
  name: string;
  code: string;
  type: Type;
  pathNodo: string;
  dirCollection: number;
  account: string;
  accountNode: string;
  agency: Agency;
  agencyCode: string;
  insertDate: string;
  lastUpdate: string;
  isNew?: boolean;

  constructor(
    name: string,
    code: string,
    type: Type,
    pathNodo: string,
    dirCollection: number,
    account: string,
    accountNode: string,
    agency: Agency,
    agencyCode: string,
    insertDate: string,
    lastUpdate: string
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.pathNodo = pathNodo;
    this.dirCollection = dirCollection;
    this.account = account;
    this.accountNode = accountNode;
    this.agency = agency;
    this.agencyCode = agencyCode;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
  }

  static fromJson(data: any) {
    return new LinkedSubAccount(
      data.name,
      data.code,
      data.type,
      data.pathNodo,
      data.dirCollection,
      data.account,
      data.accountNode,
      data.agency,
      data.agencyCode,
      data.insertDate,
      data.lastUpdate
    );
  }
}

export class Account {
  accountNumber: number;
  name: string;
  code: string;
  type: Type;
  pathNodo: string;
  carry: number;
  carryDate: string;
  firstNoteManager: Manager;
  linkedSubAccounts: LinkedSubAccount[];
  agencyCode: string;
  agency: Agency;
  value: number;
  status: boolean;
  insertDate: string;
  lastUpdate: string;
  ledger: Type;
  showDate?: string;
  showStatus?: string;
  displayName?: string;

  constructor(
    accountNumber: number,
    name: string,
    code: string,
    type: Type,
    pathNodo: string,
    carry: number,
    carryDate: string,
    firstNoteManager: Manager,
    linkedSubAccounts: LinkedSubAccount[],
    agencyCode: string,
    agency: Agency,
    value: number,
    status: boolean,
    insertDate: string,
    lastUpdate: string,
    ledger: Type,
    showDate?: string,
    showStatus?: string,
    displayName?: string
  ) {
    this.accountNumber = accountNumber;
    this.name = name;
    this.code = code;
    this.type = type;
    this.pathNodo = pathNodo;
    this.carry = carry;
    this.carryDate = carryDate;
    this.firstNoteManager = firstNoteManager;
    this.value = value;
    this.status = status;
    this.linkedSubAccounts = linkedSubAccounts;
    this.agencyCode = agencyCode;
    this.agency = agency;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
    this.ledger = ledger;
    this.showDate = showDate;
    this.showStatus = showStatus;
    this.displayName = displayName;
  }

  static fromJson(data: Account) {
    const datePipe = new DatePipe('en-US');
    const formattedDateString = datePipe.transform(
      data.carryDate,
      'dd/MM/yyyy',
      'UTC+1'
    );

    return new Account(
      data.accountNumber,
      data.name,
      data.code,
      data.type,
      data.pathNodo,
      data.carry,
      data.carryDate,
      data.firstNoteManager,
      data.linkedSubAccounts ?? [],
      data.agencyCode,
      data.agency,
      data.value,
      data.status,
      data.insertDate,
      data.lastUpdate,
      data.ledger,
      formattedDateString?.toString(),
      data.status ? 'Abilitato' : 'Disabilitato',
      `${data.code} - ${data.name}`
    );
  }

  static fromCall(datas: Account[] | undefined) {
    return datas?.map((data: Account) => Account.fromJson(data));
  }
}
