import { Agency, Category, RateType, Variation } from '../commission-detail';

export class CommissionTableEditRequest {
  id: number | null;
  commissionTable: string;
  category: Category;
  agency: Agency;
  isBase: boolean;
  validityStartDate: string;
  validityEndDate: string | null;
  rateTypes: RateType[];

  constructor(
    id: number | null,
    commissionTable: string,
    category: Category,
    agency: Agency,
    isBase: boolean,
    validityStartDate: string,
    validityEndDate: string | null,
    rateTypes: RateType[]
  ) {
    this.id = id;
    this.commissionTable = commissionTable;
    this.category = category;
    this.agency = agency;
    this.isBase = isBase;
    this.validityStartDate = validityStartDate;
    this.validityEndDate = validityEndDate;
    this.rateTypes = this.rateAndVarCleaner(rateTypes);
  }

  private rateAndVarCleaner(rateTypes: RateType[]): RateType[] {
    return (rateTypes = rateTypes.map((rateType) => {
      rateType.variations = rateType.variations.map((variation: Variation) => {
        return new Variation(
          variation.id ?? null,
          variation.variation,
          variation.conditions,
          variation.type,
          variation.date ? new Date(variation.date).toISOString() : null,
          variation.percentageValue,
          variation.netValue
        );
      });
      return new RateType(
        rateType.id ?? null,
        rateType.type,
        rateType.mode,
        rateType.types,
        rateType.modes,
        rateType.percentage,
        rateType.variations
      );
    }));
  }
}
