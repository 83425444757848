import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  PickListModule,
  PickListMoveToSourceEvent,
  PickListMoveToTargetEvent,
} from 'primeng/picklist';
import {
  CoinsuranceAgency,
  Producer,
  Product,
} from '../../models/commission-detail';
import { CommissionsFormService } from '../../services/commissions-table/commissions-form.service';

@Component({
  selector: 'app-associate-dissociate',
  standalone: true,
  imports: [PickListModule],
  templateUrl: './associate-dissociate.component.html',
  styleUrl: './associate-dissociate.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AssociateDissociateComponent {
  @Input()
  from: Product[] | Producer[] | CoinsuranceAgency[] = [];
  @Input()
  to: Product[] | Producer[] | CoinsuranceAgency[] = [];
  @Input()
  type: string = '';

  constructor(public commissionService: CommissionsFormService) {}

  onMoveToTarget($event: PickListMoveToTargetEvent) {
    let item: Product | Producer | CoinsuranceAgency = $event.items[0];
    if (
      this.commissionService.assoDissoWrapper.values[
        'disso' + this.type
      ].includes(item.code)
    ) {
      this.commissionService.assoDissoWrapper.values['disso' + this.type] =
        this.commissionService.assoDissoWrapper.values[
          'disso' + this.type
        ].filter((code) => code !== item.code);
      return;
    }
    this.commissionService.assoDissoWrapper.values['asso' + this.type].push(
      item.code
    );
  }

  onMoveToSource($event: PickListMoveToSourceEvent) {
    let item: Product | Producer | CoinsuranceAgency = $event.items[0];
    if (
      this.commissionService.assoDissoWrapper.values[
        'asso' + this.type
      ].includes(item.code)
    ) {
      this.commissionService.assoDissoWrapper.values['asso' + this.type] =
        this.commissionService.assoDissoWrapper.values[
          'asso' + this.type
        ].filter((code) => code !== item.code);
      return;
    }
    this.commissionService.assoDissoWrapper.values['disso' + this.type].push(
      item.code
    );
  }

  onMoveAllToTarget($event: PickListMoveToTargetEvent) {
    let items: Product[] | Producer[] | CoinsuranceAgency[] = $event.items;
    items.forEach((item) => {
      if (
        this.commissionService.assoDissoWrapper.values[
          'disso' + this.type
        ].includes(item.code)
      ) {
        this.commissionService.assoDissoWrapper.values['disso' + this.type] =
          this.commissionService.assoDissoWrapper.values[
            'disso' + this.type
          ].filter((code) => code !== item.code);
        return;
      }
      this.commissionService.assoDissoWrapper.values['asso' + this.type].push(
        item.code
      );
    });
  }
  onMoveAllToSource($event: PickListMoveToSourceEvent) {
    let items: Product[] | Producer[] | CoinsuranceAgency[] = $event.items;
    items.forEach((item) => {
      if (
        this.commissionService.assoDissoWrapper.values[
          'asso' + this.type
        ].includes(item.code)
      ) {
        this.commissionService.assoDissoWrapper.values['asso' + this.type] =
          this.commissionService.assoDissoWrapper.values[
            'asso' + this.type
          ].filter((code) => code !== item.code);
        return;
      }
      this.commissionService.assoDissoWrapper.values['disso' + this.type].push(
        item.code
      );
    });
  }
}
