import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Modal } from 'bootstrap';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { ModalService } from '../../../services/modal/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-modal.component.html',
  styleUrl: './partial-error-modal.component.scss',
})

//modalPartialError
export class ModalComponent implements OnDestroy {
  modalInfos: ModalInfos | undefined;
  subModal$: Subscription | undefined;
  subClose$: Subscription | undefined;

  constructor(private modalService: ModalService) {}
  ngOnDestroy(): void {
    this.subModal$?.unsubscribe();
    this.subClose$?.unsubscribe();
  }

  ngOnInit(): void {
    this.subModal$ = this.modalService.getSubject()?.subscribe((modalInfos) => {
      this.modalInfos = modalInfos;
      this.showModal();
    });

    this.subClose$ = this.modalService.getCloseSubject()?.subscribe(() => {
      this.closeModal();
    });
  }

  showModal(): void {
    const modalElement = document.getElementById('customModal') as HTMLElement;
    if (modalElement) {
      const modal = new Modal(modalElement, {
        backdrop: 'static',
        keyboard: false,
      });
      modal.show();
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('customModal') as HTMLElement;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  }
}
