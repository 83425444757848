<div class="commission-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="d-flex align-items-center my-4 go-back">
      <div (click)="goBack()">
        <i class="pi pi-arrow-left m-0 me-2 icon-list"> </i>
        <span class="label">Indietro</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="collectionDate">Data registrazione*</label>
        <p-calendar
          id="collectionDate"
          formControlName="collectionDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="statementType">Modalità*</label>
        <p-dropdown
          id="statementType"
          formControlName="statementType"
          [options]="modalitiesOptions"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown my-2"
          showClear="true"
          [ngClass]="{ 'ng-dirty': getDirty(form, 'statementType') }"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="statementDes">Descrizione*</label>
        <input
          pInputText
          id="statementDes"
          type="text"
          formControlName="statementDes"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="provvPdre">Importo*</label>
        <input
          id="provvPdre"
          pInputText
          type="number"
          formControlName="provvCalcolata"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="ifr">IFR*</label>
        <p-dropdown
          id="ifr"
          formControlName="ifr"
          [options]="valueModes"
          placeholder="Seleziona..."
          styleClass="commission-dropdown my-2"
          optionLabel="description"
          showClear="true"
          [ngClass]="{ 'ng-dirty': getDirty(form, 'ifr') }"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="ifrValue">Valore IFR*</label>
        <input
          id="ifrValue"
          pInputText
          type="number"
          formControlName="ifrValue"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
      <div
        class="col-lg-4 col-md-12"
        *ngIf="!this.commissionService.isProducer"
      >
        <label for="witholdingTax">Ritenuta fiscale(%)*</label>
        <p-dropdown
          id="witholdingTax"
          formControlName="witholdingTax"
          [ngClass]="{ 'ng-dirty': getDirty(form, 'witholdingTax') }"
          [options]="valueModes"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown my-2"
          showClear="true"
        ></p-dropdown>
      </div>
      <div
        class="col-lg-4 col-md-12"
        *ngIf="!this.commissionService.isProducer"
      >
        <label for="witholdingTaxValue">Valore Ritenuta fiscale*</label>
        <input
          id="witholdingTaxValue"
          pInputText
          type="number"
          formControlName="witholdingTaxValue"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="witholdingTax">Ritenuta fiscale(%)*</label>
        <p-dropdown
          id="witholdingTax"
          formControlName="witholdingTax"
          [ngClass]="{ 'ng-dirty': getDirty(form, 'witholdingTax') }"
          [options]="valueModes"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown my-2"
          showClear="true"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="witholdingTaxValue">Valore Ritenuta fiscale*</label>
        <input
          id="witholdingTaxValue"
          pInputText
          type="number"
          formControlName="witholdingTaxValue"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
      <div class="col-lg-4 col-md-12"></div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong *ngIf="!isModify">Crea</strong>
        <strong *ngIf="isModify">Modifica</strong>
      </button>
    </div>
  </form>
</div>
