export class AssoDissoWrapper {
  values: { [key: string]: string[] } = {
    assoproducers: [],
    dissoproducers: [],
    assoproducts: [],
    dissoproducts: [],
    assocoinsurances: [],
    dissocoinsurances: [],
  };

  public reset(): void {
    this.values = {
      assoproducers: [],
      dissoproducers: [],
      assoproducts: [],
      dissoproducts: [],
      assocoinsurances: [],
      dissocoinsurances: [],
    };
  }

  public isEmpty(): boolean {
    return (
      this.values['assoproducers'].length === 0 &&
      this.values['dissoproducers'].length === 0 &&
      this.values['assoproducts'].length === 0 &&
      this.values['dissoproducts'].length === 0 &&
      this.values['assocoinsurances'].length === 0 &&
      this.values['dissocoinsurances'].length === 0
    );
  }
}
