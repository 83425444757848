import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  modifyAddModalVisibilitySub: Subject<boolean> = new Subject();
  tableReportsSub = new Subject<void>();

  constructor() {}
}
