import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SubAccount } from '../../../models/plan-accounts/sub-account/sub-account';
import { Modal } from 'bootstrap';
import { UserInfo } from '../../../models/user/user-info';
import { MessageService } from 'primeng/api';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { ModalAddSubAccountComponent } from '../../../components/modals/modal-add-sub-account/modal-add-sub-account.component';
import { splitAgencyCode } from '../../../constants/general';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalService } from '../../../services/modal/modal.service';
import { Subscription } from 'rxjs';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { Agency } from '../../../models/commission-detail';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { Router } from '@angular/router';
import { getDirty } from '../../../utils';
import { TYPE } from '../../../enums/path-of-accounts';
@Component({
  selector: 'app-sub-accounts-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ModalAddSubAccountComponent,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  templateUrl: './sub-accounts-screen.component.html',
  styleUrl: './sub-accounts-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SubAccountsScreenComponent implements OnInit, OnDestroy {
  subAccounts: SubAccount[] | undefined;
  selecteddSubAccounts: SubAccount | undefined;
  userInfo: UserInfo | undefined;
  modifiedSubAccount: SubAccount | undefined;
  modalAdd: any | undefined;
  modalRefAdd: Modal | undefined;
  subAccountsSub$: Subscription | undefined;
  agenciesOptions: Agency[] | undefined;
  showTable: boolean = false;
  form: FormGroup | undefined;
  isLoading: boolean = true;

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService,
    private router: Router
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );

      this.form = new FormGroup({
        agency: new FormControl(null, Validators.required),
      });
    } else {
      this.modalService.showModal(
        new ModalInfos(
          'Attenzione',
          'Informazioni utente non disponibili',
          () => {
            this.router.navigate(['']);
          }
        )
      );
    }
  }
  ngOnDestroy(): void {
    this.subAccountsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeModals();
    this.subAccountsSub$ = this.planOfAccountsService.subAccountsSub.subscribe({
      next: (subAccounts) => {
        this.subAccounts = subAccounts;
      },
    });
    if (this.userInfo) {
      if (!this.userInfo.isDirectionalUser) {
        this.loadSubAccounts();
      } else {
        this.isLoading = true;
        this.httpUtilisPlanOfAccounts.getAllInfos(TYPE.AGENCY).subscribe({
          next: (response) => {
            this.agenciesOptions = response['types'];
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
      }
    }
  }

  loadSubAccounts(agency?: Agency): void {
    this.isLoading = true;
    this.httpUtilisPlanOfAccounts
      .getSubAccount(
        this.userInfo!.agencyDescription.split(splitAgencyCode)[0],
        undefined,
        agency
      )
      .subscribe({
        next: (response) => {
          this.planOfAccountsService.subAccountsSub.next(
            SubAccount.fromCall(response['subaccounts'])
          );
          this.showTable = true;
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form?.markAllAsTouched();
      return;
    }

    this.loadSubAccounts(this.form?.value.agency);
  }

  onModify(subAccount: SubAccount) {
    this.modifiedSubAccount = subAccount;
    this.modalRefAdd?.show();
  }
  onAgencyChange(event: DropdownChangeEvent): void {
    this.planOfAccountsService.subAccountAgency = event.value;
  }

  initializeModals(): void {
    this.modalAdd = document.getElementById('modalAddSubAccount');

    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });
    }
  }

  getDirty = getDirty;
}
