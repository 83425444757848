import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SubAccount } from '../../models/plan-accounts/sub-account/sub-account';
import { Account } from '../../models/plan-accounts/account/account';
import { Master } from '../../models/plan-accounts/master/master';
import { Agency } from '../../models/commission-detail';

@Injectable({
  providedIn: 'root',
})
export class PlanOfAccountService {
  subAccountsSub: Subject<SubAccount[] | undefined> = new Subject();
  accountsSub: Subject<Account[] | undefined> = new Subject();
  mastersSub: Subject<Master[] | undefined> = new Subject();
  ledgerAgency: Agency | undefined;
  accountAgency: Agency | undefined;
  subAccountAgency: Agency | undefined;
  constructor() {}
}
