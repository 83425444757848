import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CommissionsFormService } from '../../services/commissions-table/commissions-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import {
  BANK_RECORD_PATH,
  CASH_CONTROL_PATH,
  CENSUS_PATH,
  COMMISSION_PATH,
  FIRST_NOTE_PATH,
  PLAN_ACCOUNT_PATH,
  REPORTING_PATH,
} from '../../enums/path';
import {
  CommissionTableRequest,
  Filters,
} from '../../models/commission-table/commission_table_request';
import { QueryTypeEnum } from '../../enums/commission';
import { BankRecordsService } from '../../services/bank-records/bank-records.service';
import { Title } from '@angular/platform-browser';
import { getType } from '../../utils';
import { UserInfo } from '../../models/user/user-info';
import { VISIBILITY } from '../../enums/visibility';
import { waitForUserInfo } from '../../guard/visibility-guard';
import { environment } from '../../../environments/environment';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MenubarModule, MenuModule, ToastModule, ImageModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  commission: MenuItem[] | undefined;
  backRecord: MenuItem[] | undefined;
  plansAccounts: MenuItem[] | undefined;
  cashControl: MenuItem[] | undefined;
  census: MenuItem[] | undefined;
  userInfo: UserInfo | undefined;

  constructor(
    private commissionFormService: CommissionsFormService,
    private bankRecordService: BankRecordsService,
    private router: Router,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.userInfo = await waitForUserInfo();
    this.commission = this.initializeCommissionMenu();
    this.backRecord = this.initializeBackRecordMenu();
    this.plansAccounts = this.initializePlansAccountsMenu();
    this.cashControl = this.initializeCashControlMenu();
    // this.plansAccounts = [
    //   ...(this.userInfo?.userFunctions?.includes(VISIBILITY.PRIMANOTA)
    //     ? [
    //         {
    //           label: 'Prima nota',
    //           items: [
    //             ...(this.userInfo?.userFunctions?.includes(
    //               VISIBILITY.PRIMANOTA_PIANODEICONTI
    //             )
    //               ? [
    //                   {
    //                     label: 'Piano dei conti',
    //                     items: [
    //                       ...(this.userInfo?.userFunctions?.includes(
    //                         VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_VISUALIZZA
    //                       )
    //                         ? [
    //                             {
    //                               label: 'Mastri',
    //                               command: () => {
    //                                 this.titleService.setTitle('Mastri');
    //                                 this.router.navigate([
    //                                   PLAN_ACCOUNT_PATH.MASTERS,
    //                                 ]);
    //                               },
    //                             },
    //                           ]
    //                         : []),

    //                       ...(this.userInfo?.userFunctions?.includes(
    //                         VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT
    //                       ) ||
    //                       this.userInfo?.userFunctions?.includes(
    //                         VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT
    //                       )
    //                         ? [
    //                             {
    //                               label: 'Conti',
    //                               command: () => {
    //                                 this.titleService.setTitle('Conti');
    //                                 this.router.navigate([
    //                                   PLAN_ACCOUNT_PATH.ACCOUNTS,
    //                                 ]);
    //                               },
    //                             },
    //                           ]
    //                         : []),

    //                       ...(this.userInfo?.userFunctions?.includes(
    //                         VISIBILITY.PRIMANOTA_PIANODEICONTI_SOTTOCONTI
    //                       )
    //                         ? [
    //                             {
    //                               label: 'Sottoconti',
    //                               command: () => {
    //                                 this.titleService.setTitle('Sottoconti');
    //                                 this.router.navigate([
    //                                   PLAN_ACCOUNT_PATH.SUB_ACCOUNTS,
    //                                 ]);
    //                               },
    //                             },
    //                           ]
    //                         : []),
    //                     ],
    //                   },
    //                 ]
    //               : []),
    //             ...(this.userInfo?.userFunctions?.includes(
    //               VISIBILITY.PRIMANOTA_PRIMANOTA
    //             )
    //               ? [
    //                   {
    //                     label: 'Prima nota',
    //                     command: () => {
    //                       this.titleService.setTitle('Prima nota');
    //                       this.router.navigate([FIRST_NOTE_PATH.NODE]);
    //                     },
    //                   },
    //                 ]
    //               : []),
    //             ...(this.userInfo?.userFunctions?.includes(
    //               VISIBILITY.REPORTISTICA
    //             )
    //               ? [
    //                   {
    //                     label: 'Reportistica',
    //                     command: () => {
    //                       this.titleService.setTitle('Reportistica');
    //                       this.router.navigate([REPORTING_PATH.TABLE]);
    //                     },
    //                   },
    //                 ]
    //               : []),
    //           ],
    //         },
    //       ]
    //     : []),
    // ];

    // this.cashControl = this.userInfo.userFunctions?.includes(
    //   VISIBILITY.CONTROLLO_CASSA
    // )
    //   ? [
    //       {
    //         label: 'Controllo cassa',
    //         command: () => {
    //           this.titleService.setTitle('Controllo Cassa');
    //           this.router.navigate([CASH_CONTROL_PATH.SEARCH]);
    //         },
    //       },
    //     ]
    //   : [];
  }

  private initializeCommissionMenu(): MenuItem[] {
    if (!this.userInfo?.userFunctions?.includes(VISIBILITY.PROVVIGIONI)) {
      return [];
    }

    const items: MenuItem[] = [];

    if (
      this.userInfo?.userFunctions?.includes(VISIBILITY.PROVVIGIONI_PRODUTTORE)
    ) {
      items.push({
        label: 'Produttori',
        command: () => {
          this.commissionFormService.isProducer = true;
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.commissionFormService.formComSearch.reset();
          this.commissionFormService.$CommissionTableSub.next(
            new CommissionTableRequest(
              QueryTypeEnum.PRODUCERS,
              new Filters(null, null, null, null, null, null, false, null)
            )
          );
          this.titleService.setTitle('Provvigioni Produttore');
          this.router.navigate([COMMISSION_PATH.SEARCH], {
            queryParams: {
              type: getType(this.commissionFormService),
            },
          });
        },
      });
    }

    if (
      this.userInfo?.userFunctions?.includes(
        VISIBILITY.PROVVIGIONI_COASSICURAZIONE
      )
    ) {
      items.push({
        label: 'Coassicurazione',
        command: () => {
          this.commissionFormService.isProducer = false;
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.commissionFormService.formComSearch.reset();
          this.commissionFormService.$CommissionTableSub.next(
            new CommissionTableRequest(
              QueryTypeEnum.COINSURANCES,
              new Filters(null, null, null, null, null, null, false, null)
            )
          );
          this.titleService.setTitle('Provvigioni Coassicurazione');
          this.router.navigate([COMMISSION_PATH.SEARCH], {
            queryParams: {
              type: getType(this.commissionFormService),
            },
          });
        },
      });
    }

    if (
      this.userInfo?.userFunctions?.includes(
        VISIBILITY.CENSIMENTO_COLLABORATORI
      )
    ) {
      items.push({
        label: 'Gestione collaboratori',
        command: () => {
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.titleService.setTitle('Censimento collab');
          this.router.navigate([CENSUS_PATH.SEARCH_COLLAB]);
        },
      });
    }

    if (this.userInfo?.userFunctions?.includes(VISIBILITY.CENSIMENTO_AGENZIE)) {
      items.push({
        label: 'Censimento agenzie coassicurazione',
        command: () => {
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.titleService.setTitle('Censimento agenzie');
          this.router.navigate([CENSUS_PATH.SEARCH_AGENCY]);
        },
      });
    }

    return [{ label: 'Provvigioni', items }];
  }

  private initializeBackRecordMenu(): MenuItem[] {
    if (!this.userInfo?.userFunctions?.includes(VISIBILITY.ESTRATTICONTO)) {
      return [];
    }

    const items: MenuItem[] = [];

    if (
      this.userInfo?.userFunctions?.includes(
        VISIBILITY.ESTRATTICONTO_PRODUTTORE
      )
    ) {
      items.push({
        label: 'Produttori',
        command: () => {
          this.commissionFormService.isProducer = true;
          this.bankRecordService.bankRecordRowForm.get('collaborator')?.reset();
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.bankRecordService.showInfoTableBankRecord = false;
          this.bankRecordService.showRowsTable = false;
          this.titleService.setTitle('Estratti Conto Produttore');
          this.router.navigate([BANK_RECORD_PATH.SEARCH], {
            queryParams: {
              type: getType(this.commissionFormService),
            },
          });
        },
      });
    }
    if (
      this.userInfo?.userFunctions?.includes(
        VISIBILITY.ESTRATTICONTO_COASSICURAZIONE
      )
    ) {
      items.push({
        label: 'Coassicurazione',
        command: () => {
          this.commissionFormService.isProducer = false;
          this.bankRecordService.bankRecordRowForm.get('collaborator')?.reset();
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.bankRecordService.showInfoTableBankRecord = false;
          this.bankRecordService.showRowsTable = false;
          this.titleService.setTitle('Estratti Conto Coassicurazione');
          this.router.navigate([BANK_RECORD_PATH.SEARCH], {
            queryParams: {
              type: getType(this.commissionFormService),
            },
          });
        },
      });
    }

    return [{ label: 'Estratti conto', items }];
  }

  private initializePlansAccountsMenu(): MenuItem[] {
    if (!this.userInfo?.userFunctions?.includes(VISIBILITY.PRIMANOTA)) {
      return [];
    }

    const items: MenuItem[] = [];

    if (
      this.userInfo?.userFunctions?.includes(VISIBILITY.PRIMANOTA_PIANODEICONTI)
    ) {
      const subItems: MenuItem[] = [];

      if (
        this.userInfo?.userFunctions?.includes(
          VISIBILITY.PRIMANOTA_PIANODEICONTI_MASTRI_VISUALIZZA
        )
      ) {
        subItems.push({
          label: 'Mastri',
          command: () => {
            localStorage.setItem(
              'isProducer',
              JSON.stringify(this.commissionFormService.isProducer)
            );
            this.titleService.setTitle('Mastri');
            this.router.navigate([PLAN_ACCOUNT_PATH.MASTERS]);
          },
        });
      }

      if (
        this.userInfo?.userFunctions?.includes(
          VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_VISUALIZZATOT
        )
      ) {
        subItems.push({
          label: 'Conti',
          command: () => {
            localStorage.setItem(
              'isProducer',
              JSON.stringify(this.commissionFormService.isProducer)
            );
            this.titleService.setTitle('Conti');
            this.router.navigate([PLAN_ACCOUNT_PATH.ACCOUNTS]);
          },
        });
      }

      if (
        this.userInfo?.userFunctions?.includes(
          VISIBILITY.PRIMANOTA_PIANODEICONTI_SOTTOCONTI
        )
      ) {
        subItems.push({
          label: 'Sottoconti',
          command: () => {
            localStorage.setItem(
              'isProducer',
              JSON.stringify(this.commissionFormService.isProducer)
            );
            this.titleService.setTitle('Sottoconti');
            this.router.navigate([PLAN_ACCOUNT_PATH.SUB_ACCOUNTS]);
          },
        });
      }

      items.push({
        label: 'Piano dei conti',
        items: subItems,
      });
    }

    if (
      this.userInfo?.userFunctions?.includes(VISIBILITY.PRIMANOTA_PRIMANOTA)
    ) {
      items.push({
        label: 'Prima nota',
        command: () => {
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.titleService.setTitle('Prima nota');
          this.router.navigate([FIRST_NOTE_PATH.NODE]);
        },
      });
    }

    if (this.userInfo?.userFunctions?.includes(VISIBILITY.REPORTISTICA)) {
      items.push({
        label: 'Reportistica',
        command: () => {
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.titleService.setTitle('Reportistica');
          this.router.navigate([REPORTING_PATH.TABLE]);
        },
      });
    }

    return [
      {
        label: 'Prima nota',
        items,
      },
    ];
  }

  private initializeCashControlMenu(): MenuItem[] {
    if (!this.userInfo?.userFunctions?.includes(VISIBILITY.CONTROLLO_CASSA)) {
      return [];
    }

    return [
      {
        label: 'Controllo cassa',
        command: () => {
          localStorage.setItem(
            'isProducer',
            JSON.stringify(this.commissionFormService.isProducer)
          );
          this.titleService.setTitle('Controllo Cassa');
          this.router.navigate([CASH_CONTROL_PATH.SEARCH]);
        },
      },
    ];
  }

  onClickLogout(): void {
    window.location.href = environment.APP_LOGOUT;
    localStorage.clear();
  }
}
