import { CommonModule, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { CATEGORY_OPTIONS } from '../../../constants/test';
import { QueryTypeEnum } from '../../../enums/commission';
import { BANK_RECORD_PATH } from '../../../enums/path';
import { BCRow } from '../../../models/bank-records/bank-records-row-search-response';
import { BankRecAddOrRemoveRequest } from '../../../models/bank-records/request/bank-records-remove-add-request';
import { CoinsuranceAgency, Producer } from '../../../models/commission-detail';
import { HttpUtilsBankRecords } from '../../../network/http-utilis-bank-records';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { BankRecordsService } from '../../../services/bank-records/bank-records.service';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { getDirty, getType } from '../../../utils/commons-utilis';
import { ModalInfos } from '../../../models/modal/modal-infos';

@Component({
  selector: 'app-add-bank-records',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    CommonModule,
    TableModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    ImageModule,
    CustomCurrencyPipe,
    FormsModule,
  ],
  templateUrl: './add-bank-records.component.html',
  styleUrl: './add-bank-records.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddBankRecordsComponent implements OnDestroy {
  form: FormGroup;
  cetegoryOptions: string[] = CATEGORY_OPTIONS;
  bankRows: BCRow[] = [];
  selectedBankRows: BCRow[] = [];
  enumType = QueryTypeEnum;
  filteredValues: Producer[] | CoinsuranceAgency[] = [];
  $subRoute: Subscription | undefined;
  addToDraft: boolean = false;
  isLoading: boolean = false;
  bankRecordID: number | undefined;
  collaborator: Producer | CoinsuranceAgency | undefined;

  constructor(
    private router: Router,
    public bankRecService: BankRecordsService,
    private httpUtils: HttpUtilisCommissions,
    private modalService: ModalService,
    private activatedRouter: ActivatedRoute,
    public commissionService: CommissionsFormService,
    private httpUtilisBank: HttpUtilsBankRecords,
    private messageService: MessageService
  ) {
    this.form = this.bankRecService.bankRecordRowForm;
  }
  ngOnDestroy(): void {
    this.$subRoute?.unsubscribe();
  }

  ngOnInit(): void {
    this.$subRoute = this.activatedRouter.queryParams.subscribe((params) => {
      if (params['ECid'] && params['userName'] && params['userID']) {
        this.addToDraft = true;
        this.bankRecordID = params['ECid'];
        this.collaborator = new Producer(params['userID'], params['userName']);
        this.form.get('collaborator')?.setValue(this.collaborator);
      }
    });

    this.getRecords();
    this.addToDraft
      ? this.form.get('collaborator')?.disable()
      : this.form.get('collaborator')?.enable();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.getRecords();
    } else {
      this.form.markAllAsTouched();
    }
  }

  getRecords(): void {
    this.selectedBankRows = [];
    if (
      this.form.get('collaborator')!.value?.code &&
      this.form.get('titleCollectionDate')!.value
    ) {
      this.isLoading = true;
      this.httpUtilisBank
        .getRowsBankRecord(
          this.form.get('collaborator')!.value.code,
          this.form.get('titleCollectionDate')!.value
        )
        .subscribe({
          next: (response) => {
            this.bankRows = [];
            response['statementRows'].forEach((bankRow: any) => {
              this.bankRows.push(new BCRow(bankRow));
            });

            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.modalService.showError(error);
          },
        });

      this.bankRecService.showRowsTable = true;
    }
  }

  onReset(): void {
    this.form.reset({ registryDate: [] });
  }

  goBack(): void {
    this.bankRecService.showRowsTable = false;
    this.form.get('collaborator')?.reset();

    if (this.bankRecordID) {
      this.router.navigate([BANK_RECORD_PATH.EDIT], {
        queryParams: {
          id: this.bankRecordID,
          type: getType(this.commissionService),
        },
      });
    } else {
      this.router.navigate([BANK_RECORD_PATH.SEARCH], {
        queryParams: {
          type: getType(this.commissionService),
        },
      });
    }
  }

  responseIsEmpty(): boolean {
    return false;
  }

  onModify(event: MouseEvent, rowID: number, manuallyCreated: boolean): void {
    event?.preventDefault();
    if (manuallyCreated) {
      this.router.navigate([BANK_RECORD_PATH.ADD_ROW], {
        queryParams: {
          id: rowID,
          userId: this.form.get('collaborator')?.value.code,
        },
      });
    } else {
      this.router.navigate([BANK_RECORD_PATH.EDIT_ROW], {
        queryParams: {
          id: rowID,
          userId: this.form.get('collaborator')?.value.code,
        },
      });
    }
  }

  onDetail(event: MouseEvent, rowID: number): void {
    event?.preventDefault();
    this.router.navigate([BANK_RECORD_PATH.DETAIL_ROW], {
      queryParams: {
        id: rowID,
        userId: this.form.get('collaborator')?.value.code,
      },
    });
  }

  onModifyRedirect(rowID: number, manuallyCreated: boolean): string {
    if (manuallyCreated) {
      return this.router
        .createUrlTree([BANK_RECORD_PATH.ADD_ROW], {
          queryParams: {
            id: rowID,
            userId: this.form.get('collaborator')?.value.code,
          },
        })
        .toString();
    } else {
      return this.router
        .createUrlTree([BANK_RECORD_PATH.EDIT_ROW], {
          queryParams: {
            id: rowID,
            userId: this.form.get('collaborator')?.value.code,
          },
        })
        .toString();
    }
  }

  onDetailRedirect(rowID: number) {
    return this.router
      .createUrlTree([BANK_RECORD_PATH.DETAIL_ROW], {
        queryParams: {
          id: rowID,
          userId: this.form.get('collaborator')?.value.code,
        },
      })
      .toString();
  }

  onCreateDraft(): void {
    if (this.selectedBankRows.length !== 0) {
      if (this.addToDraft && this.bankRecordID) {
        this.modalLogicToAddNewRows();
      } else {
        this.addNewDraft();
      }
    }
  }

  private thereAreCalculatedRows(): boolean {
    let result: boolean = false;
    for (const row of this.selectedBankRows) {
      if (row.calculated) {
        result = true;
        break;
      }
    }

    return result;
  }

  private allRowsAreCalculated(): boolean {
    let result: boolean = true;
    for (const row of this.selectedBankRows) {
      if (!row.calculated) {
        result = false;
        break;
      }
    }

    return result;
  }

  private addNewDraft(): void {
    if (this.allRowsAreCalculated()) {
      this.createNewDraft();
    } else {
      if (this.thereAreCalculatedRows()) {
        this.modalService.showModal(
          new ModalInfos(
            'Attenzione',
            'Sono state incluse nell’estratto conto righe dove non è stato possibile calcolare la provvigione, queste verranno escluse automaticamente dal nuovo estratto conto',
            () => {
              this.createNewDraft();
            },
            'pi pi-exclamation-triangle',
            () => {
              return;
            }
          )
        );
      } else {
        this.modalService.showModal(
          new ModalInfos(
            'Attenzione',
            'Non è possibile creare un nuovo estratto conto in quanto per tutte le righe selezionate non è stato possibile calcolare la provvigione',
            () => {
              return;
            },
            'pi pi-exclamation-triangle'
          )
        );
      }
    }
  }

  private modalLogicToAddNewRows(): void {
    if (this.allRowsAreCalculated()) {
      this.addRowsToDraft();
    } else {
      if (this.thereAreCalculatedRows()) {
        this.modalService.showModal(
          new ModalInfos(
            'Attenzione',
            'Sono state incluse nell’estratto conto righe dove non è stato possibile calcolare la provvigione, queste verranno escluse automaticamente dal nuovo estratto conto',
            () => {
              this.addRowsToDraft();
            },
            'pi pi-exclamation-triangle',
            () => {
              return;
            }
          )
        );
      } else {
        this.modalService.showModal(
          new ModalInfos(
            'Attenzione',
            'Non è possibile creare un nuovo estratto conto in quanto per tutte le righe selezionate non è stato possibile calcolare la provvigione',
            () => {
              return;
            },
            'pi pi-exclamation-triangle'
          )
        );
      }
    }
  }

  private createNewDraft(): void {
    if (this.selectedBankRows.length === 0) {
      return;
    }

    let request = { statementRows: this.selectedBankRows };
    this.isLoading = true;
    this.httpUtilisBank
      .createNewDraft(this.form.get('collaborator')?.value.code, request)
      .subscribe({
        next: (response: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Estratto conto creato con successo',
          });
          this.isLoading = false;

          this.router.navigate([BANK_RECORD_PATH.EDIT], {
            queryParams: { id: response['statement'].id },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
  }

  private addRowsToDraft(): void {
    const bankRecordAddRequest: BankRecAddOrRemoveRequest =
      new BankRecAddOrRemoveRequest(null, this.selectedBankRows);
    this.isLoading = true;
    this.httpUtilisBank
      .addOrRemoveRowsBankRec(bankRecordAddRequest, this.bankRecordID!)
      .subscribe({
        next: (_response) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Righe aggiunte con successo',
          });
          this.router.navigate([BANK_RECORD_PATH.EDIT], {
            queryParams: { id: this.bankRecordID },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
  }

  onCreatRow(): void {
    this.router.navigate([BANK_RECORD_PATH.ADD_ROW], {
      queryParams: { userId: this.form.get('collaborator')?.value.code },
    });
  }

  onDelete(rowID: number): void {
    this.isLoading = true;
    this.httpUtilisBank
      .deleteAndReload(this.form.get('collaborator')?.value.code, rowID)
      .subscribe({
        next: (response) => {
          this.bankRows = [];
          response['statementRows'].forEach((bankRow: any) => {
            this.bankRows.push(new BCRow(bankRow));
          });
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Riga eliminata con successo',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
  }

  onRecalculates(): void {
    this.isLoading = true;

    // this.httpUtilisBank.calculateRows(this.selectedBankRows).subscribe({
    //   next: (_response) => {
    //     this.isLoading = false;
    //     this.messageService.add({
    //       severity: 'warn',
    //       summary: 'Attenzione',
    //       key: 'br',
    //       detail: 'Il ricalcolo è in corso, potrebbe richiedere alcuni minuti',
    //     });
    //   },
    //   error: (_error: HttpErrorResponse) => {
    //     this.isLoading = false;
    //     this.messageService.add({
    //       severity: 'error',
    //       summary: 'Errore',
    //       key: 'br',
    //       detail: 'Impossibile effettuare il ricalcolo',
    //     });
    //   },
    // });

    this.httpUtilisBank
      .calculateAndReload(
        this.selectedBankRows,
        this.form.get('collaborator')?.value.code
      )
      .subscribe({
        next: (response) => {
          this.bankRows = [];
          response['statementRows'].forEach((bankRow: any) => {
            this.bankRows.push(new BCRow(bankRow));
          });
          this.isLoading = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Attenzione',
            key: 'br',
            detail:
              'Il ricalcolo è in corso, potrebbe richiedere alcuni minuti',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.modalService.showError(error);
        },
      });
  }

  getButtonCreateModify() {
    return this.addToDraft ? 'Aggiungi alla Bozza' : 'Crea Bozza';
  }

  filterValues($event: AutoCompleteCompleteEvent, type: string): void {
    let query = $event.query;
    if (query.length == 1 || query.length == 2) {
      this.filteredValues = [];
      return;
    }
    switch (type) {
      case this.enumType.PRODUCERS:
        this.httpUtilisBank?.getCollabOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;

      case this.enumType.COINSURANCES:
        this.httpUtilisBank?.getCollabOrCoass(query).subscribe({
          next: (response) => {
            this.filteredValues = response['items'];
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
          },
        });
        break;
    }
  }

  customDateFilter(value: string, filter: string): boolean {
    const formattedDate = formatDate(value, 'dd/MM/yyyy', 'en-US');
    return formattedDate.includes(filter);
  }

  getDirty = getDirty;
}
