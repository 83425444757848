import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Account } from '../../../models/plan-accounts/account/account';
import { CalendarModule } from 'primeng/calendar';
import { getDirty } from '../../../utils';
import { UserInfo } from '../../../models/user/user-info';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { Agency } from '../../../models/commission-detail';
import { QueryTypeEnum } from '../../../enums/commission';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { ModalService } from '../../../services/modal/modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Master, Type } from '../../../models/plan-accounts/master/master';
import { Modal } from 'bootstrap';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { TYPE } from '../../../enums/path-of-accounts';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { concatMap, forkJoin } from 'rxjs';
import {
  CreateAccountRequest,
  Manager,
} from '../../../models/plan-accounts/account/create-account-request';
import { splitAgencyCode } from '../../../constants/general';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { ModalInfos } from '../../../models/modal/modal-infos';

@Component({
  selector: 'app-modal-add-account',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
  ],
  templateUrl: './modal-add-account.component.html',
  styleUrl: './modal-add-account.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalAddAccountComponent implements OnChanges {
  @Input() account: Account | undefined;
  @Input() modalRef: Modal | undefined;
  form: FormGroup = new FormGroup({});
  typeOptions: Type[] | undefined;
  masterOptions: any[] | undefined;
  firstNoteManagerOptions: Manager[] | undefined;
  userInfo: UserInfo | undefined;
  filteredValues: Agency[] = [];
  enumType = QueryTypeEnum;
  isLoading: boolean = false;
  //TODO: set default value isLoading to true
  isModify: boolean | undefined;
  code: number | undefined;

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilsCom: HttpUtilisCommissions,
    private httpServicePlanAccounts: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService
  ) {
    if (sessionStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(sessionStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['account']) {
      if (this.account) {
        this.populateForm();
        this.isModify = true;
      } else {
        this.onCreate();
      }
    }
  }

  ngOnInit(): void {
    if (this.userInfo) {
      const getCode$ = this.httpServicePlanAccounts.getCode(
        TYPE.ACCOUNT,
        this.userInfo?.node,
        this.userInfo?.agencyDescription.split(splitAgencyCode)[0]
      );

      const getType$ = this.httpServicePlanAccounts.getAllInfos(
        TYPE.NATURA,
        undefined,
        undefined,
        undefined
      );

      const getManager$ = this.httpServicePlanAccounts.getAllInfos(
        TYPE.GESTORE,
        undefined,
        this.userInfo.agencyDescription?.split(splitAgencyCode)[0],
        this.userInfo.node
      );

      this.form = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        code: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        carryDate: new FormControl(new Date()),
        carry: new FormControl(null),
        firstNoteManager: new FormControl(null, [Validators.required]),
        agency: new FormControl(
          this.userInfo?.isDirectionalUser
            ? null
            : {
                code: this.userInfo?.node,
                description: this.userInfo?.agencyDescription,
              },
          [Validators.required]
        ),
        master: new FormControl(null, [Validators.required]),
      });

      if (this.userInfo?.isDirectionalUser) {
        forkJoin([getCode$, getType$]).subscribe({
          next: ([codeResponse, typeResponse]) => {
            this.code = codeResponse['generatedCode'];
            //this.form.get('code')?.setValue(this.code);
            this.typeOptions = typeResponse['types'];
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
      } else {
        forkJoin([getCode$, getType$, getManager$]).subscribe({
          next: ([codeResponse, typeResponse, managerResponse]) => {
            this.code = codeResponse['generatedCode'];
            this.firstNoteManagerOptions = managerResponse['types'];
            //this.form.get('code')?.setValue(this.code);
            this.typeOptions = typeResponse['types'];
            this.form.get('agency')?.disable();
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
      }
    }
    this.form.get('code')?.disable();
  }

  onSubmit(): void {
    if (this.form.valid) {
      if (!this.isModify && this.userInfo && this.code) {
        this.isLoading = true;

        const createAccountRequest: CreateAccountRequest =
          CreateAccountRequest.fromForm(this.form, this.code);

        const getCode$ = this.httpServicePlanAccounts.getCode(
          TYPE.ACCOUNT,
          this.userInfo?.node,
          this.userInfo?.agencyDescription.split(splitAgencyCode)[0]
        );

        const createAccount$ = this.httpServicePlanAccounts.createAccount(
          createAccountRequest,
          this.form.get('master')?.value.code
        );

        createAccount$
          .pipe(
            concatMap((_createAccountResponse) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Conto creato con successo',
              });
              return getCode$.pipe(
                concatMap((codeResponse) => {
                  this.code = codeResponse['generatedCode'];
                  //this.form.get('code')?.setValue(this.code);
                  return this.httpServicePlanAccounts.getAccounts(
                    this.userInfo!.agencyDescription.split(splitAgencyCode)[0]
                  );
                })
              );
            })
          )
          .subscribe({
            next: (getAccountsResponse) => {
              this.planOfAccountsService.accountsSub.next(
                getAccountsResponse['accounts']
              );
              this.onCreate();
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                key: 'br',
                detail: 'Errore durante la creazione del conto',
              });
              //this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      } else {
        const formValue = this.form.getRawValue();

        const request = {
          ...formValue,
          ledger: formValue.master,
          agencyCode: formValue.agency.description,
          pathNodo: formValue.agency.code,
          accountNumber: this.account?.accountNumber,
          master: undefined,
          status: this.account?.status,
        };

        this.httpServicePlanAccounts
          .modifyAccount(request)
          .pipe(
            concatMap((_modifyResponse) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Conto modificato con successo',
              });
              return this.httpServicePlanAccounts.getAccounts(
                this.userInfo!.agencyDescription.split(splitAgencyCode)[0]
              );
            })
          )
          .subscribe({
            next: (getAccountResponse) => {
              this.planOfAccountsService.accountsSub.next(
                getAccountResponse['accounts']
              );
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              //this.modalService.showError(error);

              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                key: 'br',
                detail: 'Errore durante la creazione del conto',
              });
              this.isLoading = false;
            },
          });
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Successo',
        detail: `Conto ${this.isModify ? 'modificato' : 'creato'} con successo`,
      });
      this.modalRef?.hide();
      this.form.reset();
    } else {
      this.form.markAllAsTouched();
    }
  }

  getTitle(): string {
    return this.account ? 'Modifica Conto' : 'Nuovo Conto';
  }

  getButtonTitle(): string {
    return this.account ? 'Modifica' : 'Crea';
  }

  goBack() {
    if (this.isModify) {
      this.populateForm();
    }
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  resetModal(): void {
    this.form.reset();
  }

  onCreate(): void {
    this.form.reset({
      carryDate: new Date(),
      agency: this.userInfo?.isDirectionalUser
        ? null
        : {
            code: this.userInfo?.node,
            description: this.userInfo?.agencyDescription,
          },
    });

    this.form.get('type')?.enable();
    this.form.get('agency')?.enable();
    this.form.get('code')?.disable();
    this.isModify = false;
  }

  populateForm() {
    this.form.setValue({
      name: this.account?.name ? this.account?.name : '',
      code: this.account?.code ? this.account.code : '',
      type: this.account?.type ? this.account.type : null,
      carryDate: this.account?.carryDate
        ? new Date(this.account.carryDate)
        : new Date(),
      carry: this.account?.carry ? this.account.carry : '',
      firstNoteManager: this.account?.firstNoteManager
        ? this.account.firstNoteManager
        : null,
      agency: this.account?.agency ? this.account.agency : null,
      master: this.account?.ledger ? this.account.ledger : null,
    });
    if (this.account?.linkedSubAccounts) {
      if (this.account.linkedSubAccounts.length > 0) {
        this.form.get('type')?.disable();
      } else {
        this.form.get('type')?.enable();
      }
      this.form.get('code')?.disable();
      this.form.get('agency')?.disable();
    }

    const ledgerInfo$ = this.httpServicePlanAccounts.getAllInfos(
      TYPE.LEDGER,
      this.account?.type.code,
      this.account?.agency?.description.split(splitAgencyCode)[0],
      this.account?.agency.code
    );

    const managerInfo$ = this.httpServicePlanAccounts.getAllInfos(
      TYPE.GESTORE,
      undefined,
      this.account?.agency?.description.split(splitAgencyCode)[0],
      this.account?.agency.code
    );

    forkJoin([ledgerInfo$, managerInfo$]).subscribe({
      next: ([ledgerResponse, managerResponse]) => {
        this.masterOptions = ledgerResponse['types'];
        this.firstNoteManagerOptions = managerResponse['types'];
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
        this.isLoading = false;
      },
    });
  }

  onChangeType(event: DropdownChangeEvent): void {
    const agency = this.form.get('agency')?.value;
    const type = this.form.get('type')?.value;
    if (agency && type) {
      this.isLoading = true;
      this.httpServicePlanAccounts
        .getAllInfos(
          TYPE.LEDGER,
          type?.code,
          agency?.description?.split(splitAgencyCode)?.[0],
          agency?.code
        )
        .subscribe({
          next: (response) => {
            this.form.get('master')?.setValue(null);
            this.masterOptions = response['types'];
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.modalService.showError(error);
          },
        });
    }
  }
  onChangeAgency(event: AutoCompleteSelectEvent): void {
    const agency = this.form.get('agency')?.value;
    const type = this.form.get('type')?.value;
    const managerInfos$ = this.httpServicePlanAccounts.getAllInfos(
      TYPE.GESTORE,
      undefined,
      agency?.description.split(splitAgencyCode)[0],
      agency?.code
    );

    if (agency && type) {
      const ledgerInfo$ = this.httpServicePlanAccounts.getAllInfos(
        TYPE.LEDGER,
        type?.code,
        agency?.description.split(splitAgencyCode)[0],
        agency?.code
      );

      forkJoin([ledgerInfo$, managerInfos$]).subscribe({
        next: ([ledgerResponse, managerResponse]) => {
          this.masterOptions = ledgerResponse['types'];
          this.firstNoteManagerOptions = managerResponse['types'];
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    } else {
      managerInfos$.subscribe({
        next: (response) => {
          this.firstNoteManagerOptions = response['types'];
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    }
  }

  onChangeMaster(event: DropdownChangeEvent): void {
    if (this.code) {
      const master: number = parseInt(this.form.get('master')?.value.code, 10);
      if (!this.isModify) {
        this.form.get('code')?.setValue(master + this.code);
        this.form.get('code')?.enable();
      } else {
        if (this.account?.accountNumber) {
          const accountNumber = this.account?.accountNumber;
          this.form.get('code')?.setValue(master + accountNumber);
        } else {
          this.modalService.showModal(
            new ModalInfos('Errore', 'Informazioni conto mancanti', () => {
              window.open('/', '_self');
            })
          );
        }
      }
    }
  }

  onClearMaster(event: Event | undefined): void {
    this.form.get('code')?.setValue(null);
    this.form.get('code')?.disable();
  }

  onClearAgency(event: Event | undefined): void {
    this.form.get('firstNoteManager')?.setValue(null);
    this.form.get('master')?.setValue(null);
    this.form.get('code')?.setValue(null);
    this.form.get('code')?.disable();
  }

  onClearType(event: Event | undefined): void {
    this.form.get('master')?.setValue(null);
    this.form.get('code')?.setValue(null);
    this.form.get('code')?.disable();
  }

  getDirty = getDirty;
}
