import { Component } from '@angular/core';
import { AddRowBankRecordComponent } from '../../../components/forms/bank-records/add-row-bank-record/add-row-bank-record.component';

@Component({
  selector: 'app-add-row-bank-record-screen',
  standalone: true,
  imports: [AddRowBankRecordComponent],
  templateUrl: './add-row-bank-record.component.html',
  styleUrl: './add-row-bank-record.component.scss',
})
export class AddRowBankRecordScreen {}
