import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { CommissionDetail } from '../../../models/commission-detail';
import { CommissionTableResponse } from '../../../models/commission-table/commission_table_response';
import { Router } from '@angular/router';
import { COMMISSION_PATH } from '../../../enums/path';
import { HttpUtilisCommissions } from '../../../network/http-utilis-commissions';
import { catchError, Subscription, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomError } from '../../../models/error/custom-error';
import { ModalService } from '../../../services/modal/modal.service';
import { getType } from '../../../utils';

@Component({
  selector: 'app-commission-table',
  standalone: true,
  imports: [TableModule, ButtonModule, CommonModule],
  templateUrl: './commission-table.component.html',
  styleUrl: './commission-table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CommissionTableComponent implements OnInit, OnDestroy {
  editNewCommission: boolean = false;
  commissions: CommissionDetail[] = [];
  response: CommissionTableResponse | undefined;
  isLoading: boolean = false;
  isEmbeddedEmpty: boolean = false;
  rowsPerPage: number = 10;
  sortField: string | undefined = undefined;
  subComTable: Subscription | undefined;

  constructor(
    public commissionFormService: CommissionsFormService,
    private router: Router,
    private httpUtils: HttpUtilisCommissions,
    private modalService: ModalService
  ) {}
  ngOnDestroy(): void {
    this.subComTable?.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.subComTable = this.commissionFormService.$CommissionTableSub.subscribe(
      {
        next: (value) => {
          if (value !== undefined) {
            this.commissionFormService.commissionTableRequest = value;
            this.httpUtils
              .getCommissions(value, this.sortField, this.rowsPerPage)
              .pipe(
                catchError((error: CustomError) => {
                  return throwError(() => error);
                })
              )
              .subscribe({
                next: (response) => {
                  this.response = response;
                  this.isLoading = false;
                  this.isEmbeddedEmpty = this.embeddedIsEmpty(response);
                },
                error: (error: HttpErrorResponse) => {
                  this.isLoading = false;

                  this.modalService.showError(error);
                },
              });
          }
        },
      }
    );

    //this.response = CommissionTableResponse.fromJson(commissionTableResponse);
  }

  onModify(id: number) {
    this.router.navigate([COMMISSION_PATH.EDIT], { queryParams: { id: id } });
  }

  onDetail(id: number) {
    this.router.navigate([COMMISSION_PATH.DETAIL], { queryParams: { id: id } });
  }

  onCreate() {
    let collaborator = getType(this.commissionFormService);

    this.router.navigate([COMMISSION_PATH.ADD], {
      queryParams: { type: collaborator },
    });
  }

  embeddedIsEmpty(commissionTableResponse: CommissionTableResponse): boolean {
    if (commissionTableResponse === undefined) {
      return true;
    } else {
      return commissionTableResponse.embedded.length === 0;
    }
  }

  updateRowsPerPage(newRowsValue: number): void {
    this.rowsPerPage = newRowsValue;
  }

  loadData($event: TableLazyLoadEvent) {
    let sortField: string | undefined = undefined;
    let page: number | undefined = undefined;
    let rows: number | undefined = undefined;

    if ($event.sortField !== undefined) {
      sortField = `${$event.sortField}.${
        $event.sortOrder === 1 ? 'asc' : 'desc'
      }`;
    }
    if (
      $event.first !== undefined &&
      $event.rows !== undefined &&
      $event.rows !== null
    ) {
      page = $event.first / $event.rows;
      rows = $event.rows;
    }

    if (this.commissionFormService.commissionTableRequest !== undefined)
      this.httpUtils
        .getCommissions(
          this.commissionFormService.commissionTableRequest,
          sortField,
          rows,
          page
        )
        .subscribe({
          next: (response) => {
            this.response = response;
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.modalService.showError(error);
          },
        });

    if (rows) this.updateRowsPerPage(rows);
    if (sortField) this.sortField = sortField;
  }
}
