<p-dialog
  [header]="isModify ? 'Modifica Prima Nota ' : 'Crea Prima Nota '"
  [(visible)]="visible"
  [style]="{
    width: '50rem',
    height: '30rem',
    'min-width': '49rem',
    'min-height': '29rem',
    'max-height': '30rem',

  }"
  (onHide)="onDialogHide()"
>
  <div class="commission-form">
    <div *ngIf="isLoading" class="spinner-overlay">
      <div class="spinner-container">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-4">
          <label for="operationID">Numero operazione</label>
          <input
            pInputText
            id="operationID"
            type="text"
            formControlName="operationID"
            [placeholder]="isModify ? 'Inserisci' : 'Generato automaticamente'"
            class="commission-input my-2"
          />
        </div>
        <div class="col-lg-4">
          <label for="description">Descrizione</label>
          <input
            pInputText
            id="description"
            type="text"
            formControlName="description"
            placeholder="Inserisci"
            class="commission-input my-2"
          />
        </div>
        <div class="col-lg-4">
          <label for="competenceDate">Data competenza</label>
          <p-calendar
            id="competenceDate"
            formControlName="competenceDate"
            [showIcon]="true"
            styleClass="commission-calendar my-2"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="account">Conto*</label>
          <p-dropdown
            id="account"
            formControlName="account"
            [options]="accountOptions ?? []"
            filter="true"
            filterBy="displayName"
            optionLabel="displayName"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            showClear="true"
            (onChange)="onChangeAccount($event)"
            (onClear)="onClearAccount()"
            [ngClass]="{
              'ng-dirty': getDirty(form, 'account'),

            }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
          ></p-dropdown>
        </div>
        <div class="col-lg-4">
          <label for="mode">Modalità</label>
          <p-dropdown
            id="mode"
            formControlName="mode"
            [options]="modeOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            [readonly]="this.form.get('account')?.value === null"
            showClear="true"
            [ngClass]="{
              'ng-dirty': getDirty(form, 'mode'),
              'dropdown-disabled': this.form.get('account')?.value === null
            }"
          ></p-dropdown>
        </div>
        <div class="col-lg-4">
          <label for="registryDate">Data registrazione</label>
          <p-calendar
            id="registryDate"
            formControlName="registryDate"
            [showIcon]="true"
            styleClass="commission-calendar my-2"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="revenueGive">Entrate</label>
          <input
            pInputText
            id="revenueGive"
            type="text"
            formControlName="revenueGive"
            [readOnly]="isExpenseGetFilled()"
            [ngClass]="{
              'commission-input-disabled': isExpenseGetFilled(),
              'ng-dirty': getDirty(form, 'revenueGive')
            }"
            placeholder="Inserisci"
            class="commission-input my-2"
          />
        </div>
        <div class="col-lg-4">
          <label for="expenseGet">Uscite</label>
          <input
            pInputText
            id="expenseGet"
            type="text"
            formControlName="expenseGet"
            [readOnly]="isRevenueGiveFilled()"
            [ngClass]="{
              'commission-input-disabled': isRevenueGiveFilled()
            }"
            placeholder="Inserisci"
            class="commission-input my-2"
          />
        </div>
        <div class="col-lg-4">
          <label for="policyNumber">Numero polizza</label>
          <div *ngIf="!this.firstNote?.manuallyCreated && this.isModify">
            <a [href]="this.firstNote?.policyLink">
              {{ this.firstNote?.policyNumber }}
            </a>
          </div>
          <div *ngIf="this.firstNote?.manuallyCreated || !this.isModify">
            <input
              pInputText
              id="policyNumber"
              type="text"
              formControlName="policyNumber"
              placeholder="Inserisci"
              class="commission-input my-2"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="manager">Gestore nodo</label>
          <p-dropdown
            id="manager"
            formControlName="manager"
            [options]="managerOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            showClear="true"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'manager') }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
          ></p-dropdown>
        </div>
        <div class="col-lg-4">
          <label for="producer">Produttore polizza</label>
          <input
            pInputText
            id="producer"
            type="text"
            formControlName="producer"
            placeholder="Inserisci"
            class="commission-input my-2"
          />
        </div>
        <div class="col-lg-4">
          <label for="operationIdRev">Num. operazione stornata</label>
          <input
            pInputText
            id="operationIdRev"
            type="text"
            formControlName="operationIdRev"
            placeholder="Inserisci"
            class="commission-input my-2"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary button mx-3 my-2" type="submit">
          SALVA
        </button>
      </div>
    </form>
  </div>
</p-dialog>
