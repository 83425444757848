import { CommonModule, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { BANK_RECORD_PATH } from '../../../enums/path';
import { BankRecRowDetailResp } from '../../../models/bank-records/bank-record-detail-row-response';
import { HttpUtilsBankRecords } from '../../../network/http-utilis-bank-records';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { getType } from '../../../utils';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-detail-row-bank-record',
  standalone: true,
  imports: [DatePipe, TableModule, CommonModule, ImageModule],
  templateUrl: './detail-row-bank-record.component.html',
  styleUrl: './detail-row-bank-record.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DetailRowBankRecordScreen implements OnInit, OnDestroy {
  rowDetail: BankRecRowDetailResp | undefined;
  $subRoute: Subscription | undefined;
  bankRecordRowID: number | undefined;
  userID: number | undefined;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    public commissionService: CommissionsFormService,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService,
    private activatedRouter: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this.$subRoute?.unsubscribe();
  }
  ngOnInit(): void {
    this.$subRoute = this.activatedRouter.queryParams.subscribe((params) => {
      this.bankRecordRowID = params['id'];
      this.userID = params['userId'];

      if (this.bankRecordRowID && this.userID) {
        this.isLoading = true;
        this.httpUtilsBank
          .getRowDetailBankRec(this.userID, this.bankRecordRowID)
          .subscribe({
            next: (response) => {
              this.rowDetail = response;
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.modalService.showError(error);
            },
          });
      }
    });
  }

  goBack(): void {
    this.router.navigate([BANK_RECORD_PATH.ADD], {
      queryParams: {
        type: getType(this.commissionService),
      },
    });
  }
}
