import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Table, TableFilterEvent, TableModule } from 'primeng/table';
import { Manager } from '../../../models/plan-accounts/account/create-account-request';
import { CalendarModule } from 'primeng/calendar';
import { FirstNote } from '../../../models/first-note/first-note';
import { ModifyModalComponent } from '../../../components/modals/first-note/modify-modal/modify-modal.component';
import { FirstNoteService } from '../../../services/first-note/first-note.service';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { DoubleEntryModalComponent } from '../../../components/modals/first-note/double-entry-modal/double-entry-modal.component';
import { Modal } from 'bootstrap';
import { HttpUtilisFirstNote } from '../../../network/http-utilis-first-note';
import { RadioButtonClickEvent, RadioButtonModule } from 'primeng/radiobutton';
import { DateType, FirstNoteFilter } from '../../../enums/first-note';
import { concatMap, Subscription } from 'rxjs';
import { UserInfo } from '../../../models/user/user-info';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { TYPE } from '../../../enums/path-of-accounts';
import { splitAgencyCode, typeCalendar } from '../../../constants/general';
import { ModalService } from '../../../services/modal/modal.service';
import { BankRecordUtilis } from '../../../utils/bank-record-utilis';
import { AccountDetailModalComponent } from '../../../components/modals/first-note/account-detail-modal/account-detail-modal.component';
import { ManagerOperation } from '../../../models/first-note/manager-operation';
import { MessageService } from 'primeng/api';
import { VISIBILITY } from '../../../enums/visibility';
import { ActivatedRoute } from '@angular/router';
import { DetailAccountFirstNote } from '../../../models/first-note/detail-account';
import { handleError } from '../../../utils';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-first-note-screen',
  standalone: true,
  imports: [
    ModifyModalComponent,
    DropdownModule,
    CalendarModule,
    CommonModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    CustomCurrencyPipe,
    DoubleEntryModalComponent,
    RadioButtonModule,
    AccountDetailModalComponent,
  ],
  providers: [DatePipe],
  templateUrl: './first-note-screen.component.html',
  styleUrl: './first-note-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FirstNoteScreenComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: Table | undefined;
  form: FormGroup = new FormGroup({});
  typeCalendarOptions = typeCalendar;
  firstNoteManagerList: Manager[] = [];
  allFirstNoteList: FirstNote[] | undefined;
  firstNoteList: FirstNote[] = [];
  firstNoteExport: FirstNote[] = [];
  modifyFirstNoteValue: FirstNote | undefined;
  firstNoteValueDetail: FirstNote | undefined;
  showTable: boolean = false;
  filterSub$: Subscription | undefined;
  isVisibleSub$: Subscription | undefined;
  queryParamsSub$: Subscription | undefined;
  filter: FirstNoteFilter = FirstNoteFilter.PRIMANOTA;
  enumFilter = FirstNoteFilter;
  modalPartitaDoppia: any | undefined;
  modalAccountDetail: any | undefined;
  modalRefPartitaDoppia: Modal | undefined;
  modalRefAccountDetail: Modal | undefined;
  currentDetailAccountFirstNote: DetailAccountFirstNote | undefined;
  userInfo: UserInfo | undefined;
  isLoading = false;
  isVisible = false;
  isEditableReportistica: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public firstNoteService: FirstNoteService,
    public httpFirstNoteService: HttpUtilisFirstNote,
    public httpPlanOfAccounts: HttpUtilisPlanOfAccounts,
    public modalService: ModalService,
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute,
    private titleService: Title
  ) {
    this.filterSub$ = this.firstNoteService.filterSubject.subscribe(
      (_value) => {
        this.getFirstNoteByFilter();
      }
    );

    this.isVisibleSub$ =
      this.firstNoteService.modifyModalVisibilitySub.subscribe((isVisible) => {
        this.isVisible = isVisible;
      });

    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnDestroy(): void {
    this.filterSub$?.unsubscribe();
    this.isVisibleSub$?.unsubscribe();
    this.queryParamsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.userInfo) {
      this.isEditableReportistica = this.userInfo.userFunctions.includes(
        VISIBILITY.REPORTISTICA
      );
    }

    this.initState();
    this.initForm();
    this.modalPartitaDoppia = document.getElementById('modalPartitaDoppia');
    this.modalAccountDetail = document.getElementById('accountDetailModal');
    this.onRedirectFromCash();
    this.titleService.setTitle('Prima Nota');
  }

  initForm() {
    let today: Date = new Date();
    let threeMonthsAgo: Date = new Date();
    threeMonthsAgo.setDate(today.getDate() - 7);

    this.form = this.formBuilder.group({
      firstNoteManager: new FormControl(undefined, [Validators.required]),
      type: new FormControl(DateType.REGISTRY, [Validators.required]),
      dates: new FormControl([threeMonthsAgo, today], [Validators.required]),
    });
  }

  onFilter(event: RadioButtonClickEvent) {
    this.firstNoteService.filterSubject.next(event.value);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.firstNoteService.firstNoteMangerSub.next(
        new ManagerOperation(this.form.get('firstNoteManager')?.value, false)
      );
      this.firstNoteService.filterSubject.next(this.filter);

      this.showTable = true;
    } else {
      this.form.markAllAsTouched();
    }
  }

  onDelete(firstNote: FirstNote) {
    this.isLoading = true;
    this.httpFirstNoteService.deleteFirstNote(firstNote).subscribe({
      next: (_deleteResponse) => {
        this.firstNoteService.filterSubject.next(this.filter);
        this.messageService.add({
          severity: 'success',
          summary: 'Successo',
          key: 'br',
          detail: 'Riga eliminata con successo',
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'br',
          detail: 'Impossibile eliminare la riga',
        });
      },
    });
  }

  onDuplicate(firstNote: FirstNote) {
    const duplicateNote: FirstNote = FirstNote.duplicateRequest(firstNote);
    this.isLoading = true;
    this.httpFirstNoteService.createFirstNote(duplicateNote).subscribe({
      next: (_createResponse) => {
        this.firstNoteService.filterSubject.next(this.filter);
        this.messageService.add({
          severity: 'success',
          summary: 'Successo',
          key: 'br',
          detail: 'Riga duplicata con successo',
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'br',
          detail: 'Impossibile duplicare la riga',
        });
        //this.modalService.showError(error);
        this.isLoading = false;
      },
    });
  }

  onPartitaDoppia(firstNote: FirstNote) {
    this.firstNoteValueDetail = firstNote;
    this.firstNoteService.doubleEntryModalVisibilitySub.next(true);
  }

  onAccountDetail(firstNote: FirstNote) {
    if (this.modalAccountDetail && firstNote.account) {
      this.isLoading = true;
      this.httpFirstNoteService
        .getAccountDetail(
          firstNote.account?.accountNumber,
          firstNote.account?.agencyCode
        )
        .subscribe({
          next: (response) => {
            this.currentDetailAccountFirstNote =
              DetailAccountFirstNote.fromJson(
                response as DetailAccountFirstNote
              );
            this.modalRefAccountDetail = new Modal(this.modalAccountDetail, {
              keyboard: false,
              backdrop: 'static',
            });
            this.isLoading = false;
            this.modalRefAccountDetail.show();
          },
          error: (error) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
          },
        });
    }
  }

  onModify(firstNote: FirstNote) {
    this.modifyFirstNoteValue = firstNote;
    this.firstNoteService.modifyModalVisibilitySub.next(true);
  }

  onCreate() {
    this.modifyFirstNoteValue = undefined;
    this.firstNoteService.modifyModalVisibilitySub.next(true);
  }

  onExport(): void {
    this.isLoading = true;
    const ids: (string | undefined)[] | undefined =
      this.firstNoteExport.map((firstNote) => firstNote.operationID) ?? [];
    this.httpFirstNoteService.exportFirstNoteRows(ids).subscribe({
      next: (response) => {
        this.isLoading = false;
        BankRecordUtilis.downloadExcel(response['base64']);
      },
      error: (error) => {
        //this.modalService.showError(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'br',
          detail: 'Impossibile esportare la tabella',
        });
        this.isLoading = false;
      },
    });
  }

  getFirstNoteByFilter() {
    this.isLoading = true;
    switch (this.filter) {
      case FirstNoteFilter.PRIMANOTA:
        this.httpFirstNoteService
          .getFirstNotes(
            this.form.get('firstNoteManager')?.value,
            this.form.get('dates')?.value,
            this.form.get('type')?.value
          )
          .subscribe({
            next: (firstNotesResponse) => {
              this.allFirstNoteList = FirstNote.fromCall(
                firstNotesResponse['accountingEntries'] ?? []
              );
              this.firstNoteList = (this.allFirstNoteList ?? []).filter(
                (firstNote) => {
                  return !firstNote.isBrogliaccio;
                }
              );
              this.isLoading = false;
            },
            error: (error) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });

        break;
      case FirstNoteFilter.BROGLIACCIO:
        this.httpFirstNoteService
          .getFirstNotes(
            this.form.get('firstNoteManager')?.value,
            this.form.get('dates')?.value,
            this.form.get('type')?.value
          )
          .subscribe({
            next: (firstNotesResponse) => {
              this.allFirstNoteList = FirstNote.fromCall(
                firstNotesResponse['accountingEntries'] ?? []
              );
              this.firstNoteList = (this.allFirstNoteList ?? []).filter(
                (firstNote) => {
                  return firstNote.isBrogliaccio;
                }
              );
              this.isLoading = false;
            },

            error: (error) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });

        break;
      case FirstNoteFilter.ENTRAMBI:
        this.httpFirstNoteService
          .getFirstNotes(
            this.form.get('firstNoteManager')?.value,
            this.form.get('dates')?.value,
            this.form.get('type')?.value
          )
          .subscribe({
            next: (firstNotesResponse) => {
              this.allFirstNoteList = FirstNote.fromCall(
                firstNotesResponse['accountingEntries'] ?? []
              );
              this.firstNoteList = this.allFirstNoteList ?? [];
              this.isLoading = false;
            },
            error: (error) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });

        break;
    }
  }

  onFilterTable(event: TableFilterEvent) {
    this.firstNoteExport = (event.filteredValue as FirstNote[]) ?? [];
  }

  initState() {
    // this.isLoading = true;
    const agencyCode =
      this.userInfo?.agencyDescription.split(splitAgencyCode)[0];
    this.httpPlanOfAccounts
      .getAllInfos(
        TYPE.GESTORE_PN,
        'ONLY_GEST_PN',
        agencyCode,
        this.userInfo?.node
      )
      .subscribe({
        next: (response) => {
          this.firstNoteManagerList = response['types'];
          // this.isLoading = false;
        },
        error: (error) => {
          this.modalService.showError(error);
          // this.isLoading = false;
        },
      });
  }

  onRedirectFromCash() {
    this.queryParamsSub$ = this.activatedRouter.queryParams.subscribe(
      (params) => {
        if (
          params['mode'] &&
          params['date'] &&
          params['firstNoteManagerCode'] &&
          params['firstNoteManagerDescription'] &&
          params['firstNoteManagerDetail'] &&
          params['account']
        ) {
          this.isLoading = true;
          const mode: string = params['mode'];
          const date = params['date'];
          const account = params['account'];

          const firstNoteManager: Manager = new Manager(
            params['firstNoteManagerCode'],
            params['firstNoteManagerDescription'],
            params['firstNoteManagerDetail']
          );

          this.form.get('firstNoteManager')?.setValue(firstNoteManager);
          this.form.get('dates')?.setValue([new Date(date), new Date(date)]);
          this.httpFirstNoteService
            .getFirstNotes(
              firstNoteManager,
              this.form.get('dates')?.value,
              DateType.REGISTRY
            )
            .subscribe({
              next: (firstNotesResponse) => {
                this.allFirstNoteList = FirstNote.fromCall(
                  firstNotesResponse['accountingEntries'] ?? []
                );
                this.firstNoteService.firstNoteMangerSub.next(
                  new ManagerOperation(
                    this.form.get('firstNoteManager')?.value,
                    false
                  )
                );
                this.firstNoteService.filterSubject.next(this.filter);
                this.showTable = true;

                if (this.table) {
                  this.table.filter(mode, 'mode.description', 'contains');
                  this.table.filter(account, 'account.displayName', 'contains');
                }
              },
              error: (error) => {
                this.isLoading = false;
                this.modalService.showError(error);
              },
            });
        }
      }
    );
  }
}
