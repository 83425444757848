<p-dialog
  [header]="this.modalTitle"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70%',
     minHeight: '40rem',}"
  (onHide)="onDialogHide()"
>
  <div class="commission-form">
    <div *ngIf="isLoading" class="spinner-overlay">
      <div class="spinner-overlay">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-4">
          <label for="reportName">Nome Report</label>
          <p-dropdown
            id="reportName"
            formControlName="reportName"
            [options]="reportNameOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            showClear="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'reportName') }"
          ></p-dropdown>
        </div>
        <div class="col-lg-4">
          <label for="dates">Periodo</label>
          <p-calendar
            id="dates"
            formControlName="dates"
            [showIcon]="true"
            styleClass="commission-calendar my-2"
            dateFormat="dd/mm/yy"
            selectionMode="range"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'dates') }"
          ></p-calendar>
        </div>
        <div class="col-lg-4">
          <label for="exportingDate">Data di Esportazione</label>
          <p-dropdown
            id="exportingDate"
            formControlName="exportingDate"
            [options]="exportingDateOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            showClear="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'exportingDate') }"
          ></p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="frequency">Frequenza schedulazione</label>
          <p-dropdown
            id="frequency"
            formControlName="frequency"
            [options]="frequencyOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown my-2  "
            showClear="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'frequency') }"
          ></p-dropdown>
        </div>
        <div *ngIf="this.userInfo?.isDirectionalUser" class="col-lg-4">
          <label for="agency">Agenzia</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown my-2"
            formControlName="agency"
            showClear="true"
            [dropdown]="true"
            [suggestions]="filteredValues"
            (completeMethod)="filterValues($event)"
            placeholder="Seleziona..."
            showEmptyMessage="false"
            field="description"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'agency') }"
          />
        </div>
      </div>
      <hr class="mb-4" />
      <p-table
        [value]="this.getReceiversArray().controls"
        dataKey="id"
        [scrollable]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="table-report text-center"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2rem">
              <p-button
                type="button"
                icon="pi pi-plus"
                styleClass="p-button-text"
                (click)="addRow()"
              ></p-button>
            </th>
            <th style="width: auto">Destinatario</th>
            <th style="width: auto">Tipo Destinatario</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="text-center">
              <i class="pi pi-trash icon" (click)="onRemove(rowIndex)"></i>
            </td>
            <td>
              <div formArrayName="receivers">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    type="email"
                    id="email"
                    formControlName="email"
                    class="commission-input"
                    [ngClass]="{
                      'ng-dirty': getDirty(
                        this.getReceiversArray().controls[rowIndex],
                        'email'
                      )
                    }"
                  />
                </div>
              </div>
            </td>
            <td>
              <div formArrayName="receivers">
                <div [formGroupName]="rowIndex">
                  <p-dropdown
                    appendTo="body"
                    showClear="true"
                    formControlName="type"
                    optionLabel="description"
                    placeholder="Seleziona tipologia..."
                    styleClass="report-dropdown"
                    id="id"
                    [options]="receiversTypeOptions ?? []"
                    [ngClass]="{
                      'ng-dirty': getDirty(
                        this.getReceiversArray().controls[rowIndex],
                        'type'
                      )
                    }"
                  >
                  </p-dropdown>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary button mx-3 my-2" type="submit">
          SALVA
        </button>
      </div>
    </form>
  </div>
</p-dialog>
