export class BankRecordTableResponse {
  id: number;
  consolidated: boolean;
  registrationDate: string;
  codCollaborator: string;
  collaboratorName: string;
  collaboratorSurname: string;
  fullName: string;
  totStatementLordo: number;
  consolidatingUser: string;
  lastUpdateUser: string;
  lastUpdateDate: string;
  signedDocument: boolean;

  constructor(
    id: number,
    consolidated: boolean,
    registrationDate: string,
    codCollaborator: string,
    collaboratorName: string,
    collaboratorSurname: string,
    totStatementLordo: number,
    consolidatingUser: string,
    lastUpdateUser: string,
    lastUpdateDate: string,
    signedDocument: boolean
  ) {
    this.id = id;
    this.consolidated = consolidated;
    this.registrationDate = registrationDate;
    this.codCollaborator = codCollaborator;
    this.collaboratorName = collaboratorName;
    this.collaboratorSurname = collaboratorSurname;
    this.fullName = `${collaboratorName} ${collaboratorSurname}`;
    this.totStatementLordo = totStatementLordo;
    this.consolidatingUser = consolidatingUser;
    this.lastUpdateUser = lastUpdateUser;
    this.lastUpdateDate = lastUpdateDate;
    this.signedDocument = signedDocument;
  }

  static fromJson(json: BankRecordTableResponse): BankRecordTableResponse {
    return new BankRecordTableResponse(
      json.id,
      json.consolidated,
      json.registrationDate,
      json.codCollaborator,
      json.collaboratorName,
      json.collaboratorSurname,
      json.totStatementLordo,
      json.consolidatingUser,
      json.lastUpdateUser,
      json.lastUpdateDate,
      json.signedDocument
    );
  }
}
