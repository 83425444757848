import { Category } from '../models/commission-detail';

export const CATEGORY_OPTIONS: string[] = ['RE', 'VITA', 'AUTO', 'ARD'];
export const MOCK_VALUES: Category[] = [
  { code: '0', description: 'RE' },
  { code: '1', description: 'VITA' },
  { code: '2', description: 'AUTO' },
  { code: '3', description: 'ARD' },
];
export const COMMISSION_TYPE: string[] = [
  'PROVVIGIONE BASE',
  'PROVVIGIONE SUPPLEMENTARE',
  'PROVVIGIONE FACOLTATIVA',
  'PROVVIGIONE INCENTIVANTE',
];
export const COMMISIOM_MODE: string[] = [
  '% Premio netto',
  '% Provvigione agenzia',
  '% Imponibile annuo',
  '% Provvigione acquisto',
];

export const COMMISSION_BASE: string[] = ['BASE', 'NON BASE'];
