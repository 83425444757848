import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LinkedSubAccount } from '../../models/plan-accounts/account/account';

@Injectable({
  providedIn: 'root',
})
export class LinkedSubAccountsService {
  public linkedSubAccountsSubject = new BehaviorSubject<LinkedSubAccount[]>([]);
  linkedSubAccounts$ = this.linkedSubAccountsSubject.asObservable();

  addLinkedSubAccount(newSubAccount: LinkedSubAccount) {
    const currentSubAccounts = this.linkedSubAccountsSubject.getValue();
    this.linkedSubAccountsSubject.next([...currentSubAccounts, newSubAccount]);
  }

  removeLinkedSubAccount(subAccount: LinkedSubAccount) {
    const currentSubAccounts = this.linkedSubAccountsSubject.value;
    this.linkedSubAccountsSubject.next(
      currentSubAccounts.filter((subAcc) => subAcc !== subAccount)
    );
  }

  getLinkedSubAccounts() {
    return this.linkedSubAccountsSubject.getValue();
  }
}
