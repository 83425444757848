import { Injectable } from '@angular/core';
import {
  Agency,
  Category,
  CommissionDetail,
  Producer,
  Product,
} from '../../models/commission-detail';
import { CommissionTableResponse } from '../../models/commission-table/commission_table_response';
import { AssoDissoWrapper } from '../../models/associate-dissociate/asso-disso-wrapper';
import { CommissionTableRequest } from '../../models/commission-table/commission_table_request';
import { Subject } from 'rxjs';
import { CommissionData } from '../../models/commission/commission-data';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CommissionsFormService {
  showInfoTableCommission: boolean = true;
  showInfoTableBankRecord: boolean = false;

  isProducer: boolean | undefined;
  commissionTableResponse: CommissionTableResponse | undefined;
  commissionTableRequest: CommissionTableRequest | undefined;
  selectedCommission: CommissionDetail | undefined;
  tableTypes: string[] = [];
  $CommissionTableSub: Subject<CommissionTableRequest | undefined> =
    new Subject();
  formComSearch = new FormGroup({
    commissionTable: new FormControl(null, []),
    agency: new FormControl(null, []),
    producer: new FormControl(null, []),
    coinsuranceAgency: new FormControl(null, []),
    product: new FormControl(null, []),
    category: new FormControl(null, []),
    tableType: new FormControl(null, []),
    isActive: new FormControl(false, []),
  });
  categories: Category[] = [
    new Category('0', 'AUTO'),
    new Category('1', 'Category 1'),
    new Category('2', 'Category 2'),
    new Category('3', 'Category 3'),
    new Category('4', 'Category 4'),
  ];
  producers: Producer[] = [];
  products: Product[] = [];
  coinsuranceAngencies: Producer[] = [];
  agencies: Agency[] = [];

  commissionTypes: CommissionData | undefined;

  assoDissoWrapper = new AssoDissoWrapper();

  constructor() {
    this.isProducer = JSON.parse(
      sessionStorage.getItem('isProducer') || 'false'
    );
  }
}
