<div class="row border-white-bt m-0">
  <div class="col-2 logo-container d-flex justify-content-center">
    <img
      src="../../../assets/images/logo_itas.png"
      alt="image"
      class="img-fluid p-1 logo-image align-self-center"
    />
  </div>
  <div
    class="col-6 bar-container d-flex align-items-center justify-content-center"
  >
    <p-menubar
      [model]="commission"
      styleClass="menubar"
      [autoDisplay]="false"
    />
    <p-menubar
      [model]="backRecord"
      styleClass="menubar w-120 "
      [autoDisplay]="false"
    />
    <p-menubar
      [model]="plansAccounts"
      styleClass="menubar w-120 "
      [autoDisplay]="false"
    />
    <p-menubar
      [model]="cashControl"
      styleClass="menubar w-120 "
      [autoDisplay]="false"
    />
  </div>
  <div class="col-1 bar-container"></div>
  <div class="col-3 bar-container d-flex align-items-center text-white">
    <!--
    <div class="d-flex justify-content-center align-items-center">
      <i class="pi pi-user m-2"></i>
      <div>PEPEM (PEPEM UDETIR)</div>
    </div>
    <div class="hidden"></div>
    <div>
      <i class="pi pi-cog m-2"></i>
      <i class="pi pi-sign-out m-2"></i>
    </div>
    -->
  </div>
</div>
