export const DAY_NAMES = [
  'Domenica',
  'Lunedi',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
];

export const DAY_NAMES_MIN = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'];

export const DAY_NAMES_SHORT = [
  'Dom',
  'Lun',
  'Mar',
  'Mer',
  'Gio',
  'Ven',
  'Sab',
];

export const MONTH_NAMES = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const MONTH_NAMES_SHORT = [
  'Gen',
  'Feb',
  'Mar',
  'Apr',
  'Mag',
  'Giu',
  'Lug',
  'Ago',
  'Set',
  'Ott',
  'Nov',
  'Dic',
];
