<div *ngIf="isLoading" class="text-center">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</div>
<div *ngIf="!isLoading" class="commission-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="d-flex align-items-center my-4 go-back">
        <div (click)="goBack()">
          <i class="pi pi-arrow-left m-0 me-2 icon-list"> </i>
          <span class="label">Indietro</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="commissionTable">Nome tabella provvigionale*</label>
        <input
          pInputText
          id="commissionTable"
          type="text"
          formControlName="commissionTable"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <div
          *ngIf="this.commissionFormService.isProducer"
          [ngClass]="{ 'missing-field': this.showMissingProducer }"
        >
          <label for="producer">Produttore*</label>
          <div class="d-flex align-items-center my-2">
            <div>
              Elementi selezionati:
              {{ this.form.get("producer")?.value.length }}
            </div>
            <i
              data-bs-toggle="modal"
              data-bs-target="#associateModal"
              (click)="clickOpenModal('produttori')"
              class="pi pi-list icon mx-3"
            ></i>
          </div>
        </div>
        <div
          *ngIf="!this.commissionFormService.isProducer"
          [ngClass]="{ 'missing-field': this.showMissingCoinsuranceAgency }"
        >
          <label for="coinsuranceAgency">Agenzia Coassicurazione*</label>
          <div class="d-flex align-items-center my-2" id="coinsuranceAgency">
            <div>
              Elementi selezionati:
              {{ this.form.get("coinsuranceAgency")?.value.length }}
            </div>
            <i
              data-bs-toggle="modal"
              data-bs-target="#associateModal"
              (click)="clickOpenModal('agenzia Coassicurazione')"
              class="pi pi-list icon mx-3"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-12"
        [ngClass]="{ 'missing-field': this.showMissingProduct }"
      >
        <label for="product">Prodotto*</label>
        <div class="d-flex align-items-center my-2" id="product">
          <div>
            Elementi selezionati: {{ this.form.get("product")?.value.length }}
          </div>
          <i
            *ngIf="!disablePickList()"
            data-bs-toggle="modal"
            data-bs-target="#associateModal"
            (click)="clickOpenModal('prodotti')"
            class="pi pi-list icon mx-3"
          ></i>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="category">Categoria*</label>
        <p-dropdown
          id="category"
          formControlName="category"
          [options]="categories"
          placeholder="Seleziona..."
          styleClass="commission-dropdown mt-2"
          [showClear]="!this.isModifying ? true : false"
          [readonly]="this.isModifying"
          [ngClass]="{ 'commission-dropdown-disabled': this.isModifying }"
          optionLabel="description"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="validityStartDate">Data inizio validità*</label>
        <p-calendar
          id="validityStartDate"
          formControlName="validityStartDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="validityEndDate">Data fine validità</label>
        <p-calendar
          id="validityEndDate"
          formControlName="validityEndDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="tableType">Tipologia tabella*</label>
        <p-dropdown
          id="tableType"
          formControlName="tableType"
          [options]="baseTable"
          placeholder="Seleziona..."
          styleClass="commission-dropdown mt-2"
          showClear="true"
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.userInfo.isDirectionalUser">
        <div>
          <label for="agency">Agenzia*</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown my-2"
            formControlName="agency"
            [dropdown]="true"
            showClear="true"
            [suggestions]="filteredValues"
            (completeMethod)="filterValues($event, 'agency')"
            placeholder="Seleziona..."
            showEmptyMessage="false"
            field="description"
          />
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <p-table
      [value]="getRateTypes().controls"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="table-commission"
      dataKey="value.idd"
      rowExpandMode="single"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 2rem">
            <p-button
              type="button"
              icon="pi pi-plus"
              styleClass="p-button-text"
              [disabled]="this.form.get('category')?.value === null"
              (click)="addRateType()"
            ></p-button>
          </th>
          <th>Tipo Provvigione</th>
          <th>Modalità</th>
          <th>Perc.(%)</th>
          <th style="width: 2rem"></th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rateType
        let-expanded="expanded"
        let-rowIndex="rowIndex"
        let-editing="editing"
      >
        <tr>
          <td style="width: 2rem">
            <i class="pi pi-trash icon" (click)="deleteRateType(rowIndex)"></i>
          </td>
          <td>
            <div formArrayName="rateTypes">
              <div [formGroupName]="rowIndex">
                <p-dropdown
                  appendTo="body"
                  class="upward-dropdown"
                  formControlName="type"
                  [readonly]="this.form.get('category')?.value === null"
                  [options]="this.form.get('rateTypes')?.value[rowIndex]?.types"
                  placeholder="Seleziona..."
                  styleClass="commission-dropdown"
                  showClear="true"
                  optionLabel="description"
                  (onChange)="getModeInRateType(rowIndex)"
                ></p-dropdown>
              </div>
            </div>
          </td>
          <td>
            <div formArrayName="rateTypes">
              <div [formGroupName]="rowIndex">
                <p-dropdown
                  appendTo="body"
                  formControlName="mode"
                  [readonly]="
                    this.form.get('category')?.value === null ||
                    this.form.get('rateTypes')?.value[rowIndex]?.type === null
                  "
                  [options]="this.form.get('rateTypes')?.value[rowIndex]?.modes"
                  placeholder="Seleziona..."
                  styleClass="commission-dropdown"
                  showClear="true"
                  optionLabel="description"
                ></p-dropdown>
              </div>
            </div>
          </td>
          <td>
            <div formArrayName="rateTypes">
              <div [formGroupName]="rowIndex">
                <input
                  pInputText
                  type="number"
                  formControlName="percentage"
                  min="0"
                  max="100"
                  placeholder="Inserisci"
                  class="commission-input"
                  step="any"
                  inputmode="decimal"
                  lang="en"
                />
              </div>
            </div>
          </td>
          <td>
            <p-button
              type="button"
              text="true"
              pRipple
              [pRowToggler]="rateType"
              [text]="true"
              [rounded]="true"
              [plain]="true"
              [icon]="!expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
              class="icon"
            >
              ({{ rateType.value.variations.length }})</p-button
            >
          </td>
        </tr></ng-template
      >

      <ng-template
        pTemplate="rowexpansion"
        let-variations
        let-rowIndex="rowIndex"
      >
        <tr>
          <td colspan="7">
            <div class="pt-3 pb-3">
              <p-table
                [value]="getVariations(variations).controls"
                dataKey="value.idd"
              >
                <ng-template pTemplate="header" let-rowData let-i="rowIndex">
                  <tr>
                    <th style="width: 2rem">
                      <i
                        class="pi pi-plus icon"
                        (click)="addVariation(rowIndex)"
                      ></i>
                    </th>
                    <th>Variazione aliquota</th>
                    <th>Condizioni</th>
                    <th>Tipo variazione</th>
                    <th>Data</th>
                    <th>Perc.(%)</th>
                    <th>Netto</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-variation let-i="rowIndex">
                  <tr [formGroup]="variation">
                    <td>
                      <i
                        class="pi pi-trash icon"
                        (click)="deleteVariation(rowIndex, i)"
                      ></i>
                    </td>
                    <td>
                      <p-dropdown
                        appendTo="body"
                        formControlName="variation"
                        [options]="varAli"
                        optionLabel="description"
                        placeholder="Seleziona..."
                        styleClass="commission-dropdown variation-width"
                        showClear="true"
                      ></p-dropdown>
                    </td>
                    <td>
                      <p-dropdown
                        appendTo="body"
                        formControlName="conditions"
                        [options]="varConditions"
                        optionLabel="description"
                        placeholder="Seleziona..."
                        styleClass="commission-dropdown conditions-width"
                        showClear="true"
                        (onChange)="dataReseter(variation)"
                      ></p-dropdown>
                    </td>
                    <td>
                      <p-dropdown
                        appendTo="body"
                        formControlName="type"
                        [options]="varType"
                        optionLabel="description"
                        placeholder="Seleziona..."
                        styleClass="commission-dropdown type-width"
                        showClear="true"
                      ></p-dropdown>
                    </td>
                    <td>
                      <p-calendar
                        formControlName="date"
                        appendTo="body"
                        [showIcon]="true"
                        styleClass="commission-calendar my-2 date-width"
                        dateFormat="dd/mm/yy"
                      ></p-calendar>
                    </td>
                    <td>
                      <input
                        pInputText
                        type="number"
                        max="100"
                        min="0"
                        formControlName="percentageValue"
                        placeholder="Inserisci"
                        class="commission-input percentage-width"
                        [readonly]="isNetValueFilled(variation)"
                        [ngClass]="{
                          'commission-input-disabled':
                            isNetValueFilled(variation)
                        }"
                        step="any"
                        inputmode="decimal"
                        lang="en"
                      />
                    </td>
                    <td>
                      <input
                        pInputText
                        type="number"
                        formControlName="netValue"
                        placeholder="Inserisci"
                        min="0"
                        class="commission-input net-width"
                        [ngClass]="{
                          'commission-input-disabled':
                            isPercentageValueFilled(variation)
                        }"
                        [readonly]="isPercentageValueFilled(variation)"
                        step="any"
                        inputmode="decimal"
                        lang="en"
                      />
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td></tr></ng-template
    ></p-table>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong>{{ this.isModifying ? "Modifica" : "Crea" }}</strong>
      </button>
    </div>
  </form>
</div>

<!-- Modal picklist-->
<div
  class="modal fade"
  id="associateModal"
  tabindex="-1"
  aria-hidden="true"
  (blur)="onCloseModal()"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title commission-form" id="exampleModalLabel">
          {{ modalTitle | uppercase }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          (click)="onCloseModalPickList()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <app-associate-dissociate
          *ngIf="modalTitle === 'produttori'"
          [from]="notAssociatedProducer"
          [to]="this.form.get('producer')?.value"
          [type]="queryEnum.PRODUCERS"
        ></app-associate-dissociate>
        <app-associate-dissociate
          *ngIf="modalTitle === 'prodotti'"
          [from]="notAssociatedProduct"
          [to]="this.form.get('product')?.value"
          [type]="queryEnum.PRODUCTS"
        ></app-associate-dissociate>
        <app-associate-dissociate
          *ngIf="modalTitle === 'agenzia Coassicurazione'"
          [from]="notAssociatedCoinsuranceAgency"
          [to]="this.form.get('coinsuranceAgency')?.value"
          [type]="queryEnum.COINSURANCES"
        ></app-associate-dissociate>
      </div>
    </div>
  </div>
</div>

<!-- Modal reset product-->
<div class="modal" tabindex="-1" id="modalResetPro">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Attenzione</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Se modifichi la categoria, i prodotti precedentemente selezionati
          verranno rimossi
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="noResetProduct()"
          data-bs-dismiss="modal"
        >
          Annulla
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="resetProduct()"
          data-bs-dismiss="modal"
        >
          Procedi
        </button>
      </div>
    </div>
  </div>
</div>
