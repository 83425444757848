import { Component } from '@angular/core';
import { ErrorModalService } from '../../../services/error-modal/error-modal.service';
import { Modal } from 'bootstrap';
import { ErrorInfo } from '../../../models/error/error-info';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss',
})
export class ErrorModalComponent {
  errorInfo: ErrorInfo | undefined;

  constructor(private errorModalService: ErrorModalService) {}

  ngOnInit(): void {
    this.errorModalService.getSubject().subscribe((errorInfo) => {
      this.errorInfo = errorInfo;
      this.showModal();
    });
  }

  showModal(): void {
    const modalElement = document.getElementById('modalError') as HTMLElement;
    if (modalElement) {
      const modal = new Modal(modalElement, {
        backdrop: 'static',
        keyboard: false,
      });
      modal.show();
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('modalError') as HTMLElement;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  }
}
