<div class="commission-form" *ngIf="this.userInfo?.isDirectionalUser">
  <form [formGroup]="this.form!" (ngSubmit)="onSubmit()">
    <div class="d-flex align-items-center justify-content-between">
      <div class="col-lg-4">
        <label for="agency">Agenzia</label>
        <p-dropdown
          id="agency"
          formControlName="agency"
          [options]="agenciesOptions ?? []"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown"
          showClear="true"
          [filter]="true"
          filterBy="code,description"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
          (onChange)="onAgencyChange($event)"
          [ngClass]="{
            'ng-dirty': getDirty(form, 'agency'),
          }"
        ></p-dropdown>
      </div>
      <button class="btn btn-primary my-4" type="submit">
        <strong>CERCA</strong>
      </button>
    </div>
  </form>
</div>
<div *ngIf="isLoading" class="text-center spinner-overlay">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div class="text-end mt-3">
  <p-table
    *ngIf="this.showTable"
    [value]="subAccounts ?? []"
    dataKey="id"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass=" table-commission text-center"
    [(selection)]="selecteddSubAccounts"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
        <th style="width: auto"></th>
        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code" style="width: auto">
          Codice <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="type.description" style="width: auto">
          Modalità<p-sortIcon field="type.description"></p-sortIcon>
        </th>
        <th pSortableColumn="pathNodo" style="width: auto">
          Nodo Sottoconto <p-sortIcon field="pathNodo"></p-sortIcon>
        </th>
        <th pSortableColumn="dirCollection" style="width: auto">
          Incasso direzionale <p-sortIcon field="dirCollection"></p-sortIcon>
        </th>
        <th pSortableColumn="account.description" style="width: auto">
          Conto <p-sortIcon field="account.description"></p-sortIcon>
        </th>
        <th pSortableColumn="accountNode" style="width: auto">
          Nodo del conto
          <p-sortIcon field="accountNode"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agencyCode"
          style="width: auto"
        >
          Codice Agenzia
          <p-sortIcon field="agencyCode"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter field="name" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="code" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="type.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="pathNodo"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="showDir"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="account.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="accountNode"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter
            field="agencyCode"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account let-i="rowIndex">
      <tr>
        <td>
          <p-tableCheckbox [value]="account" />
        </td>
        <td>
          <i
            class="pi pi-pen-to-square icon col-md-6 d-flex"
            (click)="onModify(account)"
            title="Modifica"
          ></i>
        </td>

        <td class="text-center">
          {{ account.name }}
        </td>
        <td class="text-center">
          {{ account.code }}
        </td>
        <td class="text-center">
          {{ account.type.description }}
        </td>
        <td class="text-center">{{ account.pathNodo }}</td>
        <td class="text-center">{{ account.showDir }}</td>
        <td class="text-center">{{ account.account?.description ?? "" }}</td>
        <td class="text-center">{{ account.accountNode ?? "" }}</td>

        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ account.agencyCode }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-modal-add-sub-account
  [modalRefAdd]="this.modalRefAdd"
  [subAccount]="this.modifiedSubAccount"
></app-modal-add-sub-account>
