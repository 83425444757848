import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'EUR',
    symbolDisplay: boolean = true,
    digits: string = '1.2-2'
  ): string {
    if (value == null) {
      return '';
    }

    const locale = 'it-IT';
    const formatValue = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formatValue;
  }
}
