<p-pickList
  [source]="from"
  [target]="to"
  sourceHeader="Escludi"
  targetHeader="Includi"
  [dragdrop]="true"
  [responsive]="true"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  filterBy="description"
  sourceFilterPlaceholder="Ricerca"
  targetFilterPlaceholder="Ricerca"
  breakpoint="700px"
  styleClass="drag-and-drop"
  [targetStyle]="{ height: '20rem' }"
  [sourceStyle]="{ height: '20rem' }"
  (onMoveToTarget)="onMoveToTarget($event)"
  (onMoveToSource)="onMoveToSource($event)"
  (onMoveAllToTarget)="onMoveAllToTarget($event)"
  (onMoveAllToSource)="onMoveAllToSource($event)"
  showSourceControls="false"
  showTargetControls="false"
>
  <ng-template let-product pTemplate="item">
    <div class="flex flex-wrap p-2 align-items-center gap-3">
      <div class="flex-1 flex flex-column gap-2">
        <span class="font-bold">
          {{ product.description }}
        </span>
      </div>
    </div>
  </ng-template>
</p-pickList>
