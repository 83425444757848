import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { ErrorInfo } from '../../models/error/error-info';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorModalService {
  private errorSubject = new Subject<ErrorInfo>();

  constructor() {}

  showError(httpErrorResp: HttpErrorResponse): void {
    this.errorSubject.next(this.getErrorInfoFromCode(httpErrorResp));
  }

  showErrorTest(httpErrorResp: HttpErrorResponse): void {
    this.errorSubject.next(this.getErrorInfoFromCode(httpErrorResp));
  }

  getSubject(): Subject<ErrorInfo> {
    return this.errorSubject;
  }

  getErrorInfoFromCode(httpErrorResp: HttpErrorResponse): ErrorInfo {
    const status: string = httpErrorResp.status.toString();

    switch (status) {
      case '401':
      case '403':
        return new ErrorInfo(
          status,
          ' La sessione è scaduta, si prega di effettuare nuovamente il login',
          () => {
            window.location.href = environment.APP_LOGIN;
          }
        );
      default:
        return new ErrorInfo(
          httpErrorResp.error.code,
          httpErrorResp.error.message || 'Errore generico',
          () => {
            window.open('/', '_self');
          }
        );
    }
  }
}
