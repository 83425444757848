<div class="commission-form" *ngIf="this.userInfo?.isDirectionalUser">
  <form [formGroup]="this.form!" (ngSubmit)="onSubmit()">
    <div class="d-flex align-items-center justify-content-between">
      <div class="col-lg-4">
        <label for="agency">Agenzia</label>
        <p-dropdown
          id="agency"
          formControlName="agency"
          [options]="agenciesOptions ?? []"
          optionLabel="description"
          placeholder="Seleziona..."
          styleClass="commission-dropdown"
          showClear="true"
          [filter]="true"
          filterBy="code,description"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
          (onChange)="onAgencyChange($event)"
          [ngClass]="{
            'ng-dirty': getDirty(form, 'agency'),
          }"
        ></p-dropdown>
      </div>
      <button class="btn btn-primary my-4" type="submit">
        <strong>CERCA</strong>
      </button>
    </div>
  </form>
</div>
<div class="text-end mt-3">
  <div *ngIf="isEditable">
    <button
      class="btn btn-danger mb-2 me-2"
      (click)="onDeleteMultipleMasters()"
      [disabled]="this.selectedMasters.length === 0"
    >
      <strong>ELIMINA MASTRI</strong>
    </button>
    <button class="btn btn-primary mb-2" (click)="createNewMaster()">
      <strong>CREA NUOVO</strong>
    </button>
  </div>
  <div *ngIf="isLoading" class="text-center spinner-overlay">
    <p-progressSpinner ariaLabel="loading" />
  </div>
  <p-table
    *ngIf="this.showTable"
    [value]="masters ?? []"
    dataKey="id"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass=" table-commission text-center"
    [(selection)]="selectedMasters"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem" *ngIf="isEditable">
          <p-tableHeaderCheckbox />
        </th>
        <th style="width: 6rem" *ngIf="isEditable"></th>

        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code" style="width: auto">
          Codice <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="type.description" style="width: auto">
          Tipologia <p-sortIcon field="type.description"></p-sortIcon>
        </th>
        <th pSortableColumn="value" style="width: auto">
          Importo <p-sortIcon field="value"></p-sortIcon>
        </th>
        <th pSortableColumn="linkedAccounts" style="width: auto">
          Conti collegati
          <p-sortIcon field="linkedAccounts"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agencyCode"
          style="width: auto"
        >
          Codice Agenzia
          <p-sortIcon field="agencyCode"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngIf="isEditable"></th>
        <th *ngIf="isEditable"></th>
        <th>
          <p-columnFilter field="name" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="code" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="type.description"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="value" matchMode="contains" [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="linkedAccounts.length"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter
            field="agencyCode"
            matchMode="contains"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext"
              />
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-master let-i="rowIndex">
      <tr>
        <td *ngIf="isEditable">
          <p-tableCheckbox [value]="master" />
        </td>
        <td *ngIf="isEditable">
          <i
            class="pi pi-pen-to-square icon col-md-6"
            (click)="onModify(master)"
            title="Modifica"
          ></i>
          <i
            title="Elimina bozza"
            class="pi pi-trash icon col-md-6"
            (click)="onDelete(master)"
            title="Elimina"
          ></i>
        </td>
        <td class="text-center">
          {{ master.name }}
        </td>
        <td class="text-center">
          {{ master.code }}
        </td>
        <td class="text-center">
          {{ master.type.description }}
        </td>
        <td class="text-center">{{ master.value | customCurrency }}</td>
        <td class="text-center">
          <a class="underline" (click)="this.showMasterDetail(master)">
            {{ master.linkedAccounts.length }}
          </a>
        </td>
        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ master.agencyCode }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-modal-add-master
  [master]="modifiedMaster"
  [modalRefAdd]="this.modalRefAdd"
></app-modal-add-master>
<app-modal-linked-accounts
  [master]="modifiedMaster"
  [modalRefDetail]="modalRefDetail"
></app-modal-linked-accounts>
