import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import { CASH_CONTROL_PATH, FIRST_NOTE_PATH } from '../../../enums/path';
import {
  DetailCashControl,
  SubAccountValue,
} from '../../../models/cash-control/detail-cash-control';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { HttpUtilisCashControl } from '../../../network/http-utilis-cash-control';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { ModalService } from '../../../services/modal/modal.service';
import { exits } from '../../../utils/commons-utilis';

@Component({
  selector: 'app-detail-cash-control-screen',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TooltipModule,
    ProgressSpinnerModule,
    CustomCurrencyPipe,
    PanelModule,
  ],
  templateUrl: './detail-cash-control-screen.component.html',
  styleUrl: './detail-cash-control-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DetailCashControlScreenComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  detailCashControl: DetailCashControl | undefined;
  queryParamsSub$: Subscription | undefined;
  id: number | undefined;

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRouter: ActivatedRoute,
    private modalService: ModalService,
    private httpCashControl: HttpUtilisCashControl,
    private messageService: MessageService
  ) {}
  ngOnDestroy(): void {
    this.queryParamsSub$?.unsubscribe();
  }
  ngOnInit(): void {
    this.queryParamsSub$ = this.activatedRouter.queryParams.subscribe(
      (params) => {
        this.id = params['id'];
        if (this.id) {
          this.isLoading = true;
          this.httpCashControl.getCashControlDetail(this.id).subscribe({
            next: (response: any) => {
              this.detailCashControl = DetailCashControl.fromJson(response);
              this.isLoading = false;
            },
            error: (error) => {
              this.isLoading = false;
              this.modalService.showError(error);
            },
          });
        } else {
          this.modalService.showModal(
            new ModalInfos(
              'Errore',
              'Impossiible caricare la schermata di dettaglio',
              () => {
                window.open('/', '_self');
              }
            )
          );
        }
      }
    );
  }

  goBack() {
    this.router.navigate([CASH_CONTROL_PATH.SEARCH]);
  }

  onModify(event: MouseEvent) {
    event?.preventDefault();
    if (this.id) {
      this.router.navigate([CASH_CONTROL_PATH.EDIT], {
        queryParams: { id: this.id },
      });
    }
  }

  onModifyRedirect() {
    if (this.id) {
      return this.router
        .createUrlTree([CASH_CONTROL_PATH.EDIT], {
          queryParams: { id: this.id },
        })
        .toString();
    }
    return;
  }

  onRecalculate() {
    if (this.id) {
      this.isLoading = true;
      this.httpCashControl.recalculateCashControl(this.id).subscribe({
        next: (response: DetailCashControl) => {
          this.detailCashControl = DetailCashControl.fromJson(response);
          this.id = this.detailCashControl.id;
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Ricalcolo effettuato con successo',
          });
        },
        error: (_error) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            key: 'br',
            detail: 'Impossibile effettuare il ricalcolo ',
          });
        },
      });
    }
  }

  onNavigateToFirstNote(subAccount: SubAccountValue, accountIndex: number) {
    this.titleService.setTitle('Prima Nota');

    return this.router
      .createUrlTree([FIRST_NOTE_PATH.NODE], {
        queryParams: {
          account:
            this.detailCashControl?.accountsCashControl[accountIndex].account
              .description,
          mode: subAccount.mode.description,
          date: subAccount.date,
          firstNoteManagerCode: this.detailCashControl?.firstNoteManager?.code,
          firstNoteManagerDescription:
            this.detailCashControl?.firstNoteManager.description,
          firstNoteManagerDetail:
            this.detailCashControl?.firstNoteManager.detail,
        },
      })
      .toString();
  }

  exist = exits;
}
