import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { STORAGE_KEYS } from '../enums/commission';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomError } from '../models/error/custom-error';
import { Account } from '../models/plan-accounts/account/account';
import { Master } from '../models/plan-accounts/master/master';
import { CreateMasterRequest } from '../models/plan-accounts/master/create-master-request';
import { CreateAccountRequest } from '../models/plan-accounts/account/create-account-request';
import { SubAccount } from '../models/plan-accounts/sub-account/sub-account';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilisPlanOfAccounts {
  constructor(private http: HttpClient) {}

  // Account

  /**
   * Retrieves accounts based on the provided agency code and optional association parameter.
   *
   * @param agencyCode - The code of the agency for which accounts are to be retrieved.
   * @param canBeAssociatedWith - (Optional) A parameter to filter accounts that can be associated with a specific ledger.
   * @returns An observable that emits the retrieved accounts or an error if the request fails.
   */
  public getAccounts(
    agencyCode: string,
    canBeAssociatedWith?: string,
    fromPrimaNota?: boolean
  ): Observable<any> {
    let params: { [key: string]: number | string } = {};
    params['agencyCode'] = agencyCode;
    if (canBeAssociatedWith) {
      params['canBeAssociatedWith'] = canBeAssociatedWith;
    }

    if (fromPrimaNota) {
      params['fromPrimaNota'] = fromPrimaNota.toString();
    }

    return this.http
      .get(environment.URL_PLAN_OF_ACCOUNTS + 'accounts', {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new account by sending a POST request to the server.
   *
   * @param accountRequest - The request payload containing account details.
   * @param masterID - The ID of the master ledger to which the account belongs.
   * @returns An Observable that emits the server's response.
   *
   * @throws CustomError - If the HTTP request fails.
   */
  public createAccount(
    accountRequest: CreateAccountRequest,
    masterID: number
  ): Observable<any> {
    return this.http
      .post(
        environment.URL_PLAN_OF_ACCOUNTS + `ledgers/${masterID}/accounts`,
        accountRequest,
        {
          headers: {
            Authorization: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies an existing account by sending a PUT request to the server.
   *
   * @param {Account} modifiedAccount - The account object containing updated information.
   * @returns {Observable<any>} - An observable that emits the server's response.
   *
   * @throws {CustomError} - Throws an error if the HTTP request fails.
   */
  public modifyAccount(modifiedAccount: Account): Observable<any> {
    return this.http
      .put(
        environment.URL_PLAN_OF_ACCOUNTS + `accounts/${modifiedAccount.code}`,
        modifiedAccount,
        {
          headers: {
            Authorization: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes the specified accounts by sending a POST request to the server.
   *
   * @param {Account[]} accounts - The list of accounts to be deleted.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public deleteAccount(accounts: Account[]): Observable<any> {
    const request = { accounts: accounts };

    return this.http
      .post(environment.URL_PLAN_OF_ACCOUNTS + 'accounts/deletion', request, {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  //GENERAL

  /**
   * Fetches all information based on the provided type and optional filters.
   *
   * @param {string} type - The type of information to retrieve.
   * @param {string} [filter] - Optional filter to apply to the query. Code of nature or mode
   * @param {string} [agencyCode] - Optional agency description to filter the results.
   * @param {string} [pathNodo] - Optional path node to filter the results. Is the code of the agency
   * @returns {Observable<any>} An observable containing the retrieved information.
   *
   * @throws {CustomError} Throws an error if the HTTP request fails.
   */
  public getAllInfos(
    type: string,
    filter?: string,
    agencyCode?: string,
    pathNodo?: string,
    parentCode?: string
  ): Observable<any> {
    let params: { [key: string]: number | string } = {};
    if (filter) {
      params['filter'] = filter;
    }
    if (agencyCode) {
      params['agencyCode'] = agencyCode;
    }
    if (pathNodo) {
      params['pathNodo'] = pathNodo;
    }

    if (parentCode) {
      params['parentCode'] = parentCode;
    }

    return this.http
      .get(environment.URL_PLAN_OF_ACCOUNTS + `query/${type}`, {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Generates a code based on the provided type, path node, and agency description.
   *
   * @param type - The type of code to generate. LEDGER/ACCOUNT/SUBACCOUNT
   * @param pathNodo - The path node associated with the code. agency.code
   * @param agencyCode - The agency description or code. agency.description
   *
   * @returns A generated code from the server.
   */
  public getCode(
    type: string,
    pathNodo: string,
    agencyCode: string
  ): Observable<any> {
    let params: { [key: string]: number | string } = {};
    params['type'] = type;
    params['pathNodo'] = pathNodo;
    params['agencyCode'] = agencyCode;

    return this.http.get(
      environment.URL_PLAN_OF_ACCOUNTS + `${type}/generateCode`,
      {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      }
    );
  }

  // Master

  public getMasters(agencyCode: string): Observable<any> {
    let params: { [key: string]: number | string } = {};
    params['agencyCode'] = agencyCode;

    return this.http
      .get(environment.URL_PLAN_OF_ACCOUNTS + 'ledgers', {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public createMaster(masterRequest: CreateMasterRequest): Observable<any> {
    return this.http
      .post(environment.URL_PLAN_OF_ACCOUNTS + `ledgers`, masterRequest, {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public modifyMaster(modifiedMaster: CreateMasterRequest): Observable<any> {
    return this.http
      .put(
        environment.URL_PLAN_OF_ACCOUNTS + `ledgers/${modifiedMaster.code}`,
        modifiedMaster,
        {
          headers: {
            Authorization: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  public deleteMaster(masters: Master[]): Observable<any> {
    const request = { ledgers: masters };
    return this.http
      .post(environment.URL_PLAN_OF_ACCOUNTS + 'ledgers/deletion', request, {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  // SUB ACCOUNT

  /**
   * Retrieves sub-accounts based on the provided agency code and optional association criteria.
   *
   * @param {string} agencyCode - The code of the agency for which sub-accounts are to be retrieved.
   * @param {string} [canBeAssociatedWith] - Optional parameter to filter sub-accounts that can be associated with a specific entity.
   * @returns {Observable<any>} - An observable containing the sub-accounts data.
   */
  public getSubAccount(
    agencyCode: string,
    canBeAssociatedWith?: string
  ): Observable<any> {
    let params: { [key: string]: number | string } = {};
    params['agencyCode'] = agencyCode;
    if (canBeAssociatedWith) {
      params['canBeAssociatedWith'] = canBeAssociatedWith;
    }

    return this.http
      .get(environment.URL_PLAN_OF_ACCOUNTS + 'subaccounts', {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: params,
      })
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Modifies an existing sub-account with the provided details.
   *
   * @param {SubAccount} modifiedAccount - The sub-account object containing updated information.
   * @returns {Observable<any>} An observable that emits the server's response.
   *
   * @throws {CustomError} If an error occurs during the HTTP request.
   */
  public modifySubAccount(modifiedAccount: SubAccount): Observable<any> {
    return this.http
      .put(
        environment.URL_PLAN_OF_ACCOUNTS +
          `subaccounts/${modifiedAccount.code}`,
        modifiedAccount,
        {
          headers: {
            Authorization: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
            }`,
            CustomHeader: `Bearer ${
              sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
            }`,
          },
        }
      )
      .pipe(
        catchError((error: CustomError) => {
          return throwError(() => error);
        })
      );
  }
}
