import { FormGroup } from '@angular/forms';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';

export const getDirty = (form: any, formName: string): boolean | undefined => {
  return form.get(formName)?.invalid && form.get(formName)?.touched;
};

export const getType = (
  commissionFormService: CommissionsFormService
): string => {
  return commissionFormService.isProducer ? 'produttore' : 'coassicurazione';
};

export const exits = (value: any): boolean => {
  return value != null && value != undefined;
};

export const debugForm = (formGroup: FormGroup): void => {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormGroup) {
      debugForm(control);
    } else if (control && control.invalid) {
      console.log(`Control: ${key}, Errors:`, control.errors);
    }
  });
};
