import { FormGroup } from '@angular/forms';
import { Agency, Type } from '../../commission-detail';
import { splitAgencyCode } from '../../../constants/general';

export class CreateMasterRequest {
  name: string;
  code: number;
  agency: Agency;
  pathNodo: string;
  agencyCode: string;
  type: Type;

  constructor(
    name: string,
    code: number,
    agency: Agency,
    pathNodo: string,
    agencyCode: string,
    type: Type
  ) {
    this.name = name;
    this.code = code;
    this.agency = agency;
    this.pathNodo = pathNodo;
    this.agencyCode = agencyCode;
    this.type = type;
  }

  static fromForm(form: FormGroup): CreateMasterRequest {
    return new CreateMasterRequest(
      form.get('name')?.value,
      form.get('code')?.value,
      form.get('agency')?.value,
      form.get('agency')?.value.code,
      form.get('agency')?.value.description.split(splitAgencyCode)[0],
      form.get('type')?.value
    );
  }
}
