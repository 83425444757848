<div class="commission-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <label for="collaborator">Collaboratore</label>
        <p-autoComplete
          styleClass="autocomplete-dropdown my-2"
          formControlName="collaborator"
          showEmptyMessage="false"
          [dropdown]="true"
          showClear="true"
          [suggestions]="filteredValues"
          (completeMethod)="filterValues($event, this.enumType.PRODUCERS)"
          placeholder="Seleziona..."
          field="description"
        />
      </div>
      <div class="col-lg-6 col-md-12">
        <label for="registryDate">Data registrazione</label>
        <p-calendar
          id="registryDate"
          formControlName="registryDate"
          [showIcon]="true"
          selectionMode="range"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong>CERCA</strong>
      </button>
      <button
        type="button"
        class="btn btn-primary-white mx-3 my-4"
        (click)="onReset()"
      >
        <strong>RIPRISTINA </strong>
      </button>
    </div>
  </form>
  <div>
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="p-button-text"
      label="Crea"
      (click)="onCreate()"
    ></p-button>
  </div>
</div>
