<div class="commission-form">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="producer"> Produttore</label>
        <input
          pInputText
          id="producer"
          type="text"
          formControlName="producer"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="category">Categoria</label>
        <p-dropdown
          id="category"
          formControlName="category"
          [options]="categoryOptions"
          placeholder="Seleziona..."
          styleClass="commission-dropdown mt-2  "
        ></p-dropdown>
      </div>
      <div class="col-lg-4 col-md-12"></div>
      <div class="col-lg-4 col-md-12">
        <label for="validityStartDate">Data inizio validità</label>
        <p-calendar
          id="validityStartDate"
          formControlName="validityStartDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="validityEndDate">Data fine validità</label>
        <p-calendar
          id="validityEndDate"
          formControlName="validityEndDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="desciption">Descrizione</label>
        <textarea
          id="desciption"
          formControlName="desciption"
          rows="1"
          cols="30"
          pInputTextarea
          [autoResize]="true"
          class="commission-text-area my-2"
        ></textarea>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-center">
    <button class="btn btn-primary m-2" (click)="printForm()">Modifica</button>
    <button class="btn btn-danger m-2" (click)="getBack()">Annulla</button>
  </div>
</div>
