<div class="modal" tabindex="-1" id="customModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <i
          *ngIf="this.modalInfos?.icon"
          [ngClass]="this.modalInfos?.icon"
          class="mx-3 scale-icon"
        >
        </i>
        <h4 class="modal-title">{{ this.modalInfos?.header }}</h4>
      </div>
      <div class="modal-body modal-body-scrollable">
        <p>{{ this.modalInfos?.description }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          (click)="this.modalInfos?.onClick()"
        >
          Procedi
        </button>
        <button
          *ngIf="this.modalInfos?.onCancel"
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          (click)="this.modalInfos?.onCancel()"
        >
          Annulla
        </button>
      </div>
    </div>
  </div>
</div>
