import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  Account,
  LinkedSubAccount,
} from '../../../models/plan-accounts/account/account';
import { Modal } from 'bootstrap';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { LinkedSubAccountsService } from '../../../services/linked-sub-accounts/linked-accounts.service';
import { Subscription } from 'rxjs';
import { Agency, Type } from '../../../models/commission-detail';
import { MessageService } from 'primeng/api';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { UserInfo } from '../../../models/user/user-info';
import { splitAgencyCode } from '../../../constants/general';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../services/modal/modal.service';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { VISIBILITY } from '../../../enums/visibility';
import { SubAccount } from '../../../models/plan-accounts/sub-account/sub-account';

@Component({
  selector: 'app-modal-detail-account',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
    ButtonModule,
  ],
  templateUrl: './modal-detail-account.component.html',
  styleUrl: './modal-detail-account.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalDetailAccountComponent implements OnInit, OnDestroy {
  @Input() account: Account | undefined;
  @Input() modalRef: Modal | undefined;
  form: FormGroup = new FormGroup({});
  subAccountsList: LinkedSubAccount[] = [];
  linkedSubAccounts: LinkedSubAccount[] | undefined;
  isLoading: boolean = false;
  isSubAccountListDropdownVisible: boolean = false;
  private linkedSubAccountsSub$: Subscription | undefined;
  showDialog: boolean = false;
  userInfo: UserInfo | undefined;
  isEditable: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private linkedSubAccountsService: LinkedSubAccountsService,
    private messageService: MessageService,
    private httpUtilisPlanOfAccount: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService,
    private modalService: ModalService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnInit(): void {
    if (this.userInfo) {
      this.isEditable = this.userInfo.userFunctions.includes(
        VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_AGGIORNA
      );
    }
    this.initForm();
    // this.getSubAccountsList();

    this.linkedSubAccountsSub$ =
      this.linkedSubAccountsService.linkedSubAccounts$.subscribe(
        (subAccounts) => {
          this.linkedSubAccounts = subAccounts;
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSubAccountsList();
  }

  ngOnDestroy(): void {
    if (this.linkedSubAccountsSub$) {
      this.linkedSubAccountsSub$.unsubscribe();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      subAccount: new FormControl(undefined, [Validators.required]),
    });
  }

  getSubAccountsList() {
    if (
      this.account?.agency?.code &&
      this.account?.code &&
      this.account?.agencyCode
    ) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccount
        .getSubAccount(this.account?.agencyCode, this.account.code.toString())
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.subAccountsList = response.subaccounts.map(
              (subAccount: SubAccount) => ({
                ...subAccount,
                displayName: `${subAccount.code} - ${subAccount.name} - ${
                  subAccount.pathNodo
                } ${
                  subAccount.account
                    ? `- ${subAccount?.account.description}`
                    : ''
                }`,
              })
            );
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              key: 'br',
              detail: error.error.message,
            });
            this.isSubAccountListDropdownVisible = false;
            this.initForm();
          },
        });
    }
  }

  showSubAccountListDropdown() {
    this.isSubAccountListDropdownVisible =
      !this.isSubAccountListDropdownVisible;
  }

  addSubAccount() {
    const selectedSubAccount = this.form.get('subAccount')?.value;
    if (selectedSubAccount) {
      const newSubAccount = { ...selectedSubAccount, isNew: true };
      this.linkedSubAccountsService.addLinkedSubAccount(newSubAccount);

      this.subAccountsList = this.subAccountsList.filter(
        (subAccount) =>
          subAccount.code !== selectedSubAccount.code ||
          subAccount.pathNodo !== selectedSubAccount.pathNodo ||
          subAccount.agencyCode !== selectedSubAccount.agencyCode
      );

      this.form.reset();
    }
  }

  removeSubAccount(subAccount: LinkedSubAccount) {
    if (subAccount.isNew) {
      this.linkedSubAccountsService.removeLinkedSubAccount(subAccount);
      this.subAccountsList.push({
        ...subAccount,
        isNew: false,
      });
      this.subAccountsList.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  saveAddedSubAccounts() {
    if (this.account) {
      this.isLoading = true;

      const newSubAccounts = this.linkedSubAccounts?.filter(
        (subAccount) => subAccount.isNew
      );
      const subAccountsToSave =
        newSubAccounts?.map(({ isNew, ...rest }) => rest) ?? [];

      this.account?.linkedSubAccounts.push(...subAccountsToSave);

      this.httpUtilisPlanOfAccount.modifyAccount(this.account).subscribe({
        next: (_response: any) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Sottoconti associati con successo',
          });

          this.updateAccountsList();

          this.modalRef?.hide();
          this.reset();
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            key: 'br',
            detail: error.error.message,
          });

          this.updateAccountsList();
        },
      });
    }
  }

  updateAccountsList() {
    if (this.userInfo?.agencyDescription) {
      this.httpUtilisPlanOfAccount
        .getAccounts(
          this.userInfo?.agencyDescription.split(splitAgencyCode)[0],
          undefined,
          undefined,
          this.planOfAccountsService.accountAgency
        )
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.planOfAccountsService.accountsSub.next(response['accounts']);
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  goBack(): void {
    const newSubAccounts = this.linkedSubAccounts?.filter(
      (subAccount) => subAccount.isNew
    );
    if (newSubAccounts?.length === 0) {
      if (this.account) {
        this.reset();
        this.modalRef?.hide();
      }
    } else {
      this.showDialog = true;
    }
  }

  checkNewSubAccountsAdded() {
    if (
      this.linkedSubAccounts?.filter((subAccount) => subAccount.isNew)
        .length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeModal() {
    this.showDialog = false;
    this.modalRef?.hide();
    this.reset();
  }

  hideDialog() {
    this.showDialog = false;
  }

  reset() {
    this.isSubAccountListDropdownVisible = false;
    this.getSubAccountsList();
    this.initForm();
  }
}
