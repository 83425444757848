import { Agency, Type } from '../../commission-detail';

export class SubAccount {
  name: string;
  code: string;
  type: Type;
  pathNodo: string;
  dirCollection: boolean;
  account: Type;
  accountNode: string;
  agency: Agency;
  agencyCode: string;
  insertDate: string;
  lastUpdate: string;
  showDir?: string;
  id?: string;

  constructor(
    name: string,
    code: string,
    type: Type,
    pathNodo: string,
    dirCollection: boolean,
    account: Type,
    accountNode: string,
    agency: Agency,
    agencyCode: string,
    insertDate: string,
    lastUpdate: string,
    showDir?: string
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.pathNodo = pathNodo;
    this.dirCollection = dirCollection;
    this.account = account;
    this.accountNode = accountNode;
    this.agency = agency;
    this.agencyCode = agencyCode;
    this.insertDate = insertDate;
    this.lastUpdate = lastUpdate;
    this.showDir = showDir;
    this.id = `${this.code}${this.agencyCode}`;
  }

  static fromJson(json: SubAccount): SubAccount {
    return new SubAccount(
      json.name,
      json.code,
      json.type,
      json.pathNodo,
      json.dirCollection,
      json.account,
      json.accountNode,
      json.agency,
      json.agencyCode,
      json.insertDate,
      json.lastUpdate,
      json.dirCollection ? 'Si' : 'No'
    );
  }

  static fromCall(jsonArray: SubAccount[] | undefined): SubAccount[] {
    return jsonArray?.map((json) => SubAccount.fromJson(json)) ?? [];
  }
}
