export class UserInfo {
  username: string;
  name: string;
  surname: string;
  node: string;
  agencyDescription: string;
  role: string;
  profile?: string;
  userFunctions: string[];
  canManageEc: boolean;
  isDirectionalUser: boolean;

  constructor(
    username: string,
    name: string,
    surname: string,
    node: string,
    agencyDescription: string,
    role: string,
    userFunctions: string[],
    canManageEc: boolean,
    isDirectionalUser: boolean,
    profile?: string
  ) {
    this.username = username;
    this.name = name;
    this.surname = surname;
    this.node = node;
    this.agencyDescription = agencyDescription;
    this.role = role;
    this.userFunctions = userFunctions;
    this.canManageEc = canManageEc;
    this.isDirectionalUser = isDirectionalUser;
    this.profile = profile;
  }

  static fromJson(json: any): UserInfo {
    return new UserInfo(
      json.username,
      json.name,
      json.surname,
      json.node,
      json.agencyDescription,
      json.role,
      json.userFunctions,
      json.canManageEc,
      json.isDirectionalUser,
      json.profile
    );
  }
}
