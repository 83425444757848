export class ErrorInfo {
  code: string;
  description: string;
  onClick: () => void;

  constructor(code: string, description: string, onClick: () => void) {
    this.code = code;
    this.description = description;
    this.onClick = onClick;
  }
}
