import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ReportingService } from '../../../../services/reporting/reporting-service';
import { forkJoin, Subscription } from 'rxjs';
import { Report } from '../../../../models/reporting/reports';
import { UserInfo } from '../../../../models/user/user-info';
import { Agency, Type } from '../../../../models/commission-detail';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { HttpUtilisCommissions } from '../../../../network/http-utilis-commissions';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../../services/modal/modal.service';
import { getDirty } from '../../../../utils';
import { TableModule } from 'primeng/table';
import { customEmailValidator } from '../../../../validators/custom-email-validator';
import { HttpUtilisPlanOfAccounts } from '../../../../network/http-utilis-plan-of-accounts';
import { TYPE } from '../../../../enums/path-of-accounts';
import { HttpUtilisReporting } from '../../../../network/http-reporting';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-add-modify-report-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    CalendarModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    TableModule,
    InputTextModule,
  ],
  templateUrl: './add-modify-report-modal.component.html',
  styleUrl: './add-modify-report-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddModifyReportModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() report: Report | undefined;
  form: FormGroup = new FormGroup({});
  visibilitySub$: Subscription | undefined;
  visible: boolean = false;
  isLoading: boolean = false;
  modalTitle: string = 'Crea Report';
  userInfo: UserInfo | undefined;
  filteredValues: Agency[] = [];
  reportNameOptions: Type[] | undefined;
  frequencyOptions: Type[] | undefined;
  exportingDateOptions: Type[] | undefined;
  receiversTypeOptions: Type[] | undefined;
  isModifing: boolean = false;

  constructor(
    public reportingService: ReportingService,
    private httpUtilsCom: HttpUtilisCommissions,
    private modalService: ModalService,
    private httpUtilisPlans: HttpUtilisPlanOfAccounts,
    private httpUtilisReport: HttpUtilisReporting,
    private messageService: MessageService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }

    this.form = new FormGroup({
      id: new FormControl(null),
      reportName: new FormControl(null, Validators.required),
      dates: new FormControl(null, Validators.required),
      exportingDate: new FormControl(null, Validators.required),
      frequency: new FormControl(null, Validators.required),
      agency: new FormControl(
        null,
        this.userInfo?.isDirectionalUser ? Validators.required : null
      ),
      receivers: new FormArray([]),
    });
  }
  ngOnInit(): void {
    this.visibilitySub$ =
      this.reportingService.modifyAddModalVisibilitySub.subscribe(
        (visibleValue) => {
          this.visible = visibleValue;
        }
      );
    this.initStates();
  }
  ngOnDestroy(): void {
    this.visibilitySub$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['report'].currentValue === undefined) {
      this.resetForm();
    } else {
      this.populateForm();
    }
  }

  resetForm() {
    this.modalTitle = 'Crea Report';
    this.isModifing = false;
    this.form.reset();
    this.form.get('agency')?.enable();
  }

  populateForm() {
    if (this.report === undefined) return;

    this.modalTitle = 'Modifica Report';
    this.isModifing = true;

    let dates: Date[] = [
      new Date(this.report?.from),
      new Date(this.report?.to),
    ];
    this.form.patchValue({
      id: this.report?.id,
      reportName: this.report?.reportName,
      dates: dates,
      exportingDate: this.report?.exportingDate,
      frequency: this.report?.frequency,
      agency: this.report?.agency,
    });

    let receivers = this.form.get('receivers') as FormArray;
    receivers.clear();
    this.report?.receivers.forEach((receiver) => {
      receivers.push(
        new FormGroup({
          id: new FormControl(receiver.id),
          email: new FormControl(receiver.email, [
            Validators.required,
            customEmailValidator(),
          ]),
          type: new FormControl(receiver.type, Validators.required),
        })
      );
    });
    this.form.get('agency')?.disable();
  }

  initStates() {
    this.isLoading = true;
    const getExportingDateOptions = this.httpUtilisPlans.getAllInfos(
      TYPE.DATA_ESPORTAZIONE
    );
    const getFrequencyOptions = this.httpUtilisPlans.getAllInfos(
      TYPE.FREQUENZA_SCHEDULAZIONE
    );
    const getReceiversTypeOptions = this.httpUtilisPlans.getAllInfos(
      TYPE.TIPO_DESTINATARIO
    );
    const getReportNameOptions = this.httpUtilisPlans.getAllInfos(
      TYPE.NOME_REPORT
    );

    forkJoin([
      getExportingDateOptions,
      getFrequencyOptions,
      getReceiversTypeOptions,
      getReportNameOptions,
    ]).subscribe({
      next: ([
        exportingDateResponse,
        frequencyResponse,
        receiversTypeResponse,
        reportNameResponse,
      ]) => {
        this.exportingDateOptions = exportingDateResponse['types'];
        this.frequencyOptions = frequencyResponse['types'];
        this.receiversTypeOptions = receiversTypeResponse['types'];
        this.reportNameOptions = reportNameResponse['types'];
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
        this.isLoading = false;
      },
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    if (this.isModifing && this.userInfo) {
      this.httpUtilisReport
        .modifyReport(Report.createRequestFromForm(this.form, this.userInfo))
        .subscribe({
          next: (_response) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Report modificato con successo',
            });
            this.reportingService.modifyAddModalVisibilitySub.next(false);
            this.reportingService.tableReportsSub.next();
          },
          error: (_error: HttpErrorResponse) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Errore',
              key: 'br',
              detail: 'Impossibile modificare il report',
            });
          },
        });
    } else if (this.userInfo && !this.isModifing) {
      this.httpUtilisReport
        .createReport(Report.createRequestFromForm(this.form, this.userInfo))
        .subscribe({
          next: (_response) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Report creato con successo',
            });
            this.reportingService.modifyAddModalVisibilitySub.next(false);
            this.reportingService.tableReportsSub.next();
          },
          error: (_error: HttpErrorResponse) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Errore',
              key: 'br',
              detail: 'Impossibile creare il report',
            });
          },
        });
    }
  }

  onDialogHide() {
    if (!this.isModifing) {
      return;
    }

    this.populateForm();
  }

  filterValues($event: AutoCompleteCompleteEvent): void {
    let query = $event.query;

    this.httpUtilsCom?.getAgencies(query).subscribe({
      next: (response) => {
        this.filteredValues = response['items'];
      },
      error: (error: HttpErrorResponse) => {
        this.modalService.showError(error);
      },
    });
  }

  addRow() {
    let receivers = this.form.get('receivers') as FormArray;
    receivers.push(this.createRow());
  }

  createRow(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      email: new FormControl(null, [
        Validators.required,
        customEmailValidator(),
      ]),
      type: new FormControl(null, Validators.required),
    });
  }

  onRemove(rowIndex: number) {
    let receivers = this.form.get('receivers') as FormArray;
    receivers.removeAt(rowIndex);
  }

  getReceiversArray(): FormArray {
    return this.form.get('receivers') as FormArray;
  }

  getDirty = getDirty;
}
