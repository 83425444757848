import { Component } from '@angular/core';
import { EditRowBankRecordComponent } from '../../../components/forms/bank-records/edit-row-bank-record/edit-row-bank-record.component';

@Component({
  selector: 'app-edit-row-bank-record',
  standalone: true,
  imports: [EditRowBankRecordComponent],
  templateUrl: './edit-row-bank-record.component.html',
  styleUrl: './edit-row-bank-record.component.scss',
})
export class EditRowBankRecordScreen {}
