export class StatementType {
  code: string;
  description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}

export class IFR {
  type: StatementType;
  value: number | null;

  constructor(type: StatementType, value: number | null) {
    this.type = type;
    this.value = value;
  }
}

export class WithholdingTax {
  type: StatementType;
  value: number | null;

  constructor(type: StatementType, value: number | null) {
    this.type = type;
    this.value = value;
  }
}

export class BankRecordAddRequest {
  bankStatementId: number | null;
  rowId: number | null;
  collectionDate: string;
  statementType: StatementType;
  statementDes: string;
  provv: number;
  ifr: IFR | null;
  witholdingTax: WithholdingTax | null;

  constructor(
    bankStatementId: number | null,
    rowId: number | null,
    collectionDate: string,
    statementType: StatementType,
    statementDes: string,
    provv: number,
    ifr: IFR | null,
    witholdingTax: WithholdingTax | null
  ) {
    this.bankStatementId = bankStatementId;
    this.rowId = rowId;
    this.collectionDate = collectionDate;
    this.statementType = statementType;
    this.statementDes = statementDes;
    this.provv = provv;
    this.ifr = ifr;
    this.witholdingTax = witholdingTax;
  }

  static fromForm(data: any): BankRecordAddRequest {
    return new BankRecordAddRequest(
      data.bankStatementId ?? null,
      data.rowId ?? null,
      new Date(data.collectionDate).toISOString(),
      new StatementType(
        data.statementType.code,
        data.statementType.description
      ),
      data.statementDes,
      data.provvCalcolata,
      data.ifr
        ? new IFR(
            new StatementType(data.ifr.code, data.ifr.description),
            data.ifrValue ?? null
          )
        : null,
      data.witholdingTax
        ? new WithholdingTax(
            new StatementType(
              data.witholdingTax.code,
              data.witholdingTax.description
            ),
            data.witholdingTaxValue ?? null
          )
        : null
    );
  }
}
