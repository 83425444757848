import { Component } from '@angular/core';
import { SearchBankRecordsFormComponent } from '../../../components/forms/bank-records/search-bank-records-form/search-bank-records-form.component';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { BankRecordTableComponent } from '../../../components/tables/bank-record-table/bank-record-table.component';
import { CommonModule } from '@angular/common';
import { BankRecordsService } from '../../../services/bank-records/bank-records.service';

@Component({
  selector: 'app-search-bank-records',
  standalone: true,
  templateUrl: './search-bank-records.component.html',
  styleUrl: './search-bank-records.component.scss',
  imports: [
    SearchBankRecordsFormComponent,
    BankRecordTableComponent,
    CommonModule,
  ],
})
export class SearchBankRecordsComponent {
  constructor(public bankRecordService: BankRecordsService) {}
}
