<div>
  <div class="commission-form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <label for="firstNoteManager">Gestore prima nota</label>
          <p-dropdown
            id="firstNoteManager"
            formControlName="firstNoteManager"
            [options]="firstNoteManagerList"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="commission-dropdown"
            showClear="true"
            [filter]="true"
            [readonly]="this.isVisible"
            [ngClass]="{ 'dropdown-disabled': this.isVisible }"
            filterBy="code,description"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
          ></p-dropdown>
        </div>
        <div class="col-lg-3 col-md-12">
          <label for="type">Tipologia data</label>
          <p-dropdown
            id="type"
            formControlName="type"
            [options]="typeCalendarOptions"
            placeholder="Seleziona..."
            styleClass="commission-dropdown"
            showClear="true"
            [filter]="true"
            [readonly]="this.isVisible"
            [ngClass]="{ 'dropdown-disabled': this.isVisible }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
          ></p-dropdown>
        </div>
        <div class="col-lg-3 col-md-12">
          <label for="dates">Periodo</label>
          <p-calendar
            id="dates"
            formControlName="dates"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            selectionMode="range"
            styleClass="commission-calendar"
            [disabled]="this.isVisible"
          >
          </p-calendar>
        </div>
        <div class="col-lg-3 col-md-12">
          <button
            class="btn btn-primary mx-3 my-4"
            type="submit"
            [disabled]="form.invalid || this.isVisible"
          >
            <strong>CERCA</strong>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isLoading" class="text-center spinner-overlay">
    <p-progressSpinner ariaLabel="loading" />
  </div>
  <div
    class="row"
    [ngClass]="{
      'hide-table': !this.showTable
    }"
  >
    <div class="row mb-3">
      <div class="col-6">
        <p-button
          type="button"
          icon="pi pi-plus"
          class="me-2"
          styleClass="p-button-text"
          label="Crea"
          (click)="onCreate()"
        ></p-button>
        <p-button
          type="button"
          icon="pi pi-file-export"
          class="mx-2"
          styleClass="p-button-text"
          label="Esporta"
          (click)="onExport()"
        ></p-button>
      </div>
      <div class="col-6 d-flex flex-row">
        <div class="flex align-items-center mr-3">
          <p-radioButton
            class="mx-2"
            name="filter"
            [value]="this.enumFilter.PRIMANOTA"
            (onClick)="this.onFilter($event)"
            [(ngModel)]="filter"
            inputId="option1"
          />
          <label for="option1" class="ml-2"> Prima nota </label>
        </div>

        <div class="flex align-items-center mr-3">
          <p-radioButton
            class="mx-2"
            name="filter"
            [value]="this.enumFilter.BROGLIACCIO"
            (onClick)="this.onFilter($event)"
            [(ngModel)]="filter"
            inputId="option2"
          />
          <label for="option2" class="ml-2"> Brogliaccio </label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton
            class="mx-2"
            name="filter"
            [value]="this.enumFilter.ENTRAMBI"
            (onClick)="this.onFilter($event)"
            [(ngModel)]="filter"
            inputId="option3"
          />
          <label for="option3" class="ml-2"> Entrambi </label>
        </div>
      </div>
    </div>
    <p-table
      #table
      [value]="firstNoteList"
      dataKey="id"
      [scrollable]="true"
      [paginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [tableStyle]="{ 'min-width': '60rem' }"
      styleClass="table-commission text-center"
      (onFilter)="onFilterTable($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th pSortableColumn="sortableOperationID" style="width: auto">
            Numero operazione
            <p-sortIcon field="sortableOperationID"></p-sortIcon>
          </th>
          <th pSortableColumn="registryDate" style="width: auto">
            Data registrazione <p-sortIcon field="registryDate"></p-sortIcon>
          </th>
          <th pSortableColumn="competenceDate" style="width: auto">
            Data competenza <p-sortIcon field="competenceDate"></p-sortIcon>
          </th>
          <th pSortableColumn="account.displayName" style="width: auto">
            Conto <p-sortIcon field="account.displayName"></p-sortIcon>
          </th>
          <th pSortableColumn="mode.description" style="width: auto">
            Modalità
            <p-sortIcon field="mode.description"></p-sortIcon>
          </th>
          <th pSortableColumn="description" style="width: auto">
            Descrizione
            <p-sortIcon field="description"></p-sortIcon>
          </th>
          <th pSortableColumn="revenueGive" style="width: auto">
            Entrate
            <p-sortIcon field="revenueGive"></p-sortIcon>
          </th>
          <th pSortableColumn="expenseGet" style="width: auto">
            Uscite
            <p-sortIcon field="expenseGet"></p-sortIcon>
          </th>
          <th pSortableColumn="policyNumber" style="width: auto">
            Numero polizza
            <p-sortIcon field="policyNumber"></p-sortIcon>
          </th>
          <th pSortableColumn="manager.description" style="width: auto">
            Gestore nodo
            <p-sortIcon field="manager.description"></p-sortIcon>
          </th>
          <th pSortableColumn="producer.description" style="width: auto">
            Produttore polizza
            <p-sortIcon field="producer.description"></p-sortIcon>
          </th>
          <th pSortableColumn="transactionNumberRev" style="width: auto">
            Numero operazione stornata
            <p-sortIcon field="transactionNumberRev"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <p-columnFilter
              field="operationID"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="formattedRegistryDate"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="formattedCompetenceDate"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="account.displayName"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="mode.description"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="description"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="filterableRevenueGive"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="filterableExpenseGet"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="policyNumber"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="manager.description"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="producer.description"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              field="transactionNumberRev"
              matchMode="contains"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                />
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-firstNote let-i="rowIndex">
        <tr>
          <td>
            <i
              *ngIf="firstNote.manuallyCreated"
              title="Elimina prima nota"
              class="pi pi-trash icon col-md-6"
              title="Elimina"
              (click)="this.onDelete(firstNote)"
            ></i>
          </td>
          <td>
            <i
              class="pi pi-pen-to-square icon col-md-6"
              title="Modifica"
              (click)="onModify(firstNote)"
            ></i>
          </td>
          <td>
            <i
              class="pi pi-clone icon col-md-6"
              title="Duplica"
              (click)="this.onDuplicate(firstNote)"
            ></i>
          </td>
          <td>
            <i
              *ngIf="!firstNote.isPartitaDoppia && firstNote.manuallyCreated"
              class="pi pi-angle-double-right icon col-md-6"
              title="Partita doppia"
              (click)="this.onPartitaDoppia(firstNote)"
            ></i>
          </td>
          <td class="text-center">
            {{ firstNote.operationID }}
          </td>
          <td class="text-center">
            {{ firstNote.registryDate | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-center">
            {{ firstNote.competenceDate | date : "dd/MM/yyyy" }}
          </td>
          <td
            *ngIf="firstNote.account.code"
            class="text-center link-style"
            (click)="this.onAccountDetail(firstNote)"
          >
            {{ firstNote.account.displayName ?? "" }}
          </td>
          <td *ngIf="!firstNote.account.code" class="text-center"></td>
          <td class="text-center">
            {{ firstNote.mode.description ?? "" }}
          </td>
          <td class="text-center">
            {{ firstNote.description ?? "" }}
          </td>
          <td class="text-center">
            {{ firstNote.revenueGive ?? 0 | customCurrency }}
          </td>
          <td class="text-center">
            {{ firstNote.expenseGet ?? 0 | customCurrency }}
          </td>
          <td class="text-center" *ngIf="!firstNote?.manuallyCreated">
            <a [href]="firstNote?.policyLink" target="_blank">
              {{ firstNote?.policyNumber }}
            </a>
          </td>
          <td class="text-center" *ngIf="firstNote?.manuallyCreated">
            {{ firstNote.policyNumber ?? "" }}
          </td>
          <td class="text-center">
            {{ firstNote.manager.description ?? "" }}
          </td>
          <td class="text-center">
            {{ firstNote.producer.description ?? "" }}
          </td>
          <td class="text-center">
            {{ firstNote.operationIdRev ?? "" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <app-modify-modal [firstNote]="this.modifyFirstNoteValue"></app-modify-modal>
  <app-double-entry-modal
    [firstNote]="this.firstNoteValueDetail"
  ></app-double-entry-modal>
  <app-account-detail-modal
    [detailAccount]="this.currentDetailAccountFirstNote"
    [modalRef]="this.modalRefAccountDetail"
  ></app-account-detail-modal>
</div>
