import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ModalInfos } from '../models/modal/modal-infos';
import { AuthService } from '../services/auth/auth.service';
import { ModalService } from '../services/modal/modal.service';
import { environment } from '../../environments/environment';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const cookieService = inject(CookieService);
  const modalService = inject(ModalService);

  if (!authService.isLogged) {
    if (cookieService.check('iPlanetDirectoryPro')) {
      authService.getToken().subscribe({
        next: (_) => {
          authService.isLogged = true;
        },
        error: (error) => {
          modalService.showModal(
            new ModalInfos(
              'Attenzione',
              'Si è verificato un errore in fase di login',
              () => {
                window.location.href = environment.APP_LOGOUT;
              },
              'pi pi-exclamation-triangle'
            )
          );
        },
      });
    } else {
      window.location.href = environment.APP_LOGIN;
    }
  }

  return true;
};
