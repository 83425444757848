export class BCRowSosp {
  rowId: number;
  collectionDate: string;
  numPoli: string;
  collaboratorName: string | null;
  collaboratorSurname: string | null;
  gross: number;
  totSospeso: number;
  codPdre: string | undefined;
  codCoas: string | undefined;

  constructor(
    rowId: number,
    collectionDate: string,
    numPoli: string,
    collaboratorName: string | null,
    collaboratorSurname: string | null,
    gross: number,
    totSospeso: number,
    codPdre: string,
    codCoas: string
  ) {
    this.rowId = rowId;
    this.collectionDate = collectionDate;
    this.numPoli = numPoli;
    this.collaboratorName = collaboratorName;
    this.collaboratorSurname = collaboratorSurname;
    this.gross = gross;
    this.totSospeso = totSospeso;
    this.codPdre = codPdre;
    this.codCoas = codCoas;
  }
}
