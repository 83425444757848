import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LinkedAccount } from '../../models/plan-accounts/master/master';

@Injectable({
  providedIn: 'root',
})
export class LinkedAccountsService {
  public linkedAccountsSubject = new BehaviorSubject<LinkedAccount[]>([]);
  linkedAccounts$ = this.linkedAccountsSubject.asObservable();

  addLinkedAccount(newAccount: LinkedAccount) {
    const currentAccounts = this.linkedAccountsSubject.getValue();
    this.linkedAccountsSubject.next([...currentAccounts, newAccount]);
  }

  removeLinkedAccount(account: LinkedAccount) {
    const currentAccounts = this.linkedAccountsSubject.value;
    this.linkedAccountsSubject.next(
      currentAccounts.filter((acc) => acc.code !== account.code)
    );
  }

  getLinkedAccounts() {
    return this.linkedAccountsSubject.getValue();
  }
}
