import { createReducer, on } from '@ngrx/store';
import { CommissionTableRequest } from '../../../models/commission-table/commission_table_request';
import { setCommissions } from '../../actions/commission/commissions-action';

const initalCommissionsValue: CommissionTableRequest[] = [];

export const commissionsReducer = createReducer(
  initalCommissionsValue,
  on(
    setCommissions,
    (
      state: CommissionTableRequest[],
      params: { commissions: CommissionTableRequest[] }
    ) => params.commissions
  )
);
