<p-dialog
  [header]="this.modalTitle"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80%',
    height: '80%', }"
  (onHide)="onDialogHide()"
>
  <div class="commission-form">
    <div *ngIf="isLoading" class="spinner-overlay">
      <div class="spinner-overlay">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-4">
          <label for="nameAgency">Nome Agenzia</label>
          <input
            pInputText
            type="text"
            id="nameAgency"
            formControlName="nameAgency"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'nameAgency') }"
          />
        </div>
        <div class="col-lg-4">
          <label for="address">Indirizzo</label>
          <input
            pInputText
            type="text"
            id="address"
            formControlName="address"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'address') }"
          />
        </div>
        <div class="col-lg-4">
          <label for="zipCode">CAP</label>
          <input
            pInputText
            type="text"
            id="zipCode"
            formControlName="zipCode"
            class="commission-input"
            pattern="\d*"
            maxlength="5"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="city">Comune</label>
          <input
            pInputText
            type="text"
            id="city"
            formControlName="city"
            class="commission-input"
          />
        </div>
        <div class="col-lg-4">
          <label for="province">Provincia</label>
          <input
            pInputText
            type="text"
            id="province"
            formControlName="province"
            class="commission-input"
            pattern="[A-Za-z]*"
            maxlength="2"
          />
        </div>
        <div class="col-lg-4">
          <label for="active">Attivo</label>
          <div>
            <p-inputSwitch formControlName="active" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="vatNumber">Partita IVA</label>
          <input
            pInputText
            type="text"
            id="vatNumber"
            formControlName="vatNumber"
            class="commission-input"
          />
        </div>
        <div class="col-lg-4">
          <label for="fiscalCode">Codice fiscale</label>
          <input
            pInputText
            type="text"
            id="fiscalCode"
            formControlName="fiscalCode"
            class="commission-input"
          />
        </div>
        <div class="col-lg-4">
          <label for="withdrawalTax">% Ritenuta Fiscale</label>
          <input
            pInputText
            type="number"
            id="withdrawalTax"
            formControlName="withdrawalTax"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'withdrawalTax') }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div *ngIf="this.userInfos?.isDirectionalUser">
            <label for="agency">Agenzia</label>
            <p-autoComplete
              styleClass="autocomplete-dropdown"
              formControlName="agency"
              showClear="true"
              [dropdown]="true"
              [suggestions]="filteredValues"
              (completeMethod)="filterValues($event)"
              placeholder="Seleziona..."
              showEmptyMessage="false"
              field="description"
              [ngClass]="{ 'ng-dirty': getDirty(form, 'agency') }"
            />
          </div>
        </div>
      </div>
      <hr class="mb-4" />
      <p-table
        [value]="this.getCompaniesArray().controls"
        dataKey="id"
        [scrollable]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="table-census text-center"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2rem">
              <p-button
                type="button"
                icon="pi pi-plus"
                styleClass="p-button-text"
                (click)="addCompany()"
              ></p-button>
            </th>
            <th style="width: auto">Compagnia coassicurazione</th>
            <th style="width: auto">Attivo/Disattivo</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="text-center">
              <i
                class="pi pi-trash icon"
                (click)="onRemoveCompany(rowIndex)"
              ></i>
            </td>
            <td>
              <div formArrayName="companies">
                <div [formGroupName]="rowIndex">
                  <p-autoComplete
                    appendTo="body"
                    styleClass="autocomplete-dropdown"
                    formControlName="description"
                    showClear="true"
                    [dropdown]="true"
                    [suggestions]="filteredCompanies"
                    (completeMethod)="filterValuesCompanyConsurance($event)"
                    placeholder="Seleziona..."
                    showEmptyMessage="false"
                    field="description"
                    [ngClass]="{
                      'ng-dirty': getDirty(
                        this.getCompaniesArray().controls[rowIndex],
                        'description'
                      )
                    }"
                  />
                </div>
              </div>
            </td>
            <td class="text-center">
              <div formArrayName="companies">
                <div [formGroupName]="rowIndex">
                  <div>
                    <p-inputSwitch formControlName="active" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <hr class="my-5" />
      <p-table
        [value]="this.getPoliciesArray().controls"
        dataKey="id"
        [scrollable]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="table-census text-center"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2rem">
              <p-button
                type="button"
                icon="pi pi-plus"
                styleClass="p-button-text"
                (click)="addPolicy()"
              ></p-button>
            </th>
            <th style="width: auto">Polizze gestite</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="text-center">
              <i
                class="pi pi-trash icon"
                (click)="onRemovePolicy(rowIndex)"
              ></i>
            </td>
            <td>
              <div formArrayName="policies">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    type="text"
                    id="desc"
                    formControlName="desc"
                    class="commission-input"
                    maxlength="15"
                    style="text-transform: uppercase"
                    [ngClass]="{
                      'ng-dirty': getDirty(
                        this.getPoliciesArray().controls[rowIndex],
                        'desc'
                      )
                    }"
                  />
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary button mx-3 my-2" type="submit">
          SALVA
        </button>
      </div>
    </form>
  </div>
</p-dialog>
