import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AgencyCashControl } from '../../models/cash-control/agency-cash';

@Injectable({
  providedIn: 'root',
})
export class CashControlService {
  showSearchTable = false;
  isFirstTime = true;
  tableValues: AgencyCashControl[] = [];
  searchForm = new FormGroup({
    name: new FormControl(null),
  });

  constructor() {}
}
