import { CommonModule, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from 'bootstrap';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { concatMap } from 'rxjs';
import { ConDecons } from '../../../enums/bank-records';
import { BANK_RECORD_PATH } from '../../../enums/path';
import { BCRowSosp } from '../../../models/bank-records/bank-record-rows-sosp';
import {
  BankRecDetailResp,
  Totals,
} from '../../../models/bank-records/bank-records-detail-response';
import { BCRow } from '../../../models/bank-records/bank-records-row-search-response';
import {
  ConDeconsRequest,
  RecuperoSospRows,
} from '../../../models/bank-records/request/bank-records-con-decons-request';
import { HttpUtilsBankRecords } from '../../../network/http-utilis-bank-records';
import { BankRecordsService } from '../../../services/bank-records/bank-records.service';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';

import { Title } from '@angular/platform-browser';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { BankRecordUtilis, debugForm, getType } from '../../../utils';

@Component({
  selector: 'app-edit-bank-record',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    TableModule,
    AccordionModule,
    ReactiveFormsModule,
    InputTextModule,
    ImageModule,
    CustomCurrencyPipe,
    ProgressSpinnerModule,
    FormsModule,
  ],
  templateUrl: './edit-bank-record.component.html',
  styleUrl: './edit-bank-record.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EditBankRecordComponent implements OnInit {
  bankRows: BCRow[] = [];
  bankRecordDetail: BankRecDetailResp | undefined;
  isLoading: boolean = false;
  bankRecordID: number | undefined;
  selectedSposRows: FormGroup[] = [];
  bankRowsSosp: BCRowSosp[] = [];
  formRowsSosp: FormGroup = new FormGroup({
    rows: new FormArray([]),
  });
  recapValuesTable: { key: string; value: Totals | undefined }[] = [];

  constructor(
    public bankRecService: BankRecordsService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    public commissionService: CommissionsFormService,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService,
    private messageService: MessageService,
    private serviceTitle: Title
  ) {}
  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['id']) {
        this.isLoading = true;
        this.bankRecordID = params['id'];

        this.httpUtilsBank
          .getBankRecordDetail(params['id'])
          .pipe(
            concatMap((responseDetail) => {
              this.bankRecordDetail =
                BankRecDetailResp.fromJson(responseDetail);
              this.recapToArray();
              return this.httpUtilsBank.getRowsBankRecord(
                this.commissionService?.isProducer
                  ? this.bankRecordDetail.codCollaborator
                  : this.bankRecordDetail.codCoas,
                undefined,
                params['id']
              );
            })
          )
          .subscribe({
            next: (response) => {
              response['statementRows'].forEach((bankRow: any) => {
                this.bankRows.push(new BCRow(bankRow));
              });
              this.getBankRowsSosp();
              this.setFormRowsSosp();
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      }
    });
  }
  goBack(): void {
    this.router.navigate([BANK_RECORD_PATH.SEARCH], {
      queryParams: {
        type: getType(this.commissionService),
      },
    });
  }
  onDelete(): void {
    let modalReset = document.getElementById('deleteModal');
    if (modalReset) {
      new Modal(modalReset).show();
    }
  }
  onCreateRow(): void {
    this.router.navigate([BANK_RECORD_PATH.ADD_ROW], {
      queryParams: {
        ECid: this.bankRecordDetail?.id,
        userId: this.commissionService.isProducer
          ? this.bankRecordDetail?.codCollaborator
          : this.bankRecordDetail?.codCoas,
      },
    });
  }

  onAddRow(): void {
    if (this.bankRecordDetail) {
      this.router.navigate([BANK_RECORD_PATH.ADD], {
        queryParams: {
          ECid: this.bankRecordDetail?.id,
          userName: this.commissionService.isProducer
            ? `${this.bankRecordDetail.collaboratorName} ${this.bankRecordDetail.collaboratorSurname}`
            : this.bankRecordDetail.desCoas,
          userID: this.commissionService.isProducer
            ? this.bankRecordDetail?.codCollaborator
            : this.bankRecordDetail?.codCoas,
        },
      });
    }
  }

  onStamp(): void {
    if (this.bankRows.length > 0 && this.bankRecordID) {
      this.isLoading = true;
      this.httpUtilsBank.getPDF(this.bankRecordID).subscribe({
        next: (respose) => {
          this.isLoading = false;
          BankRecordUtilis.downloadPdf(respose.reportB64);
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    }
  }

  onRecalculates(): void {
    if (true) {
      let modalReset = document.getElementById('ricalculatesModal');
      if (modalReset) {
        new Modal(modalReset).show();
      }
    }
  }

  recalculate() {
    const id: string | undefined = this.commissionService.isProducer
      ? this.bankRecordDetail?.codCollaborator
      : this.bankRecordDetail?.codCoas;
    if (id && this.bankRecordID) {
      this.isLoading = true;

      // this.httpUtilsBank.calculateRows(this.bankRows).subscribe({
      //   next: (_response) => {
      //     this.isLoading = false;
      //     this.messageService.add({
      //       severity: 'warn',
      //       summary: 'Attenzione',
      //       key: 'br',
      //       detail:
      //         'Il ricalcolo è in corso, potrebbe richiedere alcuni minuti',
      //     });
      //   },
      //   error: (_error: HttpErrorResponse) => {
      //     this.messageService.add({
      //       severity: 'error',
      //       summary: 'Errore',
      //       key: 'br',
      //       detail: 'Impossibile effettuare il ricalcolo',
      //     });
      //     this.isLoading = false;
      //   },
      // });

      this.httpUtilsBank
        .calculateAndReload(this.bankRows, id, this.bankRecordID)
        .pipe(
          concatMap((responseRows) => {
            this.bankRows = [];
            this.bankRowsSosp = [];

            responseRows['statementRows'].forEach((bankRow: any) => {
              this.bankRows.push(new BCRow(bankRow));
            });
            this.getBankRowsSosp();
            this.setFormRowsSosp();
            return this.httpUtilsBank.getBankRecordDetail(this.bankRecordID!);
          })
        )
        .subscribe({
          next: (detailResponse) => {
            this.bankRecordDetail = BankRecDetailResp.fromJson(detailResponse);
            this.recapToArray();
            this.isLoading = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Attenzione',
              key: 'br',
              detail:
                'Il ricalcolo è in corso, potrebbe richiedere alcuni minuti',
            });
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    } else {
      this.modalService.showModal(
        new ModalInfos(
          'Errore',
          'Mancano informazioni per procedere al ricalcolo',
          () => {
            this.serviceTitle.setTitle('Home | Gestionale Agenzia');
            this.router.navigate(['']);
          }
        )
      );
    }
  }

  onOpenConsolidatesAndPay(): void {
    let modalReset = document.getElementById('consolidateModal');
    if (modalReset) {
      new Modal(modalReset).show();
    }
  }

  closeConsolidatesAndPay(): void {
    let modalReset = document.getElementById('consolidateModal');
    if (modalReset) {
      new Modal(modalReset).hide();
    }
  }

  consolidateAndPay(): void {
    if (this.formRowsSosp.valid) {
      if (this.bankRecordID) {
        this.isLoading = true;
        this.httpUtilsBank
          .consolidatesAndDeconBankRecord(
            this.getConsolidateRequest(),
            this.bankRecordID
          )
          .subscribe({
            next: (_response) => {
              this.isLoading = false;
              this.closeConsolidatesAndPay();
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Sospesi consolidati con successo',
              });
              this.router.navigate([BANK_RECORD_PATH.SEARCH]);
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      }
    }
  }

  onModify(event: MouseEvent, rowID: number, manuallyCreated: boolean): void {
    event?.preventDefault();
    const id: string | undefined = this.commissionService.isProducer
      ? this.bankRecordDetail?.codCollaborator
      : this.bankRecordDetail?.codCoas;

    if (manuallyCreated) {
      this.router.navigate([BANK_RECORD_PATH.ADD_ROW], {
        queryParams: { id: rowID, userId: id, ECid: this.bankRecordDetail?.id },
      });
    } else {
      this.router.navigate([BANK_RECORD_PATH.EDIT_ROW], {
        queryParams: { id: rowID, userId: id, ECid: this.bankRecordDetail?.id },
      });
    }
  }
  onModifyRedirect(rowID: number, manuallyCreated: boolean) {
    const id: string | undefined = this.commissionService.isProducer
      ? this.bankRecordDetail?.codCollaborator
      : this.bankRecordDetail?.codCoas;

    if (manuallyCreated) {
      return this.router
        .createUrlTree([BANK_RECORD_PATH.ADD_ROW], {
          queryParams: {
            id: rowID,
            userId: id,
            ECid: this.bankRecordDetail?.id,
          },
        })
        .toString();
    } else {
      return this.router
        .createUrlTree([BANK_RECORD_PATH.EDIT_ROW], {
          queryParams: {
            id: rowID,
            userId: id,
            ECid: this.bankRecordDetail?.id,
          },
        })
        .toString();
    }
  }

  deleteBankRecord(): void {
    if (this.bankRecordID) {
      this.isLoading = true;
      this.httpUtilsBank.deleteBankRecord(this.bankRecordID).subscribe({
        next: (_response) => {
          this.isLoading = false;
          this.router.navigate([BANK_RECORD_PATH.SEARCH]);
          this.messageService.add({
            severity: 'success',
            summary: 'Successo',
            key: 'br',
            detail: 'Estratto conto eliminato con successo',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    }
  }

  getState(): string {
    return this.bankRecordDetail?.consolidated ? 'Consolidato' : 'Bozza';
  }

  onClickLoadNewDetail(id: number | null | undefined): void {
    if (id === null || id === undefined) {
      return;
    }
    this.router.navigate([BANK_RECORD_PATH.EDIT], {
      queryParams: { id: id },
    });
  }

  public recapToArray(): void {

    this.recapValuesTable.push({
      key: 'Tot. Movimenti manuali',
      value: this.bankRecordDetail?.recap.totManualMovements,
    })

    this.recapValuesTable.push({
      key: 'Tot. Provvigioni',
      value: this.bankRecordDetail?.recap.totProvisions,
    });

    this.recapValuesTable.push({
      key: 'Saldo Gestione Provvigionale',
      value: this.bankRecordDetail?.recap.provisionsBalance,
    });

    this.recapValuesTable.push({
      key: 'Ritenute fiscali',
      value: this.bankRecordDetail?.recap.totWitholdingTax,
    });

    if (this.commissionService.isProducer) {
      this.recapValuesTable.push({
        key: 'E/C ' + this.bankRecordDetail?.collaboratorName,
        value: this.bankRecordDetail?.recap.totBankStatementPdre,
      });
    }

    if(this.commissionService.isProducer){
      this.recapValuesTable.push({
        key: 'Sospesi',
        value: this.bankRecordDetail?.recap.totSospesi,
      });
    }

    if(this.commissionService.isProducer){
      this.recapValuesTable.push({
        key: 'Recupero Sospesi',
        value: this.bankRecordDetail?.recap.totRecuperoSospesi,
      });
    }

    if(this.commissionService.isProducer){

      this.recapValuesTable.push({
        key: 'Tot. IFR Immediato',
        value: this.bankRecordDetail?.recap.totIFRImmediato,
      });
    }

    this.recapValuesTable.push({
      key: 'Saldo',
      value: this.bankRecordDetail?.recap.totBalance,
    });
  }

  public getRows(): FormArray {
    return this.formRowsSosp.get('rows') as FormArray;
  }

  private getBankRowsSosp(): void {
    this.bankRows.forEach((row) => {
      if (row.totSospeso != 0 && row.totSospeso != null) {
        this.bankRowsSosp.push(
          new BCRowSosp(
            row.rowId,
            row.collectionDate,
            row.numPoli,
            row.collaboratorName,
            row.collaboratorSurname,
            row.gross,
            row.totSospeso,
            row.codPdre,
            row.codCoas
          )
        );
      }
    });
  }

  private setFormRowsSosp(): void {
    const formGroups = this.bankRowsSosp.map((row) => {
      const formGroup = new FormGroup({
        rowId: new FormControl(row.rowId),
        collectionDate: new FormControl(row.collectionDate),
        numPoli: new FormControl(row.numPoli),
        fullName: new FormControl(
          row.collaboratorName + ' ' + row.collaboratorSurname
        ),
        gross: new FormControl(row.gross),
        totSospeso: new FormControl(row.totSospeso),
        codPdre: new FormControl(row.codPdre),
        codCoas: new FormControl(row.codCoas),
      });

      if (this.commissionService.isProducer) {
        formGroup.get('codCoas')?.disable();
      } else {
        formGroup.get('codPdre')?.disable();
      }

      formGroup.get('collectionDate')?.disable();
      formGroup.get('numPoli')?.disable();
      formGroup.get('fullName')?.disable();
      formGroup.get('gross')?.disable();

      return formGroup;
    });

    const formArray = new FormArray(formGroups);

    this.formRowsSosp.setControl('rows', formArray);
  }

  private getConsolidateRequest(): ConDeconsRequest {
    let recuperoSospRows: RecuperoSospRows[] = [];
    if (this.selectedSposRows.length > 0) {
      recuperoSospRows = this.selectedSposRows.map((formGroup) => {
        return new RecuperoSospRows(
          formGroup.get('rowId')?.value,
          formGroup.get('totSospeso')?.value
        );
      });
    }

    return new ConDeconsRequest(ConDecons.consolidate, recuperoSospRows);
  }

  select(selectedRow: any) {
    const index = this.selectedSposRows.findIndex(
      (row) => row.get('rowId')?.value === selectedRow.get('rowId')?.value
    );

    if (index === -1) {
      this.selectedSposRows = [...this.selectedSposRows, selectedRow];
    }
  }
}
