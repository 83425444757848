<div class="commission-form">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="d-flex align-items-center my-4 go-back">
      <div (click)="goBack()">
        <i class="pi pi-arrow-left m-0 me-2 icon-list"> </i>
        <span class="label">Indietro</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="numPoli">Polizza*</label>
        <input
          id="numPoli"
          pInputText
          type="text"
          formControlName="numPoli"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="desClie">Cliente*</label>
        <input
          pInputText
          id="desClie"
          type="text"
          formControlName="desClie"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="collectionDate">Data registrazione*</label>
        <p-calendar
          id="collectionDate"
          formControlName="collectionDate"
          [showIcon]="true"
          styleClass="commission-calendar my-2"
          dateFormat="dd/mm/yy"
        ></p-calendar>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="branchPoli">Ramo*</label>
        <input
          pInputText
          id="branchPoli"
          type="text"
          formControlName="branchPoli"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="reason">Causale*</label>
        <input
          pInputText
          id="reason"
          type="text"
          formControlName="reason"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="gross">Premio lordo*</label>
        <input
          id="gross"
          pInputText
          type="number"
          formControlName="gross"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <label for="net">Premio netto*</label>
        <input
          id="net"
          pInputText
          type="number"
          formControlName="net"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="codPdre">Produttore*</label>
        <input
          pInputText
          id="codPdre"
          type="text"
          formControlName="codPdre"
          placeholder="Inserisci"
          class="commission-input my-2"
        />
      </div>
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="provvAgency">Provvigioni di agenzia*</label>
        <input
          id="provvAgency"
          pInputText
          type="number"
          formControlName="provvAgency"
          placeholder="Inserisci"
          class="commission-input my-2"
          step="any"
          inputmode="decimal"
          lang="en"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12" *ngIf="this.commissionService.isProducer">
        <label for="provvCalcolata">Provvigioni di produttore*</label>
        <div class="d-flex align-items-center">
          <input
            id="provvCalcolata"
            pInputText
            type="number"
            formControlName="provvCalcolata"
            placeholder="Inserisci"
            class="commission-input my-2"
            step="any"
            inputmode="decimal"
            lang="en"
          />
          <p-image
            src="../../../../../assets/images/touched.svg"
            alt="Touched SVG"
            width="36"
            class="ms-1"
            *ngIf="this.rowDetail?.manuallyUpdated"
          ></p-image>
        </div>
      </div>
      <div class="col-lg-4 col-md-12"></div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary mx-3 my-4" type="submit">
        <strong>Modifica</strong>
      </button>
    </div>
    <div class="mt-2">
      <p-table
        [value]="this.getDetails().controls"
        dataKey="id"
        [scrollable]="true"
        [tableStyle]="{ 'min-width': '60rem' }"
        styleClass=" table-commission"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="registrationDate" style="width: auto">
              Tipo provvigione
            </th>
            <th pSortableColumn="fullName" style="width: auto">Modalità</th>
            <th pSortableColumn="id" style="width: auto">Perc. (%)</th>
            <th pSortableColumn="totStatementLordo" style="width: auto">
              Valore (€)
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-bankRecord
          let-i="rowIndex"
          let-rowIndex="rowIndex"
          let-editing="editing"
        >
          <tr>
            <td>
              <div formArrayName="details">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    id="commissionType"
                    type="text"
                    formControlName="commissionType"
                    placeholder="Inserisci"
                    class="commission-input my-2"
                  />
                </div>
              </div>
            </td>
            <td>
              <div formArrayName="details">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    id="commissionMode"
                    type="text"
                    formControlName="commissionMode"
                    placeholder="Inserisci"
                    class="commission-input my-2"
                  />
                </div>
              </div>
            </td>
            <td>
              <div formArrayName="details">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    id="commissionPerc"
                    type="number"
                    formControlName="commissionPerc"
                    min="0"
                    max="100"
                    placeholder="Inserisci"
                    class="commission-input my-2"
                  />
                </div>
              </div>
            </td>
            <td>
              <div formArrayName="details">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    id="rowValue"
                    type="number"
                    formControlName="rowValue"
                    placeholder="Inserisci"
                    class="commission-input my-2"
                  />
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
</div>
