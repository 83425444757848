import { CommonModule, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { concatMap } from 'rxjs';
import { ConDecons } from '../../../enums/bank-records';
import { BANK_RECORD_PATH } from '../../../enums/path';
import {
  BankRecDetailResp,
  Totals,
} from '../../../models/bank-records/bank-records-detail-response';
import { BCRow } from '../../../models/bank-records/bank-records-row-search-response';
import { ConDeconsRequest } from '../../../models/bank-records/request/bank-records-con-decons-request';
import { HttpUtilsBankRecords } from '../../../network/http-utilis-bank-records';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { BankRecordsService } from '../../../services/bank-records/bank-records.service';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../services/modal/modal.service';
import { getType } from '../../../utils';
import { BankRecordUtilis } from '../../../utils/bank-record-utilis';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-detail-bank-records',
  standalone: true,
  imports: [
    DatePipe,
    TableModule,
    CommonModule,
    AccordionModule,
    ImageModule,
    CustomCurrencyPipe,
    ProgressSpinnerModule,
    FormsModule,
  ],
  templateUrl: './detail-bank-records.component.html',
  styleUrl: './detail-bank-records.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DetailBankRecordsComponent implements OnInit {
  bankRows: BCRow[] = [];
  bankRecordDetail: BankRecDetailResp | undefined;
  bankRecordID: number | undefined;
  isLoading: boolean = false;
  recapValuesTable: { key: string; value: Totals | undefined }[] = [];

  constructor(
    public bankRecService: BankRecordsService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService,
    public commissionService: CommissionsFormService,
    private messageService: MessageService
  ) {}
  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params['id']) {
        this.httpUtilsBank
          .getBankRecordDetail(params['id'])
          .pipe(
            concatMap((responseDetail) => {
              this.bankRecordDetail =
                BankRecDetailResp.fromJson(responseDetail);
              this.recapToArray();
              this.bankRecordID = this.bankRecordDetail?.id;

              return this.httpUtilsBank.getRowsBankRecord(
                this.bankRecordDetail.codCollaborator,
                undefined,
                params['id']
              );
            })
          )
          .subscribe({
            next: (response) => {
              response['statementRows'].forEach((bankRow: any) => {
                this.bankRows.push(new BCRow(bankRow));
              });
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      }
    });
  }
  goBack(): void {
    this.router.navigate([BANK_RECORD_PATH.SEARCH], {
      queryParams: {
        type: getType(this.commissionService),
      },
    });
  }
  onDeconso(): void {
    if (this.bankRecordID) {
      this.httpUtilsBank
        .consolidatesAndDeconBankRecord(
          new ConDeconsRequest(ConDecons.deconsolidate, []),
          this.bankRecordID
        )
        .subscribe({
          next: (_response) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Estratto deconsolidato con successo',
            });
            this.router.navigate([BANK_RECORD_PATH.SEARCH]);
          },
          error: (error: HttpErrorResponse) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }
  onStamp(): void {
    if (this.bankRecordID) {
      this.isLoading = true;
      this.httpUtilsBank.getPDF(this.bankRecordID).subscribe({
        next: (respose) => {
          this.isLoading = false;
          BankRecordUtilis.downloadPdf(respose.reportB64);
        },
        error: (error: HttpErrorResponse) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
    }
  }

  onModify(event: MouseEvent): void {
    event?.preventDefault();
    this.router.navigate([BANK_RECORD_PATH.EDIT], {
      queryParams: { id: this.bankRecordID },
    });
  }

  onModifyRedirect(): string {
    return this.router
      .createUrlTree([BANK_RECORD_PATH.EDIT], {
        queryParams: { id: this.bankRecordID },
      })
      .toString();
  }

  getState(): string {
    return this.bankRecordDetail?.consolidated ? 'Consolidato' : 'Bozza';
  }

  onClickLoadNewDetail(id: number | null | undefined): void {
    if (id === null || id === undefined) {
      return;
    }

    this.router.navigate([BANK_RECORD_PATH.DETAIL], {
      queryParams: { id: id },
    });
  }

  public recapToArray(): void {
    this.recapValuesTable = [
      {
        key: 'Tot. Movimenti manuali',
        value: this.bankRecordDetail?.recap.totManualMovements,
      },
      {
        key: 'Tot. Provvigioni',
        value: this.bankRecordDetail?.recap.totProvisions,
      },
      {
        key: 'Saldo Gestione Provvigionale',
        value: this.bankRecordDetail?.recap.provisionsBalance,
      },
      {
        key: 'Ritenute fiscali',
        value: this.bankRecordDetail?.recap.totWitholdingTax,
      },
      {
        key: 'E/C ' + this.bankRecordDetail?.collaboratorName,
        value: this.bankRecordDetail?.recap.totBankStatementPdre,
      },
      {
        key: 'Sospesi',
        value: this.bankRecordDetail?.recap.totSospesi,
      },
      {
        key: 'Recupero Sospesi',
        value: this.bankRecordDetail?.recap.totRecuperoSospesi,
      },
      {
        key: 'Tot. IFR Immediato',
        value: this.bankRecordDetail?.recap.totIFRImmediato,
      },
      {
        key: 'Saldo',
        value: this.bankRecordDetail?.recap.totBalance,
      },
    ];
  }
}
