import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { HttpUtilisCashControl } from '../../../network/http-utilis-cash-control';
import { Manager } from '../../../models/plan-accounts/account/create-account-request';
import { CashControlService } from '../../../services/cash-control/cash-control.service';
import { Router } from '@angular/router';
import { CASH_CONTROL_PATH } from '../../../enums/path';
import { UserInfo } from '../../../models/user/user-info';
import { splitAgencyCode } from '../../../constants/general';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { ModalService } from '../../../services/modal/modal.service';
import { TYPE } from '../../../enums/path-of-accounts';
import { AgencyCashControl } from '../../../models/cash-control/agency-cash';

@Component({
  selector: 'app-search-cash-control-screen',
  standalone: true,
  imports: [
    DropdownModule,
    CommonModule,
    TableModule,
    ProgressSpinnerModule,
    CustomCurrencyPipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './search-cash-control-screen.component.html',
  styleUrl: './search-cash-control-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SearchCashControlScreenComponent implements OnInit {
  managerOptions: Manager[] | undefined;
  isLoading: boolean = false;
  userInfo: UserInfo | undefined;

  constructor(
    private httpUtilisCashControl: HttpUtilisCashControl,
    public cashService: CashControlService,
    private router: Router,
    private httpPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private modalService: ModalService
  ) {
    if (sessionStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(sessionStorage.getItem('userInfo')!)
      );
    }
  }

  ngOnInit(): void {
    this.initState();
    if (this.cashService.isFirstTime) {
      this.cashService.isFirstTime = false;
    } else {
      this.getCashControls();
    }
  }

  onSubmit() {
    this.getCashControls();
  }

  onModify(firstNote: AgencyCashControl) {
    this.router.navigate([CASH_CONTROL_PATH.EDIT], {
      queryParams: { id: firstNote.id, fromSearch: 'true' },
    });
  }

  onDetail(firstNote: AgencyCashControl) {
    this.router.navigate([CASH_CONTROL_PATH.DETAIL], {
      queryParams: { id: firstNote.id },
    });
  }

  initState() {
    this.isLoading = true;
    const agencyCode =
      this.userInfo?.agencyDescription.split(splitAgencyCode)[0];
    this.httpPlanOfAccounts
      .getAllInfos(
        TYPE.GESTORE_PN,
        'ONLY_GEST_PN',
        agencyCode,
        this.userInfo?.node
      )
      .subscribe({
        next: (response) => {
          this.managerOptions = response['types'];
          this.isLoading = false;
        },
        error: (error) => {
          this.modalService.showError(error);
          this.isLoading = false;
        },
      });
  }

  getCashControls() {
    this.isLoading = true;
    const manager = this.cashService.searchForm.get('name')?.value ?? undefined;
    this.httpUtilisCashControl.getCashControl(manager).subscribe({
      next: (response: any) => {
        this.cashService.tableValues = AgencyCashControl.fromJsonArray(
          response['registers']
        );
        this.cashService.showSearchTable = true;
        this.isLoading = false;
      },
      error: (error) => {
        this.modalService.showError(error);
        this.isLoading = false;
      },
    });
  }
}
