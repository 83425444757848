const SERVICE_URL_COMMISSION = 'commissions-service/';
const SERVICE_URL_BANK_RECORDS = 'statements-service/';
const SERVICE_URL_PLANS_OF_ACCOUNTS = 'accounting-service/';
const BANK_RECORD_VERSION_API = 'api/v1/';
const VERSION_API = 'api/v1/';
const SERVICE_URL_PLAN_OF_ACCOUNTS = 'api/v1/';
const BASE_URL = 'https://tst-api-gestionale-agenzia.gruppoitas.it/';

export const environment = {
  ENV_TYPE: 'DEV',
  IDTOKEN: {
    AuthFlow: 'USER_PASSWORD_AUTH',
    AuthParameters: {
      USERNAME: 'agenzie-be',
      PASSWORD: 'jmUlXdnasblriXz/THgSRKXBo3yoBF4mpQ==',
    },
    ClientId: '75qrruj4jejsc1n8619vnn9vfc',
    UserPoolId: 'eu-south-1_tZXbudE6H',
  },
  APP_LOGIN:
    'https://rpssotstx.gruppoitas.it/sso/XUI/?realm=/itasnet#login&goto=https://tst-gestionale-agenzia.gruppoitas.it/',
  APP_LOGOUT: 'https://rpssotstx.gruppoitas.it/sso/XUI/?realm=/itasnet#logout',
  URL_COMMISSION: `${BASE_URL}${SERVICE_URL_COMMISSION}${VERSION_API}commissions/`,
  URL_BANK_RECORD: `${BASE_URL}${SERVICE_URL_BANK_RECORDS}${BANK_RECORD_VERSION_API}statements/`,
  URL_PLAN_OF_ACCOUNTS: `${BASE_URL}${SERVICE_URL_PLANS_OF_ACCOUNTS}${SERVICE_URL_PLAN_OF_ACCOUNTS}`,
  URL_CASH_CONTROL: `${BASE_URL}${SERVICE_URL_PLANS_OF_ACCOUNTS}${SERVICE_URL_PLAN_OF_ACCOUNTS}register/`,
  ID_TOKEN_URL: 'https://cognito-idp.eu-south-1.amazonaws.com/',
  ACCESS_TOKEN_URL:
    'https://tst-api-gestionale-agenzia.gruppoitas.it/iam-service/oauth2/token/',
  USER_INFO_URL:
    'https://tst-api-gestionale-agenzia.gruppoitas.it/iam-service/api/v1/user',
};
