import { Manager } from '../plan-accounts/account/create-account-request';

export class ManagerOperation {
  manager: Manager;
  isCreate: boolean;

  constructor(manager: Manager, isCreate: boolean) {
    this.manager = manager;
    this.isCreate = isCreate;
  }
}
