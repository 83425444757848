import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Modal } from 'bootstrap';
import { ModalInfos } from '../../../models/modal/modal-infos';
import { ModalService } from '../../../services/modal/modal.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-modal.component.html',
  styleUrl: './partial-error-modal.component.scss',
})

//modalPartialError
export class ModalComponent {
  modalInfos: ModalInfos | undefined;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.getSubject()?.subscribe((modalInfos) => {
      this.modalInfos = modalInfos;
      this.showModal();
    });
  }

  showModal(): void {
    const modalElement = document.getElementById('customModal') as HTMLElement;
    if (modalElement) {
      const modal = new Modal(modalElement, {
        backdrop: 'static',
        keyboard: false,
      });
      modal.show();
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('customModal') as HTMLElement;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  }
}
