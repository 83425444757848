<div class="text-end mt-3">
  <div *ngIf="isEditable">
    <button
      class="btn btn-danger mb-2 me-2"
      (click)="onDeleteMultipleMasters()"
      [disabled]="this.selectedMasters.length === 0"
    >
      <strong>ELIMINA MASTRI</strong>
    </button>
    <button class="btn btn-primary mb-2" (click)="createNewMaster()">
      <strong>CREA NUOVO</strong>
    </button>
  </div>

  <p-table
    [value]="masters ?? []"
    dataKey="id"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass=" table-commission text-center"
    [(selection)]="selectedMasters"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem" *ngIf="isEditable">
          <p-tableHeaderCheckbox />
        </th>
        <th style="width: 6rem" *ngIf="isEditable"></th>

        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code" style="width: auto">
          Codice <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="type.description" style="width: auto">
          Tipologia <p-sortIcon field="type.description"></p-sortIcon>
        </th>
        <th pSortableColumn="value" style="width: auto">
          Importo <p-sortIcon field="value"></p-sortIcon>
        </th>
        <th pSortableColumn="linkedAccounts" style="width: auto">
          Conti collegati
          <p-sortIcon field="linkedAccounts"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agencyCode"
          style="width: auto"
        >
          Codice Agenzia
          <p-sortIcon field="agencyCode"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngIf="isEditable"></th>
        <th *ngIf="isEditable"></th>
        <th>
          <p-columnFilter type="text" field="name" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="code" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="type.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter type="text" field="value" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="linkedAccounts.length"
            matchMode="contains"
          />
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter type="text" field="agencyCode" matchMode="contains" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-master let-i="rowIndex">
      <tr>
        <td *ngIf="isEditable">
          <p-tableCheckbox [value]="master" />
        </td>
        <td *ngIf="isEditable">
          <i
            class="pi pi-pen-to-square icon col-md-6"
            (click)="onModify(master)"
            title="Modifica"
          ></i>
          <i
            title="Elimina bozza"
            class="pi pi-trash icon col-md-6"
            (click)="onDelete(master)"
            title="Elimina"
          ></i>
        </td>
        <td class="text-center">
          {{ master.name }}
        </td>
        <td class="text-center">
          {{ master.code }}
        </td>
        <td class="text-center">
          {{ master.type.description }}
        </td>
        <td class="text-center">{{ master.value | customCurrency }}</td>
        <td class="text-center">
          <a class="underline" (click)="this.showMasterDetail(master)">
            {{ master.linkedAccounts.length }}
          </a>
        </td>
        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ master.agencyCode }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<app-modal-add-master
  [master]="modifiedMaster"
  [modalRefAdd]="this.modalRefAdd"
></app-modal-add-master>
<app-modal-linked-accounts
  [master]="modifiedMaster"
  [modalRefDetail]="modalRefDetail"
></app-modal-linked-accounts>
