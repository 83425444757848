import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { MOCK_VALUES } from '../../../../constants/test';
import { BANK_RECORD_PATH } from '../../../../enums/path';
import {
  BankRecRowDetailResp,
  CommissionDetail,
} from '../../../../models/bank-records/bank-record-detail-row-response';
import { ModalInfos } from '../../../../models/modal/modal-infos';
import { HttpUtilsBankRecords } from '../../../../network/http-utilis-bank-records';
import { CommissionsFormService } from '../../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { getType } from '../../../../utils';
@Component({
  selector: 'app-edit-row-bank-record-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    CommonModule,
    InputTextModule,
    ImageModule,
    TableModule,
  ],
  templateUrl: './edit-row-bank-record.component.html',
  styleUrl: './edit-row-bank-record.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EditRowBankRecordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  categoryOptions = MOCK_VALUES;
  $subRoute: Subscription | undefined;
  isLoading: boolean = false;
  bankRecRowID: number | undefined;
  ecID: number | undefined;
  userID: number | undefined;
  rowDetail: BankRecRowDetailResp | undefined;

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public commissionService: CommissionsFormService,
    private modalService: ModalService,
    private httpUtils: HttpUtilsBankRecords,
    private messageService: MessageService
  ) {
    this.form = new FormGroup({
      numPoli: new FormControl(null, [Validators.required]),
      collaboratorName: new FormControl(null, [Validators.required]),
      collaboratorSurname: new FormControl(null, [Validators.required]),
      desClie: new FormControl(null, [Validators.required]),
      collectionDate: new FormControl(null, [Validators.required]),
      desCoas: new FormControl(null, [Validators.required]),
      branchPoli: new FormControl(null, [Validators.required]),
      reason: new FormControl(null, [Validators.required]),
      gross: new FormControl(null, [Validators.required]),
      net: new FormControl(null, [Validators.required]),
      provvAgency: new FormControl(null, [Validators.required]),
      provvCalcolata: new FormControl(null, [Validators.required]),
      details: new FormArray([], [Validators.required]),
      codPdre: new FormControl(null, [Validators.required]),
    });
  }

  //TODO: added codPdre check if it's required in coinsurrances
  ngOnDestroy(): void {
    this.$subRoute?.unsubscribe();
  }

  ngOnInit(): void {
    this.$subRoute = this.activatedRouter.queryParams.subscribe((params) => {
      this.bankRecRowID = params['id'];
      this.userID = params['userId'];
      this.ecID = params['ECid'];
      if (this.bankRecRowID && this.userID) {
        this.httpUtils
          .getRowDetailBankRec(this.userID, this.bankRecRowID)
          .subscribe({
            next: (bankrecordDetails) => {
              this.rowDetail = bankrecordDetails;
              this.onInitDisable();
              this.populateForm();
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.modalService.showError(error);
            },
          });

        this.onInitDisable();
        this.populateForm();
      }
    });

    if (this.commissionService.isProducer) {
      this.form.get('desCoas')?.disable();
    } else {
      this.form.get('provvPdre')?.disable();
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      let bankRequest: BankRecRowDetailResp = this.form.getRawValue();
      bankRequest.statementDes = '';
      bankRequest.details = this.getUpdatedDetails(bankRequest);

      if (this.userID && this.bankRecRowID) {
        this.isLoading = true;
        this.httpUtils
          .modifyRowBankRec(this.userID, this.bankRecRowID, bankRequest)
          .subscribe({
            next: (_response) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Riga estratto conto modificata con successo',
              });

              this.ecID
                ? this.router.navigate([BANK_RECORD_PATH.EDIT], {
                    queryParams: { id: this.ecID },
                  })
                : this.router.navigate([BANK_RECORD_PATH.ADD]);
            },
            error: (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.modalService.showError(error);
            },
          });
      }
    }
  }

  populateForm(): void {
    this.form.patchValue({
      numPoli: this.rowDetail?.numPoli,
      collaboratorName: this.rowDetail?.collaboratorName,
      collaboratorSurname: this.rowDetail?.collaboratorSurname,
      collectionDate: this.rowDetail?.collectionDate
        ? new Date(this.rowDetail.collectionDate)
        : new Date(),
      desClie: `${this.rowDetail?.clienteName} ${
        this.rowDetail?.clienteSurname ?? ''
      }`,
      branchPoli: this.rowDetail?.branchPoli,
      reason: this.rowDetail?.reason,
      gross: this.rowDetail?.gross,
      net: this.rowDetail?.net,
      provvAgency: this.rowDetail?.provvAgency,
      provvCalcolata: this.rowDetail?.provvCalcolata,
      codPdre: this.rowDetail?.codPdre,
    });

    this.form.setControl(
      'details',
      new FormArray(
        this.rowDetail?.details?.map(
          (detail) =>
            new FormGroup({
              commissionType: new FormControl({
                value: detail.commissionType.description,
                disabled: true,
              }),
              commissionMode: new FormControl({
                value: detail.commissionMode.description,
                disabled: true,
              }),
              commissionPerc: new FormControl({
                value: detail.commissionPerc,
                disabled: true,
              }),
              rowValue: new FormControl({
                value: detail.rowValue,
                disabled: true,
              }),
            })
        ) ?? []
      )
    );
  }

  getDetails(): FormArray {
    return this.form.get('details') as FormArray;
  }

  goBack(): void {
    this.ecID
      ? this.router.navigate([BANK_RECORD_PATH.EDIT], {
          queryParams: { id: this.ecID },
        })
      : this.router.navigate([BANK_RECORD_PATH.ADD], {
          queryParams: {
            type: getType(this.commissionService),
          },
        });
  }

  onInitDisable(): void {
    if (!this.rowDetail?.manuallyCreated) {
      let details = this.form.get('details') as FormArray;

      this.form.get('numPoli')?.disable();
      this.form.get('desClie')?.disable();
      this.form.get('collaboratorName')?.disable();
      this.form.get('collaboratorSurname')?.disable();
      this.form.get('collectionDate')?.disable();
      this.form.get('branchPoli')?.disable();
      this.form.get('reason')?.disable();
      this.form.get('gross')?.disable();
      this.form.get('desCoas')?.disable();
      this.form.get('net')?.disable();
      this.form.get('provvAgency')?.disable();
      this.form.get('codPdre')?.disable();
      if (!this.commissionService.isProducer)
        this.form.get('provvCalcolata')?.disable();
      details.controls.forEach((control) => {
        control.get('commissionType')?.disable();
        control.get('commissionMode')?.disable();
        control.get('commissionPerc')?.disable();
        control.get('rowValue')?.disable();
      });
    } else {
      this.modalService.showModal(
        new ModalInfos(
          'Attenzione',
          'Il record è stato creato manualmente, non sarà possibile modificarlo',
          () => {
            this.goBack();
          }
        )
      );
    }
  }

  getUpdatedDetails(bankRequest: BankRecRowDetailResp): CommissionDetail[] {
    const commissionMap = new Map();
    bankRequest?.details?.forEach((item) => {
      commissionMap.set(item.commissionType, item);
    });

    const updatedDetails = this.rowDetail?.details || [];

    updatedDetails.forEach((item) => {
      const commissionTypeDescription = item.commissionType.description;
      if (commissionMap.has(commissionTypeDescription)) {
        const updatedValues = commissionMap.get(commissionTypeDescription);
        item.commissionPerc = updatedValues.commissionPerc;
        item.rowValue = updatedValues.rowValue;
      }
    });

    return updatedDetails || [];
  }
}
