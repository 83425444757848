import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomCurrencyPipe } from '../../../pipe/custom-currency.pipe';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { Account } from '../../../models/plan-accounts/account/account';
import { UserInfo } from '../../../models/user/user-info';
import { MessageService } from 'primeng/api';
import { Modal } from 'bootstrap';
import { ModalAddAccountComponent } from '../../../components/modals/modal-add-account/modal-add-account.component';
import { ModalDetailAccountComponent } from '../../../components/modals/modal-detail-account/modal-detail-account.component';
import { HttpUtilisPlanOfAccounts } from '../../../network/http-utilis-plan-of-accounts';
import { splitAgencyCode } from '../../../constants/general';
import { ModalService } from '../../../services/modal/modal.service';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { concatMap, Subscription } from 'rxjs';
import { PlanOfAccountService } from '../../../services/plan-of-accounts/plan-of-account-service';
import { LinkedSubAccountsService } from '../../../services/linked-sub-accounts/linked-accounts.service';
import { VISIBILITY } from '../../../enums/visibility';
@Component({
  selector: 'app-accounts-screen',
  standalone: true,
  imports: [
    CustomCurrencyPipe,
    CommonModule,
    TableModule,
    ModalAddAccountComponent,
    ModalDetailAccountComponent,
    InputSwitchModule,
    FormsModule,
  ],
  templateUrl: './accounts-screen.component.html',
  styleUrl: './accounts-screen.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AccountsScreenComponent implements OnInit, OnDestroy {
  accounts: Account[] | undefined;
  selectedAccounts: Account[] = [];
  userInfo: UserInfo | undefined;
  modalAdd: any | undefined;
  modalDetail: any | undefined;
  modifiedAccount: Account | undefined;
  detailAccount: Account | undefined;
  modalRefAdd: Modal | undefined;
  modalRefDetail: Modal | undefined;
  accountsSub$: Subscription | undefined;
  isLoading = false;
  isEditable: boolean = false;

  constructor(
    private messageService: MessageService,
    private modalService: ModalService,
    private httpUtilisPlanOfAccounts: HttpUtilisPlanOfAccounts,
    private planOfAccountsService: PlanOfAccountService,
    private linkedSubAccountsService: LinkedSubAccountsService
  ) {
    if (sessionStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(sessionStorage.getItem('userInfo')!)
      );
    }
  }
  ngOnDestroy(): void {
    this.accountsSub$?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.userInfo) {
      this.isEditable = this.userInfo.userFunctions.includes(
        VISIBILITY.PRIMANOTA_PIANODEICONTI_CONTI_AGGIORNA
      );

      this.accountsSub$ = this.planOfAccountsService.accountsSub.subscribe({
        next: (next) => {
          const accounts = Account.fromCall(next ?? []);
          this.accounts = accounts;
        },
      });
      this.initializeModals();
      this.httpUtilisPlanOfAccounts
        .getAccounts(this.userInfo?.agencyDescription.split(splitAgencyCode)[0])
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.planOfAccountsService.accountsSub.next(response['accounts']);
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  createNewAccount() {
    this.modifiedAccount = undefined;
    this.modalRefAdd?.show();
  }

  onModify(account: Account) {
    this.modifiedAccount = account;
    this.modalRefAdd?.show();
  }

  onDelete(account: Account) {
    if (this.userInfo) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .deleteAccount([account])
        .pipe(
          concatMap((_deleteResponse) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Conto eliminato con successo',
              key: 'br',
            });
            return this.httpUtilisPlanOfAccounts.getAccounts(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0]
            );
          })
        )
        .subscribe({
          next: (getAccountResponse) => {
            this.planOfAccountsService.accountsSub.next(
              getAccountResponse['accounts']
            );
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  onDeleteMultipleAccounts(): void {
    if (this.selectedAccounts.length > 0) {
      this.isLoading = true;
      this.httpUtilisPlanOfAccounts
        .deleteAccount(this.selectedAccounts)
        .pipe(
          concatMap((_deleteResponse) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Conti eliminati con successo',
              key: 'br',
            });
            return this.httpUtilisPlanOfAccounts.getAccounts(
              this.userInfo!.agencyDescription.split(splitAgencyCode)[0]
            );
          })
        )
        .subscribe({
          next: (getAccountResponse) => {
            this.planOfAccountsService.accountsSub.next(
              getAccountResponse['accounts']
            );
            this.isLoading = false;
          },
          error: (error) => {
            this.modalService.showError(error);
            this.isLoading = false;
          },
        });
    }
  }

  showAccountDetail(account: Account) {
    this.detailAccount = account;
    this.linkedSubAccountsService.linkedSubAccountsSubject.next([
      ...this.detailAccount.linkedSubAccounts,
    ]);
    this.modalRefDetail?.show();
  }

  onSwitch(event: InputSwitchChangeEvent, account: Account) {
    account.status = event.checked;
    account.showStatus = event.checked ? 'Abilitato' : 'Disabilitato';

    this.httpUtilisPlanOfAccounts.modifyAccount(account).subscribe({
      next: (_modifyResponse) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Successo',
          detail: 'Stato modificato con successo',
          key: 'br',
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'br',
          detail: 'Impossibile modificare lo stato',
        });
        account.status = !event.checked;
        account.showStatus = event.checked ? 'Abilitato' : 'Disabilitato';

        //this.modalService.showError(error);
      },
    });
  }

  initializeModals(): void {
    this.modalAdd = document.getElementById('modalAddAccount');
    this.modalDetail = document.getElementById('modalDetailAccount');

    if (this.modalAdd) {
      this.modalRefAdd = new Modal(this.modalAdd, {
        keyboard: false,
        backdrop: 'static',
      });
    }
    if (this.modalDetail) {
      this.modalRefDetail = new Modal(this.modalDetail, {
        keyboard: false,
        backdrop: 'static',
      });
    }
  }
}
