<p-dialog
  [header]="this.modalTitle"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80%',
    height: '80%', }"
  (onHide)="onDialogHide()"
>
  <div class="commission-form">
    <div *ngIf="isLoading" class="spinner-overlay">
      <div class="spinner-overlay">
        <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-lg-3">
          <label for="name">Nome</label>
          <input
            pInputText
            type="text"
            id="name"
            formControlName="name"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'name') }"
          />
        </div>
        <div class="col-lg-3">
          <label for="surname">Cognome</label>
          <input
            pInputText
            type="text"
            id="surname"
            formControlName="surname"
            class="commission-input"
          />
        </div>
        <div class="col-lg-3">
          <label for="address">Indirizzo</label>
          <input
            pInputText
            type="text"
            id="address"
            formControlName="address"
            class="commission-input"
          />
        </div>
        <div class="col-lg-3">
          <label for="zipCode">CAP</label>
          <input
            pInputText
            type="text"
            id="zipCode"
            formControlName="zipCode"
            class="commission-input"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <label for="city">Comune</label>
          <input
            pInputText
            type="text"
            id="city"
            formControlName="city"
            class="commission-input"
          />
        </div>
        <div class="col-lg-3">
          <label for="province">Provincia</label>
          <input
            pInputText
            type="text"
            id="province"
            formControlName="province"
            class="commission-input"
            pattern="[A-Za-z]*"
            maxlength="2"
          />
        </div>
        <div class="col-lg-3">
          <label for="vatNumber">Partita IVA</label>
          <input
            pInputText
            type="text"
            id="vatNumber"
            formControlName="vatNumber"
            class="commission-input"
          />
        </div>
        <div class="col-lg-3">
          <label for="fc">Codice fiscale</label>
          <input
            pInputText
            type="text"
            id="fc"
            formControlName="fc"
            class="commission-input"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <label for="active">Attivo</label>
          <div>
            <p-inputSwitch formControlName="active" />
          </div>
        </div>
        <div class="col-lg-3">
          <label for="ccs">C.C separato</label>
          <div>
            <p-inputSwitch formControlName="ccs" />
          </div>
        </div>
        <div class="col-lg-3">
          <label for="ifr">IFR</label>
          <p-dropdown
            id="ifr"
            formControlName="ifr"
            [options]="ifrTypesOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="dropdown my-2  "
            showClear="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            (onChange)="onIfrChange($event)"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'ifr') }"
          ></p-dropdown>
        </div>
        <div class="col-lg-3">
          <label for="modCalcIfr">Modalità calcolo IFR</label>
          <p-dropdown
            id="modCalcIfr"
            formControlName="modCalcIfr"
            [options]="ifrModesOptions ?? []"
            filter="true"
            filterBy="description"
            optionLabel="description"
            placeholder="Seleziona..."
            styleClass="dropdown my-2  "
            showClear="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'modCalcIfr') }"
          ></p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <label for="percIfr">% IFR</label>
          <input
            pInputText
            type="number"
            id="percIfr"
            formControlName="percIfr"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'percIfr') }"
          />
        </div>
        <div class="col-lg-3">
          <label for="accIfr">IFR ACCUMULATO</label>
          <input
            pInputText
            type="number"
            id="accIfr"
            formControlName="accIfr"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'accIfr') }"
          />
        </div>
        <div class="col-lg-3">
          <label for="percRitFisc">% Ritenuta fiscale</label>
          <input
            pInputText
            type="number"
            id="percRitFisc"
            formControlName="percRitFisc"
            class="commission-input"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'percRitFisc') }"
          />
        </div>
        <div *ngIf="this.userInfos?.isDirectionalUser" class="col-lg-3">
          <label for="agency">Agenzia</label>
          <p-autoComplete
            styleClass="autocomplete-dropdown"
            formControlName="agency"
            showClear="true"
            [dropdown]="true"
            [suggestions]="filteredValues"
            (completeMethod)="filterValues($event)"
            placeholder="Seleziona..."
            showEmptyMessage="false"
            field="description"
            [ngClass]="{ 'ng-dirty': getDirty(form, 'agency') }"
          />
        </div>
      </div>
      <hr class="mb-4" />
      <p-table
        [value]="this.getProducersArray().controls"
        dataKey="id"
        [scrollable]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="table-census text-center"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2rem">
              <p-button
                type="button"
                icon="pi pi-plus"
                styleClass="p-button-text"
                (click)="addRow()"
              ></p-button>
            </th>
            <th style="width: auto">Codice produttore</th>
            <th style="width: auto">Attivo/Disattivo</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td class="text-center">
              <i class="pi pi-trash icon" (click)="onRemove(rowIndex)"></i>
            </td>
            <td>
              <div formArrayName="productorCodes">
                <div [formGroupName]="rowIndex">
                  <input
                    pInputText
                    type="number"
                    id="code"
                    formControlName="code"
                    class="commission-input no-spinner"
                    [ngClass]="{
                      'ng-dirty': getDirty(
                        this.getProducersArray().controls[rowIndex],
                        'code'
                      )
                    }"
                    pattern="[0-9]*"
                  />
                </div>
              </div>
            </td>
            <td class="text-center">
              <div formArrayName="productorCodes">
                <div [formGroupName]="rowIndex">
                  <div>
                    <p-inputSwitch formControlName="active" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary button mx-3 my-2" type="submit">
          SALVA
        </button>
      </div>
    </form>
  </div>
</p-dialog>
