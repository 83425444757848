export class ParzialErrorsResponse {
  commissionId: number;
  producerOrCoasErrors: { item: string; value: string }[] | null;
  productErrors: { item: string; value: string }[] | null;

  constructor(
    commissionId: number,
    producerOrCoasErrors: { item: string; value: string }[] | null,
    productErrors: { item: string; value: string }[] | null
  ) {
    this.commissionId = commissionId;
    this.producerOrCoasErrors = producerOrCoasErrors;
    this.productErrors = productErrors;
  }
}
