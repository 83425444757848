import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOneValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const revenueGive = control.get('revenueGive')?.value;
  const expenseGet = control.get('expenseGet')?.value;

  if (revenueGive || expenseGet) {
    return null;
  }

  return { atLeastOneRequired: true };
};
