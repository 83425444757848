export class RecuperoSospRows {
  rowId: number;
  recuperoSosp: number;

  constructor(rowId: number, recuperoSosp: number) {
    this.rowId = rowId;
    this.recuperoSosp = recuperoSosp;
  }
}
export class ConDeconsRequest {
  operation: string;
  recuperoSospRows: RecuperoSospRows[];

  constructor(operation: string, recuperoSospRows: RecuperoSospRows[]) {
    this.operation = operation;
    this.recuperoSospRows = recuperoSospRows;
  }
}
