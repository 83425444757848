export class ModalInfos {
  header: string;
  description: string;
  onClick: () => void;
  icon: string | undefined;
  onCancel: (() => void) | undefined;

  constructor(
    header: string,
    description: string,
    onClick: () => void,
    icon?: string,
    onCancel?: () => void
  ) {
    this.header = header;
    this.description = description;
    this.onClick = onClick;
    this.icon = icon;
    this.onCancel = onCancel;
  }
}
