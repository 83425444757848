import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Modal } from 'bootstrap';
import { CommonModule } from '@angular/common';
import { LinkedAccount } from '../../../../models/plan-accounts/master/master';
import { Account } from '../../../../models/plan-accounts/account/account';
import { CustomCurrencyPipe } from '../../../../pipe/custom-currency.pipe';
import { DetailAccountFirstNote } from '../../../../models/first-note/detail-account';

@Component({
  selector: 'app-account-detail-modal',
  standalone: true,
  imports: [CommonModule, CustomCurrencyPipe],
  templateUrl: './account-detail-modal.component.html',
  styleUrl: './account-detail-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AccountDetailModalComponent implements OnInit {
  @Input() detailAccount: DetailAccountFirstNote | undefined;
  @Input() modalRef: Modal | undefined;

  constructor() {}

  ngOnInit(): void {}

  goBack(): void {
    this.modalRef?.hide();
  }
}
