import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { STORAGE_KEYS } from '../enums/commission';
import { environment } from '../../environments/environment';

export class HTTPUtilsSSO {
  constructor(private http: HttpClient) {}

  public getIDToken(): Observable<{ [key: string]: any }> {
    return this.http.post<{ [key: string]: any }>(
      environment.ID_TOKEN_URL,
      environment.IDTOKEN,
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        },
      }
    );
  }

  public getAccessToken(): Observable<{ [key: string]: any }> {
    let body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    return this.http.post(environment.ACCESS_TOKEN_URL, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        CustomHeader: 'Basic Z2EtY2xpZW50OmdhLXNlY3JldA==',
        Authorization: `Bearer ${
          localStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
        }`,
      },
      withCredentials: true,
    });
  }
}
