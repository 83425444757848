import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { FirstNoteFilter } from '../../../../enums/first-note';
import { AccountEntry } from '../../../../models/first-note/accounting-entries-request';
import { FirstNote } from '../../../../models/first-note/first-note';
import { LinkedAccount } from '../../../../models/plan-accounts/master/master';
import { UserInfo } from '../../../../models/user/user-info';
import { HttpUtilisFirstNote } from '../../../../network/http-utilis-first-note';
import { HttpUtilisPlanOfAccounts } from '../../../../network/http-utilis-plan-of-accounts';
import { FirstNoteService } from '../../../../services/first-note/first-note.service';
import { atLeastOneValidator } from '../../../../validators/give-and-get-validator';
import { handleError } from '../../../../utils';
import { ModalService } from '../../../../services/modal/modal.service';
import { formatMessage } from '../../../../constants/general';

@Component({
  selector: 'app-double-entry-modal',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    DropdownModule,
    ProgressSpinnerModule,
    TableModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
  ],
  templateUrl: './double-entry-modal.component.html',
  styleUrl: './double-entry-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DoubleEntryModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() firstNote: FirstNote | undefined;

  dataKey: number = 0;
  isLoading: boolean = false;
  isVisible: boolean = false;
  userInfo: UserInfo | undefined;
  isSaveDisabled: boolean = true;
  modalVisibilitySub$: Subscription;
  accountsList: LinkedAccount[] = [];
  form: FormGroup = new FormGroup({});
  filteredAccounts: LinkedAccount[] = [];
  previousAccountsRows: { [key: number]: LinkedAccount | null } = {};
  formatMessage: string = formatMessage;

  constructor(
    private firstNoteService: FirstNoteService,
    private httpUtilisPlanOfAccount: HttpUtilisPlanOfAccounts,
    private httpUtilisFirstNote: HttpUtilisFirstNote,
    private messageService: MessageService,
    private modalService: ModalService
  ) {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = UserInfo.fromJson(
        JSON.parse(localStorage.getItem('userInfo')!)
      );
    }
    this.modalVisibilitySub$ =
      this.firstNoteService.doubleEntryModalVisibilitySub.subscribe((value) => {
        this.isVisible = value;
      });

    this.form = new FormGroup({
      doubleEntryRow: new FormArray([], [Validators.required]),
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.firstNote === undefined) {
      return;
    }
    this.getAccountsList();
  }

  ngOnDestroy() {
    this.modalVisibilitySub$.unsubscribe();
  }

  // onAccountChange(event: DropdownChangeEvent, rowIndex: number) {
  //   const selectedAccount = event.value;
  //   const accountControl = this.getDoubleEntryRow().at(rowIndex).get('account');
  //   const selectedItem = this.previousAccountsRows[rowIndex];

  //   const findAccountIndex = (accountCode: string) =>
  //     this.accountsList.findIndex((account) => account.code === accountCode);

  //   const accountIndex = selectedAccount
  //     ? findAccountIndex(selectedAccount.code)
  //     : findAccountIndex(selectedItem?.code || '');

  //   if (accountIndex !== -1) {
  //     this.accountsList[accountIndex].isDisabled = !!selectedAccount;
  //   }

  //   if (selectedAccount && selectedItem) {
  //     selectedAccount.isDisabled = true;
  //     selectedItem.isDisabled = false;
  //   }

  //   this.previousAccountsRows[rowIndex] = accountControl?.value || null;

  //   this.calculateSaveButtonState();
  // }

  onInputChange() {
    this.calculateSaveButtonState();
  }

  getDoubleEntryRow(): FormArray {
    return this.form.get('doubleEntryRow') as FormArray;
  }

  getAccountsList() {
    if (this.firstNote?.firstNoteManager) {
      this.httpUtilisPlanOfAccount
        .getAccounts(this.firstNote?.firstNoteManager.code, undefined, true)
        .subscribe({
          next: (response) => {
            this.accountsList = response.accounts.map((account: any) => ({
              ...account,
              displayName: `${account.code} - ${account.name} - ${account.firstNoteManager.description} - ${account.ledger.description}`,
              isDisabled: false,
            }));

            this.filteredAccounts = this.accountsList;
          },
          error: (error: HttpErrorResponse) => {
            handleError(error, this.modalService, this.messageService);
          },
        });
    }
  }

  addRow() {
    let row = this.form.get('doubleEntryRow') as FormArray;
    row.push(this.createRow());
    this.calculateSaveButtonState();
  }

  createRow() {
    return new FormGroup(
      {
        id: new FormControl(this.dataKey++),
        account: new FormControl(null, Validators.required),
        revenueGive: new FormControl(null, [
          Validators.pattern(/^\d+(?:[.,]\d{0,2})?$/),
        ]),
        expenseGet: new FormControl(
          null,
          Validators.pattern(/^\d+(?:[.,]\d{0,2})?$/)
        ),
      },
      {
        validators: atLeastOneValidator,
      }
    );
  }

  onRemove(rowIndex: number) {
    // const selectedItem = this.previousAccountsRows[rowIndex];

    // const findAccountIndex = (accountCode: string) =>
    //   this.accountsList.findIndex((account) => account.code === accountCode);

    // const accountIndex = findAccountIndex(selectedItem?.code || '');

    // if (accountIndex !== -1) {
    //   this.accountsList[accountIndex].isDisabled = false;
    // }

    // const previousAccountsArray = Object.values(this.previousAccountsRows);
    // previousAccountsArray.splice(rowIndex, 1);

    // this.previousAccountsRows = previousAccountsArray.reduce(
    //   (acc: any, item, index) => {
    //     acc[index] = item;
    //     return acc;
    //   },
    //   {}
    // );

    this.getDoubleEntryRow().removeAt(rowIndex);

    this.calculateSaveButtonState();
  }

  submitForm() {
    var accountEntries: AccountEntry[] = [];
    this.getDoubleEntryRow().value.forEach((element: any) => {
      accountEntries.push({
        account: element?.account,
        revenueGive: element?.revenueGive?.replace(',', '.'),
        expenseGet: element?.expenseGet?.replace(',', '.'),
      });
    });

    if (this.firstNote?.operationID) {
      this.isLoading = true;
      this.httpUtilisFirstNote
        .createAccountingEntris(accountEntries, this.firstNote?.operationID)
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              key: 'br',
              detail: 'Operazione effettuata con successo',
            });
            this.firstNoteService.filterSubject.next(FirstNoteFilter.ENTRAMBI);
            this.firstNoteService.doubleEntryModalVisibilitySub.next(false);
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            handleError(error, this.modalService, this.messageService);
          },
        });
    }
  }

  isExpenseGetFilled(rowIndex: number): boolean {
    const row = this.getDoubleEntryRow().at(rowIndex) as FormGroup;
    return !!row.get('expenseGet')?.value;
  }

  isRevenueGiveFilled(rowIndex: number): boolean {
    const row = this.getDoubleEntryRow().at(rowIndex) as FormGroup;
    return !!row.get('revenueGive')?.value;
  }

  calculateTotals() {
    let totalRevenueGive = 0;
    let totalExpenseGet = 0;

    this.getDoubleEntryRow().controls.forEach((row) => {
      let revenueGive: string = row.get('revenueGive')?.value || '0';
      let expenseGet: string = row.get('expenseGet')?.value || '0';

      revenueGive = revenueGive.replace(',', '.');
      expenseGet = expenseGet.replace(',', '.');

      totalRevenueGive += +revenueGive;
      totalExpenseGet += +expenseGet;
    });

    if (this.firstNote) {
      totalRevenueGive += +this.firstNote.revenueGive || 0;
      totalExpenseGet += +this.firstNote.expenseGet || 0;
    }

    totalRevenueGive = Math.round(totalRevenueGive * 100) / 100;
    totalExpenseGet = Math.round(totalExpenseGet * 100) / 100;

    return { totalRevenueGive, totalExpenseGet };
  }

  calculateSaveButtonState() {
    const { totalRevenueGive, totalExpenseGet } = this.calculateTotals();

    const isFormValid = this.form.valid;

    this.isSaveDisabled =
      totalRevenueGive !== totalExpenseGet ||
      this.getDoubleEntryRow().length === 0 ||
      !isFormValid;
  }

  reset() {
    this.form = new FormGroup({
      doubleEntryRow: new FormArray([], [Validators.required]),
    });
  }

  goBack() {
    this.reset();
    this.firstNoteService.doubleEntryModalVisibilitySub.next(false);
  }
}
