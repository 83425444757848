import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { EditCommisionFormComponent } from '../../../components/forms/commission/edit-commision-form/edit-commision-form.component';
import { CommissionsSearchFormComponent } from '../../../components/forms/commissions-search-form/commissions-search-form.component';
import { CommissionTableComponent } from '../../../components/tables/commission-table/commission-table.component';
import { CommissionsFormService } from '../../../services/commissions-table/commissions-form.service';
import { DetailScreenComponent } from '../detail-screen-commission/detail-screen.component';

@Component({
  selector: 'app-search-form-screen',
  standalone: true,
  imports: [
    CommonModule,
    CommissionTableComponent,
    CommissionsSearchFormComponent,
    EditCommisionFormComponent,
    DetailScreenComponent,
  ],
  templateUrl: './search-form-screen.component.html',
  styleUrl: './search-form-screen.component.scss',
})
export class SearchFormScreenComponent {
  constructor(public commissionTableService: CommissionsFormService) {}
}
