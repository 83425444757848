import { FormGroup } from '@angular/forms';
import { CommissionsFormService } from '../services/commissions-table/commissions-form.service';

export const getDirty = (
  form: FormGroup,
  formName: string
): boolean | undefined => {
  return form.get(formName)?.invalid && form.get(formName)?.touched;
};

export const getType = (
  commissionFormService: CommissionsFormService
): string => {
  return commissionFormService.isProducer ? 'produttore' : 'coassicurazione';
};

export const exits = (value: any): boolean => {
  return value != null && value != undefined;
};
