<app-header></app-header>
<div class="container-fluid mt-2 px-5 py-3">
  <router-outlet></router-outlet>
  <div class="d-flex justify-content-center" *ngIf="showAssociate">
    <app-associate-dissociate></app-associate-dissociate>
  </div>
  <app-error-modal></app-error-modal>
  <app-modal></app-modal>
  <p-toast position="bottom-right" key="br" />
</div>
