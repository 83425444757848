import { formatDate } from '@angular/common';
import { Category } from '../commission-detail';

export class BCRow {
  branch: string;
  rowId: number;
  codPdre: string;
  codCoas: string;
  desCoas: string;
  collaboratorName: string | null;
  collaboratorSurname: string | null;
  collectionDate: string;
  numPoli: string;
  policyLink?: string;
  isCoass: boolean;
  category: Category;
  reason: string;
  gross: number;
  net: number;
  provvAgency: number;
  provvCalcolata: number;
  ifrImmediato: number;
  ifrDifferito: number;
  totSospeso: number;
  agencyReferral: string;
  calculated: boolean;
  manuallyUpdated: boolean;
  manuallyCreated: boolean;
  fullName: string | null;
  signedDocument: boolean;
  formatedCollectionDate: string;

  constructor(data: any) {
    this.branch = data.branch;
    this.rowId = data.rowId;
    this.codPdre = data.codPdre;
    this.collaboratorName = data.collaboratorName;
    this.collaboratorSurname = data.collaboratorSurname;
    this.codCoas = data.codCoas;
    this.desCoas = data.desCoas;
    this.collectionDate = data.collectionDate;
    this.numPoli = data.numPoli;
    this.policyLink = data.policyLink;
    this.isCoass = data.isCoass;
    this.category = data.category;
    this.reason = data.reason;
    this.gross = data.gross;
    this.net = data.net;
    this.provvAgency = data.provvAgency;
    this.provvCalcolata = data.provvCalcolata;
    this.ifrImmediato = data.ifrImmediato;
    this.ifrDifferito = data.ifrDifferito;
    this.totSospeso = data.totSospeso;
    this.agencyReferral = data.agencyReferral;
    this.calculated = data.calculated;
    this.manuallyUpdated = data.manuallyUpdated;
    this.manuallyCreated = data.manuallyCreated;
    this.signedDocument = data.signedDocument;
    this.fullName = `${this.collaboratorName} ${this.collaboratorSurname}`;
    this.formatedCollectionDate = formatDate(
      this.collectionDate,
      'dd/MM/yyyy',
      'en-US'
    );
  }
}
