import { CommissionsFormService } from "../../services/commissions-table/commissions-form.service";
import { UserInfo } from "../user/user-info";

export class Filters {
  commissionTable: string | null;
  agency: string | null;
  producer: string | null;
  coinsuranceAgency: string | null;
  product: string | null;
  category: string | null;
  showExpiredTables: boolean | null;
  isBase: boolean | null;

  constructor(
    commissionTable: string | null,
    agency: string | null,
    producer: string | null,
    coinsuranceAgency: string | null,
    product: string | null,
    category: string | null,
    showExpiredTables: boolean | null,
    isBase: boolean | null
  ) {
    this.commissionTable = commissionTable;
    this.agency = agency;
    this.producer = producer;
    this.coinsuranceAgency = coinsuranceAgency;
    this.product = product;
    this.category = category;
    this.showExpiredTables = showExpiredTables;
    this.isBase = isBase;
  }

  static fromForm(form: any, userInfo: UserInfo): Filters {
    let tableType: boolean | undefined | null;
    if (form['tableType'] === 'BASE') {
      tableType = true;
    } else if (form['tableType'] === 'NON BASE') {
      tableType = false;
    } else {
      tableType = null;
    }
    
    let agencyValue: string | null;
    if (userInfo?.isDirectionalUser) {
      agencyValue = form['agency'] ? form['agency'].code : null;
    } else {
      agencyValue = userInfo?.node || null;
    }

    return new Filters(
      form['commissionTable'],
      agencyValue,
      form['producer'] ? form['producer'].code : null,
      form['coinsuranceAgency'] ? form['coinsuranceAgency'].code : null,
      form['product'] ? form['product'].code : null,
      form['category'],
      form['isActive'],
      tableType
    );
  }
}

export class CommissionTableRequest {
  commissionType: string | null;
  filters: Filters | null;

  constructor(commissionType: string, filters: Filters) {
    this.commissionType = commissionType;
    this.filters = filters;
  }
}
