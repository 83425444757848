<div class="modal" tabindex="-1" id="accountDetailModal">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title label">Dettaglio conto</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="goBack()"
        ></button>
      </div>
      <div class="modal-body position-relative">
        <!-- <div *ngIf="isLoading" class="spinner-overlay">
          <div class="spinner-container">
            <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
            <div class="label">Nome:</div>
            <div class="ms-2 value">
              {{ this.detailAccount?.name }}
            </div>
          </div>
          <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
            <div class="label">Codice:</div>
            <div class="ms-2 value">
              {{ this.detailAccount?.code }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="label">Tipologia:</div>
            <div class="ms-2 value">
              {{ this.detailAccount?.type?.description }}
            </div>
          </div>
          <div class="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="label">Importo:</div>
            <div class="ms-2 value">
              {{ this.detailAccount?.value ?? 0 | customCurrency }}
            </div>
          </div>
        </div>
        <hr class="my-4" />

        <div class="row d-flex justify-content-center">
          <div class="col-lg-2 col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" (click)="goBack()">
              <strong>CHIUDI</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
