<div *ngIf="isLoading" class="text-center">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div *ngIf="!isLoading" class="text-end mt-3">
  <p-table
    [value]="subAccounts ?? []"
    dataKey="id"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '60rem' }"
    styleClass=" table-commission text-center"
    [(selection)]="selecteddSubAccounts"
    [paginator]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
        <th style="width: auto"></th>
        <th pSortableColumn="name" style="width: auto">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code" style="width: auto">
          Codice <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="type.description" style="width: auto">
          Modalità<p-sortIcon field="type.description"></p-sortIcon>
        </th>
        <th pSortableColumn="pathNodo" style="width: auto">
          Nodo Sottoconto <p-sortIcon field="pathNodo"></p-sortIcon>
        </th>
        <th pSortableColumn="dirCollection" style="width: auto">
          Incasso direzionale <p-sortIcon field="dirCollection"></p-sortIcon>
        </th>
        <th pSortableColumn="account.description" style="width: auto">
          Conto <p-sortIcon field="account.description"></p-sortIcon>
        </th>
        <th pSortableColumn="accountNode" style="width: auto">
          Nodo del conto
          <p-sortIcon field="accountNode"></p-sortIcon>
        </th>
        <th
          *ngIf="this.userInfo?.isDirectionalUser"
          pSortableColumn="agencyCode"
          style="width: auto"
        >
          Codice Agenzia
          <p-sortIcon field="agencyCode"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter type="text" field="name" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="code" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="type.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter type="text" field="pathNodo" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter type="text" field="showDir" matchMode="contains" />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="account.description"
            matchMode="contains"
          />
        </th>
        <th>
          <p-columnFilter
            type="text"
            field="accountNode"
            matchMode="contains"
          />
        </th>
        <th *ngIf="this.userInfo?.isDirectionalUser">
          <p-columnFilter type="text" field="agencyCode" matchMode="contains" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account let-i="rowIndex">
      <tr>
        <td>
          <p-tableCheckbox [value]="account" />
        </td>
        <td>
          <i
            class="pi pi-pen-to-square icon col-md-6 d-flex"
            (click)="onModify(account)"
            title="Modifica"
          ></i>
        </td>

        <td class="text-center">
          {{ account.name }}
        </td>
        <td class="text-center">
          {{ account.code }}
        </td>
        <td class="text-center">
          {{ account.type.description }}
        </td>
        <td class="text-center">{{ account.pathNodo }}</td>
        <td class="text-center">{{ account.showDir }}</td>
        <td class="text-center">{{ account.account?.description ?? "" }}</td>
        <td class="text-center">{{ account.accountNode ?? "" }}</td>

        <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
          {{ account.agencyCode }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-modal-add-sub-account
  [modalRefAdd]="this.modalRefAdd"
  [subAccount]="this.modifiedSubAccount"
></app-modal-add-sub-account>
