import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, concatMap, map } from 'rxjs/operators';
import { STORAGE_KEYS } from '../../enums/commission';
import { UserInfo } from '../../models/user/user-info';
import { HTTPUtilsSSO } from '../../network/http-sso';
import { CommissionsFormService } from '../commissions-table/commissions-form.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private httpUtlis: HTTPUtilsSSO = new HTTPUtilsSSO(this.http);

  constructor(
    private http: HttpClient,
    private commisionsFormService: CommissionsFormService
  ) {}

  isLogged: boolean = false;

  public getToken(): Observable<any> {
    return this.httpUtlis.getIDToken().pipe(
      concatMap((responseF) => {
        sessionStorage.setItem(
          STORAGE_KEYS.ID_TOKEN,
          responseF['AuthenticationResult']['IdToken']
        );

        return this.httpUtlis.getAccessToken().pipe(
          concatMap((response) => {
            sessionStorage.setItem(
              STORAGE_KEYS.ACCESS_TOKEN,
              response['access_token']
            );

            return this.getUserInfo().pipe(
              map((response) => {
                sessionStorage.setItem(
                  STORAGE_KEYS.USER_INFO,
                  JSON.stringify(response)
                );
              })
            );
          })
        );
      }),
      catchError((error) => {
        return error;
      })
    );
  }

  public getUserInfo(): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(environment.USER_INFO_URL, {
        headers: {
          Authorization: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) ?? ''
          }`,
          CustomHeader: `Bearer ${
            sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? ''
          }`,
        },
        params: {},
      })
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
