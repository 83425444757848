import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ModalService } from '../services/modal/modal.service';
import { UserInfo } from '../models/user/user-info';
import { STORAGE_KEYS } from '../enums/commission';
import { VISIBILITY } from '../constants/visibility';
import { ModalInfos } from '../models/modal/modal-infos';
import { Title } from '@angular/platform-browser';

export const waitForUserInfo = (): Promise<UserInfo> => {
  return new Promise((resolve) => {
    const checkUserInfo = () => {
      const userInfo = sessionStorage.getItem(STORAGE_KEYS.USER_INFO);
      if (userInfo) {
        resolve(JSON.parse(userInfo));
      } else {
        setTimeout(checkUserInfo, 100);
      }
    };
    checkUserInfo();
  });
};

export const visibilityGuard: CanActivateFn = async (route, state) => {
  const modalService = inject(ModalService);
  const routing = inject(Router);
  const serviceTitle = inject(Title);

  const userInfo = await waitForUserInfo();
  const path = route.routeConfig?.path;

  if (!path) {
    return true;
  }

  const visibilityValue = VISIBILITY.get(path);
  if (!visibilityValue) {
    modalService.showModal(
      new ModalInfos('Attenzione', 'Permesso negato', () => {
        serviceTitle.setTitle('Home | Gestionale Agenzia');
        routing.navigate(['']);
      })
    );
    return false;
  }

  return userInfo.userFunctions?.includes(visibilityValue) ?? false;
};
