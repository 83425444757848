export enum FirstNoteFilter {
  PRIMANOTA = 'PRIMANOTA',
  BROGLIACCIO = 'BROGLIACCIO',
  ENTRAMBI = 'ENTRAMBI',
}

export enum DateType {
  REGISTRY = 'REGISTRAZIONE',
  COMPETENCE = 'COMPETENZA',
}
