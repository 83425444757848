import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Subscription } from 'rxjs';
import { MOCK_VALUES } from '../../../../constants/test';
import { RowMode, ValueModes } from '../../../../enums/bank-records';
import { BANK_RECORD_PATH } from '../../../../enums/path';
import {
  BankRecRowDetailResp,
  TaxType,
} from '../../../../models/bank-records/bank-record-detail-row-response';
import {
  BankRecordAddRequest,
  StatementType,
} from '../../../../models/bank-records/request/bank-records-row-add-request';
import { Category } from '../../../../models/commission-detail';
import { HttpUtilsBankRecords } from '../../../../network/http-utilis-bank-records';
import { CommissionsFormService } from '../../../../services/commissions-table/commissions-form.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { getDirty, getType } from '../../../../utils';

@Component({
  selector: 'app-add-row-bank-record',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    CommonModule,
    InputTextModule,
    InputTextareaModule,
  ],
  templateUrl: './add-row-bank-record.component.html',
  styleUrl: './add-row-bank-record.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddRowBankRecordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  categoryOptions: Category[] | undefined = MOCK_VALUES;
  modalitiesOptions = Object.entries(RowMode).map(([code, description]) => ({
    code,
    description,
  }));

  valueModes = Object.entries(ValueModes).map(([code, description]) => ({
    code,
    description,
  }));

  bankRecordAddRequest: BankRecordAddRequest | undefined;
  userID: number | undefined;
  bankRecordID: number | undefined;
  bankRecordRowID: number | undefined;
  bankRecordRowDetail: BankRecRowDetailResp | undefined;
  isLoading: boolean = false;
  isModify: boolean = false;
  $subForm: Subscription | undefined;
  $subIFR: Subscription | undefined;
  $subWitholdingTax: Subscription | undefined;
  $subRoute: Subscription | undefined;
  $queryParams: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    public commissionService: CommissionsFormService,
    private httpUtilsBank: HttpUtilsBankRecords,
    private modalService: ModalService,
    private messageService: MessageService
  ) {
    this.form = new FormGroup({
      collectionDate: new FormControl(new Date(), [Validators.required]),
      statementType: new FormControl(null, [Validators.required]),
      statementDes: new FormControl(null, [Validators.required]),
      provvCalcolata: new FormControl(null, [Validators.required]),
      ifr: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      ifrValue: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      witholdingTax: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      witholdingTaxValue: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
    });
  }
  ngOnDestroy(): void {
    this.$subForm?.unsubscribe();
    this.$subIFR?.unsubscribe();
    this.$subWitholdingTax?.unsubscribe();
    this.$subRoute?.unsubscribe();
    this.$queryParams?.unsubscribe();
  }
  ngOnInit(): void {
    let statementControl: FormControl = this.form.get(
      'statementType'
    ) as FormControl;

    this.$subForm = statementControl.valueChanges.subscribe(
      (value: StatementType) => {
        switch (value?.code) {
          case 'MODE01':
          case 'MODE02':
          case 'MODE03':
          case 'MODE06':
            if (this.commissionService.isProducer) {
              this.form.get('ifr')?.enable();
            }
            this.form.get('witholdingTax')?.enable();
            this.form.get('statementDes')?.setValue(value?.description);

            break;
          case 'MODE04':
          case 'MODE05':
            if (this.commissionService.isProducer) {
              this.form.get('ifr')?.disable();
              this.form.get('ifrValue')?.disable();
            }
            this.form.get('witholdingTax')?.enable();
            this.form.get('statementDes')?.setValue(value?.description);
            break;
          case 'MODE07':
          case 'MODE08':
            this.form.get('ifr')?.disable();
            this.form.get('ifrValue')?.disable();
            this.form.get('witholdingTax')?.disable();
            this.form.get('witholdingTaxValue')?.disable();
            this.form.get('statementDes')?.setValue(value?.description);
            break;
          case undefined:
            this.form.get('statementDes')?.setValue(null);
            break;
        }
      }
    );
    if (this.commissionService.isProducer) {
      this.$subIFR = this.form
        .get('ifr')
        ?.valueChanges.subscribe((value: TaxType) => {
          if (value?.code === 'AUTOMATICO') {
            this.form.get('ifrValue')?.disable();
            this.form.get('ifrValue')?.setValue(null);
          } else {
            this.form.get('ifrValue')?.enable();
          }
        });
    }

    this.$subWitholdingTax = this.form
      .get('witholdingTax')
      ?.valueChanges.subscribe((value: TaxType) => {
        if (value?.code === 'AUTOMATICO') {
          this.form.get('witholdingTaxValue')?.disable();
          this.form.get('witholdingTaxValue')?.setValue(null);
        } else {
          this.form.get('witholdingTaxValue')?.enable();
        }
      });

    this.$subRoute = this.activatedRouter.queryParams.subscribe((params) => {
      if (params['userId']) this.userID = params['userId'];
      if (params['ECid']) this.bankRecordID = params['ECid'];
      if (params['id']) this.bankRecordRowID = params['id'];

      if (this.bankRecordRowID && this.userID) {
        this.isModify = true;
        this.httpUtilsBank
          .getRowDetailBankRec(this.userID, this.bankRecordRowID)
          .subscribe({
            next: (response) => {
              this.bankRecordRowDetail = response;
              this.populateForm();
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.bankRecordAddRequest = BankRecordAddRequest.fromForm(
        this.form.value
      );

      if (this.bankRecordRowID && this.userID) {
        let request: BankRecRowDetailResp = BankRecRowDetailResp.fromEditForm(
          this.form,
          this.bankRecordRowDetail!
        );

        this.httpUtilsBank
          .modifyRowBankRec(this.userID, this.bankRecordRowID!, request)
          .subscribe({
            next: (_response) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Riga estratto conto modificata con successo',
              });
              this.bankRecordID
                ? this.router.navigate([BANK_RECORD_PATH.EDIT], {
                    queryParams: { id: this.bankRecordID },
                  })
                : this.router.navigate([BANK_RECORD_PATH.ADD]);
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      } else if (this.userID) {
        this.bankRecordAddRequest = BankRecordAddRequest.fromForm(
          this.form.value
        );

        if (this.bankRecordID) {
          this.bankRecordAddRequest.bankStatementId = this.bankRecordID;
        }

        this.isLoading = true;
        this.httpUtilsBank
          .createNewRowBankRecord(this.userID, this.bankRecordAddRequest)
          .subscribe({
            next: (_response) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Successo',
                key: 'br',
                detail: 'Riga estratto conto creata con successo',
              });
              this.bankRecordID
                ? this.router.navigate([BANK_RECORD_PATH.EDIT], {
                    queryParams: { id: this.bankRecordID },
                  })
                : this.router.navigate([BANK_RECORD_PATH.ADD], {
                    queryParams: {
                      type: getType(this.commissionService),
                    },
                  });
            },
            error: (error: HttpErrorResponse) => {
              this.modalService.showError(error);
              this.isLoading = false;
            },
          });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
  goBack(): void {
    this.bankRecordID
      ? this.router.navigate([BANK_RECORD_PATH.EDIT], {
          queryParams: { id: this.bankRecordID },
        })
      : this.router.navigate([BANK_RECORD_PATH.ADD], {
          queryParams: {
            type: getType(this.commissionService),
          },
        });
  }

  populateForm(): void {
    this.form.patchValue({
      collectionDate: new Date(this.bankRecordRowDetail?.collectionDate || ''),
      statementType: this.bankRecordRowDetail?.statementType,
      statementDes: this.bankRecordRowDetail?.statementDes,
      provvCalcolata: this.bankRecordRowDetail?.provvCalcolata,
      ifr:
        this.bankRecordRowDetail?.ifr.type.code === undefined
          ? { code: 'AUTOMATICO', description: 'Automatico' }
          : this.valueModes?.find(
              (item) => this.bankRecordRowDetail?.ifr?.type?.code === item?.code
            ),
      ifrValue: this.bankRecordRowDetail?.ifr.value,
      witholdingTax:
        this.bankRecordRowDetail?.witholdingTax.type.code === undefined
          ? {
              code: 'AUTOMATICO',
              description: 'Automatico',
            }
          : this.valueModes?.find(
              (item) =>
                this.bankRecordRowDetail?.witholdingTax?.type.code ===
                item?.code
            ),
      witholdingTaxValue: this.bankRecordRowDetail?.witholdingTax.value,
    });
  }

  getDirty = getDirty;
}
