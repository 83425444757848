export enum COMMISSION_PATH {
  EDIT = 'provvigioni/modifica',
  DETAIL = 'provvigioni/dettaglio',
  ADD = 'provvigioni/nuovo',
  SEARCH = 'provvigioni/ricerca',
}

export enum BANK_RECORD_PATH {
  EDIT = 'estratti-conto/modifica',
  DETAIL = 'estratti-conto/dettaglio',
  ADD = 'estratti-conto/nuovo',
  SEARCH = 'estratti-conto/ricerca',
  ADD_ROW = 'estratti-conto/manuale/riga',
  EDIT_ROW = 'estratti-conto/modifica/riga',
  DETAIL_ROW = 'estratti-conto/dettaglio/riga',
}

export enum PLAN_ACCOUNT_PATH {
  MASTERS = 'piano-dei-conti/mastri',
  ACCOUNTS = 'piano-dei-conti/conti',
  SUB_ACCOUNTS = 'piano-dei-conti/sottoconti',
}

export enum FIRST_NOTE_PATH {
  NODE = 'prima-nota/nodo',
}

export enum COMMON_PATH {
  ERROR = 'errore',
}

export enum CASH_CONTROL_PATH {
  SEARCH = 'controllo-cassa/ricerca',
  DETAIL = 'controllo-cassa/dettaglio',
  EDIT = 'controllo-cassa/modifica',
}
