import { Injectable } from '@angular/core';
import { ModalInfos } from '../../models/modal/modal-infos';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalSubject = new Subject<ModalInfos>();
  constructor(private authService: AuthService) {}

  showModal(modalInfos: ModalInfos): void {
    this.modalSubject.next(modalInfos);
  }

  showError(httpErrorResp: HttpErrorResponse): void {
    this.modalSubject.next(this.getModalInfosFromCode(httpErrorResp));
  }

  getSubject(): Subject<ModalInfos> {
    return this.modalSubject;
  }

  getModalInfosFromCode(httpErrorResp: HttpErrorResponse): ModalInfos {
    const status: string = httpErrorResp.status.toString();
    const code: string = httpErrorResp.error.code;

    switch (status) {
      case '401':
      case '403':
        if (code) {
          return new ModalInfos(
            'Attenzione',
            httpErrorResp.error.message || 'Errore generico',
            () => {
              window.open('/', '_self');
            },
            'pi pi-exclamation-triangle'
          );
        }
        this.authService.isLogged = false;
        return new ModalInfos(
          'Attenzione',
          ' La sessione è scaduta, si prega di effettuare nuovamente il login',
          () => {
            window.location.href = environment.APP_LOGIN;
          },
          'pi pi-exclamation-triangle'
        );
      default:
        return new ModalInfos(
          'Attenzione',
          httpErrorResp.error.message || 'Errore generico',
          () => {
            window.open('/', '_self');
          },
          'pi pi-exclamation-triangle'
        );
    }
  }
}
