<div class="modal" tabindex="-1" id="modalError">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <i
          [ngClass]="{ 'pi pi-exclamation-triangle': true }"
          class="mx-3 scale-icon"
        >
        </i>

        <h4 class="modal-title">Attenzione</h4>
      </div>
      <div class="modal-body modal-body-scrollable">
        <p>
          {{ this.errorInfo?.description }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          (click)="errorInfo?.onClick()"
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
        >
          Procedi
        </button>
      </div>
    </div>
  </div>
</div>
