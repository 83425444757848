<div *ngIf="isLoading" class="text-center">
  <p-progressSpinner ariaLabel="loading" />
</div>
<div *ngIf="!isLoading" class="row">
  <div class="d-flex align-items-center my-4 p-0 go-back">
    <div (click)="goBack()">
      <i class="pi pi-arrow-left me-2 icon-list"> </i>
      <span class="label">Indietro</span>
    </div>

    <div class="hidden"></div>
    <div>
      <a [href]="onModifyRedirect()" (click)="onModify($event)">
        <i class="pi pi-pen-to-square m-2 icon-list" title="Modifica"></i>
      </a>
      <i
        class="pi pi-trash m-2 icon-list"
        (click)="onClickDelete()"
        title="Elimina"
      ></i>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Nome tabella provvigionale:</div>
    <div class="ms-2 value">
      {{ commission.commissionTable }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mb-3">
    <div class="label">Categoria:</div>
    <div class="ms-2 value">
      {{ commission.category?.description }}
    </div>
  </div>
  <div
    *ngIf="commissionFormService.isProducer"
    class="col-lg-6 col-md-12 d-flex align-items-center my-3"
  >
    <div class="label">Produttori:</div>
    <div class="ms-2 value">
      {{ commission.producer?.length }}
    </div>
    <i
      data-bs-toggle="modal"
      data-bs-target="#detailModal"
      (click)="openModal('Produttori', commission.producer ?? [])"
      class="pi pi-list icon-list ms-2"
    ></i>
  </div>
  <div
    *ngIf="!commissionFormService.isProducer"
    class="col-lg-6 col-md-12 d-flex align-items-center my-3"
  >
    <div class="label">Agenzie coassicurazioni:</div>
    <div class="ms-2 value">
      {{ commission.coinsuranceAgency.length }}
    </div>
    <i
      data-bs-toggle="modal"
      data-bs-target="#detailModal"
      (click)="
        openModal('Agenzia Coassicurazione', commission.coinsuranceAgency)
      "
      class="pi pi-list icon-list ms-2"
    ></i>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center my-3">
    <div class="label">Prodotti:</div>
    <div class="ms-2 value">
      {{ commission.product?.length }}
    </div>

    <i
      data-bs-toggle="modal"
      data-bs-target="#detailModal"
      (click)="openModal('Prodotti', commission.product ?? [])"
      class="pi pi-list icon-list ms-2"
    ></i>
  </div>

  <div class="col-lg-6 col-md-12 d-flex align-items-center my-3">
    <div class="label">Data Inzio Validità:</div>
    <div class="ms-2 value">
      {{ commission.validityStartDate | date : "dd/MM/yyyy" : "UTC+1" }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center my-3">
    <div class="label">Data Fine Validità:</div>
    <div class="ms-2 value">
      {{ commission.validityEndDate | date : "dd/MM/yyyy" : "UTC+1" }}
    </div>
  </div>
  <div class="col-lg-6 col-md-12 d-flex align-items-center mt-3">
    <div class="label">Tabella base:</div>
    <div class="ms-2 value">{{ isBase() }}</div>
  </div>
  <hr class="my-4 test" />
  <p-table
    [value]="commission.rateTypes"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="table-commission"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Tipo Provvigione</th>
        <th>Modalità</th>
        <th>Perc.(%)</th>
        <th style="width: 2rem"></th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-comDet
      let-expanded="expanded"
      let-rowIndex="rowIndex"
    >
      <tr>
        <td>{{ comDet.type.description }}</td>
        <td>{{ comDet.mode.description }}</td>
        <td>{{ comDet.percentage }}</td>
        <td>
          <p-button
            type="button"
            text="true"
            pRipple
            [pRowToggler]="commission.rateTypes[rowIndex]"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="!expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
          >
            ({{ comDet.variations.length }})</p-button
          >
        </td>
      </tr></ng-template
    >

    <ng-template pTemplate="rowexpansion" let-rateType>
      <tr>
        <td colspan="7">
          <div class="p-3">
            <p-table [value]="rateType.variations" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>Variazione aliquota</th>
                  <th>Condizioni</th>
                  <th>Tipo variazione</th>
                  <th>Data</th>
                  <th>Perc.(%)</th>
                  <th>Netto</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-variation>
                <tr>
                  <td>{{ variation.variation.description }}</td>
                  <td>{{ variation.conditions.description }}</td>
                  <td>{{ variation.type.description }}</td>
                  <td>{{ variation.date | date : "dd/MM/yyyy" : "UTC+1" }}</td>
                  <td>{{ variation.percentageValue }}</td>
                  <td>{{ variation.netValue }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr></ng-template
    ></p-table
  >
</div>

<div
  class="modal fade"
  id="detailModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  (blur)="onBlur()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title label" id="exampleModalLabel">
          {{ modalTitle }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p-listbox
          #listbox
          [options]="modalValues"
          filter="true"
          [filterFields]="['description']"
          filterBy="description"
          optionLabel="name"
          [listStyle]="{ 'max-height': '250px' }"
          [style]="{ width: '100%' }"
          [listStyle]="{ 'max-height': '220px' }"
          emptyFilterMessage="Nessun risultato trovato"
          styleClass="modal-filter"
        >
          <ng-template let-value pTemplate="item">
            <div>{{ value.description }}</div>
          </ng-template>
        </p-listbox>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal-->
<div class="modal" tabindex="-1" id="deleteModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Attenzione</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Sei sicuro di voler eliminare la tabella provvigionale?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Annulla
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="deleteCommission()"
          data-bs-dismiss="modal"
        >
          Procedi
        </button>
      </div>
    </div>
  </div>
</div>
