<div class="modal" tabindex="-1" id="modalAddMaster">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title label">{{ getTitle() }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (blur)="goBack()"
        ></button>
      </div>
      <div class="modal-body position-relative">
        <div *ngIf="isLoading" class="spinner-overlay">
          <div class="text-center">
            <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
          </div>
        </div>
        <div class="commission-form">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <label for="name">Nome</label>
                <input
                  pInputText
                  id="name"
                  type="text"
                  formControlName="name"
                  placeholder="Inserisci"
                  class="commission-input my-2"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="code">Codice</label>
                <input
                  pInputText
                  id="code"
                  type="text"
                  formControlName="code"
                  placeholder="Inserisci"
                  class="commission-input my-2"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="type">Tipologia</label>
                <p-dropdown
                  id="type"
                  formControlName="type"
                  [options]="typeOptions ?? []"
                  filter="true"
                  filterBy="description"
                  optionLabel="description"
                  placeholder="Seleziona..."
                  styleClass="commission-dropdown my-2  "
                  showClear="true"
                  [ngClass]="{ 'ng-dirty': getDirty(form, 'type') }"
                ></p-dropdown>
              </div>
              <div
                *ngIf="this.userInfo?.isDirectionalUser"
                class="col-lg-6 col-md-12"
              >
                <label for="agency">Agenzia</label>
                <p-autoComplete
                  styleClass="autocomplete-dropdown my-2"
                  formControlName="agency"
                  showClear="true"
                  [dropdown]="true"
                  [suggestions]="filteredValues"
                  (completeMethod)="filterValues($event)"
                  (onSelect)="onChangeAgency($event)"
                  (onClear)="onClearAgency()"
                  placeholder="Seleziona..."
                  showEmptyMessage="false"
                  field="description"
                  [ngClass]="{ 'ng-dirty': getDirty(form, 'agency') }"
                />
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary mx-3 my-4" type="submit">
                <strong>{{ getButtonTitle() }}</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
