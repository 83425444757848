<div class="mt-2">
  <div *ngIf="!this.isEmbeddedEmpty">
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="p-button-text "
      label="Crea"
      (click)="onCreate()"
    ></p-button>
    <p-table
      [value]="response?.embedded ?? []"
      dataKey="id"
      [scrollable]="true"
      [paginator]="true"
      [loading]="isLoading"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [totalRecords]="response?.page?.totalElements ?? 0"
      [tableStyle]="{ 'min-width': '60rem' }"
      [rows]="rowsPerPage"
      lazy="true"
      (onLazyLoad)="loadData($event)"
      styleClass=" table-commission"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="commissionTable" class="w-auto">
            Nome Tabella provvigionale
            <p-sortIcon field="commissionTable"></p-sortIcon>
          </th>
          <th
            *ngIf="this.userInfo?.isDirectionalUser"
            pSortableColumn="node"
            class="text-center w-auto"
          >
            Agenzia <p-sortIcon field="agency"></p-sortIcon>
          </th>
          <th
            *ngIf="!this.commissionFormService.isProducer"
            pSortableColumn="coinsuranceAgency"
            class="text-center w-auto"
          >
            Agenzie Coassicurazione
            <p-sortIcon field="coinsuranceAgency"></p-sortIcon>
          </th>
          <th
            *ngIf="this.commissionFormService.isProducer"
            pSortableColumn="producer"
            class="text-center w-auto"
          >
            Produttori <p-sortIcon field="producer"></p-sortIcon>
          </th>
          <th pSortableColumn="category" class="text-center w-auto">
            Categoria <p-sortIcon field="category"></p-sortIcon>
          </th>
          <th pSortableColumn="product" class="text-center w-auto">
            Prodotti <p-sortIcon field="product"></p-sortIcon>
          </th>
          <th pSortableColumn="validityStartDate" class="text-center w-auto">
            Data inizio validità
            <p-sortIcon field="validityStartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="validityEndDate" class="text-center w-auto">
            Data fine validità
            <p-sortIcon
              field="validityEndDate"
              class="text-center w-auto"
            ></p-sortIcon>
          </th>
          <th class="no-sortable text-center w-auto">Tabella Base</th>

          <th style="width: 6rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-embedded let-i="rowIndex">
        <tr>
          <td>{{ embedded.commissionTable }}</td>
          <td *ngIf="this.userInfo?.isDirectionalUser" class="text-center">
            {{ embedded.agency?.description }}
          </td>
          <td
            class="text-center"
            *ngIf="!this.commissionFormService.isProducer"
          >
            {{ embedded.coinsuranceAgency }}
          </td>
          <td class="text-center" *ngIf="this.commissionFormService.isProducer">
            {{ embedded.producer }}
          </td>
          <td class="text-center">{{ embedded.category }}</td>
          <td class="text-center">{{ embedded.product }}</td>
          <td class="text-center">
            {{ embedded.validityStartDate | date : "dd/MM/yyyy" : "UTC+1" }}
          </td>
          <td class="text-center">
            {{ embedded.validityEndDate | date : "dd/MM/yyyy" : "UTC+1" }}
          </td>
          <td *ngIf="embedded.isBase === false"></td>
          <td class="text-center" *ngIf="embedded.isBase === true">
            <i class="pi pi-check-circle icon-base"></i>
          </td>

          <td>
            <div class="row text-end">
              <div class="col-md">
                <a
                  [href]="onModifyRedirect(embedded.id)"
                  (click)="onModify($event, embedded.id)"
                >
                  <i class="pi pi-pen-to-square icon" title="Modifica"></i>
                </a>
              </div>
              <div class="col-md">
                <a
                  [href]="onDetailRedirect(embedded.id)"
                  (click)="onDetail($event, embedded.id)"
                >
                  <i class="pi pi-address-book icon" title="Dettaglio"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="this.isEmbeddedEmpty">
    <p-button
      type="button"
      icon="pi pi-plus"
      styleClass="p-button-text "
      label="Crea"
      (click)="onCreate()"
    ></p-button>
    <p class="text-center text">La ricerca non ha prodotto nessun risultato</p>
  </div>
</div>
