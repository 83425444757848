import { BCRow } from '../bank-records-row-search-response';

export class BankRecAddOrRemoveRequest {
  removedStatementRows: BCRow[] | null;
  addedStatementRows: BCRow[] | null;

  constructor(
    removedStatementRows: BCRow[] | null,
    addedStatementRows: BCRow[] | null
  ) {
    this.addedStatementRows = addedStatementRows;
    this.removedStatementRows = removedStatementRows;
  }
}
