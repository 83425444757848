import { Type } from '../commission-detail';

export class DetailAccountFirstNote {
  name: string;
  code: string;
  type: Type;
  value: number;

  constructor(name: string, code: string, type: Type, value: number) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.value = value;
  }

  static fromJson(json: DetailAccountFirstNote): DetailAccountFirstNote {
    return new DetailAccountFirstNote(
      json.name,
      json.code,
      json.type,
      json.value
    );
  }
}
